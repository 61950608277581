import React, { useState, useEffect, useCallback } from 'react';
import 'sass/globals.scss';
import {
  Container,
  Grid,
  Box,
  Button,
  CircularProgress,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Autocomplete,
} from '@mui/material';
import Page from 'sharedComponents/Page';
import InputSignature from 'sharedComponents/InputSignature';
import UserImage from 'sharedComponents/UserImage';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { getBase64FromUrl } from 'services/logic/base64';
import ButtonResetPassword from 'sharedComponents/ButtonResetPassword';
import { hasErrorRequired } from 'services/logic/formValidation';
import Query from 'services/firebase/Query';
import Command from 'services/firebase/Command';
import Executer from 'services/firebase/Executer';
import useWatchManifestIfSignedByScaleReleaseDriver from 'services/hooks/useWatchManifestIfSignedByScaleReleaseDriver';
import useShowError from 'modules/errors';
import PermissionsWrapper from 'sharedComponents/PermissionsWrapper';
import { UserTypes, UserTypesArray } from 'lg-helpers/dist/constants/user/UserTypes';
import CompaniesApi from 'services/postgres/CompaniesApi';
import ErrorMessages from 'services/constants/errorMessages';
import UserAppTokenReveal from './UserAppTokenReveal';
const { isRequired } = ErrorMessages;

const companiesApi = new CompaniesApi();

const query = new Query();
const command = new Command();
const executer = new Executer();

const hasAccessToSignature = [
  UserTypes.transporter.driver,
  UserTypes.scale.admin,
  UserTypes.scale.attendant,
  UserTypes.broker.trucking.driver,
  UserTypes.transporter.driver,
  UserTypes.generator.admin,
  UserTypes.generator.user,
  UserTypes.civil.siteUser,
  UserTypes.civil.projectManager,
  UserTypes.civil.accountant,
];

const hasAccessToTruckNumber = [
  UserTypes.transporter.driver,
  UserTypes.broker.trucking.driver,
  UserTypes.transporter.driver,
];

const hasScaleInputs = [UserTypes.scale.admin, UserTypes.scale.attendant];

const AccountView = () => {
  const authUser = useSelector(selectAuthUser);
  useWatchManifestIfSignedByScaleReleaseDriver();
  const [userRegion, setUserRegion] = useState({} as any);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [userScales, setUserScales] = useState([] as any[]);
  const [isDisabledSaveDetails, setIsDisabledSaveDetails] = useState(true);
  const [isLoadingSaveDetails, setIsSavingSaveDetails] = useState(false);
  const [signatureBase64, setSignatureBase64] = useState('');
  const [isLoadingSignatureBase64, setIsLoadingSignatureBase64] = useState(null as boolean | null);
  const [isModifiedSignatureBase64, setIsModifiedSignatureBase64] = useState(false);
  const [userForEdit, setUserForEdit] = useState({} as any);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const showError = useShowError();

  const onSignatureBase64Update = useCallback((base64: any) => {
    setSignatureBase64(base64);
    setIsDisabledSaveDetails(false);
    setIsModifiedSignatureBase64(true);
  }, []);

  useEffect(() => {
    if (
      !authUser ||
      (authUser.actingAsType !== UserTypes.admin.super && !authUser.companies?.length)
    ) {
      return;
    }

    if (hasScaleInputs.includes(authUser.actingAsType) && !authUser.scalesIds) {
      return;
    }

    const retrieveDatabaseInfo = async () => {
      setIsLoadingData(true);
      const scales = await executer.getMultipleDocuments(
        query.manifests.getScalesByIds(authUser.scalesIds)
      );
      setUserScales(scales);

      const company = await companiesApi.getById(authUser.companies[0].id);

      if (company?.regions) {
        const region = company?.regions.find((r: any) => r.id === authUser.regionId);
        if (region) {
          setUserRegion(region);
        }
      }

      setIsLoadingData(false);
    };

    if (hasScaleInputs.includes(authUser.actingAsType)) {
      retrieveDatabaseInfo();
    }

    setUserForEdit({
      ...authUser,
    });
  }, [authUser]);

  useEffect(() => {
    if (!authUser || !authUser.signature || !authUser.signature.url) {
      return;
    }

    (async () => {
      try {
        setIsLoadingSignatureBase64(true);
        //https://jefrydco.id/en/blog/fix-cors-issue-firebase-google-cloud/
        const base64 = await getBase64FromUrl(authUser.signature.url);
        setSignatureBase64(base64);
      } finally {
        setIsLoadingSignatureBase64(false);
      }
    })();
  }, [authUser]);

  const handleChange = (event: any) => {
    setUserForEdit({
      ...userForEdit,
      [event.target.name]: event.target.value,
    });
    setIsDisabledSaveDetails(false);
  };

  const handleCustomDataChange = (event: any) => {
    setUserForEdit({
      ...userForEdit,
      customData: {
        [event.target.name]: event.target.value,
      },
    });
    setIsDisabledSaveDetails(false);
  };

  const hasErrors = () => {
    return (
      hasErrorRequired(userForEdit.firstName) ||
      hasErrorRequired(userForEdit.lastName) ||
      (hasAccessToSignature.includes(authUser.actingAsType) && !signatureBase64) ||
      (hasScaleInputs.includes(authUser.actingAsType) && !userForEdit.selectedScaleId)
    );
  };

  const handleSaveDetails = async () => {
    setWasSubmitted(true);
    if (hasErrors()) {
      if (hasErrorRequired(userForEdit.selectedScaleId)) {
        showError({ title: 'Scale is required!' });
      } else if (!signatureBase64 && hasAccessToSignature.includes(authUser.actingAsType)) {
        showError({ title: 'Signature is required!' });
      } else {
        showError({ title: 'Validation errors' });
      }
      return;
    }
    setIsSavingSaveDetails(true);
    try {
      let data = userForEdit;
      if (isModifiedSignatureBase64) {
        if (userForEdit.signature && userForEdit.signature.url && !userForEdit.signature.wasUsed) {
          try {
            await command.deleteFile(userForEdit.signature.url);
          } catch (err) {
            // This error doesn't break anything
            console.warn('Delete signature error:', err);
          }
        }

        let signature = null;
        if (signatureBase64) {
          const url = await command
            .uploadUserSignatureAndGetUrl(authUser.id, signatureBase64)
            .catch(() => {
              console.error('Error uploading signature and getting URL!');
              showError({ title: 'Error uploading signature!' });
            });
          signature = {
            url,
            wasUsed: false,
          };
        }

        data = {
          ...userForEdit,
          signature,
        };
      }

      const updateData = {
        firstName: data.firstName || null,
        lastName: data.lastName || null,
        phone: data.phone || null,
        selectedScaleId: data.selectedScaleId || null,
      } as any;

      if (hasAccessToSignature.includes(authUser.actingAsType)) {
        updateData.signature = data.signature || null;
      }

      if ([hasAccessToTruckNumber, hasScaleInputs].flat().includes(authUser.actingAsType)) {
        updateData.customData = data.customData || {};
      }

      await command.users.updateUserAccountData(authUser.id, updateData);

      setIsModifiedSignatureBase64(false);
      setIsDisabledSaveDetails(true);
    } catch (error) {
      console.error(error);
      showError({ title: 'Save error' });
    }

    setIsSavingSaveDetails(false);
  };

  if (!authUser) {
    return null;
  }

  return (
    <Page>
      <Card>
        <Container className="min-padding-and-height">
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSaveDetails}
                  disabled={isDisabledSaveDetails}
                >
                  Save
                  {isLoadingSaveDetails && (
                    <Box marginLeft={2}>
                      <CircularProgress color="inherit" />
                    </Box>
                  )}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <UserImage user={userForEdit} />
            </Grid>
            <Grid item xs={12}>
              <PermissionsWrapper canAccess={hasAccessToSignature}>
                <InputSignature
                  signatureBase64={signatureBase64}
                  onSignatureBase64Update={onSignatureBase64Update}
                  isLoading={isLoadingSignatureBase64 || isLoadingSaveDetails}
                />
              </PermissionsWrapper>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="User info" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="First name"
                        name="firstName"
                        onChange={handleChange}
                        value={userForEdit.firstName || ''}
                        variant="outlined"
                        error={hasErrorRequired(userForEdit.firstName, wasSubmitted)}
                        helperText={
                          hasErrorRequired(userForEdit.firstName, wasSubmitted) ? isRequired : ''
                        }
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="Last name"
                        name="lastName"
                        onChange={handleChange}
                        value={userForEdit.lastName || ''}
                        variant="outlined"
                        error={hasErrorRequired(userForEdit.lastName, wasSubmitted)}
                        helperText={
                          hasErrorRequired(userForEdit.lastName, wasSubmitted) ? isRequired : ''
                        }
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Phone Number"
                        name="phone"
                        onChange={handleChange}
                        value={userForEdit.phone || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Email Address"
                        helperText="Used at log in"
                        disabled
                        value={userForEdit.email || ''}
                        variant="outlined"
                      />
                    </Grid>

                    <PermissionsWrapper
                      canAccess={UserTypesArray.filter(iUserType => iUserType !== 'Super Admin')}
                    >
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Company"
                          disabled
                          variant="outlined"
                          value={(userForEdit.companies && userForEdit.companies[0]?.name) || ''}
                        />
                      </Grid>
                    </PermissionsWrapper>
                    <PermissionsWrapper canAccess={hasAccessToTruckNumber}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Truck Number"
                          name="truckNumber"
                          variant="outlined"
                          onChange={handleCustomDataChange}
                          value={userForEdit.customData?.truckNumber || ''}
                        />
                      </Grid>
                    </PermissionsWrapper>

                    <PermissionsWrapper canAccess={hasScaleInputs}>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Region"
                          disabled
                          value={userRegion.name || ''}
                          variant="outlined"
                          InputProps={{
                            endAdornment: isLoadingData && (
                              <Box marginLeft={2}>
                                <CircularProgress size="15px" color="inherit" />
                              </Box>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Autocomplete
                          fullWidth
                          options={userScales}
                          getOptionLabel={(option: any) => option.name}
                          value={
                            userScales.find(scale => scale.id === userForEdit.selectedScaleId) ||
                            null
                          }
                          onChange={(event: any, value: any) =>
                            handleChange({
                              target: {
                                name: 'selectedScaleId',
                                value: value && value.id,
                              },
                            })
                          }
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              label="Scale"
                              required
                              variant="outlined"
                              error={hasErrorRequired(userForEdit.selectedScaleId, wasSubmitted)}
                              helperText={
                                hasErrorRequired(userForEdit.selectedScaleId, wasSubmitted)
                                  ? isRequired
                                  : ''
                              }
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {isLoadingData && (
                                      <Box marginLeft={2}>
                                        <CircularProgress size="15px" color="inherit" />
                                      </Box>
                                    )}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Operator Id"
                          name="operatorId"
                          variant="outlined"
                          onChange={handleCustomDataChange}
                          value={userForEdit.customData?.operatorId || ''}
                        />
                      </Grid>
                    </PermissionsWrapper>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {[UserTypes.admin.super, UserTypes.scale.admin].includes(authUser.actingAsType) && (
              <Grid item xs={12}>
                <UserAppTokenReveal />
              </Grid>
            )}
            <Grid item xs={12}>
              <ButtonResetPassword />
            </Grid>
          </Grid>
        </Container>
      </Card>
    </Page>
  );
};

export default AccountView;
