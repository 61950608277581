import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from '@mui/material';
import api from 'services/api/autogenerated';

interface CustomInputsModalProps {
  workCodeId: string;
  customInputsDefinitions: any[];
  open: boolean;
  onClose: () => void;
  onWorkCodeUpdate: () => void;
}

const WorkCodeCustomInputModal: React.FC<CustomInputsModalProps> = ({
  workCodeId,
  customInputsDefinitions,
  open,
  onClose,
  onWorkCodeUpdate,
}) => {
  const [localCustomInputs, setLocalCustomInputs] = useState<any>(customInputsDefinitions);
  const [isCustomInputsUpdated, setIsCustomInputsUpdated] = useState(true);

  const [isLoadingSaveCustomInputs, setIsLoadingSaveCustomInputs] = useState(true);

  useEffect(() => {
    const load = async () => {
      setIsLoadingSaveCustomInputs(true);

      const customInputDefaultsForWorkCode = await api.workCode.getCustomInputDefaultsForWorkCode(
        workCodeId
      );

      setLocalCustomInputs(customInputDefaultsForWorkCode.data.custom_defaults || {});
      setIsLoadingSaveCustomInputs(false);
    };

    load();
  }, [workCodeId]);

  const handleChange = (name: any, value: any) => {
    setLocalCustomInputs((prevState: any) => ({
      ...prevState,
      [name]:
        typeof value === 'object' && value !== null && !Array.isArray(value) ? { ...value } : value,
    }));

    setIsCustomInputsUpdated(true);
  };

  const saveCustomInputs = async () => {
    setIsLoadingSaveCustomInputs(true);

    if (Object.keys(localCustomInputs).length === 0) return;

    try {
      await api.workCode.addCustomInputDefaultsForWorkCode(workCodeId, localCustomInputs);
      onWorkCodeUpdate();
      onClose();
    } catch (error) {
      console.error('Failed to save custom inputs', error);
    } finally {
      setIsLoadingSaveCustomInputs(false);
    }
  };

  const renderCustomInput = (inputDefinition: any) => {
    const inputKey = inputDefinition.propertyPathInManifestCustomInputs;
    const currentValue = localCustomInputs[inputKey];

    switch (inputDefinition.type) {
      case 'Text':
        return (
          <TextField
            fullWidth
            value={currentValue || ''}
            onChange={event => handleChange(inputKey, event.target.value)}
            variant="outlined"
            margin="normal"
          />
        );

      case 'List':
        return (
          <Autocomplete
            disableClearable
            options={inputDefinition.items}
            getOptionLabel={option => option.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={currentValue || null}
            onChange={(event, newValue) => {
              handleChange(inputKey, newValue);
            }}
            renderInput={params => (
              <TextField {...params} variant="outlined" label={inputDefinition.label} fullWidth />
            )}
          />
        );

      case 'Checkbox':
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={!!currentValue?.checked}
                onChange={event => handleChange(inputKey, { checked: event.target.checked })}
              />
            }
            label={inputDefinition.label}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          backgroundColor: 'white',
          boxShadow: 24,
          padding: 4,
          borderRadius: 1,
          width: '80%',
          maxHeight: '90vh',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            zIndex: -1,
          }}
        />
        <Typography variant="h6" id="modal-modal-title" component="h2">
          Custom Inputs
        </Typography>
        <DialogContent style={{ width: '100%', overflowY: 'auto', flex: '1 1 auto' }}>
          {customInputsDefinitions.map(inputDefinition => {
            if (inputDefinition.type === 'Signature') return null;
            return (
              <Box key={inputDefinition.id} style={{ flex: 1 }}>
                <Typography variant="body1" id="modal-modal-description" component="p">
                  {inputDefinition.label}
                </Typography>
                <Box style={{ flex: 1 }}>{renderCustomInput(inputDefinition)}</Box>
              </Box>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={saveCustomInputs} disabled={!isCustomInputsUpdated}>
            Save
          </Button>
        </DialogActions>
      </Box>
    </Modal>
  );
};

export default WorkCodeCustomInputModal;
