import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '../modules/auth/storeSliceAuth';
import { GlobalFeatureFlags } from 'lg-helpers/dist/constants/feature-flags/GlobalFeatureFlags';
import api from '../services/api/autogenerated';

interface ITransporterCompany {
  id: string;
  name: string;
  canViewAllManifests: Boolean;
}

const ProjectViewTabs = ({ project, tabValue, tab, isAuthUser, handleTabChangeCallback }: any) => {
  const authUser = useSelector(selectAuthUser);

  const [companyAllowsWorkCodes, setCompanyAllowsWorkCodes] = useState(false);

  const isGeneratorOrContractor =
    (authUser.actingAsType === UserTypes.contractor.admin ||
      authUser.actingAsType === UserTypes.contractor.user ||
      authUser.actingAsType === UserTypes.generator.admin ||
      authUser.actingAsType === UserTypes.generator.user) &&
    [project?.generatorCompany?.id, project?.contractorCompany?.id].includes(
      authUser.companiesIds[0]
    );

  useEffect(() => {
    const load = async () => {
      const { data: flags } = await api.featureFlags.getCompanyFeatureFlag(
        authUser.companiesIds[0] || project.companiesIds[0]
      );

      const allowWorkCodesFlag = flags.find((flag: any) => flag.feature_name === 'allowWorkCodes');

      setCompanyAllowsWorkCodes(allowWorkCodesFlag?.is_enabled || false);
    };

    load();
  }, []);

  const allowedWorkCodeTabUserType =
    authUser.actingAsType === UserTypes.contractor.admin ||
    authUser.actingAsType === UserTypes.generator.admin ||
    authUser.actingAsType === UserTypes.transporter.admin ||
    authUser.actingAsType === UserTypes.admin.super;

  const isTransporterCompanyUser =
    isAuthUser.transporterAdmin &&
    (project.transporterCompanies || []).some(
      (transporter: ITransporterCompany) => transporter?.id === authUser.companiesIds[0]
    );

  return (
    <Tabs
      value={tabValue}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleTabChangeCallback}
    >
      <Tab label="Manifests" value={tab.MANIFESTS} />
      {isTransporterCompanyUser && <Tab label="Drivers" value={tab.DRIVERS} />}
      {isGeneratorOrContractor && (
        <Tab label="Assign Manifest to Driver" value={tab.ASSIGN_MANIFEST_TO_DRIVER} />
      )}
      {isGeneratorOrContractor && project.canAssignSpecificManifestToDriver && (
        <Tab
          label="Assign Specific Manifest to Driver"
          value={tab.ASSIGN_SPECIFIC_MANIFEST_TO_DRIVER}
        />
      )}
      {isGeneratorOrContractor && !!project.customInputsDefinitions?.length && (
        <Tab label="Manifest Custom Inputs" value={tab.MANIFEST_CUSTOM_INPUTS} />
      )}

      {allowedWorkCodeTabUserType && companyAllowsWorkCodes && (
        <Tab label={'Use Generic Drivers'} value={tab.WORK_CODES} />
      )}

      <Tab
        label={GlobalFeatureFlags.DOWNLOADS_v2 ? 'PDFs downloads (v2)' : 'PDFs downloads'}
        value={tab.PDFS_DOWNLOADS}
      />

      {isAuthUser.superAdmin && <Tab label="API Log" value={tab.API_LOG} />}
    </Tabs>
  );
};

export default ProjectViewTabs;
