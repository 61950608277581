/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.2.32
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * Enums
 * @export
 * @enum {string}
 */

export const ActionTypes = {
    DeleteUser: 'delete_user'
} as const;

export type ActionTypes = typeof ActionTypes[keyof typeof ActionTypes];


/**
 * Model actions_log
 * @export
 * @interface ActionsLog
 */
export interface ActionsLog {
    /**
     * 
     * @type {PrismaJsonValue}
     * @memberof ActionsLog
     */
    'payload': PrismaJsonValue | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsLog
     */
    'date': string;
    /**
     * 
     * @type {ActionTypes}
     * @memberof ActionsLog
     */
    'type': ActionTypes;
    /**
     * 
     * @type {string}
     * @memberof ActionsLog
     */
    'company_id': string | null;
    /**
     * 
     * @type {UserRoles}
     * @memberof ActionsLog
     */
    'role': UserRoles;
    /**
     * 
     * @type {string}
     * @memberof ActionsLog
     */
    'user_id': string;
    /**
     * 
     * @type {number}
     * @memberof ActionsLog
     */
    'id': number;
}


/**
 * 
 * @export
 * @interface AddCustomInputDefaultsForWorkCode200Response
 */
export interface AddCustomInputDefaultsForWorkCode200Response {
    /**
     * 
     * @type {any}
     * @memberof AddCustomInputDefaultsForWorkCode200Response
     */
    'error': any;
}
/**
 * 
 * @export
 * @interface AddCustomInputDefaultsForWorkCode200ResponseAnyOf
 */
export interface AddCustomInputDefaultsForWorkCode200ResponseAnyOf {
    /**
     * 
     * @type {any}
     * @memberof AddCustomInputDefaultsForWorkCode200ResponseAnyOf
     */
    'error'?: any;
}
/**
 * 
 * @export
 * @interface AddCustomInputDefaultsForWorkCode200ResponseAnyOf1
 */
export interface AddCustomInputDefaultsForWorkCode200ResponseAnyOf1 {
    /**
     * 
     * @type {any}
     * @memberof AddCustomInputDefaultsForWorkCode200ResponseAnyOf1
     */
    'error': any;
}
/**
 * 
 * @export
 * @interface AddDriverRequestBody
 */
export interface AddDriverRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AddDriverRequestBody
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof AddDriverRequestBody
     */
    'workCodeId': string;
    /**
     * 
     * @type {string}
     * @memberof AddDriverRequestBody
     */
    'driverId': string;
    /**
     * 
     * @type {string}
     * @memberof AddDriverRequestBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AddDriverRequestBody
     */
    'transporterId': string;
    /**
     * 
     * @type {string}
     * @memberof AddDriverRequestBody
     */
    'transporterName': string;
    /**
     * 
     * @type {string}
     * @memberof AddDriverRequestBody
     */
    'carrier': string;
    /**
     * 
     * @type {string}
     * @memberof AddDriverRequestBody
     */
    'truckNumber': string;
}
/**
 * 
 * @export
 * @interface AddFeatureFlagDefinitionRequest
 */
export interface AddFeatureFlagDefinitionRequest {
    /**
     * 
     * @type {string}
     * @memberof AddFeatureFlagDefinitionRequest
     */
    'feature_name': string;
}
/**
 * 
 * @export
 * @interface AddFieldsForUserSwitcher200Response
 */
export interface AddFieldsForUserSwitcher200Response {
    /**
     * 
     * @type {number}
     * @memberof AddFieldsForUserSwitcher200Response
     */
    'successfulUpdates': number;
}
/**
 * 
 * @export
 * @interface AddInvoice409Response
 */
export interface AddInvoice409Response {
    /**
     * 
     * @type {string}
     * @memberof AddInvoice409Response
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface AddTicketRequest
 */
export interface AddTicketRequest {
    /**
     * 
     * @type {IAddTicketCompanyReference}
     * @memberof AddTicketRequest
     */
    'company': IAddTicketCompanyReference;
    /**
     * 
     * @type {ITicket}
     * @memberof AddTicketRequest
     */
    'ticketData': ITicket;
}
/**
 * 
 * @export
 * @interface AddTicketsFromManifestRequest
 */
export interface AddTicketsFromManifestRequest {
    /**
     * 
     * @type {IAddTicketCompanyReference}
     * @memberof AddTicketsFromManifestRequest
     */
    'company'?: IAddTicketCompanyReference;
    /**
     * 
     * @type {number}
     * @memberof AddTicketsFromManifestRequest
     */
    'projectId': number;
}
/**
 * 
 * @export
 * @interface AddUserToLicenseRequest
 */
export interface AddUserToLicenseRequest {
    /**
     * 
     * @type {string}
     * @memberof AddUserToLicenseRequest
     */
    'userId': string;
}
/**
 * Model application_tokens
 * @export
 * @interface ApplicationTokens
 */
export interface ApplicationTokens {
    /**
     * 
     * @type {string}
     * @memberof ApplicationTokens
     */
    'created_date': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationTokens
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationTokens
     */
    'uid': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AuthMethod = {
    Auth0: 'auth0',
    Normal: 'normal'
} as const;

export type AuthMethod = typeof AuthMethod[keyof typeof AuthMethod];


/**
 * 
 * @export
 * @interface CancelWorkCodeRequestBody
 */
export interface CancelWorkCodeRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CancelWorkCodeRequestBody
     */
    'workCodeId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CivilApiProvider = {
    Vulcan: 'Vulcan',
    Connex: 'Connex',
    Hercules: 'Hercules'
} as const;

export type CivilApiProvider = typeof CivilApiProvider[keyof typeof CivilApiProvider];


/**
 * Model civil_company_sub_materials
 * @export
 * @interface CivilCompanySubMaterials
 */
export interface CivilCompanySubMaterials {
    /**
     * 
     * @type {string}
     * @memberof CivilCompanySubMaterials
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof CivilCompanySubMaterials
     */
    'company_id': string;
    /**
     * 
     * @type {string}
     * @memberof CivilCompanySubMaterials
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CivilCompanySubMaterials
     */
    'id': number;
}
/**
 * Model civil_project_api_mappings
 * @export
 * @interface CivilProjectApiMappings
 */
export interface CivilProjectApiMappings {
    /**
     * 
     * @type {number}
     * @memberof CivilProjectApiMappings
     */
    'project_id': number;
    /**
     * 
     * @type {boolean}
     * @memberof CivilProjectApiMappings
     */
    'default_source_supplier_as_transporter': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CivilProjectApiMappings
     */
    'api_id': string;
    /**
     * 
     * @type {CivilApiProvider}
     * @memberof CivilProjectApiMappings
     */
    'api': CivilApiProvider;
    /**
     * 
     * @type {string}
     * @memberof CivilProjectApiMappings
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CivilProjectApiMappings
     */
    'id': number;
}


/**
 * Model civil_project_sub_materials
 * @export
 * @interface CivilProjectSubMaterials
 */
export interface CivilProjectSubMaterials {
    /**
     * 
     * @type {string}
     * @memberof CivilProjectSubMaterials
     */
    'price_per_unit': string | null;
    /**
     * 
     * @type {string}
     * @memberof CivilProjectSubMaterials
     */
    'start_date': string | null;
    /**
     * 
     * @type {number}
     * @memberof CivilProjectSubMaterials
     */
    'sub_material_id': number;
    /**
     * 
     * @type {number}
     * @memberof CivilProjectSubMaterials
     */
    'project_id': number;
    /**
     * 
     * @type {number}
     * @memberof CivilProjectSubMaterials
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface ClearIdleConnections200Response
 */
export interface ClearIdleConnections200Response {
    /**
     * 
     * @type {any}
     * @memberof ClearIdleConnections200Response
     */
    'count': any;
}
/**
 * Model companies
 * @export
 * @interface Companies
 */
export interface Companies {
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'parent_company_id': string | null;
    /**
     * 
     * @type {number}
     * @memberof Companies
     */
    'location_id': number | null;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'status': string;
    /**
     * 
     * @type {boolean}
     * @memberof Companies
     */
    'deleted': boolean | null;
    /**
     * 
     * @type {number}
     * @memberof Companies
     */
    'next_auto_ticket_number': number | null;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'phone': string | null;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'email': string | null;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'postal_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'state': string | null;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'city': string | null;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'address_two': string | null;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'address_one': string | null;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'address': string | null;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Companies
     */
    'types': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'id': string;
}
/**
 * Model companies_connections
 * @export
 * @interface CompaniesConnections
 */
export interface CompaniesConnections {
    /**
     * 
     * @type {ConnectedCompanyType}
     * @memberof CompaniesConnections
     */
    'company_type': ConnectedCompanyType;
    /**
     * 
     * @type {string}
     * @memberof CompaniesConnections
     */
    'modified_by': string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesConnections
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesConnections
     */
    'modified_date': string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesConnections
     */
    'created_date': string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesConnections
     */
    'custom_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof CompaniesConnections
     */
    'connected_company_id': string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesConnections
     */
    'parent_company_id': string;
    /**
     * 
     * @type {number}
     * @memberof CompaniesConnections
     */
    'id': number;
}


/**
 * Model companies_custom_data
 * @export
 * @interface CompaniesCustomData
 */
export interface CompaniesCustomData {
    /**
     * 
     * @type {boolean}
     * @memberof CompaniesCustomData
     */
    'deleted_companies_custom_data': boolean | null;
    /**
     * 
     * @type {Array<PrismaJsonValue>}
     * @memberof CompaniesCustomData
     */
    'value_array_jsons': Array<PrismaJsonValue>;
    /**
     * 
     * @type {PrismaJsonValue}
     * @memberof CompaniesCustomData
     */
    'value_json': PrismaJsonValue | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompaniesCustomData
     */
    'value_array_strings': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CompaniesCustomData
     */
    'value_string': string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CompaniesCustomData
     */
    'value_array_reals': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CompaniesCustomData
     */
    'value_real': number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CompaniesCustomData
     */
    'value_array_integers': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CompaniesCustomData
     */
    'value_integer': number | null;
    /**
     * 
     * @type {string}
     * @memberof CompaniesCustomData
     */
    'name_companies_custom_data': string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesCustomData
     */
    'company_id_companies_custom_data': string;
    /**
     * 
     * @type {number}
     * @memberof CompaniesCustomData
     */
    'id_companies_custom_data': number;
}
/**
 * Model company_api_mapping
 * @export
 * @interface CompanyApiMapping
 */
export interface CompanyApiMapping {
    /**
     * 
     * @type {string}
     * @memberof CompanyApiMapping
     */
    'parent_contractor_company_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyApiMapping
     */
    'api': string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyApiMapping
     */
    'api_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyApiMapping
     */
    'company_id': string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyApiMapping
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface CompanyConnectionWithCompany
 */
export interface CompanyConnectionWithCompany {
    /**
     * 
     * @type {ConnectedCompanyType}
     * @memberof CompanyConnectionWithCompany
     */
    'company_type': ConnectedCompanyType;
    /**
     * 
     * @type {string}
     * @memberof CompanyConnectionWithCompany
     */
    'modified_by': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyConnectionWithCompany
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyConnectionWithCompany
     */
    'modified_date': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyConnectionWithCompany
     */
    'created_date': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyConnectionWithCompany
     */
    'custom_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyConnectionWithCompany
     */
    'connected_company_id': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyConnectionWithCompany
     */
    'parent_company_id': string;
    /**
     * 
     * @type {number}
     * @memberof CompanyConnectionWithCompany
     */
    'id': number;
    /**
     * 
     * @type {Companies}
     * @memberof CompanyConnectionWithCompany
     */
    'connected_company': Companies;
}


/**
 * 
 * @export
 * @interface CompanyConnectionWithCompanyAllOf
 */
export interface CompanyConnectionWithCompanyAllOf {
    /**
     * 
     * @type {Companies}
     * @memberof CompanyConnectionWithCompanyAllOf
     */
    'connected_company': Companies;
}
/**
 * Model company_custom_inputs_connection
 * @export
 * @interface CompanyCustomInputsConnection
 */
export interface CompanyCustomInputsConnection {
    /**
     * 
     * @type {number}
     * @memberof CompanyCustomInputsConnection
     */
    'custom_input_id': number;
    /**
     * 
     * @type {string}
     * @memberof CompanyCustomInputsConnection
     */
    'company_id': string;
    /**
     * 
     * @type {number}
     * @memberof CompanyCustomInputsConnection
     */
    'id': number;
}
/**
 * Model company_feature_flag
 * @export
 * @interface CompanyFeatureFlag
 */
export interface CompanyFeatureFlag {
    /**
     * 
     * @type {string}
     * @memberof CompanyFeatureFlag
     */
    'updated_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyFeatureFlag
     */
    'created_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyFeatureFlag
     */
    'is_enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyFeatureFlag
     */
    'feature_name': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyFeatureFlag
     */
    'company_id': string;
    /**
     * 
     * @type {number}
     * @memberof CompanyFeatureFlag
     */
    'id': number;
}
/**
 * Model company_feature_flag_definitions
 * @export
 * @interface CompanyFeatureFlagDefinitions
 */
export interface CompanyFeatureFlagDefinitions {
    /**
     * 
     * @type {string}
     * @memberof CompanyFeatureFlagDefinitions
     */
    'feature_name': string;
    /**
     * 
     * @type {number}
     * @memberof CompanyFeatureFlagDefinitions
     */
    'id': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ConnectedCompanyType = {
    Transporter: 'transporter',
    Generator: 'generator',
    Contractor: 'contractor',
    Broker: 'broker',
    TruckingBroker: 'truckingBroker',
    TruckingContractor: 'truckingContractor',
    TruckingSourceSupplier: 'truckingSourceSupplier',
    SourceSupplier: 'sourceSupplier'
} as const;

export type ConnectedCompanyType = typeof ConnectedCompanyType[keyof typeof ConnectedCompanyType];


/**
 * 
 * @export
 * @interface ConsumeGenericProfileRequest
 */
export interface ConsumeGenericProfileRequest {
    /**
     * 
     * @type {IConsumeGenericProfile}
     * @memberof ConsumeGenericProfileRequest
     */
    'profile': IConsumeGenericProfile;
}
/**
 * 
 * @export
 * @interface Create200Response
 */
export interface Create200Response {
    /**
     * 
     * @type {string}
     * @memberof Create200Response
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface Create400Response
 */
export interface Create400Response {
    /**
     * 
     * @type {Array<string>}
     * @memberof Create400Response
     */
    'message': Array<string>;
}
/**
 * 
 * @export
 * @interface CreateDownloadZipArchiveAndAddItToStorageBucket200Response
 */
export interface CreateDownloadZipArchiveAndAddItToStorageBucket200Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateDownloadZipArchiveAndAddItToStorageBucket200Response
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface CreateNewConnectedCompanyParams
 */
export interface CreateNewConnectedCompanyParams {
    /**
     * 
     * @type {string}
     * @memberof CreateNewConnectedCompanyParams
     */
    'parent_company_id': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewConnectedCompanyParams
     */
    'custom_id': string | null;
    /**
     * 
     * @type {ConnectedCompanyType}
     * @memberof CreateNewConnectedCompanyParams
     */
    'company_type': ConnectedCompanyType;
    /**
     * 
     * @type {PartialCompanies}
     * @memberof CreateNewConnectedCompanyParams
     */
    'new_company': PartialCompanies;
}


/**
 * 
 * @export
 * @interface CreateNewConnectedCompanyParamsAllOf
 */
export interface CreateNewConnectedCompanyParamsAllOf {
    /**
     * 
     * @type {PartialCompanies}
     * @memberof CreateNewConnectedCompanyParamsAllOf
     */
    'new_company': PartialCompanies;
}
/**
 * 
 * @export
 * @interface CreateVulcanPitTicket200Response
 */
export interface CreateVulcanPitTicket200Response {
}
/**
 * 
 * @export
 * @interface CreateVulcanPitTicketRequest
 */
export interface CreateVulcanPitTicketRequest {
    [key: string]: any;

    /**
     * 
     * @type {any}
     * @memberof CreateVulcanPitTicketRequest
     */
    'tickets': any;
}
/**
 * 
 * @export
 * @interface CreateWorkCodeRequest200Response
 */
export interface CreateWorkCodeRequest200Response {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkCodeRequest200Response
     */
    'nickname': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkCodeRequest200Response
     */
    'deleted_date': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkCodeRequest200Response
     */
    'expiration_date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkCodeRequest200Response
     */
    'created_date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkCodeRequest200Response
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkCodeRequest200Response
     */
    'work_code_id': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkCodeRequest200Response
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface CreateWorkCodeRequest200ResponseAnyOf
 */
export interface CreateWorkCodeRequest200ResponseAnyOf {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkCodeRequest200ResponseAnyOf
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface CreateWorkCodeRequestBody
 */
export interface CreateWorkCodeRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkCodeRequestBody
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkCodeRequestBody
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkCodeRequestBody
     */
    'nickname'?: string;
}
/**
 * 
 * @export
 * @interface CustomInput
 */
export interface CustomInput {
    /**
     * 
     * @type {number}
     * @memberof CustomInput
     */
    'columnIndex': number;
    /**
     * 
     * @type {string}
     * @memberof CustomInput
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomInput
     */
    'isRequired': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomInput
     */
    'isValid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomInput
     */
    'isValidCustomInputDefinition': boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomInput
     */
    'label': string;
    /**
     * 
     * @type {number}
     * @memberof CustomInput
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof CustomInput
     */
    'propertyPathInManifestCustomInputs': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomInput
     */
    'sectionsCanOnlyRead'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CustomInput
     */
    'type': CustomInputTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomInput
     */
    'sectionsCanEdit': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomInput
     */
    'userTypesCanEdit': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomInput
     */
    'userTypesCanOnlyRead'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CustomInput
     */
    'isHidden': boolean;
    /**
     * 
     * @type {Array<CustomInputListItem>}
     * @memberof CustomInput
     */
    'items': Array<CustomInputListItem>;
    /**
     * 
     * @type {string}
     * @memberof CustomInput
     */
    'defaultValue': string | null;
}

export const CustomInputTypeEnum = {
    List: 'List',
    Text: 'Text',
    Checkbox: 'Checkbox'
} as const;

export type CustomInputTypeEnum = typeof CustomInputTypeEnum[keyof typeof CustomInputTypeEnum];

/**
 * 
 * @export
 * @interface CustomInputBase
 */
export interface CustomInputBase {
    /**
     * 
     * @type {number}
     * @memberof CustomInputBase
     */
    'columnIndex': number;
    /**
     * 
     * @type {string}
     * @memberof CustomInputBase
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomInputBase
     */
    'isRequired': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomInputBase
     */
    'isValid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomInputBase
     */
    'isValidCustomInputDefinition': boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomInputBase
     */
    'label': string;
    /**
     * 
     * @type {number}
     * @memberof CustomInputBase
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof CustomInputBase
     */
    'propertyPathInManifestCustomInputs': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomInputBase
     */
    'sectionsCanOnlyRead'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CustomInputBase
     */
    'type': CustomInputBaseTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomInputBase
     */
    'sectionsCanEdit': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomInputBase
     */
    'userTypesCanEdit': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomInputBase
     */
    'userTypesCanOnlyRead'?: Array<string>;
}

export const CustomInputBaseTypeEnum = {
    List: 'List',
    Text: 'Text',
    Checkbox: 'Checkbox'
} as const;

export type CustomInputBaseTypeEnum = typeof CustomInputBaseTypeEnum[keyof typeof CustomInputBaseTypeEnum];

/**
 * 
 * @export
 * @interface CustomInputCheckbox
 */
export interface CustomInputCheckbox {
    /**
     * 
     * @type {number}
     * @memberof CustomInputCheckbox
     */
    'columnIndex': number;
    /**
     * 
     * @type {string}
     * @memberof CustomInputCheckbox
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomInputCheckbox
     */
    'isRequired': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomInputCheckbox
     */
    'isValid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomInputCheckbox
     */
    'isValidCustomInputDefinition': boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomInputCheckbox
     */
    'label': string;
    /**
     * 
     * @type {number}
     * @memberof CustomInputCheckbox
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof CustomInputCheckbox
     */
    'propertyPathInManifestCustomInputs': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomInputCheckbox
     */
    'sectionsCanOnlyRead'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CustomInputCheckbox
     */
    'type': CustomInputCheckboxTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomInputCheckbox
     */
    'sectionsCanEdit': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomInputCheckbox
     */
    'userTypesCanEdit': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomInputCheckbox
     */
    'userTypesCanOnlyRead'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CustomInputCheckbox
     */
    'isHidden': boolean;
    /**
     * 
     * @type {Array<CustomInputListItem>}
     * @memberof CustomInputCheckbox
     */
    'items': Array<CustomInputListItem>;
}

export const CustomInputCheckboxTypeEnum = {
    List: 'List',
    Text: 'Text',
    Checkbox: 'Checkbox'
} as const;

export type CustomInputCheckboxTypeEnum = typeof CustomInputCheckboxTypeEnum[keyof typeof CustomInputCheckboxTypeEnum];

/**
 * 
 * @export
 * @interface CustomInputList
 */
export interface CustomInputList {
    /**
     * 
     * @type {number}
     * @memberof CustomInputList
     */
    'columnIndex': number;
    /**
     * 
     * @type {string}
     * @memberof CustomInputList
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomInputList
     */
    'isRequired': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomInputList
     */
    'isValid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomInputList
     */
    'isValidCustomInputDefinition': boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomInputList
     */
    'label': string;
    /**
     * 
     * @type {number}
     * @memberof CustomInputList
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof CustomInputList
     */
    'propertyPathInManifestCustomInputs': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomInputList
     */
    'sectionsCanOnlyRead'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CustomInputList
     */
    'type': CustomInputListTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomInputList
     */
    'sectionsCanEdit': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomInputList
     */
    'userTypesCanEdit': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomInputList
     */
    'userTypesCanOnlyRead'?: Array<string>;
    /**
     * 
     * @type {Array<CustomInputListItem>}
     * @memberof CustomInputList
     */
    'items': Array<CustomInputListItem>;
    /**
     * 
     * @type {string}
     * @memberof CustomInputList
     */
    'defaultValue': string | null;
}

export const CustomInputListTypeEnum = {
    List: 'List',
    Text: 'Text',
    Checkbox: 'Checkbox'
} as const;

export type CustomInputListTypeEnum = typeof CustomInputListTypeEnum[keyof typeof CustomInputListTypeEnum];

/**
 * 
 * @export
 * @interface CustomInputListItem
 */
export interface CustomInputListItem {
    /**
     * 
     * @type {string}
     * @memberof CustomInputListItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomInputListItem
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof CustomInputListItem
     */
    'value': string;
}
/**
 * Model custom_inputs
 * @export
 * @interface CustomInputs
 */
export interface CustomInputs {
    /**
     * 
     * @type {PrismaJsonValue}
     * @memberof CustomInputs
     */
    'custom_input_items': PrismaJsonValue | null;
    /**
     * 
     * @type {PrismaJsonValue}
     * @memberof CustomInputs
     */
    'user_types_can_only_read': PrismaJsonValue | null;
    /**
     * 
     * @type {PrismaJsonValue}
     * @memberof CustomInputs
     */
    'user_types_can_edit': PrismaJsonValue | null;
    /**
     * 
     * @type {string}
     * @memberof CustomInputs
     */
    'type': string;
    /**
     * 
     * @type {PrismaJsonValue}
     * @memberof CustomInputs
     */
    'sections_can_only_read': PrismaJsonValue | null;
    /**
     * 
     * @type {PrismaJsonValue}
     * @memberof CustomInputs
     */
    'sections_can_edit': PrismaJsonValue | null;
    /**
     * 
     * @type {string}
     * @memberof CustomInputs
     */
    'property_path_in_manifest_custom_inputs': string;
    /**
     * 
     * @type {string}
     * @memberof CustomInputs
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof CustomInputs
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomInputs
     */
    'default_value': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomInputs
     */
    'is_valid_custom_input_definition': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomInputs
     */
    'is_valid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomInputs
     */
    'is_required': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomInputs
     */
    'is_hidden': boolean;
    /**
     * 
     * @type {number}
     * @memberof CustomInputs
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface DeleteUser200Response
 */
export interface DeleteUser200Response {
    /**
     * 
     * @type {DeleteUser200ResponseResult}
     * @memberof DeleteUser200Response
     */
    'result': DeleteUser200ResponseResult;
}
/**
 * 
 * @export
 * @interface DeleteUser200ResponseResult
 */
export interface DeleteUser200ResponseResult {
}
/**
 * 
 * @export
 * @interface DeleteWMTicketCacheForManifest200Response
 */
export interface DeleteWMTicketCacheForManifest200Response {
    /**
     * 
     * @type {string}
     * @memberof DeleteWMTicketCacheForManifest200Response
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface DetectAuthMethodRequest
 */
export interface DetectAuthMethodRequest {
    /**
     * 
     * @type {string}
     * @memberof DetectAuthMethodRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface Dimensions
 */
export interface Dimensions {
    /**
     * 
     * @type {number}
     * @memberof Dimensions
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof Dimensions
     */
    'width': number;
    /**
     * 
     * @type {string}
     * @memberof Dimensions
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface DownloadBrokerLoadsParams
 */
export interface DownloadBrokerLoadsParams {
    /**
     * 
     * @type {string}
     * @memberof DownloadBrokerLoadsParams
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadBrokerLoadsParams
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadBrokerLoadsParams
     */
    'timeZone'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadBrokerLoadsParams
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadBrokerLoadsParams
     */
    'companyId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DownloadBrokerLoadsParams
     */
    'perDay'?: boolean;
}
/**
 * 
 * @export
 * @interface DownloadManifests200Response
 */
export interface DownloadManifests200Response {
    /**
     * 
     * @type {any}
     * @memberof DownloadManifests200Response
     */
    'message': any;
}
/**
 * 
 * @export
 * @interface DownloadManifestsParams
 */
export interface DownloadManifestsParams {
    /**
     * 
     * @type {boolean}
     * @memberof DownloadManifestsParams
     */
    'entireProject'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DownloadManifestsParams
     */
    'projectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadManifestsParams
     */
    'projectProfileNr'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadManifestsParams
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadManifestsParams
     */
    'endDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DownloadManifestsParams
     */
    'perDay'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DownloadManifestsParams
     */
    'projectTimeZone'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadManifestsParams
     */
    'manifestId'?: string;
    /**
     * 
     * @type {any}
     * @memberof DownloadManifestsParams
     */
    'filters'?: any;
}
/**
 * 
 * @export
 * @interface DownloadTicketsParams
 */
export interface DownloadTicketsParams {
    /**
     * 
     * @type {string}
     * @memberof DownloadTicketsParams
     */
    'search'?: string;
    /**
     * 
     * @type {number}
     * @memberof DownloadTicketsParams
     */
    'projectId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DownloadTicketsParams
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadTicketsParams
     */
    'endDate'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof DownloadTicketsParams
     */
    'ids'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof DownloadTicketsParams
     */
    'projectTimeZone'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadTicketsParams
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadTicketsParams
     */
    'companyId'?: string;
}
/**
 * 
 * @export
 * @interface Dropbox
 */
export interface Dropbox {
    /**
     * 
     * @type {string}
     * @memberof Dropbox
     */
    'sharedLinkPdf': string;
}
/**
 * 
 * @export
 * @interface ExecuteFirestoreBigQueryBatchedManifestsImport200Response
 */
export interface ExecuteFirestoreBigQueryBatchedManifestsImport200Response {
    /**
     * 
     * @type {number}
     * @memberof ExecuteFirestoreBigQueryBatchedManifestsImport200Response
     */
    'totalBatchedManifestsImported': number;
}
/**
 * 
 * @export
 * @interface ExecuteFirestoreBigQueryManifestsImportByDate200Response
 */
export interface ExecuteFirestoreBigQueryManifestsImportByDate200Response {
    /**
     * 
     * @type {number}
     * @memberof ExecuteFirestoreBigQueryManifestsImportByDate200Response
     */
    'totalManifestsImported': number;
}
/**
 * 
 * @export
 * @interface ExecuteSpecificProjectScheduledTasks200Response
 */
export interface ExecuteSpecificProjectScheduledTasks200Response {
    /**
     * 
     * @type {number}
     * @memberof ExecuteSpecificProjectScheduledTasks200Response
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof ExecuteSpecificProjectScheduledTasks200Response
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface FiltersApiLog
 */
export interface FiltersApiLog {
    /**
     * 
     * @type {Array<string>}
     * @memberof FiltersApiLog
     */
    'filterDateRange': Array<string>;
    /**
     * 
     * @type {SortColumn}
     * @memberof FiltersApiLog
     */
    'sortColumn': SortColumn;
    /**
     * 
     * @type {string}
     * @memberof FiltersApiLog
     */
    'filterWithTicketID'?: string;
}
/**
 * 
 * @export
 * @interface FirestoreTimestamp
 */
export interface FirestoreTimestamp {
    /**
     * The number of seconds of UTC time since Unix epoch 1970-01-01T00:00:00Z.
     * @type {number}
     * @memberof FirestoreTimestamp
     */
    'seconds': number;
    /**
     * The non-negative fractions of a second at nanosecond resolution.
     * @type {number}
     * @memberof FirestoreTimestamp
     */
    'nanoseconds': number;
}
/**
 * 
 * @export
 * @interface GenerateApplicationToken200Response
 */
export interface GenerateApplicationToken200Response {
    /**
     * 
     * @type {string}
     * @memberof GenerateApplicationToken200Response
     */
    'created_date': string;
    /**
     * 
     * @type {string}
     * @memberof GenerateApplicationToken200Response
     */
    'token': string;
    /**
     * 
     * @type {any}
     * @memberof GenerateApplicationToken200Response
     */
    'uid': any;
}
/**
 * 
 * @export
 * @interface GenerateIntercomUserHash200Response
 */
export interface GenerateIntercomUserHash200Response {
    /**
     * 
     * @type {any}
     * @memberof GenerateIntercomUserHash200Response
     */
    'hash': any;
}
/**
 * 
 * @export
 * @interface GetApiLogEntriesByProject200Response
 */
export interface GetApiLogEntriesByProject200Response {
}
/**
 * 
 * @export
 * @interface GetApiLogEntriesOptions
 */
export interface GetApiLogEntriesOptions {
    /**
     * 
     * @type {string}
     * @memberof GetApiLogEntriesOptions
     */
    'project_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApiLogEntriesOptions
     */
    'projectId'?: string;
    /**
     * 
     * @type {GetApiLogEntriesOptionsColumns}
     * @memberof GetApiLogEntriesOptions
     */
    'columns'?: GetApiLogEntriesOptionsColumns;
    /**
     * 
     * @type {FiltersApiLog}
     * @memberof GetApiLogEntriesOptions
     */
    'filters'?: FiltersApiLog;
    /**
     * 
     * @type {string}
     * @memberof GetApiLogEntriesOptions
     */
    'search'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetApiLogEntriesOptions
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetApiLogEntriesOptions
     */
    'limit'?: number;
}
/**
 * 
 * @export
 * @interface GetApiLogEntriesOptionsColumns
 */
export interface GetApiLogEntriesOptionsColumns {
}
/**
 * 
 * @export
 * @interface GetApiMappingsByProject200ResponseInner
 */
export interface GetApiMappingsByProject200ResponseInner {
    /**
     * 
     * @type {number}
     * @memberof GetApiMappingsByProject200ResponseInner
     */
    'project_id': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetApiMappingsByProject200ResponseInner
     */
    'default_source_supplier_as_transporter': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetApiMappingsByProject200ResponseInner
     */
    'api_id': string;
    /**
     * 
     * @type {CivilApiProvider}
     * @memberof GetApiMappingsByProject200ResponseInner
     */
    'api': CivilApiProvider;
    /**
     * 
     * @type {string}
     * @memberof GetApiMappingsByProject200ResponseInner
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof GetApiMappingsByProject200ResponseInner
     */
    'id': number;
    /**
     * 
     * @type {GetApiMappingsByProject200ResponseInnerAllOfCount}
     * @memberof GetApiMappingsByProject200ResponseInner
     */
    '_count': GetApiMappingsByProject200ResponseInnerAllOfCount;
}


/**
 * 
 * @export
 * @interface GetApiMappingsByProject200ResponseInnerAllOf
 */
export interface GetApiMappingsByProject200ResponseInnerAllOf {
    /**
     * 
     * @type {GetApiMappingsByProject200ResponseInnerAllOfCount}
     * @memberof GetApiMappingsByProject200ResponseInnerAllOf
     */
    '_count': GetApiMappingsByProject200ResponseInnerAllOfCount;
}
/**
 * 
 * @export
 * @interface GetApiMappingsByProject200ResponseInnerAllOfCount
 */
export interface GetApiMappingsByProject200ResponseInnerAllOfCount {
    /**
     * 
     * @type {number}
     * @memberof GetApiMappingsByProject200ResponseInnerAllOfCount
     */
    'unmatched_incoming_tickets_from_api': number;
}
/**
 * 
 * @export
 * @interface GetCivilTicketTemplateHTMLRequest
 */
export interface GetCivilTicketTemplateHTMLRequest {
    [key: string]: any;

    /**
     * 
     * @type {any}
     * @memberof GetCivilTicketTemplateHTMLRequest
     */
    'notes'?: any;
    /**
     * 
     * @type {string}
     * @memberof GetCivilTicketTemplateHTMLRequest
     */
    'companyId': string;
}
/**
 * 
 * @export
 * @interface GetCompaniesBySearch200Response
 */
export interface GetCompaniesBySearch200Response {
    /**
     * 
     * @type {Array<GetCompanyById200Response>}
     * @memberof GetCompaniesBySearch200Response
     */
    'data': Array<GetCompanyById200Response>;
    /**
     * 
     * @type {SearchConnectedCompanies200ResponsePagination}
     * @memberof GetCompaniesBySearch200Response
     */
    'pagination': SearchConnectedCompanies200ResponsePagination;
}
/**
 * 
 * @export
 * @interface GetCompanyById200Response
 */
export interface GetCompanyById200Response {
    /**
     * 
     * @type {string}
     * @memberof GetCompanyById200Response
     */
    'parent_company_id': string | null;
    /**
     * 
     * @type {number}
     * @memberof GetCompanyById200Response
     */
    'location_id': number | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyById200Response
     */
    'status': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetCompanyById200Response
     */
    'deleted': boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetCompanyById200Response
     */
    'next_auto_ticket_number': number | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyById200Response
     */
    'phone': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyById200Response
     */
    'email': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyById200Response
     */
    'postal_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyById200Response
     */
    'state': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyById200Response
     */
    'city': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyById200Response
     */
    'address_two': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyById200Response
     */
    'address_one': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyById200Response
     */
    'address': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyById200Response
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCompanyById200Response
     */
    'types': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyById200Response
     */
    'id': string;
    /**
     * 
     * @type {Array<CompaniesCustomData>}
     * @memberof GetCompanyById200Response
     */
    'companies_custom_data': Array<CompaniesCustomData>;
}
/**
 * 
 * @export
 * @interface GetCompanyById200ResponseAllOf
 */
export interface GetCompanyById200ResponseAllOf {
    /**
     * 
     * @type {Array<CompaniesCustomData>}
     * @memberof GetCompanyById200ResponseAllOf
     */
    'companies_custom_data': Array<CompaniesCustomData>;
}
/**
 * 
 * @export
 * @interface GetCustomInputDefaultsForWorkCode200Response
 */
export interface GetCustomInputDefaultsForWorkCode200Response {
    /**
     * 
     * @type {PrismaJsonValue}
     * @memberof GetCustomInputDefaultsForWorkCode200Response
     */
    'custom_defaults': PrismaJsonValue | null;
    /**
     * 
     * @type {string}
     * @memberof GetCustomInputDefaultsForWorkCode200Response
     */
    'work_code_id': string;
    /**
     * 
     * @type {any}
     * @memberof GetCustomInputDefaultsForWorkCode200Response
     */
    'error': any;
}
/**
 * 
 * @export
 * @interface GetCustomInputs200ResponseInner
 */
export interface GetCustomInputs200ResponseInner {
    /**
     * 
     * @type {any}
     * @memberof GetCustomInputs200ResponseInner
     */
    'companiesIds': any;
    /**
     * 
     * @type {any}
     * @memberof GetCustomInputs200ResponseInner
     */
    'items': any;
    /**
     * 
     * @type {any}
     * @memberof GetCustomInputs200ResponseInner
     */
    'userTypesCanOnlyRead': any;
    /**
     * 
     * @type {any}
     * @memberof GetCustomInputs200ResponseInner
     */
    'userTypesCanEdit': any;
    /**
     * 
     * @type {any}
     * @memberof GetCustomInputs200ResponseInner
     */
    'type': any;
    /**
     * 
     * @type {any}
     * @memberof GetCustomInputs200ResponseInner
     */
    'sectionsCanOnlyRead': any;
    /**
     * 
     * @type {any}
     * @memberof GetCustomInputs200ResponseInner
     */
    'sectionsCanEdit': any;
    /**
     * 
     * @type {any}
     * @memberof GetCustomInputs200ResponseInner
     */
    'propertyPathInManifestCustomInputs': any;
    /**
     * 
     * @type {any}
     * @memberof GetCustomInputs200ResponseInner
     */
    'label': any;
    /**
     * 
     * @type {any}
     * @memberof GetCustomInputs200ResponseInner
     */
    'name': any;
    /**
     * 
     * @type {any}
     * @memberof GetCustomInputs200ResponseInner
     */
    'defaultValue': any;
    /**
     * 
     * @type {any}
     * @memberof GetCustomInputs200ResponseInner
     */
    'isValidCustomInputDefinition': any;
    /**
     * 
     * @type {any}
     * @memberof GetCustomInputs200ResponseInner
     */
    'isValid': any;
    /**
     * 
     * @type {any}
     * @memberof GetCustomInputs200ResponseInner
     */
    'isRequired': any;
    /**
     * 
     * @type {any}
     * @memberof GetCustomInputs200ResponseInner
     */
    'isHidden': any;
    /**
     * 
     * @type {any}
     * @memberof GetCustomInputs200ResponseInner
     */
    'id': any;
}
/**
 * 
 * @export
 * @interface GetManifestPdfBufferRequest
 */
export interface GetManifestPdfBufferRequest {
    /**
     * 
     * @type {any}
     * @memberof GetManifestPdfBufferRequest
     */
    'options': any;
}
/**
 * 
 * @export
 * @interface GetManifestTemplateHTMLRequest
 */
export interface GetManifestTemplateHTMLRequest {
    [key: string]: any;

    /**
     * 
     * @type {any}
     * @memberof GetManifestTemplateHTMLRequest
     */
    'notes'?: any;
    /**
     * 
     * @type {boolean}
     * @memberof GetManifestTemplateHTMLRequest
     */
    'templateDisplay'?: boolean;
}
/**
 * 
 * @export
 * @interface GetProfileNumbers200ResponseInner
 */
export interface GetProfileNumbers200ResponseInner {
    /**
     * 
     * @type {any}
     * @memberof GetProfileNumbers200ResponseInner
     */
    'name': any;
    /**
     * 
     * @type {any}
     * @memberof GetProfileNumbers200ResponseInner
     */
    'profileNr': any;
    /**
     * 
     * @type {any}
     * @memberof GetProfileNumbers200ResponseInner
     */
    'id': any;
}
/**
 * 
 * @export
 * @interface GetReconciliationReport200Response
 */
export interface GetReconciliationReport200Response {
    /**
     * 
     * @type {string}
     * @memberof GetReconciliationReport200Response
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface GetSourceSuppliersByCompanyId200ResponseInner
 */
export interface GetSourceSuppliersByCompanyId200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetSourceSuppliersByCompanyId200ResponseInner
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSourceSuppliersByCompanyId200ResponseInner
     */
    'deleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSourceSuppliersByCompanyId200ResponseInner
     */
    'sourceSupplierId': string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceSuppliersByCompanyId200ResponseInner
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof GetSourceSuppliersByCompanyId200ResponseInner
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface GetTemplateDisplayHTMLRequest
 */
export interface GetTemplateDisplayHTMLRequest {
    [key: string]: any;

    /**
     * 
     * @type {boolean}
     * @memberof GetTemplateDisplayHTMLRequest
     */
    'templateDisplay'?: boolean;
    /**
     * 
     * @type {any}
     * @memberof GetTemplateDisplayHTMLRequest
     */
    'notes'?: any;
    /**
     * 
     * @type {any}
     * @memberof GetTemplateDisplayHTMLRequest
     */
    'template': any;
}
/**
 * 
 * @export
 * @interface GetTemplateTypes200Response
 */
export interface GetTemplateTypes200Response {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof GetTemplateTypes200Response
     */
    'manifestTemplateContextFields': { [key: string]: any; };
    /**
     * 
     * @type {ProvidedType}
     * @memberof GetTemplateTypes200Response
     */
    'types': ProvidedType;
}
/**
 * 
 * @export
 * @interface GetTimesheetById200Response
 */
export interface GetTimesheetById200Response {
    /**
     * 
     * @type {string}
     * @memberof GetTimesheetById200Response
     */
    'modified_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetTimesheetById200Response
     */
    'created_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetTimesheetById200Response
     */
    'modified_by': string;
    /**
     * 
     * @type {string}
     * @memberof GetTimesheetById200Response
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof GetTimesheetById200Response
     */
    'end_time': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTimesheetById200Response
     */
    'start_time': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTimesheetById200Response
     */
    'notes': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTimesheetById200Response
     */
    'truck_number': string;
    /**
     * 
     * @type {string}
     * @memberof GetTimesheetById200Response
     */
    'company_id': string;
    /**
     * 
     * @type {number}
     * @memberof GetTimesheetById200Response
     */
    'id': number;
    /**
     * 
     * @type {Array<TruckingTimesheetReferences>}
     * @memberof GetTimesheetById200Response
     */
    'trucking_timesheet_references': Array<TruckingTimesheetReferences>;
}
/**
 * 
 * @export
 * @interface GetTimesheetById200ResponseAllOf
 */
export interface GetTimesheetById200ResponseAllOf {
    /**
     * 
     * @type {Array<TruckingTimesheetReferences>}
     * @memberof GetTimesheetById200ResponseAllOf
     */
    'trucking_timesheet_references': Array<TruckingTimesheetReferences>;
}
/**
 * 
 * @export
 * @interface GetTransporters200ResponseInner
 */
export interface GetTransporters200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetTransporters200ResponseInner
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetTransporters200ResponseInner
     */
    'deleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetTransporters200ResponseInner
     */
    'transporterId': string;
    /**
     * 
     * @type {string}
     * @memberof GetTransporters200ResponseInner
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof GetTransporters200ResponseInner
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface GetUserProjectLists200Response
 */
export interface GetUserProjectLists200Response {
    /**
     * 
     * @type {Array<{ [key: string]: any; }>}
     * @memberof GetUserProjectLists200Response
     */
    'projects': Array<{ [key: string]: any; }>;
}
/**
 * 
 * @export
 * @interface GetUserProjectOptions200Response
 */
export interface GetUserProjectOptions200Response {
    /**
     * 
     * @type {Array<Companies>}
     * @memberof GetUserProjectOptions200Response
     */
    'contractorOptions': Array<Companies>;
    /**
     * 
     * @type {Array<Companies>}
     * @memberof GetUserProjectOptions200Response
     */
    'generatorOptions': Array<Companies>;
    /**
     * 
     * @type {Array<Companies>}
     * @memberof GetUserProjectOptions200Response
     */
    'transporterOptions': Array<Companies>;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetUserProjectOptions200Response
     */
    'receiptTemplates': Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetUserProjectOptions200Response
     */
    'manifestTemplates': Array<any>;
    /**
     * 
     * @type {Array<{ [key: string]: any; }>}
     * @memberof GetUserProjectOptions200Response
     */
    'scales': Array<{ [key: string]: any; }>;
}
/**
 * 
 * @export
 * @interface GetWorkCodeDrivers200Response
 */
export interface GetWorkCodeDrivers200Response {
    /**
     * 
     * @type {any}
     * @memberof GetWorkCodeDrivers200Response
     */
    'error': any;
    /**
     * 
     * @type {any}
     * @memberof GetWorkCodeDrivers200Response
     */
    'data': any;
}
/**
 * 
 * @export
 * @interface GetWorkCodeDrivers200ResponseAnyOf
 */
export interface GetWorkCodeDrivers200ResponseAnyOf {
    /**
     * 
     * @type {any}
     * @memberof GetWorkCodeDrivers200ResponseAnyOf
     */
    'error'?: any;
    /**
     * 
     * @type {Array<WorkCodeDrivers>}
     * @memberof GetWorkCodeDrivers200ResponseAnyOf
     */
    'data': Array<WorkCodeDrivers>;
}
/**
 * 
 * @export
 * @interface GetWorkCodeDrivers200ResponseAnyOf1
 */
export interface GetWorkCodeDrivers200ResponseAnyOf1 {
    /**
     * 
     * @type {any}
     * @memberof GetWorkCodeDrivers200ResponseAnyOf1
     */
    'data'?: any;
    /**
     * 
     * @type {any}
     * @memberof GetWorkCodeDrivers200ResponseAnyOf1
     */
    'error': any;
}
/**
 * 
 * @export
 * @interface IActivity
 */
export interface IActivity {
    /**
     * 
     * @type {string}
     * @memberof IActivity
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof IActivity
     */
    'activityTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IActivity
     */
    'activityTypeName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IActivity
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IActivity
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface IActivityType
 */
export interface IActivityType {
    /**
     * 
     * @type {string}
     * @memberof IActivityType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IActivityType
     */
    'companyId'?: string;
    /**
     * 
     * @type {number}
     * @memberof IActivityType
     */
    'projectId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof IActivityType
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IActivityType
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof IActivityType
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface IAddTicketCompanyReference
 */
export interface IAddTicketCompanyReference {
    /**
     * 
     * @type {string}
     * @memberof IAddTicketCompanyReference
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IAddTicketCompanyReference
     */
    'name': string;
    /**
     * 
     * @type {Array<UserTypeGenerated>}
     * @memberof IAddTicketCompanyReference
     */
    'types': Array<UserTypeGenerated>;
}
/**
 * 
 * @export
 * @interface IAddress
 */
export interface IAddress {
    /**
     * 
     * @type {string}
     * @memberof IAddress
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAddress
     */
    'postcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAddress
     */
    'place'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAddress
     */
    'locality'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAddress
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAddress
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAddress
     */
    'disctrict'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAddress
     */
    'neighborhood'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAddress
     */
    'poi'?: string;
}
/**
 * 
 * @export
 * @interface IBaseProjectMaterial
 */
export interface IBaseProjectMaterial {
    /**
     * 
     * @type {number}
     * @memberof IBaseProjectMaterial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof IBaseProjectMaterial
     */
    'projectId': number;
    /**
     * 
     * @type {number}
     * @memberof IBaseProjectMaterial
     */
    'materialId': number | null;
    /**
     * 
     * @type {string}
     * @memberof IBaseProjectMaterial
     */
    'sourceSupplierId': string | null;
    /**
     * 
     * @type {string}
     * @memberof IBaseProjectMaterial
     */
    'profileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IBaseProjectMaterial
     */
    'profileType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IBaseProjectMaterial
     */
    'testFrequency'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBaseProjectMaterial
     */
    'units'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IBaseProjectMaterial
     */
    'splitCost'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IBaseProjectMaterial
     */
    'transportCost'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBaseProjectMaterial
     */
    'materialCost'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBaseProjectMaterial
     */
    'totalCost': number | null;
    /**
     * 
     * @type {IProjectMaterialDateActive}
     * @memberof IBaseProjectMaterial
     */
    'dateActive'?: IProjectMaterialDateActive | null;
    /**
     * 
     * @type {IProjectMaterialDateActive}
     * @memberof IBaseProjectMaterial
     */
    'dateExpired'?: IProjectMaterialDateActive | null;
    /**
     * 
     * @type {number}
     * @memberof IBaseProjectMaterial
     */
    'markup'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IBaseProjectMaterial
     */
    'totalPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IBaseProjectMaterial
     */
    'supplierMaterialId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IBaseProjectMaterial
     */
    'requireGeneratorSignature'?: boolean | null;
}
/**
 * 
 * @export
 * @interface ICompany
 */
export interface ICompany {
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'addressOne'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'addressTwo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'phone'?: string;
    /**
     * 
     * @type {Array<UserTypeGenerated>}
     * @memberof ICompany
     */
    'types': Array<UserTypeGenerated>;
    /**
     * 
     * @type {number}
     * @memberof ICompany
     */
    'nextAutoTicketNumber'?: number;
    /**
     * 
     * @type {Array<ICompanyCustomData>}
     * @memberof ICompany
     */
    'customData'?: Array<ICompanyCustomData>;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'status'?: ICompanyStatusEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof ICompany
     */
    'geo'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof ICompany
     */
    'locationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ICompany
     */
    'parentCompanyId'?: string | null;
    /**
     * 
     * @type {ICompany}
     * @memberof ICompany
     */
    'parentCompany'?: ICompany | null;
}

export const ICompanyStatusEnum = {
    Active: 'active',
    Pending: 'pending'
} as const;

export type ICompanyStatusEnum = typeof ICompanyStatusEnum[keyof typeof ICompanyStatusEnum];

/**
 * 
 * @export
 * @interface ICompanyCustomData
 */
export interface ICompanyCustomData {
    /**
     * 
     * @type {number}
     * @memberof ICompanyCustomData
     */
    'idCompaniesCustomData'?: number;
    /**
     * 
     * @type {string}
     * @memberof ICompanyCustomData
     */
    'companyIdCompaniesCustomData'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICompanyCustomData
     */
    'nameCompaniesCustomData': string;
    /**
     * 
     * @type {number}
     * @memberof ICompanyCustomData
     */
    'valueInteger'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ICompanyCustomData
     */
    'valueArrayIntegers'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ICompanyCustomData
     */
    'valueReal'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ICompanyCustomData
     */
    'valueArrayReals'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ICompanyCustomData
     */
    'valueString'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICompanyCustomData
     */
    'valueArrayStrings'?: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof ICompanyCustomData
     */
    'valueJson'?: any;
    /**
     * 
     * @type {Array<any>}
     * @memberof ICompanyCustomData
     */
    'valueArrayJsons'?: Array<any>;
    /**
     * 
     * @type {boolean}
     * @memberof ICompanyCustomData
     */
    'deletedCompaniesCustomData'?: boolean;
}
/**
 * 
 * @export
 * @interface ICompanyMaterial
 */
export interface ICompanyMaterial {
    /**
     * 
     * @type {string}
     * @memberof ICompanyMaterial
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ICompanyMaterial
     */
    'sku'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICompanyMaterial
     */
    'companyId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ICompanyMaterial
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ICompanyMaterial
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface IConsumeCompanySWT
 */
export interface IConsumeCompanySWT {
    [key: string]: any;

    /**
     * 
     * @type {IConsumeCompanySite}
     * @memberof IConsumeCompanySWT
     */
    'site'?: IConsumeCompanySite;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySWT
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySWT
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySWT
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySWT
     */
    'addressOne'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySWT
     */
    'addressTwo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySWT
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySWT
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySWT
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySWT
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySWT
     */
    'phone'?: string;
    /**
     * 
     * @type {number}
     * @memberof IConsumeCompanySWT
     */
    'nextAutoTicketNumber'?: number;
    /**
     * 
     * @type {Array<ICompanyCustomData>}
     * @memberof IConsumeCompanySWT
     */
    'customData'?: Array<ICompanyCustomData>;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySWT
     */
    'status'?: IConsumeCompanySWTStatusEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof IConsumeCompanySWT
     */
    'geo'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof IConsumeCompanySWT
     */
    'locationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySWT
     */
    'parentCompanyId'?: string | null;
    /**
     * 
     * @type {ICompany}
     * @memberof IConsumeCompanySWT
     */
    'parentCompany'?: ICompany | null;
}

export const IConsumeCompanySWTStatusEnum = {
    Active: 'active',
    Pending: 'pending'
} as const;

export type IConsumeCompanySWTStatusEnum = typeof IConsumeCompanySWTStatusEnum[keyof typeof IConsumeCompanySWTStatusEnum];

/**
 * 
 * @export
 * @interface IConsumeCompanySite
 */
export interface IConsumeCompanySite {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySite
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySite
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySite
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySite
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySite
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySite
     */
    'address2'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySite
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySite
     */
    'county'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySite
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeCompanySite
     */
    'state'?: string;
}
/**
 * 
 * @export
 * @interface IConsumeGenericProfile
 */
export interface IConsumeGenericProfile {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof IConsumeGenericProfile
     */
    'projectStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeGenericProfile
     */
    'marketArea': string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeGenericProfile
     */
    'profileNumber': string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeGenericProfile
     */
    'profileName': string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeGenericProfile
     */
    'profileApprovalDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeGenericProfile
     */
    'profileExpirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeGenericProfile
     */
    'timezone': string;
    /**
     * 
     * @type {number}
     * @memberof IConsumeGenericProfile
     */
    'approvedProfileVolume'?: number;
    /**
     * 
     * @type {string}
     * @memberof IConsumeGenericProfile
     */
    'approvedProfileVolumeUnitOfMeasure'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IConsumeGenericProfile
     */
    'scales': Array<string>;
    /**
     * 
     * @type {ProfileCreator}
     * @memberof IConsumeGenericProfile
     */
    'profileCreator'?: ProfileCreator;
    /**
     * 
     * @type {IConsumeCompanySWT}
     * @memberof IConsumeGenericProfile
     */
    'generatorCompany': IConsumeCompanySWT;
    /**
     * 
     * @type {IConsumeCompanySWT}
     * @memberof IConsumeGenericProfile
     */
    'contractorCompany': IConsumeCompanySWT;
    /**
     * 
     * @type {IConsumeCompanySWT}
     * @memberof IConsumeGenericProfile
     */
    'primaryTransporter': IConsumeCompanySWT;
    /**
     * 
     * @type {string}
     * @memberof IConsumeGenericProfile
     */
    'manifestTemplateId': string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeGenericProfile
     */
    'manifestTemplateName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeGenericProfile
     */
    'material': string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeGenericProfile
     */
    'materialDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeGenericProfile
     */
    'receiptTemplateId': string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeGenericProfile
     */
    'receiptTemplateName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeGenericProfile
     */
    'billTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof IConsumeGenericProfile
     */
    'fileNameActionsInCronJob'?: string;
}
/**
 * 
 * @export
 * @interface IContractorCompany
 */
export interface IContractorCompany {
    /**
     * 
     * @type {string}
     * @memberof IContractorCompany
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IContractorCompany
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof IContractorCompany
     */
    'idCompaniesCustomData'?: number;
    /**
     * 
     * @type {string}
     * @memberof IContractorCompany
     */
    'companyIdCompaniesCustomData'?: string;
    /**
     * 
     * @type {string}
     * @memberof IContractorCompany
     */
    'nameCompaniesCustomData'?: string;
    /**
     * 
     * @type {number}
     * @memberof IContractorCompany
     */
    'valueInteger'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof IContractorCompany
     */
    'valueArrayIntegers'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof IContractorCompany
     */
    'valueReal'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof IContractorCompany
     */
    'valueArrayReals'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof IContractorCompany
     */
    'valueString'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IContractorCompany
     */
    'valueArrayStrings'?: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof IContractorCompany
     */
    'valueJson'?: any;
    /**
     * 
     * @type {Array<any>}
     * @memberof IContractorCompany
     */
    'valueArrayJsons'?: Array<any>;
    /**
     * 
     * @type {boolean}
     * @memberof IContractorCompany
     */
    'deletedCompaniesCustomData'?: boolean;
}
/**
 * 
 * @export
 * @interface ICustomInputDefinitions
 */
export interface ICustomInputDefinitions {
    /**
     * 
     * @type {number}
     * @memberof ICustomInputDefinitions
     */
    'columnIndex': number;
    /**
     * 
     * @type {string}
     * @memberof ICustomInputDefinitions
     */
    'defaultValue': string;
    /**
     * 
     * @type {string}
     * @memberof ICustomInputDefinitions
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomInputDefinitions
     */
    'isRequired': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomInputDefinitions
     */
    'isValid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomInputDefinitions
     */
    'isValidCustomInputDefinition': boolean;
    /**
     * 
     * @type {string}
     * @memberof ICustomInputDefinitions
     */
    'label': string;
    /**
     * 
     * @type {number}
     * @memberof ICustomInputDefinitions
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof ICustomInputDefinitions
     */
    'propertyPathInManifestCustomInputs': string;
    /**
     * 
     * @type {string}
     * @memberof ICustomInputDefinitions
     */
    'type': string;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomInputDefinitions
     */
    'isHidden': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICustomInputDefinitions
     */
    'userTypesCanEdit': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICustomInputDefinitions
     */
    'userTypesCanOnlyRead': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICustomInputDefinitions
     */
    'sectionsCanOnlyRead': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICustomInputDefinitions
     */
    'sectionsCanEdit': Array<string>;
    /**
     * 
     * @type {Array<ICustomInputListItem>}
     * @memberof ICustomInputDefinitions
     */
    'items'?: Array<ICustomInputListItem>;
    /**
     * 
     * @type {boolean}
     * @memberof ICustomInputDefinitions
     */
    'showOnQRCodeScreen'?: boolean;
}
/**
 * 
 * @export
 * @interface ICustomInputListItem
 */
export interface ICustomInputListItem {
    /**
     * 
     * @type {string}
     * @memberof ICustomInputListItem
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof ICustomInputListItem
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ICustomInputListItem
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface IDropBox
 */
export interface IDropBox {
    /**
     * 
     * @type {string}
     * @memberof IDropBox
     */
    'sharedLinkForManifestsPerDayPdfs': string;
}
/**
 * 
 * @export
 * @interface IFetchCompaniesBySearch
 */
export interface IFetchCompaniesBySearch {
    /**
     * 
     * @type {string}
     * @memberof IFetchCompaniesBySearch
     */
    'search'?: string;
    /**
     * 
     * @type {Array<UserTypeGenerated>}
     * @memberof IFetchCompaniesBySearch
     */
    'types'?: Array<UserTypeGenerated>;
    /**
     * 
     * @type {string}
     * @memberof IFetchCompaniesBySearch
     */
    'state'?: string;
    /**
     * 
     * @type {number}
     * @memberof IFetchCompaniesBySearch
     */
    'take'?: number;
    /**
     * 
     * @type {number}
     * @memberof IFetchCompaniesBySearch
     */
    'skip'?: number;
    /**
     * 
     * @type {Array<PrismaCompaniesOrderByRelevanceFieldEnum>}
     * @memberof IFetchCompaniesBySearch
     */
    'fields'?: Array<PrismaCompaniesOrderByRelevanceFieldEnum>;
    /**
     * 
     * @type {PrismaSortOrder}
     * @memberof IFetchCompaniesBySearch
     */
    'sort'?: PrismaSortOrder | null;
}


/**
 * 
 * @export
 * @interface IFetchCompaniesConnectionsBySearch
 */
export interface IFetchCompaniesConnectionsBySearch {
    /**
     * 
     * @type {string}
     * @memberof IFetchCompaniesConnectionsBySearch
     */
    'parentCompanyId'?: string;
    /**
     * 
     * @type {Array<ConnectedCompanyType>}
     * @memberof IFetchCompaniesConnectionsBySearch
     */
    'types'?: Array<ConnectedCompanyType>;
    /**
     * 
     * @type {string}
     * @memberof IFetchCompaniesConnectionsBySearch
     */
    'search'?: string;
    /**
     * 
     * @type {number}
     * @memberof IFetchCompaniesConnectionsBySearch
     */
    'take'?: number;
    /**
     * 
     * @type {number}
     * @memberof IFetchCompaniesConnectionsBySearch
     */
    'skip'?: number;
    /**
     * 
     * @type {PrismaSortOrder}
     * @memberof IFetchCompaniesConnectionsBySearch
     */
    'sort'?: PrismaSortOrder;
}


/**
 * 
 * @export
 * @interface IGeneratorCompany
 */
export interface IGeneratorCompany {
    /**
     * 
     * @type {string}
     * @memberof IGeneratorCompany
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IGeneratorCompany
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof IGeneratorCompany
     */
    'idCompaniesCustomData'?: number;
    /**
     * 
     * @type {string}
     * @memberof IGeneratorCompany
     */
    'companyIdCompaniesCustomData'?: string;
    /**
     * 
     * @type {string}
     * @memberof IGeneratorCompany
     */
    'nameCompaniesCustomData'?: string;
    /**
     * 
     * @type {number}
     * @memberof IGeneratorCompany
     */
    'valueInteger'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof IGeneratorCompany
     */
    'valueArrayIntegers'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof IGeneratorCompany
     */
    'valueReal'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof IGeneratorCompany
     */
    'valueArrayReals'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof IGeneratorCompany
     */
    'valueString'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IGeneratorCompany
     */
    'valueArrayStrings'?: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof IGeneratorCompany
     */
    'valueJson'?: any;
    /**
     * 
     * @type {Array<any>}
     * @memberof IGeneratorCompany
     */
    'valueArrayJsons'?: Array<any>;
    /**
     * 
     * @type {boolean}
     * @memberof IGeneratorCompany
     */
    'deletedCompaniesCustomData'?: boolean;
}
/**
 * 
 * @export
 * @interface IGlobalCustomInputs
 */
export interface IGlobalCustomInputs {
    /**
     * 
     * @type {string}
     * @memberof IGlobalCustomInputs
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof IGlobalCustomInputs
     */
    'order': number;
    /**
     * 
     * @type {number}
     * @memberof IGlobalCustomInputs
     */
    'columnIndex': number;
    /**
     * 
     * @type {string}
     * @memberof IGlobalCustomInputs
     */
    'defaultValue': string;
    /**
     * 
     * @type {boolean}
     * @memberof IGlobalCustomInputs
     */
    'isRequired': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IGlobalCustomInputs
     */
    'isValid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IGlobalCustomInputs
     */
    'isValidCustomInputDefinition': boolean;
    /**
     * 
     * @type {string}
     * @memberof IGlobalCustomInputs
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof IGlobalCustomInputs
     */
    'propertyPathInManifestCustomInputs': string;
    /**
     * 
     * @type {boolean}
     * @memberof IGlobalCustomInputs
     */
    'isHidden': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof IGlobalCustomInputs
     */
    'userTypesCanEdit': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IGlobalCustomInputs
     */
    'userTypesCanOnlyRead': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IGlobalCustomInputs
     */
    'sectionsCanOnlyRead': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IGlobalCustomInputs
     */
    'sectionsCanEdit': Array<string>;
    /**
     * 
     * @type {Array<ICustomInputListItem>}
     * @memberof IGlobalCustomInputs
     */
    'items'?: Array<ICustomInputListItem>;
    /**
     * 
     * @type {boolean}
     * @memberof IGlobalCustomInputs
     */
    'showOnQRCodeScreen'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IGlobalCustomInputs
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IGlobalCustomInputs
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IGlobalCustomInputs
     */
    'companiesIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface IInvoice
 */
export interface IInvoice {
    /**
     * 
     * @type {number}
     * @memberof IInvoice
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof IInvoice
     */
    'projectId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof IInvoice
     */
    'isTransporter'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IInvoice
     */
    'targetCompanyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoice
     */
    'projectName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoice
     */
    'number'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IInvoice
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IInvoice
     */
    'originatingCompanyId'?: string;
    /**
     * 
     * @type {IInvoiceInvoiceDate}
     * @memberof IInvoice
     */
    'invoiceDate'?: IInvoiceInvoiceDate;
    /**
     * 
     * @type {string}
     * @memberof IInvoice
     */
    'transporter'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IInvoice
     */
    'isFromTrucking'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IInvoice
     */
    'dateCreated'?: string;
}
/**
 * 
 * @export
 * @interface IInvoiceInvoiceDate
 */
export interface IInvoiceInvoiceDate {
}
/**
 * 
 * @export
 * @interface IJoinCompany
 */
export interface IJoinCompany {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof IJoinCompany
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IJoinCompany
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IJoinCompany
     */
    'addressOne'?: string;
    /**
     * 
     * @type {string}
     * @memberof IJoinCompany
     */
    'addressTwo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IJoinCompany
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof IJoinCompany
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof IJoinCompany
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof IJoinCompany
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof IJoinCompany
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof IJoinCompany
     */
    'phone'?: string;
    /**
     * 
     * @type {Array<UserTypeGenerated>}
     * @memberof IJoinCompany
     */
    'types': Array<UserTypeGenerated>;
    /**
     * 
     * @type {number}
     * @memberof IJoinCompany
     */
    'nextAutoTicketNumber'?: number;
    /**
     * 
     * @type {Array<ICompanyCustomData>}
     * @memberof IJoinCompany
     */
    'customData'?: Array<ICompanyCustomData>;
    /**
     * 
     * @type {string}
     * @memberof IJoinCompany
     */
    'status'?: IJoinCompanyStatusEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof IJoinCompany
     */
    'geo'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof IJoinCompany
     */
    'locationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IJoinCompany
     */
    'parentCompanyId'?: string | null;
    /**
     * 
     * @type {ICompany}
     * @memberof IJoinCompany
     */
    'parentCompany'?: ICompany | null;
    /**
     * 
     * @type {string}
     * @memberof IJoinCompany
     */
    'sourceSupplierId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IJoinCompany
     */
    'transporterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IJoinCompany
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IJoinCompany
     */
    'lastName'?: string;
    /**
     * 
     * @type {ILocation}
     * @memberof IJoinCompany
     */
    'location'?: ILocation;
    /**
     * 
     * @type {Array<string>}
     * @memberof IJoinCompany
     */
    'externalApiPropertyNamesForMappingsInProjects'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IJoinCompany
     */
    'fileNameActionsInCronJob'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof IJoinCompany
     */
    'manifestTemplates'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IJoinCompany
     */
    'receiptTemplates'?: Array<string>;
    /**
     * 
     * @type {Array<IRegions>}
     * @memberof IJoinCompany
     */
    'regions'?: Array<IRegions>;
    /**
     * 
     * @type {IJoinCompanyCompanyLogo}
     * @memberof IJoinCompany
     */
    'companyLogo'?: IJoinCompanyCompanyLogo;
    /**
     * 
     * @type {string}
     * @memberof IJoinCompany
     */
    'reportUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof IJoinCompany
     */
    'supplierApiMethod'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IJoinCompany
     */
    'requireCivilTicketShippingImages'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IJoinCompany
     */
    'requireCivilTicketQty'?: boolean;
}

export const IJoinCompanyStatusEnum = {
    Active: 'active',
    Pending: 'pending'
} as const;

export type IJoinCompanyStatusEnum = typeof IJoinCompanyStatusEnum[keyof typeof IJoinCompanyStatusEnum];

/**
 * 
 * @export
 * @interface IJoinCompanyCompanyLogo
 */
export interface IJoinCompanyCompanyLogo {
    /**
     * 
     * @type {number}
     * @memberof IJoinCompanyCompanyLogo
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof IJoinCompanyCompanyLogo
     */
    'width'?: number;
    /**
     * 
     * @type {string}
     * @memberof IJoinCompanyCompanyLogo
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface ILane
 */
export interface ILane {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ILane
     */
    'color': string;
    /**
     * 
     * @type {string}
     * @memberof ILane
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ILane
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ILane
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ILocation
 */
export interface ILocation {
    /**
     * 
     * @type {number}
     * @memberof ILocation
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ILocation
     */
    'addressString'?: string;
    /**
     * 
     * @type {string}
     * @memberof ILocation
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ILocation
     */
    'num'?: string;
    /**
     * 
     * @type {string}
     * @memberof ILocation
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof ILocation
     */
    'street2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ILocation
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ILocation
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ILocation
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ILocation
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof ILocation
     */
    'zipplus'?: string;
    /**
     * 
     * @type {string}
     * @memberof ILocation
     */
    'country'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ILocation
     */
    'geo'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof ILocation
     */
    'civilLocationToProjectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ILocation
     */
    'projectId'?: number;
}
/**
 * 
 * @export
 * @interface ILocationToProjectImport
 */
export interface ILocationToProjectImport {
    /**
     * 
     * @type {Array<IAddress>}
     * @memberof ILocationToProjectImport
     */
    'addresses'?: Array<IAddress>;
    /**
     * 
     * @type {string}
     * @memberof ILocationToProjectImport
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ILocationToProjectImport
     */
    'companyId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ILocationToProjectImport
     */
    'projectId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ILocationToProjectImport
     */
    'createdBy'?: string;
}
/**
 * 
 * @export
 * @interface IManifestApiLog
 */
export interface IManifestApiLog {
    /**
     * 
     * @type {number}
     * @memberof IManifestApiLog
     */
    'id'?: number;
    /**
     * 
     * @type {IProjectTaskProjectMaterialId}
     * @memberof IManifestApiLog
     */
    'ticket_id'?: IProjectTaskProjectMaterialId;
    /**
     * 
     * @type {string}
     * @memberof IManifestApiLog
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof IManifestApiLog
     */
    'status'?: string;
    /**
     * 
     * @type {IProjectTaskProjectMaterialId}
     * @memberof IManifestApiLog
     */
    'quantity'?: IProjectTaskProjectMaterialId;
    /**
     * 
     * @type {string}
     * @memberof IManifestApiLog
     */
    'rate_uom'?: string;
    /**
     * 
     * @type {IProjectTaskProjectMaterialId}
     * @memberof IManifestApiLog
     */
    'site_id'?: IProjectTaskProjectMaterialId;
    /**
     * 
     * @type {string}
     * @memberof IManifestApiLog
     */
    'site_name'?: string;
    /**
     * 
     * @type {IProjectTaskProjectMaterialId}
     * @memberof IManifestApiLog
     */
    'manifest_id'?: IProjectTaskProjectMaterialId;
    /**
     * 
     * @type {IProjectTaskProjectMaterialId}
     * @memberof IManifestApiLog
     */
    'project_id'?: IProjectTaskProjectMaterialId;
    /**
     * 
     * @type {boolean}
     * @memberof IManifestApiLog
     */
    'matched'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IManifestApiLog
     */
    'carrier'?: string;
    /**
     * 
     * @type {string}
     * @memberof IManifestApiLog
     */
    'truck_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof IManifestApiLog
     */
    'driver'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const IManifestApiLogColumns = {
    Driver: 'driver',
    Id: 'id',
    ProjectId: 'project_id',
    Status: 'status',
    ManifestId: 'manifest_id',
    Quantity: 'quantity',
    TruckNumber: 'truck_number',
    TicketId: 'ticket_id',
    Date: 'date',
    RateUom: 'rate_uom',
    SiteId: 'site_id',
    SiteName: 'site_name',
    Matched: 'matched',
    Carrier: 'carrier',
    Star: '*'
} as const;

export type IManifestApiLogColumns = typeof IManifestApiLogColumns[keyof typeof IManifestApiLogColumns];


/**
 * 
 * @export
 * @interface IPhoto
 */
export interface IPhoto {
    /**
     * 
     * @type {number}
     * @memberof IPhoto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IPhoto
     */
    'photoUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof IPhoto
     */
    'civilTicketId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IPhoto
     */
    'category'?: IPhotoCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof IPhoto
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof IPhoto
     */
    'createdByCompanyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IPhoto
     */
    'createdByUserId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IPhoto
     */
    'isNew'?: boolean;
}

export const IPhotoCategoryEnum = {
    Shipping: 'shipping',
    Inspection: 'inspection'
} as const;

export type IPhotoCategoryEnum = typeof IPhotoCategoryEnum[keyof typeof IPhotoCategoryEnum];

/**
 * 
 * @export
 * @interface IProject
 */
export interface IProject {
    /**
     * 
     * @type {boolean}
     * @memberof IProject
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'addressName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'additionalDescriptions'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'address'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof IProject
     */
    'assignedDriversIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'billTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'canAssignSpecificManifestToDriver'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'companyId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IProject
     */
    'companiesIds'?: Array<string>;
    /**
     * 
     * @type {IJoinCompany}
     * @memberof IProject
     */
    'contractorCompany'?: IJoinCompany;
    /**
     * 
     * @type {Array<string>}
     * @memberof IProject
     */
    'customerAdminIds'?: Array<string>;
    /**
     * 
     * @type {Array<ICustomInputDefinitions>}
     * @memberof IProject
     */
    'customInputsDefinitions'?: Array<ICustomInputDefinitions>;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'descriptionOfWasteMaterials'?: string;
    /**
     * 
     * @type {IDropBox}
     * @memberof IProject
     */
    'dropbox'?: IDropBox;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'emergencyContactAndPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'endTime'?: string;
    /**
     * 
     * @type {Timestamp}
     * @memberof IProject
     */
    'expiryDate'?: Timestamp | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof IProject
     */
    'externalApiMappings'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'fileNameActionsInCronJob'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'generatorMailingAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'generatorPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'generatorSiteAddress'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof IProject
     */
    'geo'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof IProject
     */
    'hasLocations'?: boolean;
    /**
     * 
     * @type {IProjectId}
     * @memberof IProject
     */
    'id'?: IProjectId;
    /**
     * 
     * @type {boolean}
     * @memberof IProject
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IProject
     */
    'isArchived': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IProject
     */
    'isActiveCustomApi'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IProject
     */
    'locationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'manifestTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'name'?: string;
    /**
     * 
     * @type {IJoinCompany}
     * @memberof IProject
     */
    'generatorCompany'?: IJoinCompany | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'profileNr'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'projectNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'receiptTemplateId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'regionId'?: string;
    /**
     * 
     * @type {IJoinCompany}
     * @memberof IProject
     */
    'scaleCompany'?: IJoinCompany;
    /**
     * 
     * @type {Array<IJoinCompany>}
     * @memberof IProject
     */
    'scales'?: Array<IJoinCompany>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IProject
     */
    'scalesIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'startTime'?: string;
    /**
     * 
     * @type {ITicketSummary}
     * @memberof IProject
     */
    'ticketSummary'?: ITicketSummary;
    /**
     * 
     * @type {string}
     * @memberof IProject
     */
    'timeZone'?: string;
    /**
     * 
     * @type {Array<IJoinCompany>}
     * @memberof IProject
     */
    'transporterCompanies'?: Array<IJoinCompany>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IProject
     */
    'transporterCompaniesIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IProject
     */
    'viewers'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IProject
     */
    'viewersCompaniesIds'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof IProject
     */
    'enabledTurnByTurnNavigation'?: boolean;
    /**
     * 
     * @type {IProjectProjectContacts}
     * @memberof IProject
     */
    'projectContacts'?: IProjectProjectContacts;
}
/**
 * 
 * @export
 * @interface IProjectBase
 */
export interface IProjectBase {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof IProjectBase
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IProjectBase
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectBase
     */
    'timeZone': string;
    /**
     * 
     * @type {string}
     * @memberof IProjectBase
     */
    'profileNr': string;
    /**
     * 
     * @type {boolean}
     * @memberof IProjectBase
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IProjectBase
     */
    'isArchived': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof IProjectBase
     */
    'companiesIds': Array<string>;
    /**
     * 
     * @type {IContractorCompany}
     * @memberof IProjectBase
     */
    'contractorCompany': IContractorCompany;
    /**
     * 
     * @type {IGeneratorCompany}
     * @memberof IProjectBase
     */
    'generatorCompany': IGeneratorCompany;
    /**
     * 
     * @type {Array<ITransporterCompany>}
     * @memberof IProjectBase
     */
    'transporterCompanies': Array<ITransporterCompany>;
    /**
     * 
     * @type {IScaleCompany}
     * @memberof IProjectBase
     */
    'scaleCompany': IScaleCompany;
    /**
     * 
     * @type {Array<IScale>}
     * @memberof IProjectBase
     */
    'scales': Array<IScale>;
    /**
     * 
     * @type {string}
     * @memberof IProjectBase
     */
    'regionId': string;
    /**
     * 
     * @type {string}
     * @memberof IProjectBase
     */
    'manifestTemplateId': string;
    /**
     * 
     * @type {string}
     * @memberof IProjectBase
     */
    'receiptTemplateId': string;
    /**
     * 
     * @type {string}
     * @memberof IProjectBase
     */
    'fileNameActionsInCronJob'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectBase
     */
    'descriptionOfWasteMaterials'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectBase
     */
    'generatorMailingAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectBase
     */
    'generatorPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectBase
     */
    'billTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectBase
     */
    'additionalDescriptions'?: string;
}
/**
 * 
 * @export
 * @interface IProjectCompanyCollaborator
 */
export interface IProjectCompanyCollaborator {
    /**
     * 
     * @type {number}
     * @memberof IProjectCompanyCollaborator
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IProjectCompanyCollaborator
     */
    'civilCompanyId'?: string;
    /**
     * 
     * @type {number}
     * @memberof IProjectCompanyCollaborator
     */
    'civilProjectId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IProjectCompanyCollaborator
     */
    'truckingCompanyId'?: string;
    /**
     * 
     * @type {IInvoiceInvoiceDate}
     * @memberof IProjectCompanyCollaborator
     */
    'dateCreated'?: IInvoiceInvoiceDate;
    /**
     * 
     * @type {string}
     * @memberof IProjectCompanyCollaborator
     */
    'createdBy'?: string;
}
/**
 * 
 * @export
 * @interface IProjectId
 */
export interface IProjectId {
}
/**
 * 
 * @export
 * @interface IProjectMaterial
 */
export interface IProjectMaterial {
    /**
     * 
     * @type {number}
     * @memberof IProjectMaterial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof IProjectMaterial
     */
    'projectId': number;
    /**
     * 
     * @type {number}
     * @memberof IProjectMaterial
     */
    'materialId': number | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectMaterial
     */
    'sourceSupplierId': string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectMaterial
     */
    'profileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectMaterial
     */
    'profileType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IProjectMaterial
     */
    'testFrequency'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectMaterial
     */
    'units'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IProjectMaterial
     */
    'splitCost'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IProjectMaterial
     */
    'transportCost'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IProjectMaterial
     */
    'materialCost'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IProjectMaterial
     */
    'totalCost': number | null;
    /**
     * 
     * @type {IProjectMaterialDateActive}
     * @memberof IProjectMaterial
     */
    'dateActive'?: IProjectMaterialDateActive | null;
    /**
     * 
     * @type {IProjectMaterialDateActive}
     * @memberof IProjectMaterial
     */
    'dateExpired'?: IProjectMaterialDateActive | null;
    /**
     * 
     * @type {number}
     * @memberof IProjectMaterial
     */
    'markup'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IProjectMaterial
     */
    'totalPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectMaterial
     */
    'supplierMaterialId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IProjectMaterial
     */
    'requireGeneratorSignature'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectMaterial
     */
    'material'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectMaterial
     */
    'materialName': string;
    /**
     * 
     * @type {string}
     * @memberof IProjectMaterial
     */
    'sourceSupplier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectMaterial
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof IProjectMaterial
     */
    'dateActiveString'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectMaterial
     */
    'dateExpiredString'?: string;
    /**
     * 
     * @type {IProjectMaterialDateActive}
     * @memberof IProjectMaterial
     */
    'dateActiveValidation'?: IProjectMaterialDateActive | null;
    /**
     * 
     * @type {string}
     * @memberof IProjectMaterial
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof IProjectMaterial
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface IProjectMaterialDateActive
 */
export interface IProjectMaterialDateActive {
}
/**
 * 
 * @export
 * @interface IProjectProjectContacts
 */
export interface IProjectProjectContacts {
    /**
     * 
     * @type {Array<string>}
     * @memberof IProjectProjectContacts
     */
    'transporterUserIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IProjectProjectContacts
     */
    'contractorUserIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IProjectProjectContacts
     */
    'generatorUserIds': Array<string>;
}
/**
 * 
 * @export
 * @interface IProjectTask
 */
export interface IProjectTask {
    /**
     * 
     * @type {number}
     * @memberof IProjectTask
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof IProjectTask
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof IProjectTask
     */
    'activityId'?: number;
    /**
     * 
     * @type {number}
     * @memberof IProjectTask
     */
    'materialId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IProjectTask
     */
    'sourceSupplierId'?: string;
    /**
     * 
     * @type {number}
     * @memberof IProjectTask
     */
    'dailyTarget'?: number;
    /**
     * 
     * @type {number}
     * @memberof IProjectTask
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof IProjectTask
     */
    'autoTicketNumber'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IProjectTask
     */
    'activity'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectTask
     */
    'material'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectTask
     */
    'sourceSupplier'?: string;
    /**
     * 
     * @type {IProjectTaskProjectMaterialId}
     * @memberof IProjectTask
     */
    'projectMaterialId'?: IProjectTaskProjectMaterialId;
    /**
     * 
     * @type {boolean}
     * @memberof IProjectTask
     */
    'deleted': boolean;
}
/**
 * 
 * @export
 * @interface IProjectTaskProjectMaterialId
 */
export interface IProjectTaskProjectMaterialId {
}
/**
 * 
 * @export
 * @interface IProjectTemplate
 */
export interface IProjectTemplate {
    /**
     * 
     * @type {number}
     * @memberof IProjectTemplate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IProjectTemplate
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof IProjectTemplate
     */
    'projectId': number;
    /**
     * 
     * @type {string}
     * @memberof IProjectTemplate
     */
    'companyId': string;
    /**
     * 
     * @type {boolean}
     * @memberof IProjectTemplate
     */
    'deleted': boolean;
    /**
     * 
     * @type {IInvoiceInvoiceDate}
     * @memberof IProjectTemplate
     */
    'dateCreated': IInvoiceInvoiceDate;
    /**
     * 
     * @type {string}
     * @memberof IProjectTemplate
     */
    'createdByUserId': string;
}
/**
 * 
 * @export
 * @interface IProjectTemplateDetail
 */
export interface IProjectTemplateDetail {
    /**
     * 
     * @type {number}
     * @memberof IProjectTemplateDetail
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof IProjectTemplateDetail
     */
    'civilProjectTemplateId': number;
    /**
     * 
     * @type {string}
     * @memberof IProjectTemplateDetail
     */
    'workCategoryName': string;
    /**
     * 
     * @type {string}
     * @memberof IProjectTemplateDetail
     */
    'activityName': string;
    /**
     * 
     * @type {string}
     * @memberof IProjectTemplateDetail
     */
    'materialName': string;
    /**
     * 
     * @type {string}
     * @memberof IProjectTemplateDetail
     */
    'sourceSupplierId': string;
    /**
     * 
     * @type {string}
     * @memberof IProjectTemplateDetail
     */
    'sourceSupplierName': string;
    /**
     * 
     * @type {number}
     * @memberof IProjectTemplateDetail
     */
    'totalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof IProjectTemplateDetail
     */
    'dailyTarget': number;
    /**
     * 
     * @type {boolean}
     * @memberof IProjectTemplateDetail
     */
    'autoTicketNumber': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IProjectTemplateDetail
     */
    'deleted': boolean;
    /**
     * 
     * @type {number}
     * @memberof IProjectTemplateDetail
     */
    'totalCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof IProjectTemplateDetail
     */
    'transportCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof IProjectTemplateDetail
     */
    'materialCost'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof IProjectTemplateDetail
     */
    'splitCost': boolean;
    /**
     * 
     * @type {string}
     * @memberof IProjectTemplateDetail
     */
    'profileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IProjectTemplateDetail
     */
    'profileType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IProjectTemplateDetail
     */
    'workCategoryExists'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IProjectTemplateDetail
     */
    'activityExists'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IProjectTemplateDetail
     */
    'materialExists'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IProjectTemplateDetail
     */
    'sourceSupplierExists'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IProjectTemplateDetail
     */
    'projectMaterialExists'?: boolean;
}
/**
 * 
 * @export
 * @interface IProjectUserCollaborator
 */
export interface IProjectUserCollaborator {
    /**
     * 
     * @type {number}
     * @memberof IProjectUserCollaborator
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof IProjectUserCollaborator
     */
    'civilProjectId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IProjectUserCollaborator
     */
    'userId'?: string;
    /**
     * 
     * @type {IInvoiceInvoiceDate}
     * @memberof IProjectUserCollaborator
     */
    'dateCreated'?: IInvoiceInvoiceDate;
    /**
     * 
     * @type {string}
     * @memberof IProjectUserCollaborator
     */
    'createdBy'?: string;
}
/**
 * 
 * @export
 * @interface IRegion
 */
export interface IRegion {
    /**
     * 
     * @type {string}
     * @memberof IRegion
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IRegion
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface IRegions
 */
export interface IRegions {
    /**
     * 
     * @type {string}
     * @memberof IRegions
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IRegions
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface IRequest
 */
export interface IRequest {
    /**
     * 
     * @type {number}
     * @memberof IRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IRequest
     */
    'originatingCompanyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IRequest
     */
    'targetCompanyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IRequest
     */
    'transporter'?: string;
    /**
     * 
     * @type {IProjectId}
     * @memberof IRequest
     */
    'projectId'?: IProjectId;
    /**
     * 
     * @type {number}
     * @memberof IRequest
     */
    'materialId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IRequest
     */
    'material'?: string;
    /**
     * 
     * @type {IProjectTaskProjectMaterialId}
     * @memberof IRequest
     */
    'quantity'?: IProjectTaskProjectMaterialId;
    /**
     * 
     * @type {string}
     * @memberof IRequest
     */
    'unit'?: string;
    /**
     * 
     * @type {string}
     * @memberof IRequest
     */
    'type'?: string;
    /**
     * 
     * @type {IRequestRequestedDate}
     * @memberof IRequest
     */
    'requestedDate'?: IRequestRequestedDate;
    /**
     * 
     * @type {IRequestRequestedDate}
     * @memberof IRequest
     */
    'createdDate'?: IRequestRequestedDate;
    /**
     * 
     * @type {string}
     * @memberof IRequest
     */
    'createdByUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IRequest
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof IRequest
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof IRequest
     */
    'sentByEmail'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IRequest
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface IRequestRequestedDate
 */
export interface IRequestRequestedDate {
}
/**
 * 
 * @export
 * @interface IScale
 */
export interface IScale {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof IScale
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IScale
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IScale
     */
    'address': string;
    /**
     * 
     * @type {IState}
     * @memberof IScale
     */
    'state': IState;
    /**
     * 
     * @type {Array<ILane>}
     * @memberof IScale
     */
    'lanes': Array<ILane>;
}
/**
 * 
 * @export
 * @interface IScaleCompany
 */
export interface IScaleCompany {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof IScaleCompany
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IScaleCompany
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof IScaleCompany
     */
    'idCompaniesCustomData'?: number;
    /**
     * 
     * @type {string}
     * @memberof IScaleCompany
     */
    'companyIdCompaniesCustomData'?: string;
    /**
     * 
     * @type {string}
     * @memberof IScaleCompany
     */
    'nameCompaniesCustomData'?: string;
    /**
     * 
     * @type {number}
     * @memberof IScaleCompany
     */
    'valueInteger'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof IScaleCompany
     */
    'valueArrayIntegers'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof IScaleCompany
     */
    'valueReal'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof IScaleCompany
     */
    'valueArrayReals'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof IScaleCompany
     */
    'valueString'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IScaleCompany
     */
    'valueArrayStrings'?: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof IScaleCompany
     */
    'valueJson'?: any;
    /**
     * 
     * @type {Array<any>}
     * @memberof IScaleCompany
     */
    'valueArrayJsons'?: Array<any>;
    /**
     * 
     * @type {boolean}
     * @memberof IScaleCompany
     */
    'deletedCompaniesCustomData'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof IScaleCompany
     */
    'externalApiPropertyNamesForMappingsInProjects'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IScaleCompany
     */
    'fileNameActionsInCronJob'?: string;
    /**
     * 
     * @type {Array<IRegion>}
     * @memberof IScaleCompany
     */
    'regions'?: Array<IRegion>;
    /**
     * 
     * @type {string}
     * @memberof IScaleCompany
     */
    'companyLogo'?: string;
}
/**
 * 
 * @export
 * @interface IState
 */
export interface IState {
    /**
     * 
     * @type {string}
     * @memberof IState
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof IState
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ISubMaterial
 */
export interface ISubMaterial {
    /**
     * 
     * @type {number}
     * @memberof ISubMaterial
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ISubMaterial
     */
    'civil_ticket_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ISubMaterial
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ISubMaterial
     */
    'name'?: string;
    /**
     * 
     * @type {IProjectTaskProjectMaterialId}
     * @memberof ISubMaterial
     */
    'price_per_unit'?: IProjectTaskProjectMaterialId;
    /**
     * 
     * @type {number}
     * @memberof ISubMaterial
     */
    'project_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ISubMaterial
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof ISubMaterial
     */
    'start_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ISubMaterial
     */
    'sub_material_id'?: number;
}
/**
 * 
 * @export
 * @interface ITicket
 */
export interface ITicket {
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'ticketId'?: number;
    /**
     * 
     * @type {IProjectId}
     * @memberof ITicket
     */
    'projectId'?: IProjectId;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'companyId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'projectMaterialId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'activityId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'materialId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'material'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'sourceSupplierId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'sourceSupplier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'transporterId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'projectTaskId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'destination'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'truckNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'trailerNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'equipment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'grossQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'tareQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'quantity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'volume'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'ticketNumber'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'brokerCarrier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'createdByCompanyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'createdByUserId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'sourceSupplierInvoiceId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'transporterInvoiceId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'supplierInvoiceStatus'?: ITicketSupplierInvoiceStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'transporterInvoiceStatus'?: ITicketTransporterInvoiceStatusEnum;
    /**
     * 
     * @type {IInvoiceInvoiceDate}
     * @memberof ITicket
     */
    'dateCreated'?: IInvoiceInvoiceDate;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'gateUserSignature'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'driverSignature'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'generatorSignature'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'generatorName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ITicket
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'activity'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'manifestId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'manifestNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ITicket
     */
    'needsTicketNumber'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'ticketImage'?: string | null;
    /**
     * 
     * @type {IProjectTaskProjectMaterialId}
     * @memberof ITicket
     */
    'price'?: IProjectTaskProjectMaterialId;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'materialName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'transporter'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'totalPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'wmWeighTicketNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ITicket
     */
    'isAccepted': boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'civilLocationId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'pmMaterialId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'driverName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'pitTicketNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'truckingTicketId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'note'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'supplierPitTicketId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'supplierInvoiceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'transporterInvoiceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'projectName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITicket
     */
    'locationString'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ITicket
     */
    'shouldTest'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'cost'?: number;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'totalCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'count'?: number;
    /**
     * 
     * @type {Array<ISubMaterial>}
     * @memberof ITicket
     */
    'subMaterials'?: Array<ISubMaterial>;
    /**
     * 
     * @type {number}
     * @memberof ITicket
     */
    'subMaterialsCost'?: number;
}

export const ITicketSupplierInvoiceStatusEnum = {
    Reconciled: 'reconciled',
    Rejected: 'rejected',
    Issue: 'issue'
} as const;

export type ITicketSupplierInvoiceStatusEnum = typeof ITicketSupplierInvoiceStatusEnum[keyof typeof ITicketSupplierInvoiceStatusEnum];
export const ITicketTransporterInvoiceStatusEnum = {
    Reconciled: 'reconciled',
    Rejected: 'rejected',
    Issue: 'issue'
} as const;

export type ITicketTransporterInvoiceStatusEnum = typeof ITicketTransporterInvoiceStatusEnum[keyof typeof ITicketTransporterInvoiceStatusEnum];

/**
 * 
 * @export
 * @interface ITicketMessage
 */
export interface ITicketMessage {
    /**
     * 
     * @type {number}
     * @memberof ITicketMessage
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ITicketMessage
     */
    'civilTicketId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ITicketMessage
     */
    'createdBy'?: string;
    /**
     * 
     * @type {IInvoiceInvoiceDate}
     * @memberof ITicketMessage
     */
    'dateUpdated'?: IInvoiceInvoiceDate;
    /**
     * 
     * @type {IInvoiceInvoiceDate}
     * @memberof ITicketMessage
     */
    'dateCreated'?: IInvoiceInvoiceDate;
    /**
     * 
     * @type {string}
     * @memberof ITicketMessage
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITicketMessage
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITicketMessage
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface ITicketSummary
 */
export interface ITicketSummary {
    /**
     * 
     * @type {number}
     * @memberof ITicketSummary
     */
    'rowNumber': number;
    /**
     * 
     * @type {string}
     * @memberof ITicketSummary
     */
    'companyId': string;
    /**
     * 
     * @type {number}
     * @memberof ITicketSummary
     */
    'timeRemaining': number;
    /**
     * 
     * @type {number}
     * @memberof ITicketSummary
     */
    'timePercentRemaining': number;
    /**
     * 
     * @type {number}
     * @memberof ITicketSummary
     */
    'ticketCount': number;
    /**
     * 
     * @type {number}
     * @memberof ITicketSummary
     */
    'avgLoad': number;
    /**
     * 
     * @type {number}
     * @memberof ITicketSummary
     */
    'loadCompleted': number;
    /**
     * 
     * @type {number}
     * @memberof ITicketSummary
     */
    'target': number;
    /**
     * 
     * @type {number}
     * @memberof ITicketSummary
     */
    'percentCompleted': number;
    /**
     * 
     * @type {string}
     * @memberof ITicketSummary
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof ITicketSummary
     */
    'endTime': string;
    /**
     * 
     * @type {number}
     * @memberof ITicketSummary
     */
    'duration': number;
    /**
     * 
     * @type {string}
     * @memberof ITicketSummary
     */
    'timezone': string;
    /**
     * 
     * @type {number}
     * @memberof ITicketSummary
     */
    'projectId': number;
    /**
     * 
     * @type {string}
     * @memberof ITicketSummary
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof ITicketSummary
     */
    'projectNumber': string;
    /**
     * 
     * @type {number}
     * @memberof ITicketSummary
     */
    'transporterIssue': number;
    /**
     * 
     * @type {number}
     * @memberof ITicketSummary
     */
    'supplierIssue': number;
    /**
     * 
     * @type {number}
     * @memberof ITicketSummary
     */
    'transporterOpen': number;
    /**
     * 
     * @type {number}
     * @memberof ITicketSummary
     */
    'supplierOpen': number;
    /**
     * 
     * @type {number}
     * @memberof ITicketSummary
     */
    'transporterTotal': number;
    /**
     * 
     * @type {number}
     * @memberof ITicketSummary
     */
    'supplierTotal': number;
}
/**
 * 
 * @export
 * @interface ITransporterCompany
 */
export interface ITransporterCompany {
    /**
     * 
     * @type {string}
     * @memberof ITransporterCompany
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ITransporterCompany
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ITransporterCompany
     */
    'idCompaniesCustomData'?: number;
    /**
     * 
     * @type {string}
     * @memberof ITransporterCompany
     */
    'companyIdCompaniesCustomData'?: string;
    /**
     * 
     * @type {string}
     * @memberof ITransporterCompany
     */
    'nameCompaniesCustomData'?: string;
    /**
     * 
     * @type {number}
     * @memberof ITransporterCompany
     */
    'valueInteger'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ITransporterCompany
     */
    'valueArrayIntegers'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ITransporterCompany
     */
    'valueReal'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ITransporterCompany
     */
    'valueArrayReals'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ITransporterCompany
     */
    'valueString'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ITransporterCompany
     */
    'valueArrayStrings'?: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof ITransporterCompany
     */
    'valueJson'?: any;
    /**
     * 
     * @type {Array<any>}
     * @memberof ITransporterCompany
     */
    'valueArrayJsons'?: Array<any>;
    /**
     * 
     * @type {boolean}
     * @memberof ITransporterCompany
     */
    'deletedCompaniesCustomData'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITransporterCompany
     */
    'canViewAllManifests': boolean;
}
/**
 * 
 * @export
 * @interface IUpsertCompanyApiMapping
 */
export interface IUpsertCompanyApiMapping {
    /**
     * 
     * @type {string}
     * @memberof IUpsertCompanyApiMapping
     */
    'company_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof IUpsertCompanyApiMapping
     */
    'api_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof IUpsertCompanyApiMapping
     */
    'api': string | null;
    /**
     * 
     * @type {string}
     * @memberof IUpsertCompanyApiMapping
     */
    'parent_contractor_company_id': string | null;
    /**
     * 
     * @type {number}
     * @memberof IUpsertCompanyApiMapping
     */
    'id'?: number;
}
/**
 * Model invited_users
 * @export
 * @interface InvitedUsers
 */
export interface InvitedUsers {
    /**
     * 
     * @type {string}
     * @memberof InvitedUsers
     */
    'last_email_date': string | null;
    /**
     * 
     * @type {string}
     * @memberof InvitedUsers
     */
    'created_date': string;
    /**
     * 
     * @type {Array<UserRoles>}
     * @memberof InvitedUsers
     */
    'allowed_roles': Array<UserRoles>;
    /**
     * 
     * @type {UserRoles}
     * @memberof InvitedUsers
     */
    'role': UserRoles;
    /**
     * 
     * @type {string}
     * @memberof InvitedUsers
     */
    'phone': string | null;
    /**
     * 
     * @type {string}
     * @memberof InvitedUsers
     */
    'auth0_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof InvitedUsers
     */
    'company_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof InvitedUsers
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof InvitedUsers
     */
    'id': number;
}


/**
 * 
 * @export
 * @interface Lane
 */
export interface Lane {
    /**
     * 
     * @type {string}
     * @memberof Lane
     */
    'color': string;
    /**
     * 
     * @type {string}
     * @memberof Lane
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Lane
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof Lane
     */
    'type': LaneTypeEnum;
}

export const LaneTypeEnum = {
    Lane: 'lane',
    Scale: 'scale'
} as const;

export type LaneTypeEnum = typeof LaneTypeEnum[keyof typeof LaneTypeEnum];

/**
 * 
 * @export
 * @interface List200ResponseInner
 */
export interface List200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof List200ResponseInner
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof List200ResponseInner
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof List200ResponseInner
     */
    'firstName': string;
}
/**
 * 
 * @export
 * @interface Manifest
 */
export interface Manifest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof Manifest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Manifest
     */
    'batchId': string | null;
    /**
     * 
     * @type {string}
     * @memberof Manifest
     */
    'weight'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Manifest
     */
    'brokerLoadId'?: string | null;
    /**
     * 
     * @type {Array<CustomInput>}
     * @memberof Manifest
     */
    'customInputs': Array<CustomInput> | null;
    /**
     * 
     * @type {Dropbox}
     * @memberof Manifest
     */
    'dropbox': Dropbox | null;
    /**
     * 
     * @type {string}
     * @memberof Manifest
     */
    'createdByUserId': string;
    /**
     * 
     * @type {FirestoreTimestamp}
     * @memberof Manifest
     */
    'createdAt': FirestoreTimestamp | null;
    /**
     * 
     * @type {FirestoreTimestamp}
     * @memberof Manifest
     */
    'lastUsedAt': FirestoreTimestamp | null;
    /**
     * 
     * @type {boolean}
     * @memberof Manifest
     */
    'isComplete': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Manifest
     */
    'isV1ApiCivilAndTrucking': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Manifest
     */
    'isVoid': boolean;
    /**
     * 
     * @type {number}
     * @memberof Manifest
     */
    'notesCount': number;
    /**
     * 
     * @type {IProjectId}
     * @memberof Manifest
     */
    'number': IProjectId;
    /**
     * 
     * @type {Project}
     * @memberof Manifest
     */
    'project': Project | null;
    /**
     * 
     * @type {SignatureDriver}
     * @memberof Manifest
     */
    'signatureDriver': SignatureDriver;
    /**
     * 
     * @type {SignatureGenerator}
     * @memberof Manifest
     */
    'signatureGenerator': SignatureGenerator;
    /**
     * 
     * @type {SignatureScale}
     * @memberof Manifest
     */
    'signatureScale': SignatureScale;
    /**
     * 
     * @type {Template}
     * @memberof Manifest
     */
    'template': Template;
    /**
     * 
     * @type {any}
     * @memberof Manifest
     */
    'receipt'?: any;
    /**
     * 
     * @type {FirestoreTimestamp}
     * @memberof Manifest
     */
    'scaleInTime'?: FirestoreTimestamp | null;
    /**
     * 
     * @type {string}
     * @memberof Manifest
     */
    'v1ApiCompletedCallBackUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface ManifestReceiptTemplateContext
 */
export interface ManifestReceiptTemplateContext {
    /**
     * 
     * @type {string}
     * @memberof ManifestReceiptTemplateContext
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ManifestReceiptTemplateContext
     */
    'batchId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ManifestReceiptTemplateContext
     */
    'weight'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManifestReceiptTemplateContext
     */
    'brokerLoadId'?: string | null;
    /**
     * 
     * @type {Array<CustomInput>}
     * @memberof ManifestReceiptTemplateContext
     */
    'customInputs': Array<CustomInput> | null;
    /**
     * 
     * @type {Dropbox}
     * @memberof ManifestReceiptTemplateContext
     */
    'dropbox': Dropbox | null;
    /**
     * 
     * @type {string}
     * @memberof ManifestReceiptTemplateContext
     */
    'createdByUserId': string;
    /**
     * 
     * @type {FirestoreTimestamp}
     * @memberof ManifestReceiptTemplateContext
     */
    'createdAt': FirestoreTimestamp | null;
    /**
     * 
     * @type {FirestoreTimestamp}
     * @memberof ManifestReceiptTemplateContext
     */
    'lastUsedAt': FirestoreTimestamp | null;
    /**
     * 
     * @type {boolean}
     * @memberof ManifestReceiptTemplateContext
     */
    'isComplete': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManifestReceiptTemplateContext
     */
    'isV1ApiCivilAndTrucking': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManifestReceiptTemplateContext
     */
    'isVoid': boolean;
    /**
     * 
     * @type {number}
     * @memberof ManifestReceiptTemplateContext
     */
    'notesCount': number;
    /**
     * 
     * @type {IProjectId}
     * @memberof ManifestReceiptTemplateContext
     */
    'number': IProjectId;
    /**
     * 
     * @type {ProjectWithMappedExpiryDate}
     * @memberof ManifestReceiptTemplateContext
     */
    'project': ProjectWithMappedExpiryDate;
    /**
     * 
     * @type {SignatureDriver}
     * @memberof ManifestReceiptTemplateContext
     */
    'signatureDriver': SignatureDriver;
    /**
     * 
     * @type {SignatureGenerator}
     * @memberof ManifestReceiptTemplateContext
     */
    'signatureGenerator': SignatureGenerator;
    /**
     * 
     * @type {SignatureScale}
     * @memberof ManifestReceiptTemplateContext
     */
    'signatureScale': SignatureScale;
    /**
     * 
     * @type {Template}
     * @memberof ManifestReceiptTemplateContext
     */
    'template': Template;
    /**
     * 
     * @type {any}
     * @memberof ManifestReceiptTemplateContext
     */
    'receipt'?: any;
    /**
     * 
     * @type {FirestoreTimestamp}
     * @memberof ManifestReceiptTemplateContext
     */
    'scaleInTime'?: FirestoreTimestamp | null;
    /**
     * 
     * @type {string}
     * @memberof ManifestReceiptTemplateContext
     */
    'v1ApiCompletedCallBackUrl'?: string | null;
    /**
     * 
     * @type {IJoinCompany}
     * @memberof ManifestReceiptTemplateContext
     */
    'contractorCompany': IJoinCompany;
}
/**
 * 
 * @export
 * @interface ManifestReceiptTemplateContextAllOf
 */
export interface ManifestReceiptTemplateContextAllOf {
    /**
     * 
     * @type {ProjectWithMappedExpiryDate}
     * @memberof ManifestReceiptTemplateContextAllOf
     */
    'project': ProjectWithMappedExpiryDate;
    /**
     * 
     * @type {IJoinCompany}
     * @memberof ManifestReceiptTemplateContextAllOf
     */
    'contractorCompany': IJoinCompany;
}
/**
 * 
 * @export
 * @interface ManifestTemplateContext
 */
export interface ManifestTemplateContext {
    /**
     * 
     * @type {string}
     * @memberof ManifestTemplateContext
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ManifestTemplateContext
     */
    'batchId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ManifestTemplateContext
     */
    'weight'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManifestTemplateContext
     */
    'brokerLoadId'?: string | null;
    /**
     * 
     * @type {Array<CustomInput>}
     * @memberof ManifestTemplateContext
     */
    'customInputs': Array<CustomInput> | null;
    /**
     * 
     * @type {Dropbox}
     * @memberof ManifestTemplateContext
     */
    'dropbox': Dropbox | null;
    /**
     * 
     * @type {string}
     * @memberof ManifestTemplateContext
     */
    'createdByUserId': string;
    /**
     * 
     * @type {FirestoreTimestamp}
     * @memberof ManifestTemplateContext
     */
    'createdAt': FirestoreTimestamp | null;
    /**
     * 
     * @type {FirestoreTimestamp}
     * @memberof ManifestTemplateContext
     */
    'lastUsedAt': FirestoreTimestamp | null;
    /**
     * 
     * @type {boolean}
     * @memberof ManifestTemplateContext
     */
    'isComplete': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManifestTemplateContext
     */
    'isV1ApiCivilAndTrucking': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManifestTemplateContext
     */
    'isVoid': boolean;
    /**
     * 
     * @type {number}
     * @memberof ManifestTemplateContext
     */
    'notesCount': number;
    /**
     * 
     * @type {IProjectId}
     * @memberof ManifestTemplateContext
     */
    'number': IProjectId;
    /**
     * 
     * @type {ProjectWithMappedExpiryDate}
     * @memberof ManifestTemplateContext
     */
    'project': ProjectWithMappedExpiryDate;
    /**
     * 
     * @type {SignatureDriver}
     * @memberof ManifestTemplateContext
     */
    'signatureDriver': SignatureDriver;
    /**
     * 
     * @type {SignatureGenerator}
     * @memberof ManifestTemplateContext
     */
    'signatureGenerator': SignatureGenerator;
    /**
     * 
     * @type {SignatureScale}
     * @memberof ManifestTemplateContext
     */
    'signatureScale': SignatureScale;
    /**
     * 
     * @type {Template}
     * @memberof ManifestTemplateContext
     */
    'template': Template;
    /**
     * 
     * @type {any}
     * @memberof ManifestTemplateContext
     */
    'receipt'?: any;
    /**
     * 
     * @type {FirestoreTimestamp}
     * @memberof ManifestTemplateContext
     */
    'scaleInTime'?: FirestoreTimestamp | null;
    /**
     * 
     * @type {string}
     * @memberof ManifestTemplateContext
     */
    'v1ApiCompletedCallBackUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface ManifestTemplateContextAllOf
 */
export interface ManifestTemplateContextAllOf {
    /**
     * 
     * @type {ProjectWithMappedExpiryDate}
     * @memberof ManifestTemplateContextAllOf
     */
    'project': ProjectWithMappedExpiryDate;
}
/**
 * 
 * @export
 * @interface MultipleManifestDownloadRequest200Response
 */
export interface MultipleManifestDownloadRequest200Response {
    /**
     * 
     * @type {any}
     * @memberof MultipleManifestDownloadRequest200Response
     */
    'documentIds': any;
    /**
     * 
     * @type {any}
     * @memberof MultipleManifestDownloadRequest200Response
     */
    'documentType': any;
    /**
     * 
     * @type {any}
     * @memberof MultipleManifestDownloadRequest200Response
     */
    'count': any;
    /**
     * 
     * @type {string}
     * @memberof MultipleManifestDownloadRequest200Response
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface MultipleManifestDownloadRequest200ResponseAnyOf
 */
export interface MultipleManifestDownloadRequest200ResponseAnyOf {
    /**
     * 
     * @type {Array<any>}
     * @memberof MultipleManifestDownloadRequest200ResponseAnyOf
     */
    'documentIds': Array<any>;
    /**
     * 
     * @type {string}
     * @memberof MultipleManifestDownloadRequest200ResponseAnyOf
     */
    'documentType': string;
    /**
     * 
     * @type {number}
     * @memberof MultipleManifestDownloadRequest200ResponseAnyOf
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof MultipleManifestDownloadRequest200ResponseAnyOf
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface MultipleManifestDownloadRequest200ResponseAnyOf1
 */
export interface MultipleManifestDownloadRequest200ResponseAnyOf1 {
    /**
     * 
     * @type {any}
     * @memberof MultipleManifestDownloadRequest200ResponseAnyOf1
     */
    'documentIds'?: any;
    /**
     * 
     * @type {any}
     * @memberof MultipleManifestDownloadRequest200ResponseAnyOf1
     */
    'documentType'?: any;
    /**
     * 
     * @type {any}
     * @memberof MultipleManifestDownloadRequest200ResponseAnyOf1
     */
    'count'?: any;
    /**
     * 
     * @type {string}
     * @memberof MultipleManifestDownloadRequest200ResponseAnyOf1
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface NameValue
 */
export interface NameValue {
    /**
     * 
     * @type {string}
     * @memberof NameValue
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof NameValue
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface NewLoad
 */
export interface NewLoad {
    /**
     * 
     * @type {string}
     * @memberof NewLoad
     */
    'manifestProjectID': string;
    /**
     * 
     * @type {string}
     * @memberof NewLoad
     */
    'loadID': string;
    /**
     * 
     * @type {string}
     * @memberof NewLoad
     */
    'dispatchID': string;
    /**
     * 
     * @type {string}
     * @memberof NewLoad
     */
    'transporterID': string;
    /**
     * 
     * @type {string}
     * @memberof NewLoad
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof NewLoad
     */
    'contractorName': string;
    /**
     * 
     * @type {string}
     * @memberof NewLoad
     */
    'customerNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLoad
     */
    'lotNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLoad
     */
    'poNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLoad
     */
    'material': string;
    /**
     * 
     * @type {string}
     * @memberof NewLoad
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof NewLoad
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof NewLoad
     */
    'timeZone': string;
    /**
     * 
     * @type {string}
     * @memberof NewLoad
     */
    'assignedTruckingCompanyID'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLoad
     */
    'assignedTruckNumber': string;
    /**
     * 
     * @type {string}
     * @memberof NewLoad
     */
    'specialInstr'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLoad
     */
    'callBackURL': string;
    /**
     * 
     * @type {boolean}
     * @memberof NewLoad
     */
    'canRepeat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewLoad
     */
    'lastLoad'?: boolean;
}
/**
 * 
 * @export
 * @interface OnDocumentUpdateRequest
 */
export interface OnDocumentUpdateRequest {
    /**
     * 
     * @type {any}
     * @memberof OnDocumentUpdateRequest
     */
    'after': any;
    /**
     * 
     * @type {any}
     * @memberof OnDocumentUpdateRequest
     */
    'before': any;
}
/**
 * Make all properties in T optional
 * @export
 * @interface PartialCompanies
 */
export interface PartialCompanies {
    /**
     * 
     * @type {string}
     * @memberof PartialCompanies
     */
    'id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PartialCompanies
     */
    'types'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanies
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanies
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanies
     */
    'address_one'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanies
     */
    'address_two'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanies
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanies
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanies
     */
    'postal_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanies
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanies
     */
    'phone'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PartialCompanies
     */
    'next_auto_ticket_number'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PartialCompanies
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanies
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialCompanies
     */
    'location_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanies
     */
    'parent_company_id'?: string | null;
}
/**
 * 
 * @export
 * @interface PartialCompanyInfo
 */
export interface PartialCompanyInfo {
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyInfo
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PartialCompanyInfo
     */
    'name': string;
}
/**
 * Make all properties in T optional
 * @export
 * @interface PartialPickCivilProjectApiMappingsId
 */
export interface PartialPickCivilProjectApiMappingsId {
    /**
     * 
     * @type {number}
     * @memberof PartialPickCivilProjectApiMappingsId
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PdfDocumentType = {
    Civil: 'civil',
    Trucking: 'trucking',
    Manifest: 'manifest'
} as const;

export type PdfDocumentType = typeof PdfDocumentType[keyof typeof PdfDocumentType];


/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickCivilCompanySubMaterialsExcludeKeyofcivilCompanySubMaterialsId
 */
export interface PickCivilCompanySubMaterialsExcludeKeyofcivilCompanySubMaterialsId {
    /**
     * 
     * @type {string}
     * @memberof PickCivilCompanySubMaterialsExcludeKeyofcivilCompanySubMaterialsId
     */
    'company_id': string;
    /**
     * 
     * @type {string}
     * @memberof PickCivilCompanySubMaterialsExcludeKeyofcivilCompanySubMaterialsId
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PickCivilCompanySubMaterialsExcludeKeyofcivilCompanySubMaterialsId
     */
    'description': string | null;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickCivilProjectApiMappingsExcludeKeyofcivilProjectApiMappingsId
 */
export interface PickCivilProjectApiMappingsExcludeKeyofcivilProjectApiMappingsId {
    /**
     * 
     * @type {number}
     * @memberof PickCivilProjectApiMappingsExcludeKeyofcivilProjectApiMappingsId
     */
    'project_id': number;
    /**
     * 
     * @type {string}
     * @memberof PickCivilProjectApiMappingsExcludeKeyofcivilProjectApiMappingsId
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PickCivilProjectApiMappingsExcludeKeyofcivilProjectApiMappingsId
     */
    'api_id': string;
    /**
     * 
     * @type {CivilApiProvider}
     * @memberof PickCivilProjectApiMappingsExcludeKeyofcivilProjectApiMappingsId
     */
    'api': CivilApiProvider;
    /**
     * 
     * @type {boolean}
     * @memberof PickCivilProjectApiMappingsExcludeKeyofcivilProjectApiMappingsId
     */
    'default_source_supplier_as_transporter': boolean | null;
}


/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickCivilProjectSubMaterialsExcludeKeyofcivilProjectSubMaterialsId
 */
export interface PickCivilProjectSubMaterialsExcludeKeyofcivilProjectSubMaterialsId {
    /**
     * 
     * @type {number}
     * @memberof PickCivilProjectSubMaterialsExcludeKeyofcivilProjectSubMaterialsId
     */
    'project_id': number;
    /**
     * 
     * @type {number}
     * @memberof PickCivilProjectSubMaterialsExcludeKeyofcivilProjectSubMaterialsId
     */
    'sub_material_id': number;
    /**
     * 
     * @type {string}
     * @memberof PickCivilProjectSubMaterialsExcludeKeyofcivilProjectSubMaterialsId
     */
    'start_date': string | null;
    /**
     * 
     * @type {string}
     * @memberof PickCivilProjectSubMaterialsExcludeKeyofcivilProjectSubMaterialsId
     */
    'price_per_unit': string | null;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickCreateCompanyConnectionParamsExcludeKeyofCreateCompanyConnectionParamsConnectedCompanyIdOrUserId
 */
export interface PickCreateCompanyConnectionParamsExcludeKeyofCreateCompanyConnectionParamsConnectedCompanyIdOrUserId {
    /**
     * 
     * @type {string}
     * @memberof PickCreateCompanyConnectionParamsExcludeKeyofCreateCompanyConnectionParamsConnectedCompanyIdOrUserId
     */
    'parent_company_id': string;
    /**
     * 
     * @type {string}
     * @memberof PickCreateCompanyConnectionParamsExcludeKeyofCreateCompanyConnectionParamsConnectedCompanyIdOrUserId
     */
    'custom_id': string | null;
    /**
     * 
     * @type {ConnectedCompanyType}
     * @memberof PickCreateCompanyConnectionParamsExcludeKeyofCreateCompanyConnectionParamsConnectedCompanyIdOrUserId
     */
    'company_type': ConnectedCompanyType;
}


/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickCreateCompanyConnectionParamsExcludeKeyofCreateCompanyConnectionParamsUserId
 */
export interface PickCreateCompanyConnectionParamsExcludeKeyofCreateCompanyConnectionParamsUserId {
    /**
     * 
     * @type {string}
     * @memberof PickCreateCompanyConnectionParamsExcludeKeyofCreateCompanyConnectionParamsUserId
     */
    'parent_company_id': string;
    /**
     * 
     * @type {string}
     * @memberof PickCreateCompanyConnectionParamsExcludeKeyofCreateCompanyConnectionParamsUserId
     */
    'custom_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof PickCreateCompanyConnectionParamsExcludeKeyofCreateCompanyConnectionParamsUserId
     */
    'connected_company_id': string;
    /**
     * 
     * @type {ConnectedCompanyType}
     * @memberof PickCreateCompanyConnectionParamsExcludeKeyofCreateCompanyConnectionParamsUserId
     */
    'company_type': ConnectedCompanyType;
}


/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickDownloadRequestExcludeKeyofDownloadRequestUserIdOrCompanyId
 */
export interface PickDownloadRequestExcludeKeyofDownloadRequestUserIdOrCompanyId {
    /**
     * 
     * @type {string}
     * @memberof PickDownloadRequestExcludeKeyofDownloadRequestUserIdOrCompanyId
     */
    'manifest_project_id': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PickDownloadRequestExcludeKeyofDownloadRequestUserIdOrCompanyId
     */
    'document_ids': Array<string>;
    /**
     * 
     * @type {PdfDocumentType}
     * @memberof PickDownloadRequestExcludeKeyofDownloadRequestUserIdOrCompanyId
     */
    'document_type': PdfDocumentType;
    /**
     * 
     * @type {PrismaJsonValue}
     * @memberof PickDownloadRequestExcludeKeyofDownloadRequestUserIdOrCompanyId
     */
    'parameters': PrismaJsonValue | null;
}


/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickIProjectExcludeKeyofIProjectIsArchived
 */
export interface PickIProjectExcludeKeyofIProjectIsArchived {
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'companyId'?: string;
    /**
     * 
     * @type {IProjectId}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'id'?: IProjectId;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'address'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'geo'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'locationId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'fileNameActionsInCronJob'?: string;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'profileNr'?: string;
    /**
     * 
     * @type {Array<IJoinCompany>}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'transporterCompanies'?: Array<IJoinCompany>;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'addressName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'additionalDescriptions'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'assignedDriversIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'billTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'canAssignSpecificManifestToDriver'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'companiesIds'?: Array<string>;
    /**
     * 
     * @type {IJoinCompany}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'contractorCompany'?: IJoinCompany;
    /**
     * 
     * @type {Array<string>}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'customerAdminIds'?: Array<string>;
    /**
     * 
     * @type {Array<ICustomInputDefinitions>}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'customInputsDefinitions'?: Array<ICustomInputDefinitions>;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'descriptionOfWasteMaterials'?: string;
    /**
     * 
     * @type {IDropBox}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'dropbox'?: IDropBox;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'emergencyContactAndPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'endTime'?: string;
    /**
     * 
     * @type {Timestamp}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'expiryDate'?: Timestamp | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'externalApiMappings'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'generatorMailingAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'generatorPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'generatorSiteAddress'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'hasLocations'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'isActiveCustomApi'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'manifestTemplateId'?: string;
    /**
     * 
     * @type {IJoinCompany}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'generatorCompany'?: IJoinCompany | null;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'projectNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'receiptTemplateId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'regionId'?: string;
    /**
     * 
     * @type {IJoinCompany}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'scaleCompany'?: IJoinCompany;
    /**
     * 
     * @type {Array<IJoinCompany>}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'scales'?: Array<IJoinCompany>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'scalesIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'startTime'?: string;
    /**
     * 
     * @type {ITicketSummary}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'ticketSummary'?: ITicketSummary;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'timeZone'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'transporterCompaniesIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'viewers'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'viewersCompaniesIds'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'enabledTurnByTurnNavigation'?: boolean;
    /**
     * 
     * @type {IProjectProjectContacts}
     * @memberof PickIProjectExcludeKeyofIProjectIsArchived
     */
    'projectContacts'?: IProjectProjectContacts;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickIProjectTemplateExcludeKeyofIProjectTemplateDateCreated
 */
export interface PickIProjectTemplateExcludeKeyofIProjectTemplateDateCreated {
    /**
     * 
     * @type {string}
     * @memberof PickIProjectTemplateExcludeKeyofIProjectTemplateDateCreated
     */
    'companyId': string;
    /**
     * 
     * @type {number}
     * @memberof PickIProjectTemplateExcludeKeyofIProjectTemplateDateCreated
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PickIProjectTemplateExcludeKeyofIProjectTemplateDateCreated
     */
    'deleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectTemplateExcludeKeyofIProjectTemplateDateCreated
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PickIProjectTemplateExcludeKeyofIProjectTemplateDateCreated
     */
    'projectId': number;
    /**
     * 
     * @type {string}
     * @memberof PickIProjectTemplateExcludeKeyofIProjectTemplateDateCreated
     */
    'createdByUserId': string;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickInvitedUsersExcludeKeyofinvitedUsersIdOrCreatedDateOrLastEmailDateOrAuth0Id
 */
export interface PickInvitedUsersExcludeKeyofinvitedUsersIdOrCreatedDateOrLastEmailDateOrAuth0Id {
    /**
     * 
     * @type {string}
     * @memberof PickInvitedUsersExcludeKeyofinvitedUsersIdOrCreatedDateOrLastEmailDateOrAuth0Id
     */
    'company_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof PickInvitedUsersExcludeKeyofinvitedUsersIdOrCreatedDateOrLastEmailDateOrAuth0Id
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PickInvitedUsersExcludeKeyofinvitedUsersIdOrCreatedDateOrLastEmailDateOrAuth0Id
     */
    'phone': string | null;
    /**
     * 
     * @type {UserRoles}
     * @memberof PickInvitedUsersExcludeKeyofinvitedUsersIdOrCreatedDateOrLastEmailDateOrAuth0Id
     */
    'role': UserRoles;
    /**
     * 
     * @type {Array<UserRoles>}
     * @memberof PickInvitedUsersExcludeKeyofinvitedUsersIdOrCreatedDateOrLastEmailDateOrAuth0Id
     */
    'allowed_roles': Array<UserRoles>;
}


/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId
 */
export interface PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId {
    /**
     * 
     * @type {string}
     * @memberof PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId
     */
    'company_id': string;
    /**
     * 
     * @type {string}
     * @memberof PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId
     */
    'start_time': string | null;
    /**
     * 
     * @type {string}
     * @memberof PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId
     */
    'end_time': string | null;
    /**
     * 
     * @type {string}
     * @memberof PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId
     */
    'truck_number': string;
    /**
     * 
     * @type {string}
     * @memberof PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId
     */
    'notes': string | null;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickTruckingTimesheetReferencesDispatchId
 */
export interface PickTruckingTimesheetReferencesDispatchId {
    /**
     * 
     * @type {number}
     * @memberof PickTruckingTimesheetReferencesDispatchId
     */
    'dispatch_id': number | null;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickUpdateCompanyConnectionParamsExcludeKeyofUpdateCompanyConnectionParamsUserId
 */
export interface PickUpdateCompanyConnectionParamsExcludeKeyofUpdateCompanyConnectionParamsUserId {
    /**
     * 
     * @type {number}
     * @memberof PickUpdateCompanyConnectionParamsExcludeKeyofUpdateCompanyConnectionParamsUserId
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PickUpdateCompanyConnectionParamsExcludeKeyofUpdateCompanyConnectionParamsUserId
     */
    'custom_id': string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PrismaCompaniesOrderByRelevanceFieldEnum = {
    Types: 'types',
    Id: 'id',
    Email: 'email',
    Name: 'name',
    Address: 'address',
    City: 'city',
    State: 'state',
    Phone: 'phone',
    Status: 'status',
    AddressOne: 'address_one',
    AddressTwo: 'address_two',
    PostalCode: 'postal_code',
    ParentCompanyId: 'parent_company_id'
} as const;

export type PrismaCompaniesOrderByRelevanceFieldEnum = typeof PrismaCompaniesOrderByRelevanceFieldEnum[keyof typeof PrismaCompaniesOrderByRelevanceFieldEnum];


/**
 * From https://github.com/sindresorhus/type-fest/ Matches any valid JSON value.
 * @export
 * @interface PrismaJsonValue
 */
export interface PrismaJsonValue {
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PrismaSortOrder = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type PrismaSortOrder = typeof PrismaSortOrder[keyof typeof PrismaSortOrder];


/**
 * 
 * @export
 * @interface ProfileCreator
 */
export interface ProfileCreator {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProfileCreator
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileCreator
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileCreator
     */
    'company'?: string;
}
/**
 * 
 * @export
 * @interface ProfileTypes
 */
export interface ProfileTypes {
    /**
     * 
     * @type {boolean}
     * @memberof ProfileTypes
     */
    'hide': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileTypes
     */
    'list': Array<string>;
}
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'additionalDescriptions': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Project
     */
    'assignedDriversIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'billTo': string;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    'canAssignSpecificManifestToDriver': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Project
     */
    'companiesIds': Array<string>;
    /**
     * 
     * @type {PartialCompanyInfo}
     * @memberof Project
     */
    'contractorCompany': PartialCompanyInfo;
    /**
     * 
     * @type {Array<CustomInput>}
     * @memberof Project
     */
    'customInputsDefinitions': Array<CustomInput>;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'descriptionOfWasteMaterials': string;
    /**
     * 
     * @type {ProjectDropbox}
     * @memberof Project
     */
    'dropbox': ProjectDropbox;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'fileNameActionsInCronJob': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'generatorMailingAddress': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'generatorPhone': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'manifestTemplateId': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'name': string;
    /**
     * 
     * @type {PartialCompanyInfo}
     * @memberof Project
     */
    'generatorCompany': PartialCompanyInfo;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'profileNr': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'receiptTemplateId': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'regionId': string;
    /**
     * 
     * @type {Array<ScalePartialForProject>}
     * @memberof Project
     */
    'scales': Array<ScalePartialForProject>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Project
     */
    'scalesIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'timeZone': string;
    /**
     * 
     * @type {Array<TransporterCompaniesForProject>}
     * @memberof Project
     */
    'transporterCompanies': Array<TransporterCompaniesForProject>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Project
     */
    'transporterCompaniesIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Project
     */
    'viewers': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Project
     */
    'viewersCompaniesIds': Array<string>;
    /**
     * 
     * @type {any}
     * @memberof Project
     */
    'externalApiMappings': any;
    /**
     * 
     * @type {any}
     * @memberof Project
     */
    'scaleCompany': any;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    'enabledTurnByTurnNavigation'?: boolean;
}
/**
 * 
 * @export
 * @interface ProjectDropbox
 */
export interface ProjectDropbox {
    /**
     * 
     * @type {string}
     * @memberof ProjectDropbox
     */
    'sharedLinkForManifestsPerDayPdfs': string;
}
/**
 * 
 * @export
 * @interface ProjectWithMappedExpiryDate
 */
export interface ProjectWithMappedExpiryDate {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWithMappedExpiryDate
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'addressName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'additionalDescriptions'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'address'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectWithMappedExpiryDate
     */
    'assignedDriversIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'billTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'canAssignSpecificManifestToDriver'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'companyId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectWithMappedExpiryDate
     */
    'companiesIds'?: Array<string>;
    /**
     * 
     * @type {IJoinCompany}
     * @memberof ProjectWithMappedExpiryDate
     */
    'contractorCompany'?: IJoinCompany;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectWithMappedExpiryDate
     */
    'customerAdminIds'?: Array<string>;
    /**
     * 
     * @type {Array<ICustomInputDefinitions>}
     * @memberof ProjectWithMappedExpiryDate
     */
    'customInputsDefinitions'?: Array<ICustomInputDefinitions>;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'descriptionOfWasteMaterials'?: string;
    /**
     * 
     * @type {IDropBox}
     * @memberof ProjectWithMappedExpiryDate
     */
    'dropbox'?: IDropBox;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'emergencyContactAndPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'endTime'?: string;
    /**
     * 
     * @type {Timestamp}
     * @memberof ProjectWithMappedExpiryDate
     */
    'expiryDate'?: Timestamp | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ProjectWithMappedExpiryDate
     */
    'externalApiMappings'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'fileNameActionsInCronJob'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'generatorMailingAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'generatorPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'generatorSiteAddress'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProjectWithMappedExpiryDate
     */
    'geo'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWithMappedExpiryDate
     */
    'hasLocations'?: boolean;
    /**
     * 
     * @type {IProjectId}
     * @memberof ProjectWithMappedExpiryDate
     */
    'id'?: IProjectId;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWithMappedExpiryDate
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWithMappedExpiryDate
     */
    'isArchived': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWithMappedExpiryDate
     */
    'isActiveCustomApi'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProjectWithMappedExpiryDate
     */
    'locationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'manifestTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'name'?: string;
    /**
     * 
     * @type {IJoinCompany}
     * @memberof ProjectWithMappedExpiryDate
     */
    'generatorCompany'?: IJoinCompany | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'profileNr'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'projectNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'receiptTemplateId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'regionId'?: string;
    /**
     * 
     * @type {IJoinCompany}
     * @memberof ProjectWithMappedExpiryDate
     */
    'scaleCompany'?: IJoinCompany;
    /**
     * 
     * @type {Array<IJoinCompany>}
     * @memberof ProjectWithMappedExpiryDate
     */
    'scales'?: Array<IJoinCompany>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectWithMappedExpiryDate
     */
    'scalesIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'startTime'?: string;
    /**
     * 
     * @type {ITicketSummary}
     * @memberof ProjectWithMappedExpiryDate
     */
    'ticketSummary'?: ITicketSummary;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'timeZone'?: string;
    /**
     * 
     * @type {Array<IJoinCompany>}
     * @memberof ProjectWithMappedExpiryDate
     */
    'transporterCompanies'?: Array<IJoinCompany>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectWithMappedExpiryDate
     */
    'transporterCompaniesIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectWithMappedExpiryDate
     */
    'viewers'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectWithMappedExpiryDate
     */
    'viewersCompaniesIds'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWithMappedExpiryDate
     */
    'enabledTurnByTurnNavigation'?: boolean;
    /**
     * 
     * @type {IProjectProjectContacts}
     * @memberof ProjectWithMappedExpiryDate
     */
    'projectContacts'?: IProjectProjectContacts;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDate
     */
    'expiryDateFormatted': string | null;
}
/**
 * 
 * @export
 * @interface ProjectWithMappedExpiryDateAllOf
 */
export interface ProjectWithMappedExpiryDateAllOf {
    /**
     * 
     * @type {string}
     * @memberof ProjectWithMappedExpiryDateAllOf
     */
    'expiryDateFormatted': string | null;
}
/**
 * 
 * @export
 * @interface ProvidedType
 */
export interface ProvidedType {
    /**
     * 
     * @type {ManifestReceiptTemplateContext}
     * @memberof ProvidedType
     */
    'manifestReceiptTemplateContext': ManifestReceiptTemplateContext;
    /**
     * 
     * @type {ManifestTemplateContext}
     * @memberof ProvidedType
     */
    'manifestTemplateContext': ManifestTemplateContext;
}
/**
 * 
 * @export
 * @interface PubSubBody
 */
export interface PubSubBody {
    [key: string]: any;

    /**
     * 
     * @type {PubSubBodyMessage}
     * @memberof PubSubBody
     */
    'message': PubSubBodyMessage;
}
/**
 * 
 * @export
 * @interface PubSubBodyMessage
 */
export interface PubSubBodyMessage {
    [key: string]: any;

    /**
     * 
     * @type {any}
     * @memberof PubSubBodyMessage
     */
    'data': any;
}
/**
 * 
 * @export
 * @interface RefreshWasteConnectionsTickets200Response
 */
export interface RefreshWasteConnectionsTickets200Response {
    /**
     * 
     * @type {number}
     * @memberof RefreshWasteConnectionsTickets200Response
     */
    'success': number;
    /**
     * 
     * @type {number}
     * @memberof RefreshWasteConnectionsTickets200Response
     */
    'error': number;
}
/**
 * 
 * @export
 * @interface RefreshWasteConnectionsTicketsRequest
 */
export interface RefreshWasteConnectionsTicketsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RefreshWasteConnectionsTicketsRequest
     */
    'numbers': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RefreshWasteConnectionsTicketsRequest
     */
    'projectId': string;
}
/**
 * 
 * @export
 * @interface SaveProjectApiMapping
 */
export interface SaveProjectApiMapping {
    /**
     * 
     * @type {number}
     * @memberof SaveProjectApiMapping
     */
    'project_id': number;
    /**
     * 
     * @type {string}
     * @memberof SaveProjectApiMapping
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SaveProjectApiMapping
     */
    'api_id': string;
    /**
     * 
     * @type {CivilApiProvider}
     * @memberof SaveProjectApiMapping
     */
    'api': CivilApiProvider;
    /**
     * 
     * @type {boolean}
     * @memberof SaveProjectApiMapping
     */
    'default_source_supplier_as_transporter': boolean | null;
    /**
     * 
     * @type {number}
     * @memberof SaveProjectApiMapping
     */
    'id'?: number;
}


/**
 * 
 * @export
 * @interface Scale
 */
export interface Scale {
    /**
     * 
     * @type {string}
     * @memberof Scale
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof Scale
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Scale
     */
    'companyId': string;
    /**
     * 
     * @type {NameValue}
     * @memberof Scale
     */
    'country': NameValue;
    /**
     * 
     * @type {IProjectTaskProjectMaterialId}
     * @memberof Scale
     */
    'gpsLat'?: IProjectTaskProjectMaterialId;
    /**
     * 
     * @type {IProjectTaskProjectMaterialId}
     * @memberof Scale
     */
    'gpsLong'?: IProjectTaskProjectMaterialId;
    /**
     * 
     * @type {IProjectTaskProjectMaterialId}
     * @memberof Scale
     */
    'gpsLng'?: IProjectTaskProjectMaterialId;
    /**
     * 
     * @type {boolean}
     * @memberof Scale
     */
    'isOffHours'?: boolean;
    /**
     * 
     * @type {Array<Lane>}
     * @memberof Scale
     */
    'lanes'?: Array<Lane>;
    /**
     * 
     * @type {string}
     * @memberof Scale
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Scale
     */
    'offHoursPasscode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Scale
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Scale
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Scale
     */
    'apiBaseUrl'?: string;
    /**
     * 
     * @type {ProfileTypes}
     * @memberof Scale
     */
    'profileTypes': ProfileTypes;
    /**
     * 
     * @type {string}
     * @memberof Scale
     */
    'receiptTemplateId': string;
    /**
     * 
     * @type {string}
     * @memberof Scale
     */
    'regionId': string;
    /**
     * 
     * @type {NameValue}
     * @memberof Scale
     */
    'state': NameValue;
    /**
     * 
     * @type {IProjectTaskProjectMaterialId}
     * @memberof Scale
     */
    'zipcode'?: IProjectTaskProjectMaterialId;
    /**
     * 
     * @type {IProjectTaskProjectMaterialId}
     * @memberof Scale
     */
    'postalCode'?: IProjectTaskProjectMaterialId;
}
/**
 * 
 * @export
 * @interface ScalePartialForProject
 */
export interface ScalePartialForProject {
    /**
     * 
     * @type {string}
     * @memberof ScalePartialForProject
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof ScalePartialForProject
     */
    'id': string;
    /**
     * 
     * @type {Array<Lane>}
     * @memberof ScalePartialForProject
     */
    'lanes'?: Array<Lane>;
    /**
     * 
     * @type {string}
     * @memberof ScalePartialForProject
     */
    'name': string;
    /**
     * 
     * @type {NameValue}
     * @memberof ScalePartialForProject
     */
    'state': NameValue;
}
/**
 * 
 * @export
 * @interface SearchConnectedCompanies200Response
 */
export interface SearchConnectedCompanies200Response {
    /**
     * 
     * @type {Array<CompanyConnectionWithCompany>}
     * @memberof SearchConnectedCompanies200Response
     */
    'data': Array<CompanyConnectionWithCompany>;
    /**
     * 
     * @type {SearchConnectedCompanies200ResponsePagination}
     * @memberof SearchConnectedCompanies200Response
     */
    'pagination': SearchConnectedCompanies200ResponsePagination;
}
/**
 * 
 * @export
 * @interface SearchConnectedCompanies200ResponsePagination
 */
export interface SearchConnectedCompanies200ResponsePagination {
    /**
     * 
     * @type {number}
     * @memberof SearchConnectedCompanies200ResponsePagination
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface SendGeneratorNewUnsignedManifestsNotificationSmsParams
 */
export interface SendGeneratorNewUnsignedManifestsNotificationSmsParams {
    /**
     * 
     * @type {number}
     * @memberof SendGeneratorNewUnsignedManifestsNotificationSmsParams
     */
    'numberOfNewManifests': number;
    /**
     * 
     * @type {string}
     * @memberof SendGeneratorNewUnsignedManifestsNotificationSmsParams
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof SendGeneratorNewUnsignedManifestsNotificationSmsParams
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof SendGeneratorNewUnsignedManifestsNotificationSmsParams
     */
    'projectProfileNr': string;
    /**
     * 
     * @type {string}
     * @memberof SendGeneratorNewUnsignedManifestsNotificationSmsParams
     */
    'projectGeneratorCompanyId': string;
}
/**
 * 
 * @export
 * @interface SendGeneratorsNewUnsignedManifestsNotificationParams
 */
export interface SendGeneratorsNewUnsignedManifestsNotificationParams {
    /**
     * 
     * @type {number}
     * @memberof SendGeneratorsNewUnsignedManifestsNotificationParams
     */
    'numberOfNewManifests': number;
    /**
     * 
     * @type {string}
     * @memberof SendGeneratorsNewUnsignedManifestsNotificationParams
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof SendGeneratorsNewUnsignedManifestsNotificationParams
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof SendGeneratorsNewUnsignedManifestsNotificationParams
     */
    'projectProfileNr': string;
    /**
     * 
     * @type {string}
     * @memberof SendGeneratorsNewUnsignedManifestsNotificationParams
     */
    'projectGeneratorCompanyId': string;
}
/**
 * 
 * @export
 * @interface SendManifestOutofGeofenceNotificationParams
 */
export interface SendManifestOutofGeofenceNotificationParams {
    /**
     * 
     * @type {string}
     * @memberof SendManifestOutofGeofenceNotificationParams
     */
    'manifestNum': string;
    /**
     * 
     * @type {string}
     * @memberof SendManifestOutofGeofenceNotificationParams
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof SendManifestOutofGeofenceNotificationParams
     */
    'profileNum': string;
    /**
     * 
     * @type {string}
     * @memberof SendManifestOutofGeofenceNotificationParams
     */
    'generatorId': string;
    /**
     * 
     * @type {string}
     * @memberof SendManifestOutofGeofenceNotificationParams
     */
    'truckCompany': string;
    /**
     * 
     * @type {string}
     * @memberof SendManifestOutofGeofenceNotificationParams
     */
    'truckNum': string;
}
/**
 * 
 * @export
 * @interface SendManifestOuttoGeofenceNotificationSmsParams
 */
export interface SendManifestOuttoGeofenceNotificationSmsParams {
    /**
     * 
     * @type {string}
     * @memberof SendManifestOuttoGeofenceNotificationSmsParams
     */
    'manifestNum': string;
    /**
     * 
     * @type {string}
     * @memberof SendManifestOuttoGeofenceNotificationSmsParams
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof SendManifestOuttoGeofenceNotificationSmsParams
     */
    'profileNum': string;
    /**
     * 
     * @type {string}
     * @memberof SendManifestOuttoGeofenceNotificationSmsParams
     */
    'generatorId': string;
    /**
     * 
     * @type {string}
     * @memberof SendManifestOuttoGeofenceNotificationSmsParams
     */
    'truckCompany': string;
    /**
     * 
     * @type {string}
     * @memberof SendManifestOuttoGeofenceNotificationSmsParams
     */
    'truckNum': string;
}
/**
 * 
 * @export
 * @interface SendManifestRequest
 */
export interface SendManifestRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof SendManifestRequest
     */
    'emails': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof SendManifestRequest
     */
    'isCopyToMe'?: boolean;
}
/**
 * 
 * @export
 * @interface SetCompanyBrokerLoadsTheSelectedTemplate200Response
 */
export interface SetCompanyBrokerLoadsTheSelectedTemplate200Response {
    /**
     * 
     * @type {number}
     * @memberof SetCompanyBrokerLoadsTheSelectedTemplate200Response
     */
    'count': number;
    /**
     * 
     * @type {SetCompanyBrokerLoadsTheSelectedTemplate200ResponseStatus}
     * @memberof SetCompanyBrokerLoadsTheSelectedTemplate200Response
     */
    'status': SetCompanyBrokerLoadsTheSelectedTemplate200ResponseStatus;
}
/**
 * 
 * @export
 * @interface SetCompanyBrokerLoadsTheSelectedTemplate200ResponseStatus
 */
export interface SetCompanyBrokerLoadsTheSelectedTemplate200ResponseStatus {
    /**
     * 
     * @type {string}
     * @memberof SetCompanyBrokerLoadsTheSelectedTemplate200ResponseStatus
     */
    'lastBatchDocumentId'?: string;
    /**
     * 
     * @type {number}
     * @memberof SetCompanyBrokerLoadsTheSelectedTemplate200ResponseStatus
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof SetCompanyBrokerLoadsTheSelectedTemplate200ResponseStatus
     */
    'errorInfo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SetCompanyBrokerLoadsTheSelectedTemplate200ResponseStatus
     */
    'isSuccessfull': boolean;
}
/**
 * 
 * @export
 * @interface SetLaneByManifestIdsType
 */
export interface SetLaneByManifestIdsType {
    /**
     * 
     * @type {string}
     * @memberof SetLaneByManifestIdsType
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof SetLaneByManifestIdsType
     */
    'userName': string;
    /**
     * 
     * @type {string}
     * @memberof SetLaneByManifestIdsType
     */
    'mobileBuild': string;
    /**
     * 
     * @type {string}
     * @memberof SetLaneByManifestIdsType
     */
    'mobileVersion': string;
    /**
     * 
     * @type {string}
     * @memberof SetLaneByManifestIdsType
     */
    'osVersion': string;
    /**
     * 
     * @type {string}
     * @memberof SetLaneByManifestIdsType
     */
    'platform': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SetLaneByManifestIdsType
     */
    'manifestIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SetLaneByManifestIdsType
     */
    'laneId'?: string | null;
}
/**
 * 
 * @export
 * @interface SetManualReleaseForManifestType
 */
export interface SetManualReleaseForManifestType {
    /**
     * 
     * @type {string}
     * @memberof SetManualReleaseForManifestType
     */
    'batchId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SetManualReleaseForManifestType
     */
    'releasePassCode': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SetManualReleaseForManifestType
     */
    'manifestIds': Array<string>;
}
/**
 * 
 * @export
 * @interface SetProjectLocation200Response
 */
export interface SetProjectLocation200Response {
    /**
     * 
     * @type {number}
     * @memberof SetProjectLocation200Response
     */
    'locationId': number;
}
/**
 * 
 * @export
 * @interface SetProjectLocationRequest
 */
export interface SetProjectLocationRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof SetProjectLocationRequest
     */
    'coords': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof SetProjectLocationRequest
     */
    'locationName': string;
}
/**
 * 
 * @export
 * @interface SetUserPasswordRequest
 */
export interface SetUserPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof SetUserPasswordRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface SignManifestDriverType
 */
export interface SignManifestDriverType {
    /**
     * 
     * @type {string}
     * @memberof SignManifestDriverType
     */
    'mobileBuild'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestDriverType
     */
    'mobileVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestDriverType
     */
    'osVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestDriverType
     */
    'platform'?: string;
    /**
     * 
     * @type {any}
     * @memberof SignManifestDriverType
     */
    'customInputs'?: any;
    /**
     * 
     * @type {string}
     * @memberof SignManifestDriverType
     */
    'shipperNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestDriverType
     */
    'v1ApiCompletedCallBackUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof SignManifestDriverType
     */
    'isV1ApiCivilAndTrucking': boolean;
    /**
     * 
     * @type {any}
     * @memberof SignManifestDriverType
     */
    'selectedScale': any;
    /**
     * 
     * @type {string}
     * @memberof SignManifestDriverType
     */
    'truckCapacityUnit': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestDriverType
     */
    'truckCapacity': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestDriverType
     */
    'truckNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestDriverType
     */
    'secondaryTruckingCompany': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestDriverType
     */
    'truckingCompanyId': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestDriverType
     */
    'truckingCompany': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestDriverType
     */
    'driverName': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestDriverType
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestDriverType
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestDriverType
     */
    'signatureUrl': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestDriverType
     */
    'manifestId': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestDriverType
     */
    'assignSpecificManifestId': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestDriverType
     */
    'brokerLoadId'?: string;
}
/**
 * 
 * @export
 * @interface SignManifestParams
 */
export interface SignManifestParams {
    /**
     * 
     * @type {string}
     * @memberof SignManifestParams
     */
    'brokerLoadId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestParams
     */
    'manifestId': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestParams
     */
    'signatureBase64': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestParams
     */
    'driverUserId': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestParams
     */
    'truckingCompany': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestParams
     */
    'truckingCompanyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestParams
     */
    'truckNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestParams
     */
    'truckCapacity': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestParams
     */
    'truckCapacityUnit': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestParams
     */
    'driverName': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestParams
     */
    'driverEmail': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestParams
     */
    'scaleId': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestParams
     */
    'scaleName': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestParams
     */
    'scaleAddress': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestParams
     */
    'shipperNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SignManifestParams
     */
    'completedCallBackUrl': string;
    /**
     * 
     * @type {any}
     * @memberof SignManifestParams
     */
    'customInputs'?: any;
}
/**
 * 
 * @export
 * @interface SignatureDriver
 */
export interface SignatureDriver {
    /**
     * 
     * @type {boolean}
     * @memberof SignatureDriver
     */
    'isSigned': boolean;
    /**
     * 
     * @type {FirestoreTimestamp}
     * @memberof SignatureDriver
     */
    'signedAt': FirestoreTimestamp | null;
    /**
     * 
     * @type {string}
     * @memberof SignatureDriver
     */
    'userId': string | null;
    /**
     * 
     * @type {Lane}
     * @memberof SignatureDriver
     */
    'lane': Lane;
    /**
     * 
     * @type {FirestoreTimestamp}
     * @memberof SignatureDriver
     */
    'requestedAt': FirestoreTimestamp | null;
    /**
     * 
     * @type {Scale}
     * @memberof SignatureDriver
     */
    'scale': Scale | null;
    /**
     * 
     * @type {SignatureDriverTruckCapacity}
     * @memberof SignatureDriver
     */
    'truckCapacity': SignatureDriverTruckCapacity | null;
    /**
     * 
     * @type {string}
     * @memberof SignatureDriver
     */
    'truckCapacityUnit': string | null;
    /**
     * 
     * @type {SignatureDriverTruckCapacity}
     * @memberof SignatureDriver
     */
    'truckNumber': SignatureDriverTruckCapacity | null;
    /**
     * 
     * @type {string}
     * @memberof SignatureDriver
     */
    'truckingCompany': string | null;
    /**
     * 
     * @type {string}
     * @memberof SignatureDriver
     */
    'truckingCompanyId': string | null;
    /**
     * 
     * @type {string}
     * @memberof SignatureDriver
     */
    'secondaryTruckingCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignatureDriver
     */
    'driverName': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SignatureDriver
     */
    'isReleasedManually'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SignatureDriver
     */
    'isAcceptedRejection'?: boolean | null;
}
/**
 * 
 * @export
 * @interface SignatureDriverTruckCapacity
 */
export interface SignatureDriverTruckCapacity {
}
/**
 * 
 * @export
 * @interface SignatureGenerator
 */
export interface SignatureGenerator {
    /**
     * 
     * @type {boolean}
     * @memberof SignatureGenerator
     */
    'isSigned': boolean;
    /**
     * 
     * @type {FirestoreTimestamp}
     * @memberof SignatureGenerator
     */
    'signedAt': FirestoreTimestamp | null;
    /**
     * 
     * @type {string}
     * @memberof SignatureGenerator
     */
    'userId': string | null;
    /**
     * 
     * @type {string}
     * @memberof SignatureGenerator
     */
    'email': string | null;
    /**
     * 
     * @type {string}
     * @memberof SignatureGenerator
     */
    'ownerName': string | null;
    /**
     * 
     * @type {string}
     * @memberof SignatureGenerator
     */
    'url': string | null;
}
/**
 * 
 * @export
 * @interface SignatureScale
 */
export interface SignatureScale {
    /**
     * 
     * @type {boolean}
     * @memberof SignatureScale
     */
    'isSigned': boolean;
    /**
     * 
     * @type {FirestoreTimestamp}
     * @memberof SignatureScale
     */
    'signedAt': FirestoreTimestamp | null;
    /**
     * 
     * @type {string}
     * @memberof SignatureScale
     */
    'userId': string | null;
    /**
     * 
     * @type {string}
     * @memberof SignatureScale
     */
    'profileAndType': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SignatureScale
     */
    'isRejected'?: boolean | null;
}
/**
 * 
 * @export
 * @interface SortColumn
 */
export interface SortColumn {
    /**
     * 
     * @type {string}
     * @memberof SortColumn
     */
    'column': string;
    /**
     * 
     * @type {boolean}
     * @memberof SortColumn
     */
    'isAsc': boolean;
}
/**
 * 
 * @export
 * @interface Template
 */
export interface Template {
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    'name': string;
    /**
     * 
     * @type {any}
     * @memberof Template
     */
    'propertiesDisplayOptions': any;
    /**
     * 
     * @type {Dimensions}
     * @memberof Template
     */
    'dimensions': Dimensions;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    'url': string;
}
/**
 * A Timestamp represents a point in time independent of any time zone or calendar, represented as seconds and fractions of seconds at nanosecond resolution in UTC Epoch time.  It is encoded using the Proleptic Gregorian Calendar which extends the Gregorian calendar backwards to year one. It is encoded assuming all minutes are 60 seconds long, i.e. leap seconds are \"smeared\" so that no leap second table is needed for interpretation. Range is from 0001-01-01T00:00:00Z to 9999-12-31T23:59:59.999999999Z.
 * @export
 * @interface Timestamp
 */
export interface Timestamp {
    /**
     * 
     * @type {number}
     * @memberof Timestamp
     */
    'seconds': number;
    /**
     * 
     * @type {number}
     * @memberof Timestamp
     */
    'nanoseconds': number;
}
/**
 * 
 * @export
 * @interface TransporterCompaniesForProject
 */
export interface TransporterCompaniesForProject {
    /**
     * 
     * @type {boolean}
     * @memberof TransporterCompaniesForProject
     */
    'canViewAllManifests': boolean;
    /**
     * 
     * @type {string}
     * @memberof TransporterCompaniesForProject
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TransporterCompaniesForProject
     */
    'name': string;
}
/**
 * Model trucking_timesheet
 * @export
 * @interface TruckingTimesheet
 */
export interface TruckingTimesheet {
    /**
     * 
     * @type {string}
     * @memberof TruckingTimesheet
     */
    'modified_date': string;
    /**
     * 
     * @type {string}
     * @memberof TruckingTimesheet
     */
    'created_date': string;
    /**
     * 
     * @type {string}
     * @memberof TruckingTimesheet
     */
    'modified_by': string;
    /**
     * 
     * @type {string}
     * @memberof TruckingTimesheet
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof TruckingTimesheet
     */
    'end_time': string | null;
    /**
     * 
     * @type {string}
     * @memberof TruckingTimesheet
     */
    'start_time': string | null;
    /**
     * 
     * @type {string}
     * @memberof TruckingTimesheet
     */
    'notes': string | null;
    /**
     * 
     * @type {string}
     * @memberof TruckingTimesheet
     */
    'truck_number': string;
    /**
     * 
     * @type {string}
     * @memberof TruckingTimesheet
     */
    'company_id': string;
    /**
     * 
     * @type {number}
     * @memberof TruckingTimesheet
     */
    'id': number;
}
/**
 * Model trucking_timesheet_references
 * @export
 * @interface TruckingTimesheetReferences
 */
export interface TruckingTimesheetReferences {
    /**
     * 
     * @type {number}
     * @memberof TruckingTimesheetReferences
     */
    'dispatch_id': number | null;
    /**
     * 
     * @type {number}
     * @memberof TruckingTimesheetReferences
     */
    'timesheet_id': number;
    /**
     * 
     * @type {number}
     * @memberof TruckingTimesheetReferences
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface UpdateDispatchParams
 */
export interface UpdateDispatchParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateDispatchParams
     */
    'manifestProjectID': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDispatchParams
     */
    'dispatchID': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDispatchParams
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDispatchParams
     */
    'contractorName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDispatchParams
     */
    'customerNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDispatchParams
     */
    'lotNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDispatchParams
     */
    'poNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDispatchParams
     */
    'material': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDispatchParams
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDispatchParams
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDispatchParams
     */
    'timeZone': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDispatchParams
     */
    'assignedTruckingCompanyID': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDispatchParams
     */
    'assignedTruckNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDispatchParams
     */
    'specialInstr': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDispatchParams
     */
    'canRepeat': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateDispatchParams
     */
    'callBackURL': string;
}
/**
 * 
 * @export
 * @interface UpdateLocationByIdRequest
 */
export interface UpdateLocationByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationByIdRequest
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateLocationByIdRequest
     */
    'geoData': Array<number>;
}
/**
 * 
 * @export
 * @interface UpdateManifestsPassedDay200Response
 */
export interface UpdateManifestsPassedDay200Response {
    /**
     * 
     * @type {number}
     * @memberof UpdateManifestsPassedDay200Response
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface UpdateMissedSecondaryTruckingCompany200Response
 */
export interface UpdateMissedSecondaryTruckingCompany200Response {
    /**
     * 
     * @type {any}
     * @memberof UpdateMissedSecondaryTruckingCompany200Response
     */
    'lastId': any;
    /**
     * 
     * @type {number}
     * @memberof UpdateMissedSecondaryTruckingCompany200Response
     */
    'initializedCount': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateMissedSecondaryTruckingCompany200Response
     */
    'erroredCount': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateMissedSecondaryTruckingCompany200Response
     */
    'checkedCount': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateMissedSecondaryTruckingCompany200Response
     */
    'attemptedCount': number;
}
/**
 * 
 * @export
 * @interface UpdateWorkCodeNickname200Response
 */
export interface UpdateWorkCodeNickname200Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkCodeNickname200Response
     */
    'error': string;
    /**
     * 
     * @type {any}
     * @memberof UpdateWorkCodeNickname200Response
     */
    'message': any;
}
/**
 * 
 * @export
 * @interface UpdateWorkCodeNickname200ResponseAnyOf
 */
export interface UpdateWorkCodeNickname200ResponseAnyOf {
    /**
     * 
     * @type {any}
     * @memberof UpdateWorkCodeNickname200ResponseAnyOf
     */
    'error'?: any;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkCodeNickname200ResponseAnyOf
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface UpdateWorkCodeNickname200ResponseAnyOf1
 */
export interface UpdateWorkCodeNickname200ResponseAnyOf1 {
    /**
     * 
     * @type {any}
     * @memberof UpdateWorkCodeNickname200ResponseAnyOf1
     */
    'message'?: any;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkCodeNickname200ResponseAnyOf1
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface UpsertCompanyFeatureFlagRequest
 */
export interface UpsertCompanyFeatureFlagRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpsertCompanyFeatureFlagRequest
     */
    'is_enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertCompanyFeatureFlagRequest
     */
    'feature_name': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertCompanyFeatureFlagRequest
     */
    'company_id': string;
}
/**
 * 
 * @export
 * @interface UpsertCompanySubMaterial
 */
export interface UpsertCompanySubMaterial {
    /**
     * 
     * @type {string}
     * @memberof UpsertCompanySubMaterial
     */
    'company_id': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertCompanySubMaterial
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertCompanySubMaterial
     */
    'description': string | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertCompanySubMaterial
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface UpsertCompanySubMaterialAllOf
 */
export interface UpsertCompanySubMaterialAllOf {
    /**
     * 
     * @type {number}
     * @memberof UpsertCompanySubMaterialAllOf
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface UpsertCustomInput200Response
 */
export interface UpsertCustomInput200Response {
    /**
     * 
     * @type {PrismaJsonValue}
     * @memberof UpsertCustomInput200Response
     */
    'custom_input_items': PrismaJsonValue | null;
    /**
     * 
     * @type {PrismaJsonValue}
     * @memberof UpsertCustomInput200Response
     */
    'user_types_can_only_read': PrismaJsonValue | null;
    /**
     * 
     * @type {PrismaJsonValue}
     * @memberof UpsertCustomInput200Response
     */
    'user_types_can_edit': PrismaJsonValue | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertCustomInput200Response
     */
    'type': string;
    /**
     * 
     * @type {PrismaJsonValue}
     * @memberof UpsertCustomInput200Response
     */
    'sections_can_only_read': PrismaJsonValue | null;
    /**
     * 
     * @type {PrismaJsonValue}
     * @memberof UpsertCustomInput200Response
     */
    'sections_can_edit': PrismaJsonValue | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertCustomInput200Response
     */
    'property_path_in_manifest_custom_inputs': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertCustomInput200Response
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertCustomInput200Response
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertCustomInput200Response
     */
    'default_value': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertCustomInput200Response
     */
    'is_valid_custom_input_definition': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertCustomInput200Response
     */
    'is_valid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertCustomInput200Response
     */
    'is_required': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertCustomInput200Response
     */
    'is_hidden': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpsertCustomInput200Response
     */
    'id': number;
    /**
     * 
     * @type {Array<CompanyCustomInputsConnection>}
     * @memberof UpsertCustomInput200Response
     */
    'company_custom_inputs_connection': Array<CompanyCustomInputsConnection>;
}
/**
 * 
 * @export
 * @interface UpsertCustomInput200ResponseAllOf
 */
export interface UpsertCustomInput200ResponseAllOf {
    /**
     * 
     * @type {Array<CompanyCustomInputsConnection>}
     * @memberof UpsertCustomInput200ResponseAllOf
     */
    'company_custom_inputs_connection': Array<CompanyCustomInputsConnection>;
}
/**
 * 
 * @export
 * @interface UpsertProjectSubMaterial
 */
export interface UpsertProjectSubMaterial {
    /**
     * 
     * @type {number}
     * @memberof UpsertProjectSubMaterial
     */
    'project_id': number;
    /**
     * 
     * @type {number}
     * @memberof UpsertProjectSubMaterial
     */
    'sub_material_id': number;
    /**
     * 
     * @type {string}
     * @memberof UpsertProjectSubMaterial
     */
    'start_date': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertProjectSubMaterial
     */
    'price_per_unit': string | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertProjectSubMaterial
     */
    'id'?: number;
}
/**
 * The UserObject contains common information across every user in the system regardless of type.
 * @export
 * @interface UserObject
 */
export interface UserObject {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UserObject
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof UserObject
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserObject
     */
    'phone'?: string | null;
    /**
     * 
     * @type {Array<UserObjectCompaniesInner>}
     * @memberof UserObject
     */
    'companies'?: Array<UserObjectCompaniesInner>;
    /**
     * 
     * @type {string}
     * @memberof UserObject
     */
    'regionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserObject
     */
    'preferredLoginMethod': string;
}
/**
 * 
 * @export
 * @interface UserObjectCompaniesInner
 */
export interface UserObjectCompaniesInner {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserObjectCompaniesInner
     */
    'types': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserObjectCompaniesInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserObjectCompaniesInner
     */
    'id': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserRoles = {
    Viewer: 'viewer',
    SuperAdmin: 'super_admin',
    CustomerAdmin: 'customer_admin',
    TransporterAdmin: 'transporter_admin',
    TransporterUser: 'transporter_user',
    ScaleAdmin: 'scale_admin',
    ScaleAttendant: 'scale_attendant',
    GeneratorAdmin: 'generator_admin',
    GeneratorUser: 'generator_user',
    ContractorAdmin: 'contractor_admin',
    ContractorUser: 'contractor_user',
    TruckingDispatcher: 'trucking_dispatcher',
    TruckingDriver: 'trucking_driver',
    TruckingViewer: 'trucking_viewer',
    TruckingBrokerDispatcher: 'trucking_broker_dispatcher',
    TruckingBrokerDriver: 'trucking_broker_driver',
    Driver: 'driver',
    CivilProjectManager: 'civil_project_manager',
    CivilAccountant: 'civil_accountant',
    CivilSiteUser: 'civil_site_user',
    CivilViewer: 'civil_viewer'
} as const;

export type UserRoles = typeof UserRoles[keyof typeof UserRoles];


/**
 * 
 * @export
 * @enum {string}
 */

export const UserTypeGenerated = {
    Scale: 'scale',
    Transporter: 'transporter',
    Generator: 'generator',
    Contractor: 'contractor',
    Broker: 'broker',
    TruckingBroker: 'truckingBroker',
    Viewer: 'viewer',
    Civil: 'civil',
    Trucking: 'trucking',
    TruckingContractor: 'truckingContractor',
    TruckingSourceSupplier: 'truckingSourceSupplier'
} as const;

export type UserTypeGenerated = typeof UserTypeGenerated[keyof typeof UserTypeGenerated];


/**
 * 
 * @export
 * @interface VerifyAuth0Code200Response
 */
export interface VerifyAuth0Code200Response {
    /**
     * 
     * @type {any}
     * @memberof VerifyAuth0Code200Response
     */
    'firebaseToken': any;
}
/**
 * 
 * @export
 * @interface VerifyAuth0CodeRequest
 */
export interface VerifyAuth0CodeRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyAuth0CodeRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface VerifyWorkCodeRequestBody
 */
export interface VerifyWorkCodeRequestBody {
    /**
     * 
     * @type {string}
     * @memberof VerifyWorkCodeRequestBody
     */
    'workCode': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyWorkCodeRequestBody
     */
    'driverId'?: string;
}
/**
 * 
 * @export
 * @interface WorkCodeAddDriverRequest200Response
 */
export interface WorkCodeAddDriverRequest200Response {
    /**
     * 
     * @type {string}
     * @memberof WorkCodeAddDriverRequest200Response
     */
    'error': string;
    /**
     * 
     * @type {any}
     * @memberof WorkCodeAddDriverRequest200Response
     */
    'data': any;
}
/**
 * 
 * @export
 * @interface WorkCodeAddDriverRequest200ResponseAnyOf
 */
export interface WorkCodeAddDriverRequest200ResponseAnyOf {
    /**
     * 
     * @type {any}
     * @memberof WorkCodeAddDriverRequest200ResponseAnyOf
     */
    'error'?: any;
    /**
     * 
     * @type {string}
     * @memberof WorkCodeAddDriverRequest200ResponseAnyOf
     */
    'data': string;
}
/**
 * 
 * @export
 * @interface WorkCodeAddDriverRequest200ResponseAnyOf1
 */
export interface WorkCodeAddDriverRequest200ResponseAnyOf1 {
    /**
     * 
     * @type {any}
     * @memberof WorkCodeAddDriverRequest200ResponseAnyOf1
     */
    'data'?: any;
    /**
     * 
     * @type {string}
     * @memberof WorkCodeAddDriverRequest200ResponseAnyOf1
     */
    'error': string;
}
/**
 * Model work_code_custom_input_defaults
 * @export
 * @interface WorkCodeCustomInputDefaults
 */
export interface WorkCodeCustomInputDefaults {
    /**
     * 
     * @type {PrismaJsonValue}
     * @memberof WorkCodeCustomInputDefaults
     */
    'custom_defaults': PrismaJsonValue | null;
    /**
     * 
     * @type {string}
     * @memberof WorkCodeCustomInputDefaults
     */
    'work_code_id': string;
}
/**
 * Model work_code_drivers
 * @export
 * @interface WorkCodeDrivers
 */
export interface WorkCodeDrivers {
    /**
     * 
     * @type {string}
     * @memberof WorkCodeDrivers
     */
    'truck_number': string;
    /**
     * 
     * @type {string}
     * @memberof WorkCodeDrivers
     */
    'carrier': string;
    /**
     * 
     * @type {string}
     * @memberof WorkCodeDrivers
     */
    'transporterName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkCodeDrivers
     */
    'transporterId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkCodeDrivers
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WorkCodeDrivers
     */
    'project_uid': string;
    /**
     * 
     * @type {string}
     * @memberof WorkCodeDrivers
     */
    'work_code_id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkCodeDrivers
     */
    'uid': string;
}
/**
 * Model work_codes
 * @export
 * @interface WorkCodes
 */
export interface WorkCodes {
    /**
     * 
     * @type {string}
     * @memberof WorkCodes
     */
    'nickname': string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkCodes
     */
    'deleted_date': string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkCodes
     */
    'expiration_date': string;
    /**
     * 
     * @type {string}
     * @memberof WorkCodes
     */
    'created_date': string;
    /**
     * 
     * @type {string}
     * @memberof WorkCodes
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkCodes
     */
    'work_code_id': string;
}
/**
 * 
 * @export
 * @interface WorkCodesResponse
 */
export interface WorkCodesResponse {
    /**
     * 
     * @type {string}
     * @memberof WorkCodesResponse
     */
    'message': string;
    /**
     * 
     * @type {any}
     * @memberof WorkCodesResponse
     */
    'error': any;
}

/**
 * ActionsLogApi - axios parameter creator
 * @export
 */
export const ActionsLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<ActionTypes>} [types] 
         * @param {string} [userId] 
         * @param {string} [companyId] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (types?: Array<ActionTypes>, userId?: string, companyId?: string, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/actions-log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionsLogApi - functional programming interface
 * @export
 */
export const ActionsLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionsLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<ActionTypes>} [types] 
         * @param {string} [userId] 
         * @param {string} [companyId] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(types?: Array<ActionTypes>, userId?: string, companyId?: string, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActionsLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(types, userId, companyId, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActionsLogApi - factory interface
 * @export
 */
export const ActionsLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionsLogApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<ActionTypes>} [types] 
         * @param {string} [userId] 
         * @param {string} [companyId] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(types?: Array<ActionTypes>, userId?: string, companyId?: string, page?: number, options?: any): AxiosPromise<Array<ActionsLog>> {
            return localVarFp.search(types, userId, companyId, page, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActionsLogApi - object-oriented interface
 * @export
 * @class ActionsLogApi
 * @extends {BaseAPI}
 */
export class ActionsLogApi extends BaseAPI {
    /**
     * 
     * @param {Array<ActionTypes>} [types] 
     * @param {string} [userId] 
     * @param {string} [companyId] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsLogApi
     */
    public search(types?: Array<ActionTypes>, userId?: string, companyId?: string, page?: number, options?: AxiosRequestConfig) {
        return ActionsLogApiFp(this.configuration).search(types, userId, companyId, page, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationTokensApi - axios parameter creator
 * @export
 */
export const ApplicationTokensApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateApplicationToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/application-tokens/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/application-tokens/current-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationTokensApi - functional programming interface
 * @export
 */
export const ApplicationTokensApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationTokensApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateApplicationToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateApplicationToken200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateApplicationToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationTokens>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationTokensApi - factory interface
 * @export
 */
export const ApplicationTokensApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationTokensApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateApplicationToken(options?: any): AxiosPromise<GenerateApplicationToken200Response> {
            return localVarFp.generateApplicationToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationToken(options?: any): AxiosPromise<ApplicationTokens> {
            return localVarFp.getApplicationToken(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationTokensApi - object-oriented interface
 * @export
 * @class ApplicationTokensApi
 * @extends {BaseAPI}
 */
export class ApplicationTokensApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationTokensApi
     */
    public generateApplicationToken(options?: AxiosRequestConfig) {
        return ApplicationTokensApiFp(this.configuration).generateApplicationToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationTokensApi
     */
    public getApplicationToken(options?: AxiosRequestConfig) {
        return ApplicationTokensApiFp(this.configuration).getApplicationToken(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DetectAuthMethodRequest} detectAuthMethodRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectAuthMethod: async (detectAuthMethodRequest: DetectAuthMethodRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'detectAuthMethodRequest' is not null or undefined
            assertParamExists('detectAuthMethod', 'detectAuthMethodRequest', detectAuthMethodRequest)
            const localVarPath = `/auth/detect-auth-method`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detectAuthMethodRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyAuth0CodeRequest} verifyAuth0CodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAuth0Code: async (verifyAuth0CodeRequest: VerifyAuth0CodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyAuth0CodeRequest' is not null or undefined
            assertParamExists('verifyAuth0Code', 'verifyAuth0CodeRequest', verifyAuth0CodeRequest)
            const localVarPath = `/auth/verify-auth0-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyAuth0CodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DetectAuthMethodRequest} detectAuthMethodRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detectAuthMethod(detectAuthMethodRequest: DetectAuthMethodRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthMethod>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detectAuthMethod(detectAuthMethodRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyAuth0CodeRequest} verifyAuth0CodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyAuth0Code(verifyAuth0CodeRequest: VerifyAuth0CodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyAuth0Code200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyAuth0Code(verifyAuth0CodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {DetectAuthMethodRequest} detectAuthMethodRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectAuthMethod(detectAuthMethodRequest: DetectAuthMethodRequest, options?: any): AxiosPromise<AuthMethod> {
            return localVarFp.detectAuthMethod(detectAuthMethodRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyAuth0CodeRequest} verifyAuth0CodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAuth0Code(verifyAuth0CodeRequest: VerifyAuth0CodeRequest, options?: any): AxiosPromise<VerifyAuth0Code200Response> {
            return localVarFp.verifyAuth0Code(verifyAuth0CodeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {DetectAuthMethodRequest} detectAuthMethodRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public detectAuthMethod(detectAuthMethodRequest: DetectAuthMethodRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).detectAuthMethod(detectAuthMethodRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyAuth0CodeRequest} verifyAuth0CodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public verifyAuth0Code(verifyAuth0CodeRequest: VerifyAuth0CodeRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).verifyAuth0Code(verifyAuth0CodeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BackupApi - axios parameter creator
 * @export
 */
export const BackupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeFirestoreBackup: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/backup/firestore/execute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oidcToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackupApi - functional programming interface
 * @export
 */
export const BackupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BackupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executeFirestoreBackup(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.executeFirestoreBackup(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BackupApi - factory interface
 * @export
 */
export const BackupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BackupApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeFirestoreBackup(options?: any): AxiosPromise<boolean> {
            return localVarFp.executeFirestoreBackup(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BackupApi - object-oriented interface
 * @export
 * @class BackupApi
 * @extends {BaseAPI}
 */
export class BackupApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackupApi
     */
    public executeFirestoreBackup(options?: AxiosRequestConfig) {
        return BackupApiFp(this.configuration).executeFirestoreBackup(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BigQueryApi - axios parameter creator
 * @export
 */
export const BigQueryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeFirestoreBigQueryBatchedManifestsImport: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/big-query/import/batched-manifests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeFirestoreBigQueryManifestsImportByDate: async (startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('executeFirestoreBigQueryManifestsImportByDate', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('executeFirestoreBigQueryManifestsImportByDate', 'endDate', endDate)
            const localVarPath = `/big-query/import/manifests/by_date/{startDate}/{endDate}`
                .replace(`{${"startDate"}}`, encodeURIComponent(String(startDate)))
                .replace(`{${"endDate"}}`, encodeURIComponent(String(endDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BigQueryApi - functional programming interface
 * @export
 */
export const BigQueryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BigQueryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executeFirestoreBigQueryBatchedManifestsImport(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExecuteFirestoreBigQueryBatchedManifestsImport200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.executeFirestoreBigQueryBatchedManifestsImport(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executeFirestoreBigQueryManifestsImportByDate(startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExecuteFirestoreBigQueryManifestsImportByDate200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.executeFirestoreBigQueryManifestsImportByDate(startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BigQueryApi - factory interface
 * @export
 */
export const BigQueryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BigQueryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeFirestoreBigQueryBatchedManifestsImport(options?: any): AxiosPromise<ExecuteFirestoreBigQueryBatchedManifestsImport200Response> {
            return localVarFp.executeFirestoreBigQueryBatchedManifestsImport(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeFirestoreBigQueryManifestsImportByDate(startDate: string, endDate: string, options?: any): AxiosPromise<ExecuteFirestoreBigQueryManifestsImportByDate200Response> {
            return localVarFp.executeFirestoreBigQueryManifestsImportByDate(startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BigQueryApi - object-oriented interface
 * @export
 * @class BigQueryApi
 * @extends {BaseAPI}
 */
export class BigQueryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BigQueryApi
     */
    public executeFirestoreBigQueryBatchedManifestsImport(options?: AxiosRequestConfig) {
        return BigQueryApiFp(this.configuration).executeFirestoreBigQueryBatchedManifestsImport(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BigQueryApi
     */
    public executeFirestoreBigQueryManifestsImportByDate(startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return BigQueryApiFp(this.configuration).executeFirestoreBigQueryManifestsImportByDate(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CivilCompaniesApi - axios parameter creator
 * @export
 */
export const CivilCompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCompany: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('activateCompany', 'companyId', companyId)
            const localVarPath = `/civil/companies/activate/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {ICompanyMaterial} iCompanyMaterial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCompanyMaterial: async (companyId: string, iCompanyMaterial: ICompanyMaterial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('addCompanyMaterial', 'companyId', companyId)
            // verify required parameter 'iCompanyMaterial' is not null or undefined
            assertParamExists('addCompanyMaterial', 'iCompanyMaterial', iCompanyMaterial)
            const localVarPath = `/civil/companies/{companyId}/materials`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iCompanyMaterial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewCompany: async (companyId: string, requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('addNewCompany', 'companyId', companyId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('addNewCompany', 'requestBody', requestBody)
            const localVarPath = `/civil/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} licenseId 
         * @param {AddUserToLicenseRequest} addUserToLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToLicense: async (companyId: string, licenseId: string, addUserToLicenseRequest: AddUserToLicenseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('addUserToLicense', 'companyId', companyId)
            // verify required parameter 'licenseId' is not null or undefined
            assertParamExists('addUserToLicense', 'licenseId', licenseId)
            // verify required parameter 'addUserToLicenseRequest' is not null or undefined
            assertParamExists('addUserToLicense', 'addUserToLicenseRequest', addUserToLicenseRequest)
            const localVarPath = `/civil/companies/{companyId}/licenses/{licenseId}/add-user`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"licenseId"}}`, encodeURIComponent(String(licenseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addUserToLicenseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} apiMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyApiMapping: async (companyId: string, apiMappingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteCompanyApiMapping', 'companyId', companyId)
            // verify required parameter 'apiMappingId' is not null or undefined
            assertParamExists('deleteCompanyApiMapping', 'apiMappingId', apiMappingId)
            const localVarPath = `/civil/companies/{companyId}/api-mapping/{apiMappingId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"apiMappingId"}}`, encodeURIComponent(String(apiMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (companyId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteUser', 'companyId', companyId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/civil/companies/{companyId}/users/delete-license/{userId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} type 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAffiliates: async (companyId: string, type: string, query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAffiliates', 'companyId', companyId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getAffiliates', 'type', type)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('getAffiliates', 'query', query)
            const localVarPath = `/civil/companies/{companyId}/affiliates`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/civil/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyMaterials: async (companyId: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllCompanyMaterials', 'companyId', companyId)
            const localVarPath = `/civil/companies/{companyId}/materials`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanySubMaterials: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllCompanySubMaterials', 'companyId', companyId)
            const localVarPath = `/civil/companies/sub-materials/by-company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} parentContractorCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyApiMapping: async (companyId: string, parentContractorCompanyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyApiMapping', 'companyId', companyId)
            // verify required parameter 'parentContractorCompanyId' is not null or undefined
            assertParamExists('getCompanyApiMapping', 'parentContractorCompanyId', parentContractorCompanyId)
            const localVarPath = `/civil/companies/{companyId}/{parentContractorCompanyId}/api-mapping`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"parentContractorCompanyId"}}`, encodeURIComponent(String(parentContractorCompanyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyById', 'companyId', companyId)
            const localVarPath = `/civil/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} companyMaterialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyMaterialById: async (companyId: string, companyMaterialId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyMaterialById', 'companyId', companyId)
            // verify required parameter 'companyMaterialId' is not null or undefined
            assertParamExists('getCompanyMaterialById', 'companyMaterialId', companyMaterialId)
            const localVarPath = `/civil/companies/{companyId}/materials/{companyMaterialId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"companyMaterialId"}}`, encodeURIComponent(String(companyMaterialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} licenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseById: async (companyId: string, licenseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getLicenseById', 'companyId', companyId)
            // verify required parameter 'licenseId' is not null or undefined
            assertParamExists('getLicenseById', 'licenseId', licenseId)
            const localVarPath = `/civil/companies/{companyId}/licenses/{licenseId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"licenseId"}}`, encodeURIComponent(String(licenseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenses: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getLicenses', 'companyId', companyId)
            const localVarPath = `/civil/companies/{companyId}/licenses`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourceSuppliersByCompanyId: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getSourceSuppliersByCompanyId', 'companyId', companyId)
            const localVarPath = `/civil/companies/{companyId}/sources`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubMaterialById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSubMaterialById', 'id', id)
            const localVarPath = `/civil/companies/sub-materials/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransporters: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTransporters', 'companyId', companyId)
            const localVarPath = `/civil/companies/{companyId}/transporters`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (companyId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getUserById', 'companyId', companyId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserById', 'userId', userId)
            const localVarPath = `/civil/companies/{companyId}/users/{userId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersForCompany: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getUsersForCompany', 'companyId', companyId)
            const localVarPath = `/civil/companies/{companyId}/users`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} companyMaterialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCompanyMaterial: async (companyId: string, companyMaterialId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('removeCompanyMaterial', 'companyId', companyId)
            // verify required parameter 'companyMaterialId' is not null or undefined
            assertParamExists('removeCompanyMaterial', 'companyMaterialId', companyMaterialId)
            const localVarPath = `/civil/companies/{companyId}/materials/{companyMaterialId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"companyMaterialId"}}`, encodeURIComponent(String(companyMaterialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCompanySubMaterial: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeCompanySubMaterial', 'id', id)
            const localVarPath = `/civil/companies/sub-materials/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} parentContractorCompanyId 
         * @param {IUpsertCompanyApiMapping} iUpsertCompanyApiMapping 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCompanyApiMapping: async (companyId: string, parentContractorCompanyId: string, iUpsertCompanyApiMapping: IUpsertCompanyApiMapping, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('saveCompanyApiMapping', 'companyId', companyId)
            // verify required parameter 'parentContractorCompanyId' is not null or undefined
            assertParamExists('saveCompanyApiMapping', 'parentContractorCompanyId', parentContractorCompanyId)
            // verify required parameter 'iUpsertCompanyApiMapping' is not null or undefined
            assertParamExists('saveCompanyApiMapping', 'iUpsertCompanyApiMapping', iUpsertCompanyApiMapping)
            const localVarPath = `/civil/companies/{companyId}/{parentContractorCompanyId}/api-mapping`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"parentContractorCompanyId"}}`, encodeURIComponent(String(parentContractorCompanyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iUpsertCompanyApiMapping, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertCompanySubMaterial} upsertCompanySubMaterial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCompanySubMaterial: async (upsertCompanySubMaterial: UpsertCompanySubMaterial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upsertCompanySubMaterial' is not null or undefined
            assertParamExists('saveCompanySubMaterial', 'upsertCompanySubMaterial', upsertCompanySubMaterial)
            const localVarPath = `/civil/companies/sub-materials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertCompanySubMaterial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} city 
         * @param {string} state 
         * @param {string} domain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCompany: async (name: string, city: string, state: string, domain: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('validateCompany', 'name', name)
            // verify required parameter 'city' is not null or undefined
            assertParamExists('validateCompany', 'city', city)
            // verify required parameter 'state' is not null or undefined
            assertParamExists('validateCompany', 'state', state)
            // verify required parameter 'domain' is not null or undefined
            assertParamExists('validateCompany', 'domain', domain)
            const localVarPath = `/civil/companies/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CivilCompaniesApi - functional programming interface
 * @export
 */
export const CivilCompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CivilCompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateCompany(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateCompany(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {ICompanyMaterial} iCompanyMaterial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCompanyMaterial(companyId: string, iCompanyMaterial: ICompanyMaterial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCompanyMaterial(companyId, iCompanyMaterial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNewCompany(companyId: string, requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addNewCompany(companyId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} licenseId 
         * @param {AddUserToLicenseRequest} addUserToLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserToLicense(companyId: string, licenseId: string, addUserToLicenseRequest: AddUserToLicenseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserToLicense(companyId, licenseId, addUserToLicenseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} apiMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompanyApiMapping(companyId: string, apiMappingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyApiMapping>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompanyApiMapping(companyId, apiMappingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(companyId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteUser200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(companyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} type 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAffiliates(companyId: string, type: string, query: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAffiliates(companyId, type, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompanies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ICompany>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompanies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompanyMaterials(companyId: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompanyMaterials(companyId, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompanySubMaterials(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CivilCompanySubMaterials>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompanySubMaterials(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} parentContractorCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyApiMapping(companyId: string, parentContractorCompanyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyApiMapping>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyApiMapping(companyId, parentContractorCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyById(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IJoinCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyById(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} companyMaterialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyMaterialById(companyId: string, companyMaterialId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyMaterialById(companyId, companyMaterialId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} licenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenseById(companyId: string, licenseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicenseById(companyId, licenseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicenses(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicenses(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSourceSuppliersByCompanyId(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetSourceSuppliersByCompanyId200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSourceSuppliersByCompanyId(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubMaterialById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CivilCompanySubMaterials>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubMaterialById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransporters(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetTransporters200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransporters(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(companyId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(companyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersForCompany(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersForCompany(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} companyMaterialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeCompanyMaterial(companyId: string, companyMaterialId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICompanyMaterial>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeCompanyMaterial(companyId, companyMaterialId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeCompanySubMaterial(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CivilCompanySubMaterials>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeCompanySubMaterial(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} parentContractorCompanyId 
         * @param {IUpsertCompanyApiMapping} iUpsertCompanyApiMapping 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCompanyApiMapping(companyId: string, parentContractorCompanyId: string, iUpsertCompanyApiMapping: IUpsertCompanyApiMapping, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCompanyApiMapping(companyId, parentContractorCompanyId, iUpsertCompanyApiMapping, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertCompanySubMaterial} upsertCompanySubMaterial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCompanySubMaterial(upsertCompanySubMaterial: UpsertCompanySubMaterial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CivilCompanySubMaterials>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCompanySubMaterial(upsertCompanySubMaterial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} city 
         * @param {string} state 
         * @param {string} domain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateCompany(name: string, city: string, state: string, domain: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ICompany>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateCompany(name, city, state, domain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CivilCompaniesApi - factory interface
 * @export
 */
export const CivilCompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CivilCompaniesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCompany(companyId: string, options?: any): AxiosPromise<number> {
            return localVarFp.activateCompany(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {ICompanyMaterial} iCompanyMaterial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCompanyMaterial(companyId: string, iCompanyMaterial: ICompanyMaterial, options?: any): AxiosPromise<any> {
            return localVarFp.addCompanyMaterial(companyId, iCompanyMaterial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewCompany(companyId: string, requestBody: { [key: string]: any; }, options?: any): AxiosPromise<ICompany> {
            return localVarFp.addNewCompany(companyId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} licenseId 
         * @param {AddUserToLicenseRequest} addUserToLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToLicense(companyId: string, licenseId: string, addUserToLicenseRequest: AddUserToLicenseRequest, options?: any): AxiosPromise<number> {
            return localVarFp.addUserToLicense(companyId, licenseId, addUserToLicenseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} apiMappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyApiMapping(companyId: string, apiMappingId: number, options?: any): AxiosPromise<CompanyApiMapping> {
            return localVarFp.deleteCompanyApiMapping(companyId, apiMappingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(companyId: string, userId: string, options?: any): AxiosPromise<DeleteUser200Response> {
            return localVarFp.deleteUser(companyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} type 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAffiliates(companyId: string, type: string, query: string, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.getAffiliates(companyId, type, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanies(options?: any): AxiosPromise<Array<ICompany>> {
            return localVarFp.getAllCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyMaterials(companyId: string, search?: string, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.getAllCompanyMaterials(companyId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanySubMaterials(companyId: string, options?: any): AxiosPromise<Array<CivilCompanySubMaterials>> {
            return localVarFp.getAllCompanySubMaterials(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} parentContractorCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyApiMapping(companyId: string, parentContractorCompanyId: string, options?: any): AxiosPromise<Array<CompanyApiMapping>> {
            return localVarFp.getCompanyApiMapping(companyId, parentContractorCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById(companyId: string, options?: any): AxiosPromise<IJoinCompany> {
            return localVarFp.getCompanyById(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} companyMaterialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyMaterialById(companyId: string, companyMaterialId: number, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.getCompanyMaterialById(companyId, companyMaterialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} licenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenseById(companyId: string, licenseId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getLicenseById(companyId, licenseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicenses(companyId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getLicenses(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourceSuppliersByCompanyId(companyId: string, options?: any): AxiosPromise<Array<GetSourceSuppliersByCompanyId200ResponseInner>> {
            return localVarFp.getSourceSuppliersByCompanyId(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubMaterialById(id: number, options?: any): AxiosPromise<CivilCompanySubMaterials> {
            return localVarFp.getSubMaterialById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransporters(companyId: string, options?: any): AxiosPromise<Array<GetTransporters200ResponseInner>> {
            return localVarFp.getTransporters(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(companyId: string, userId: string, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.getUserById(companyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersForCompany(companyId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getUsersForCompany(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} companyMaterialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCompanyMaterial(companyId: string, companyMaterialId: number, options?: any): AxiosPromise<ICompanyMaterial> {
            return localVarFp.removeCompanyMaterial(companyId, companyMaterialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCompanySubMaterial(id: number, options?: any): AxiosPromise<CivilCompanySubMaterials> {
            return localVarFp.removeCompanySubMaterial(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} parentContractorCompanyId 
         * @param {IUpsertCompanyApiMapping} iUpsertCompanyApiMapping 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCompanyApiMapping(companyId: string, parentContractorCompanyId: string, iUpsertCompanyApiMapping: IUpsertCompanyApiMapping, options?: any): AxiosPromise<any> {
            return localVarFp.saveCompanyApiMapping(companyId, parentContractorCompanyId, iUpsertCompanyApiMapping, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertCompanySubMaterial} upsertCompanySubMaterial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCompanySubMaterial(upsertCompanySubMaterial: UpsertCompanySubMaterial, options?: any): AxiosPromise<CivilCompanySubMaterials> {
            return localVarFp.saveCompanySubMaterial(upsertCompanySubMaterial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} city 
         * @param {string} state 
         * @param {string} domain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCompany(name: string, city: string, state: string, domain: string, options?: any): AxiosPromise<Array<ICompany>> {
            return localVarFp.validateCompany(name, city, state, domain, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CivilCompaniesApi - object-oriented interface
 * @export
 * @class CivilCompaniesApi
 * @extends {BaseAPI}
 */
export class CivilCompaniesApi extends BaseAPI {
    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public activateCompany(companyId: string, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).activateCompany(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {ICompanyMaterial} iCompanyMaterial 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public addCompanyMaterial(companyId: string, iCompanyMaterial: ICompanyMaterial, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).addCompanyMaterial(companyId, iCompanyMaterial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public addNewCompany(companyId: string, requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).addNewCompany(companyId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} licenseId 
     * @param {AddUserToLicenseRequest} addUserToLicenseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public addUserToLicense(companyId: string, licenseId: string, addUserToLicenseRequest: AddUserToLicenseRequest, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).addUserToLicense(companyId, licenseId, addUserToLicenseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {number} apiMappingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public deleteCompanyApiMapping(companyId: string, apiMappingId: number, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).deleteCompanyApiMapping(companyId, apiMappingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public deleteUser(companyId: string, userId: string, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).deleteUser(companyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} type 
     * @param {string} query 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public getAffiliates(companyId: string, type: string, query: string, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).getAffiliates(companyId, type, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public getAllCompanies(options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).getAllCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public getAllCompanyMaterials(companyId: string, search?: string, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).getAllCompanyMaterials(companyId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public getAllCompanySubMaterials(companyId: string, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).getAllCompanySubMaterials(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} parentContractorCompanyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public getCompanyApiMapping(companyId: string, parentContractorCompanyId: string, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).getCompanyApiMapping(companyId, parentContractorCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public getCompanyById(companyId: string, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).getCompanyById(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {number} companyMaterialId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public getCompanyMaterialById(companyId: string, companyMaterialId: number, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).getCompanyMaterialById(companyId, companyMaterialId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} licenseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public getLicenseById(companyId: string, licenseId: string, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).getLicenseById(companyId, licenseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public getLicenses(companyId: string, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).getLicenses(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public getSourceSuppliersByCompanyId(companyId: string, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).getSourceSuppliersByCompanyId(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public getSubMaterialById(id: number, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).getSubMaterialById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public getTransporters(companyId: string, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).getTransporters(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public getUserById(companyId: string, userId: string, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).getUserById(companyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public getUsersForCompany(companyId: string, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).getUsersForCompany(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {number} companyMaterialId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public removeCompanyMaterial(companyId: string, companyMaterialId: number, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).removeCompanyMaterial(companyId, companyMaterialId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public removeCompanySubMaterial(id: number, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).removeCompanySubMaterial(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} parentContractorCompanyId 
     * @param {IUpsertCompanyApiMapping} iUpsertCompanyApiMapping 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public saveCompanyApiMapping(companyId: string, parentContractorCompanyId: string, iUpsertCompanyApiMapping: IUpsertCompanyApiMapping, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).saveCompanyApiMapping(companyId, parentContractorCompanyId, iUpsertCompanyApiMapping, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertCompanySubMaterial} upsertCompanySubMaterial 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public saveCompanySubMaterial(upsertCompanySubMaterial: UpsertCompanySubMaterial, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).saveCompanySubMaterial(upsertCompanySubMaterial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} city 
     * @param {string} state 
     * @param {string} domain 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilCompaniesApi
     */
    public validateCompany(name: string, city: string, state: string, domain: string, options?: AxiosRequestConfig) {
        return CivilCompaniesApiFp(this.configuration).validateCompany(name, city, state, domain, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CivilDownloadsApi - axios parameter creator
 * @export
 */
export const CivilDownloadsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTicketToDownloadStorageBucket: async (requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('addTicketToDownloadStorageBucket', 'requestBody', requestBody)
            const localVarPath = `/civil/downloads/civil-pub-sub/add-ticket-to-download-storage-bucket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDownloadZipArchiveAndAddItToStorageBucket: async (requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('createDownloadZipArchiveAndAddItToStorageBucket', 'requestBody', requestBody)
            const localVarPath = `/civil/downloads/civil-pub-sub/create-download-zip-archive-and-add-it-to-download-storage-bucket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DownloadTicketsParams} downloadTicketsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTickets: async (downloadTicketsParams: DownloadTicketsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'downloadTicketsParams' is not null or undefined
            assertParamExists('downloadTickets', 'downloadTicketsParams', downloadTicketsParams)
            const localVarPath = `/civil/downloads/civil-pub-sub/download-tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(downloadTicketsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} invoiceId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReconciliationReport: async (invoiceId: number, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('getReconciliationReport', 'invoiceId', invoiceId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getReconciliationReport', 'userId', userId)
            const localVarPath = `/civil/downloads/reports/reconciliation/{invoiceId}/user/{userId}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {string} userId 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketReport: async (ticketId: number, userId: string, companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('getTicketReport', 'ticketId', ticketId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getTicketReport', 'userId', userId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTicketReport', 'companyId', companyId)
            const localVarPath = `/civil/downloads/reports/ticket/{ticketId}/user/{userId}/company/{companyId}`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CivilDownloadsApi - functional programming interface
 * @export
 */
export const CivilDownloadsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CivilDownloadsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTicketToDownloadStorageBucket(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTicketToDownloadStorageBucket(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDownloadZipArchiveAndAddItToStorageBucket(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDownloadZipArchiveAndAddItToStorageBucket(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DownloadTicketsParams} downloadTicketsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTickets(downloadTicketsParams: DownloadTicketsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadManifests200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadTickets(downloadTicketsParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} invoiceId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReconciliationReport(invoiceId: number, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReconciliationReport200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReconciliationReport(invoiceId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {string} userId 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketReport(ticketId: number, userId: string, companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReconciliationReport200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketReport(ticketId, userId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CivilDownloadsApi - factory interface
 * @export
 */
export const CivilDownloadsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CivilDownloadsApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTicketToDownloadStorageBucket(requestBody: { [key: string]: any; }, options?: any): AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response> {
            return localVarFp.addTicketToDownloadStorageBucket(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDownloadZipArchiveAndAddItToStorageBucket(requestBody: { [key: string]: any; }, options?: any): AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response> {
            return localVarFp.createDownloadZipArchiveAndAddItToStorageBucket(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DownloadTicketsParams} downloadTicketsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTickets(downloadTicketsParams: DownloadTicketsParams, options?: any): AxiosPromise<DownloadManifests200Response> {
            return localVarFp.downloadTickets(downloadTicketsParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} invoiceId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReconciliationReport(invoiceId: number, userId: string, options?: any): AxiosPromise<GetReconciliationReport200Response> {
            return localVarFp.getReconciliationReport(invoiceId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {string} userId 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketReport(ticketId: number, userId: string, companyId: string, options?: any): AxiosPromise<GetReconciliationReport200Response> {
            return localVarFp.getTicketReport(ticketId, userId, companyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CivilDownloadsApi - object-oriented interface
 * @export
 * @class CivilDownloadsApi
 * @extends {BaseAPI}
 */
export class CivilDownloadsApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilDownloadsApi
     */
    public addTicketToDownloadStorageBucket(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return CivilDownloadsApiFp(this.configuration).addTicketToDownloadStorageBucket(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilDownloadsApi
     */
    public createDownloadZipArchiveAndAddItToStorageBucket(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return CivilDownloadsApiFp(this.configuration).createDownloadZipArchiveAndAddItToStorageBucket(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DownloadTicketsParams} downloadTicketsParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilDownloadsApi
     */
    public downloadTickets(downloadTicketsParams: DownloadTicketsParams, options?: AxiosRequestConfig) {
        return CivilDownloadsApiFp(this.configuration).downloadTickets(downloadTicketsParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} invoiceId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilDownloadsApi
     */
    public getReconciliationReport(invoiceId: number, userId: string, options?: AxiosRequestConfig) {
        return CivilDownloadsApiFp(this.configuration).getReconciliationReport(invoiceId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ticketId 
     * @param {string} userId 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilDownloadsApi
     */
    public getTicketReport(ticketId: number, userId: string, companyId: string, options?: AxiosRequestConfig) {
        return CivilDownloadsApiFp(this.configuration).getTicketReport(ticketId, userId, companyId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CivilInvoicesApi - axios parameter creator
 * @export
 */
export const CivilInvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {IInvoice} iInvoice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInvoice: async (iInvoice: IInvoice, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iInvoice' is not null or undefined
            assertParamExists('addInvoice', 'iInvoice', iInvoice)
            const localVarPath = `/civil/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iInvoice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeInvoice: async (invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('removeInvoice', 'invoiceId', invoiceId)
            const localVarPath = `/civil/invoices/{invoiceId}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CivilInvoicesApi - functional programming interface
 * @export
 */
export const CivilInvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CivilInvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {IInvoice} iInvoice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addInvoice(iInvoice: IInvoice, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addInvoice(iInvoice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeInvoice(invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeInvoice(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CivilInvoicesApi - factory interface
 * @export
 */
export const CivilInvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CivilInvoicesApiFp(configuration)
    return {
        /**
         * 
         * @param {IInvoice} iInvoice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInvoice(iInvoice: IInvoice, options?: any): AxiosPromise<any> {
            return localVarFp.addInvoice(iInvoice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeInvoice(invoiceId: number, options?: any): AxiosPromise<any> {
            return localVarFp.removeInvoice(invoiceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CivilInvoicesApi - object-oriented interface
 * @export
 * @class CivilInvoicesApi
 * @extends {BaseAPI}
 */
export class CivilInvoicesApi extends BaseAPI {
    /**
     * 
     * @param {IInvoice} iInvoice 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilInvoicesApi
     */
    public addInvoice(iInvoice: IInvoice, options?: AxiosRequestConfig) {
        return CivilInvoicesApiFp(this.configuration).addInvoice(iInvoice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilInvoicesApi
     */
    public removeInvoice(invoiceId: number, options?: AxiosRequestConfig) {
        return CivilInvoicesApiFp(this.configuration).removeInvoice(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CivilProjectsApi - axios parameter creator
 * @export
 */
export const CivilProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} projectId 
         * @param {IProjectCompanyCollaborator} iProjectCompanyCollaborator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCollaborator: async (projectId: number, iProjectCompanyCollaborator: IProjectCompanyCollaborator, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addCollaborator', 'projectId', projectId)
            // verify required parameter 'iProjectCompanyCollaborator' is not null or undefined
            assertParamExists('addCollaborator', 'iProjectCompanyCollaborator', iProjectCompanyCollaborator)
            const localVarPath = `/civil/projects/{projectId}/collaborators`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iProjectCompanyCollaborator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLocationToProject: async (projectId: number, body: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addLocationToProject', 'projectId', projectId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addLocationToProject', 'body', body)
            const localVarPath = `/civil/projects/{projectId}/locations`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {IActivity} iActivity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewActivity: async (projectId: number, iActivity: IActivity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addNewActivity', 'projectId', projectId)
            // verify required parameter 'iActivity' is not null or undefined
            assertParamExists('addNewActivity', 'iActivity', iActivity)
            const localVarPath = `/civil/projects/{projectId}/activities`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iActivity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PickIProjectExcludeKeyofIProjectIsArchived} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProject: async (body: PickIProjectExcludeKeyofIProjectIsArchived, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addProject', 'body', body)
            const localVarPath = `/civil/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {IBaseProjectMaterial} iBaseProjectMaterial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectMaterial: async (projectId: number, iBaseProjectMaterial: IBaseProjectMaterial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addProjectMaterial', 'projectId', projectId)
            // verify required parameter 'iBaseProjectMaterial' is not null or undefined
            assertParamExists('addProjectMaterial', 'iBaseProjectMaterial', iBaseProjectMaterial)
            const localVarPath = `/civil/projects/{projectId}/materials`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseProjectMaterial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IProjectTask} iProjectTask 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectTask: async (iProjectTask: IProjectTask, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iProjectTask' is not null or undefined
            assertParamExists('addProjectTask', 'iProjectTask', iProjectTask)
            const localVarPath = `/civil/projects/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iProjectTask, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} companyId 
         * @param {Array<IProjectTemplateDetail>} iProjectTemplateDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectTasksFromTemplate: async (projectId: number, companyId: string, iProjectTemplateDetail: Array<IProjectTemplateDetail>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addProjectTasksFromTemplate', 'projectId', projectId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('addProjectTasksFromTemplate', 'companyId', companyId)
            // verify required parameter 'iProjectTemplateDetail' is not null or undefined
            assertParamExists('addProjectTasksFromTemplate', 'iProjectTemplateDetail', iProjectTemplateDetail)
            const localVarPath = `/civil/projects/{projectId}/templates/{companyId}/details`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iProjectTemplateDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {PickIProjectTemplateExcludeKeyofIProjectTemplateDateCreated} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectTemplate: async (projectId: number, body: PickIProjectTemplateExcludeKeyofIProjectTemplateDateCreated, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addProjectTemplate', 'projectId', projectId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addProjectTemplate', 'body', body)
            const localVarPath = `/civil/projects/{projectId}/templates`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {IProjectUserCollaborator} iProjectUserCollaborator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToProject: async (projectId: number, iProjectUserCollaborator: IProjectUserCollaborator, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addUserToProject', 'projectId', projectId)
            // verify required parameter 'iProjectUserCollaborator' is not null or undefined
            assertParamExists('addUserToProject', 'iProjectUserCollaborator', iProjectUserCollaborator)
            const localVarPath = `/civil/projects/{projectId}/users`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iProjectUserCollaborator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {IActivityType} iActivityType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWorkCategory: async (projectId: number, iActivityType: IActivityType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addWorkCategory', 'projectId', projectId)
            // verify required parameter 'iActivityType' is not null or undefined
            assertParamExists('addWorkCategory', 'iActivityType', iActivityType)
            const localVarPath = `/civil/projects/{projectId}/activities/types`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iActivityType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} mappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiMapping: async (projectId: number, mappingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteApiMapping', 'projectId', projectId)
            // verify required parameter 'mappingId' is not null or undefined
            assertParamExists('deleteApiMapping', 'mappingId', mappingId)
            const localVarPath = `/civil/projects/{projectId}/api-mappings/{mappingId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"mappingId"}}`, encodeURIComponent(String(mappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectTask: async (taskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('deleteProjectTask', 'taskId', taskId)
            const localVarPath = `/civil/projects/tasks/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [type] 
         * @param {number} [workCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivities: async (projectId: number, type?: string, workCategoryId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getActivities', 'projectId', projectId)
            const localVarPath = `/civil/projects/{projectId}/activities`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (workCategoryId !== undefined) {
                localVarQueryParameter['workCategoryId'] = workCategoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectSubMaterialsByProjectId: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getAllProjectSubMaterialsByProjectId', 'projectId', projectId)
            const localVarPath = `/civil/projects/sub-materials/by-project/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} companySubMatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectSubMaterialsByProjectIdAndCompanySubMaterial: async (projectId: number, companySubMatId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getAllProjectSubMaterialsByProjectIdAndCompanySubMaterial', 'projectId', projectId)
            // verify required parameter 'companySubMatId' is not null or undefined
            assertParamExists('getAllProjectSubMaterialsByProjectIdAndCompanySubMaterial', 'companySubMatId', companySubMatId)
            const localVarPath = `/civil/projects/sub-materials/by-project-and-company-sub-material/{projectId}/{companySubMatId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"companySubMatId"}}`, encodeURIComponent(String(companySubMatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMappingsByProject: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getApiMappingsByProject', 'projectId', projectId)
            const localVarPath = `/civil/projects/{projectId}/api-mappings`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} civilLocationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByLocationId: async (projectId: number, civilLocationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getByLocationId', 'projectId', projectId)
            // verify required parameter 'civilLocationId' is not null or undefined
            assertParamExists('getByLocationId', 'civilLocationId', civilLocationId)
            const localVarPath = `/civil/projects/{projectId}/locations/{civilLocationId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"civilLocationId"}}`, encodeURIComponent(String(civilLocationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollaborators: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getCollaborators', 'projectId', projectId)
            const localVarPath = `/civil/projects/{projectId}/collaborators`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} companyId 
         * @param {number} projectTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailsForTemplateId: async (projectId: number, companyId: string, projectTemplateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getDetailsForTemplateId', 'projectId', projectId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getDetailsForTemplateId', 'companyId', companyId)
            // verify required parameter 'projectTemplateId' is not null or undefined
            assertParamExists('getDetailsForTemplateId', 'projectTemplateId', projectTemplateId)
            const localVarPath = `/civil/projects/{projectId}/templates/{companyId}/details/{projectTemplateId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"projectTemplateId"}}`, encodeURIComponent(String(projectTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesByProjectId: async (projectId: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getInvoicesByProjectId', 'projectId', projectId)
            const localVarPath = `/civil/projects/{projectId}/invoices`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationsByProjectId: async (projectId: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getLocationsByProjectId', 'projectId', projectId)
            const localVarPath = `/civil/projects/{projectId}/locations`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaterialsByProjectId: async (projectId: number, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getMaterialsByProjectId', 'projectId', projectId)
            const localVarPath = `/civil/projects/{projectId}/materials`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectById', 'projectId', projectId)
            const localVarPath = `/civil/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTaskById: async (taskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('getProjectTaskById', 'taskId', taskId)
            const localVarPath = `/civil/projects/tasks/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTasks: async (projectId: number, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectTasks', 'projectId', projectId)
            const localVarPath = `/civil/projects/{projectId}/tasks`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTemplates: async (projectId: number, companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectTemplates', 'projectId', projectId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getProjectTemplates', 'companyId', companyId)
            const localVarPath = `/civil/projects/{projectId}/templates/{companyId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/civil/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketAccountingSummary: async (projectId: number, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTicketAccountingSummary', 'projectId', projectId)
            const localVarPath = `/civil/projects/{projectId}/accounting/ticket-summary`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByProjectId: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getUsersByProjectId', 'projectId', projectId)
            const localVarPath = `/civil/projects/{projectId}/users`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkCategories: async (projectId: number, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getWorkCategories', 'projectId', projectId)
            const localVarPath = `/civil/projects/{projectId}/activities/types`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {ILocationToProjectImport} iLocationToProjectImport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importLocationToProject: async (projectId: number, iLocationToProjectImport: ILocationToProjectImport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('importLocationToProject', 'projectId', projectId)
            // verify required parameter 'iLocationToProjectImport' is not null or undefined
            assertParamExists('importLocationToProject', 'iLocationToProjectImport', iLocationToProjectImport)
            const localVarPath = `/civil/projects/{projectId}/locations/import`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iLocationToProjectImport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeActivity: async (projectId: number, activityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('removeActivity', 'projectId', projectId)
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('removeActivity', 'activityId', activityId)
            const localVarPath = `/civil/projects/{projectId}/activities/{activityId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} collaboratorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCollaborator: async (projectId: number, collaboratorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('removeCollaborator', 'projectId', projectId)
            // verify required parameter 'collaboratorId' is not null or undefined
            assertParamExists('removeCollaborator', 'collaboratorId', collaboratorId)
            const localVarPath = `/civil/projects/{projectId}/collaborators/{collaboratorId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"collaboratorId"}}`, encodeURIComponent(String(collaboratorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} locationToProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeLocationToProject: async (projectId: number, locationToProjectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('removeLocationToProject', 'projectId', projectId)
            // verify required parameter 'locationToProjectId' is not null or undefined
            assertParamExists('removeLocationToProject', 'locationToProjectId', locationToProjectId)
            const localVarPath = `/civil/projects/{projectId}/locations/{locationToProjectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"locationToProjectId"}}`, encodeURIComponent(String(locationToProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} projectMaterialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProjectMaterial: async (projectId: number, projectMaterialId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('removeProjectMaterial', 'projectId', projectId)
            // verify required parameter 'projectMaterialId' is not null or undefined
            assertParamExists('removeProjectMaterial', 'projectMaterialId', projectMaterialId)
            const localVarPath = `/civil/projects/{projectId}/materials/{projectMaterialId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"projectMaterialId"}}`, encodeURIComponent(String(projectMaterialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProjectSubMaterial: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeProjectSubMaterial', 'id', id)
            const localVarPath = `/civil/projects/sub-materials/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} projectTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProjectTemplate: async (projectId: number, projectTemplateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('removeProjectTemplate', 'projectId', projectId)
            // verify required parameter 'projectTemplateId' is not null or undefined
            assertParamExists('removeProjectTemplate', 'projectTemplateId', projectTemplateId)
            const localVarPath = `/civil/projects/{projectId}/templates/{projectTemplateId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"projectTemplateId"}}`, encodeURIComponent(String(projectTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} civilUsersToProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromProject: async (projectId: number, civilUsersToProjectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('removeUserFromProject', 'projectId', projectId)
            // verify required parameter 'civilUsersToProjectId' is not null or undefined
            assertParamExists('removeUserFromProject', 'civilUsersToProjectId', civilUsersToProjectId)
            const localVarPath = `/civil/projects/{projectId}/users/{civilUsersToProjectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"civilUsersToProjectId"}}`, encodeURIComponent(String(civilUsersToProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} workCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWorkCategory: async (projectId: number, workCategoryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('removeWorkCategory', 'projectId', projectId)
            // verify required parameter 'workCategoryId' is not null or undefined
            assertParamExists('removeWorkCategory', 'workCategoryId', workCategoryId)
            const localVarPath = `/civil/projects/{projectId}/activities/types/{workCategoryId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"workCategoryId"}}`, encodeURIComponent(String(workCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {SaveProjectApiMapping} saveProjectApiMapping 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveApiMappingByProject: async (projectId: number, saveProjectApiMapping: SaveProjectApiMapping, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('saveApiMappingByProject', 'projectId', projectId)
            // verify required parameter 'saveProjectApiMapping' is not null or undefined
            assertParamExists('saveApiMappingByProject', 'saveProjectApiMapping', saveProjectApiMapping)
            const localVarPath = `/civil/projects/{projectId}/api-mappings`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveProjectApiMapping, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertProjectSubMaterial} upsertProjectSubMaterial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProjectSubMaterial: async (upsertProjectSubMaterial: UpsertProjectSubMaterial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upsertProjectSubMaterial' is not null or undefined
            assertParamExists('saveProjectSubMaterial', 'upsertProjectSubMaterial', upsertProjectSubMaterial)
            const localVarPath = `/civil/projects/sub-materials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertProjectSubMaterial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} companySubMatId 
         * @param {Array<UpsertProjectSubMaterial>} upsertProjectSubMaterial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProjectSubMaterialsConfiguration: async (projectId: number, companySubMatId: number, upsertProjectSubMaterial: Array<UpsertProjectSubMaterial>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('saveProjectSubMaterialsConfiguration', 'projectId', projectId)
            // verify required parameter 'companySubMatId' is not null or undefined
            assertParamExists('saveProjectSubMaterialsConfiguration', 'companySubMatId', companySubMatId)
            // verify required parameter 'upsertProjectSubMaterial' is not null or undefined
            assertParamExists('saveProjectSubMaterialsConfiguration', 'upsertProjectSubMaterial', upsertProjectSubMaterial)
            const localVarPath = `/civil/projects/sub-materials/save-project-sub-materials-configuration/{projectId}/{companySubMatId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"companySubMatId"}}`, encodeURIComponent(String(companySubMatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertProjectSubMaterial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {SetProjectLocationRequest} setProjectLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProjectLocation: async (projectId: number, setProjectLocationRequest: SetProjectLocationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('setProjectLocation', 'projectId', projectId)
            // verify required parameter 'setProjectLocationRequest' is not null or undefined
            assertParamExists('setProjectLocation', 'setProjectLocationRequest', setProjectLocationRequest)
            const localVarPath = `/civil/projects/{projectId}/accounting/ticket-summary`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setProjectLocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} civilLocationId 
         * @param {UpdateLocationByIdRequest} updateLocationByIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocationById: async (projectId: number, civilLocationId: number, updateLocationByIdRequest: UpdateLocationByIdRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateLocationById', 'projectId', projectId)
            // verify required parameter 'civilLocationId' is not null or undefined
            assertParamExists('updateLocationById', 'civilLocationId', civilLocationId)
            // verify required parameter 'updateLocationByIdRequest' is not null or undefined
            assertParamExists('updateLocationById', 'updateLocationByIdRequest', updateLocationByIdRequest)
            const localVarPath = `/civil/projects/{projectId}/locations/{civilLocationId}/update`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"civilLocationId"}}`, encodeURIComponent(String(civilLocationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLocationByIdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CivilProjectsApi - functional programming interface
 * @export
 */
export const CivilProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CivilProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} projectId 
         * @param {IProjectCompanyCollaborator} iProjectCompanyCollaborator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCollaborator(projectId: number, iProjectCompanyCollaborator: IProjectCompanyCollaborator, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCollaborator(projectId, iProjectCompanyCollaborator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLocationToProject(projectId: number, body: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLocationToProject(projectId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {IActivity} iActivity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNewActivity(projectId: number, iActivity: IActivity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addNewActivity(projectId, iActivity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PickIProjectExcludeKeyofIProjectIsArchived} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProject(body: PickIProjectExcludeKeyofIProjectIsArchived, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProject(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {IBaseProjectMaterial} iBaseProjectMaterial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProjectMaterial(projectId: number, iBaseProjectMaterial: IBaseProjectMaterial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBaseProjectMaterial>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProjectMaterial(projectId, iBaseProjectMaterial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IProjectTask} iProjectTask 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProjectTask(iProjectTask: IProjectTask, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProjectTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProjectTask(iProjectTask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} companyId 
         * @param {Array<IProjectTemplateDetail>} iProjectTemplateDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProjectTasksFromTemplate(projectId: number, companyId: string, iProjectTemplateDetail: Array<IProjectTemplateDetail>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IProjectTemplateDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProjectTasksFromTemplate(projectId, companyId, iProjectTemplateDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {PickIProjectTemplateExcludeKeyofIProjectTemplateDateCreated} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProjectTemplate(projectId: number, body: PickIProjectTemplateExcludeKeyofIProjectTemplateDateCreated, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProjectTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProjectTemplate(projectId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {IProjectUserCollaborator} iProjectUserCollaborator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserToProject(projectId: number, iProjectUserCollaborator: IProjectUserCollaborator, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserToProject(projectId, iProjectUserCollaborator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {IActivityType} iActivityType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addWorkCategory(projectId: number, iActivityType: IActivityType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IActivityType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addWorkCategory(projectId, iActivityType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} mappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiMapping(projectId: number, mappingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CivilProjectApiMappings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiMapping(projectId, mappingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectTask(taskId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProjectTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectTask(taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [type] 
         * @param {number} [workCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivities(projectId: number, type?: string, workCategoryId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivities(projectId, type, workCategoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProjectSubMaterialsByProjectId(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CivilProjectSubMaterials>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProjectSubMaterialsByProjectId(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} companySubMatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProjectSubMaterialsByProjectIdAndCompanySubMaterial(projectId: number, companySubMatId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CivilProjectSubMaterials>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProjectSubMaterialsByProjectIdAndCompanySubMaterial(projectId, companySubMatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiMappingsByProject(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetApiMappingsByProject200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiMappingsByProject(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} civilLocationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByLocationId(projectId: number, civilLocationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ILocation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByLocationId(projectId, civilLocationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollaborators(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollaborators(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} companyId 
         * @param {number} projectTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetailsForTemplateId(projectId: number, companyId: string, projectTemplateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IProjectTemplateDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetailsForTemplateId(projectId, companyId, projectTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoicesByProjectId(projectId: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IInvoice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoicesByProjectId(projectId, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationsByProjectId(projectId: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ILocation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationsByProjectId(projectId, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMaterialsByProjectId(projectId: number, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IProjectMaterial>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMaterialsByProjectId(projectId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectById(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectById(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectTaskById(taskId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProjectTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectTaskById(taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectTasks(projectId: number, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectTasks(projectId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectTemplates(projectId: number, companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectTemplates(projectId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjects(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IProject>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketAccountingSummary(projectId: number, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketAccountingSummary(projectId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersByProjectId(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersByProjectId(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkCategories(projectId: number, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkCategories(projectId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {ILocationToProjectImport} iLocationToProjectImport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importLocationToProject(projectId: number, iLocationToProjectImport: ILocationToProjectImport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importLocationToProject(projectId, iLocationToProjectImport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeActivity(projectId: number, activityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeActivity(projectId, activityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} collaboratorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeCollaborator(projectId: number, collaboratorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeCollaborator(projectId, collaboratorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} locationToProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeLocationToProject(projectId: number, locationToProjectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeLocationToProject(projectId, locationToProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} projectMaterialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeProjectMaterial(projectId: number, projectMaterialId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeProjectMaterial(projectId, projectMaterialId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeProjectSubMaterial(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CivilProjectSubMaterials>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeProjectSubMaterial(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} projectTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeProjectTemplate(projectId: number, projectTemplateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeProjectTemplate(projectId, projectTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} civilUsersToProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUserFromProject(projectId: number, civilUsersToProjectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUserFromProject(projectId, civilUsersToProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} workCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWorkCategory(projectId: number, workCategoryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWorkCategory(projectId, workCategoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {SaveProjectApiMapping} saveProjectApiMapping 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveApiMappingByProject(projectId: number, saveProjectApiMapping: SaveProjectApiMapping, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CivilProjectApiMappings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveApiMappingByProject(projectId, saveProjectApiMapping, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertProjectSubMaterial} upsertProjectSubMaterial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveProjectSubMaterial(upsertProjectSubMaterial: UpsertProjectSubMaterial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CivilProjectSubMaterials>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveProjectSubMaterial(upsertProjectSubMaterial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} companySubMatId 
         * @param {Array<UpsertProjectSubMaterial>} upsertProjectSubMaterial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveProjectSubMaterialsConfiguration(projectId: number, companySubMatId: number, upsertProjectSubMaterial: Array<UpsertProjectSubMaterial>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveProjectSubMaterialsConfiguration(projectId, companySubMatId, upsertProjectSubMaterial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {SetProjectLocationRequest} setProjectLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setProjectLocation(projectId: number, setProjectLocationRequest: SetProjectLocationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetProjectLocation200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setProjectLocation(projectId, setProjectLocationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} civilLocationId 
         * @param {UpdateLocationByIdRequest} updateLocationByIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLocationById(projectId: number, civilLocationId: number, updateLocationByIdRequest: UpdateLocationByIdRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLocationById(projectId, civilLocationId, updateLocationByIdRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CivilProjectsApi - factory interface
 * @export
 */
export const CivilProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CivilProjectsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} projectId 
         * @param {IProjectCompanyCollaborator} iProjectCompanyCollaborator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCollaborator(projectId: number, iProjectCompanyCollaborator: IProjectCompanyCollaborator, options?: any): AxiosPromise<any> {
            return localVarFp.addCollaborator(projectId, iProjectCompanyCollaborator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLocationToProject(projectId: number, body: any, options?: any): AxiosPromise<any> {
            return localVarFp.addLocationToProject(projectId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {IActivity} iActivity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewActivity(projectId: number, iActivity: IActivity, options?: any): AxiosPromise<any> {
            return localVarFp.addNewActivity(projectId, iActivity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PickIProjectExcludeKeyofIProjectIsArchived} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProject(body: PickIProjectExcludeKeyofIProjectIsArchived, options?: any): AxiosPromise<IProject> {
            return localVarFp.addProject(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {IBaseProjectMaterial} iBaseProjectMaterial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectMaterial(projectId: number, iBaseProjectMaterial: IBaseProjectMaterial, options?: any): AxiosPromise<IBaseProjectMaterial> {
            return localVarFp.addProjectMaterial(projectId, iBaseProjectMaterial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IProjectTask} iProjectTask 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectTask(iProjectTask: IProjectTask, options?: any): AxiosPromise<IProjectTask> {
            return localVarFp.addProjectTask(iProjectTask, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} companyId 
         * @param {Array<IProjectTemplateDetail>} iProjectTemplateDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectTasksFromTemplate(projectId: number, companyId: string, iProjectTemplateDetail: Array<IProjectTemplateDetail>, options?: any): AxiosPromise<Array<IProjectTemplateDetail>> {
            return localVarFp.addProjectTasksFromTemplate(projectId, companyId, iProjectTemplateDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {PickIProjectTemplateExcludeKeyofIProjectTemplateDateCreated} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectTemplate(projectId: number, body: PickIProjectTemplateExcludeKeyofIProjectTemplateDateCreated, options?: any): AxiosPromise<IProjectTemplate> {
            return localVarFp.addProjectTemplate(projectId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {IProjectUserCollaborator} iProjectUserCollaborator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToProject(projectId: number, iProjectUserCollaborator: IProjectUserCollaborator, options?: any): AxiosPromise<any> {
            return localVarFp.addUserToProject(projectId, iProjectUserCollaborator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {IActivityType} iActivityType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWorkCategory(projectId: number, iActivityType: IActivityType, options?: any): AxiosPromise<IActivityType> {
            return localVarFp.addWorkCategory(projectId, iActivityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} mappingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiMapping(projectId: number, mappingId: number, options?: any): AxiosPromise<CivilProjectApiMappings> {
            return localVarFp.deleteApiMapping(projectId, mappingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectTask(taskId: number, options?: any): AxiosPromise<IProjectTask> {
            return localVarFp.deleteProjectTask(taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [type] 
         * @param {number} [workCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivities(projectId: number, type?: string, workCategoryId?: number, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.getActivities(projectId, type, workCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectSubMaterialsByProjectId(projectId: number, options?: any): AxiosPromise<Array<CivilProjectSubMaterials>> {
            return localVarFp.getAllProjectSubMaterialsByProjectId(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} companySubMatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectSubMaterialsByProjectIdAndCompanySubMaterial(projectId: number, companySubMatId: number, options?: any): AxiosPromise<Array<CivilProjectSubMaterials>> {
            return localVarFp.getAllProjectSubMaterialsByProjectIdAndCompanySubMaterial(projectId, companySubMatId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiMappingsByProject(projectId: number, options?: any): AxiosPromise<Array<GetApiMappingsByProject200ResponseInner>> {
            return localVarFp.getApiMappingsByProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} civilLocationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByLocationId(projectId: number, civilLocationId: number, options?: any): AxiosPromise<Array<ILocation>> {
            return localVarFp.getByLocationId(projectId, civilLocationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollaborators(projectId: number, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.getCollaborators(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} companyId 
         * @param {number} projectTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailsForTemplateId(projectId: number, companyId: string, projectTemplateId: number, options?: any): AxiosPromise<Array<IProjectTemplateDetail>> {
            return localVarFp.getDetailsForTemplateId(projectId, companyId, projectTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesByProjectId(projectId: number, search?: string, options?: any): AxiosPromise<Array<IInvoice>> {
            return localVarFp.getInvoicesByProjectId(projectId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationsByProjectId(projectId: number, search?: string, options?: any): AxiosPromise<Array<ILocation>> {
            return localVarFp.getLocationsByProjectId(projectId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaterialsByProjectId(projectId: number, type?: string, options?: any): AxiosPromise<Array<IProjectMaterial>> {
            return localVarFp.getMaterialsByProjectId(projectId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById(projectId: number, options?: any): AxiosPromise<IProject> {
            return localVarFp.getProjectById(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTaskById(taskId: number, options?: any): AxiosPromise<IProjectTask> {
            return localVarFp.getProjectTaskById(taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTasks(projectId: number, type?: string, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.getProjectTasks(projectId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTemplates(projectId: number, companyId: string, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.getProjectTemplates(projectId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(options?: any): AxiosPromise<Array<IProject>> {
            return localVarFp.getProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketAccountingSummary(projectId: number, type?: string, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.getTicketAccountingSummary(projectId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByProjectId(projectId: number, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.getUsersByProjectId(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkCategories(projectId: number, type?: string, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.getWorkCategories(projectId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {ILocationToProjectImport} iLocationToProjectImport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importLocationToProject(projectId: number, iLocationToProjectImport: ILocationToProjectImport, options?: any): AxiosPromise<boolean> {
            return localVarFp.importLocationToProject(projectId, iLocationToProjectImport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeActivity(projectId: number, activityId: number, options?: any): AxiosPromise<any> {
            return localVarFp.removeActivity(projectId, activityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} collaboratorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCollaborator(projectId: number, collaboratorId: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeCollaborator(projectId, collaboratorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} locationToProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeLocationToProject(projectId: number, locationToProjectId: number, options?: any): AxiosPromise<any> {
            return localVarFp.removeLocationToProject(projectId, locationToProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} projectMaterialId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProjectMaterial(projectId: number, projectMaterialId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.removeProjectMaterial(projectId, projectMaterialId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProjectSubMaterial(id: number, options?: any): AxiosPromise<CivilProjectSubMaterials> {
            return localVarFp.removeProjectSubMaterial(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} projectTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProjectTemplate(projectId: number, projectTemplateId: number, options?: any): AxiosPromise<any> {
            return localVarFp.removeProjectTemplate(projectId, projectTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} civilUsersToProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromProject(projectId: number, civilUsersToProjectId: number, options?: any): AxiosPromise<any> {
            return localVarFp.removeUserFromProject(projectId, civilUsersToProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} workCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWorkCategory(projectId: number, workCategoryId: number, options?: any): AxiosPromise<any> {
            return localVarFp.removeWorkCategory(projectId, workCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {SaveProjectApiMapping} saveProjectApiMapping 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveApiMappingByProject(projectId: number, saveProjectApiMapping: SaveProjectApiMapping, options?: any): AxiosPromise<CivilProjectApiMappings> {
            return localVarFp.saveApiMappingByProject(projectId, saveProjectApiMapping, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertProjectSubMaterial} upsertProjectSubMaterial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProjectSubMaterial(upsertProjectSubMaterial: UpsertProjectSubMaterial, options?: any): AxiosPromise<CivilProjectSubMaterials> {
            return localVarFp.saveProjectSubMaterial(upsertProjectSubMaterial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} companySubMatId 
         * @param {Array<UpsertProjectSubMaterial>} upsertProjectSubMaterial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProjectSubMaterialsConfiguration(projectId: number, companySubMatId: number, upsertProjectSubMaterial: Array<UpsertProjectSubMaterial>, options?: any): AxiosPromise<void> {
            return localVarFp.saveProjectSubMaterialsConfiguration(projectId, companySubMatId, upsertProjectSubMaterial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {SetProjectLocationRequest} setProjectLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProjectLocation(projectId: number, setProjectLocationRequest: SetProjectLocationRequest, options?: any): AxiosPromise<SetProjectLocation200Response> {
            return localVarFp.setProjectLocation(projectId, setProjectLocationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} civilLocationId 
         * @param {UpdateLocationByIdRequest} updateLocationByIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocationById(projectId: number, civilLocationId: number, updateLocationByIdRequest: UpdateLocationByIdRequest, options?: any): AxiosPromise<any> {
            return localVarFp.updateLocationById(projectId, civilLocationId, updateLocationByIdRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CivilProjectsApi - object-oriented interface
 * @export
 * @class CivilProjectsApi
 * @extends {BaseAPI}
 */
export class CivilProjectsApi extends BaseAPI {
    /**
     * 
     * @param {number} projectId 
     * @param {IProjectCompanyCollaborator} iProjectCompanyCollaborator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public addCollaborator(projectId: number, iProjectCompanyCollaborator: IProjectCompanyCollaborator, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).addCollaborator(projectId, iProjectCompanyCollaborator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public addLocationToProject(projectId: number, body: any, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).addLocationToProject(projectId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {IActivity} iActivity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public addNewActivity(projectId: number, iActivity: IActivity, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).addNewActivity(projectId, iActivity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PickIProjectExcludeKeyofIProjectIsArchived} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public addProject(body: PickIProjectExcludeKeyofIProjectIsArchived, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).addProject(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {IBaseProjectMaterial} iBaseProjectMaterial 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public addProjectMaterial(projectId: number, iBaseProjectMaterial: IBaseProjectMaterial, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).addProjectMaterial(projectId, iBaseProjectMaterial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IProjectTask} iProjectTask 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public addProjectTask(iProjectTask: IProjectTask, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).addProjectTask(iProjectTask, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} companyId 
     * @param {Array<IProjectTemplateDetail>} iProjectTemplateDetail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public addProjectTasksFromTemplate(projectId: number, companyId: string, iProjectTemplateDetail: Array<IProjectTemplateDetail>, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).addProjectTasksFromTemplate(projectId, companyId, iProjectTemplateDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {PickIProjectTemplateExcludeKeyofIProjectTemplateDateCreated} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public addProjectTemplate(projectId: number, body: PickIProjectTemplateExcludeKeyofIProjectTemplateDateCreated, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).addProjectTemplate(projectId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {IProjectUserCollaborator} iProjectUserCollaborator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public addUserToProject(projectId: number, iProjectUserCollaborator: IProjectUserCollaborator, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).addUserToProject(projectId, iProjectUserCollaborator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {IActivityType} iActivityType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public addWorkCategory(projectId: number, iActivityType: IActivityType, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).addWorkCategory(projectId, iActivityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} mappingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public deleteApiMapping(projectId: number, mappingId: number, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).deleteApiMapping(projectId, mappingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public deleteProjectTask(taskId: number, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).deleteProjectTask(taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} [type] 
     * @param {number} [workCategoryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public getActivities(projectId: number, type?: string, workCategoryId?: number, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).getActivities(projectId, type, workCategoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public getAllProjectSubMaterialsByProjectId(projectId: number, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).getAllProjectSubMaterialsByProjectId(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} companySubMatId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public getAllProjectSubMaterialsByProjectIdAndCompanySubMaterial(projectId: number, companySubMatId: number, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).getAllProjectSubMaterialsByProjectIdAndCompanySubMaterial(projectId, companySubMatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public getApiMappingsByProject(projectId: number, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).getApiMappingsByProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} civilLocationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public getByLocationId(projectId: number, civilLocationId: number, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).getByLocationId(projectId, civilLocationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public getCollaborators(projectId: number, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).getCollaborators(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} companyId 
     * @param {number} projectTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public getDetailsForTemplateId(projectId: number, companyId: string, projectTemplateId: number, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).getDetailsForTemplateId(projectId, companyId, projectTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public getInvoicesByProjectId(projectId: number, search?: string, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).getInvoicesByProjectId(projectId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public getLocationsByProjectId(projectId: number, search?: string, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).getLocationsByProjectId(projectId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public getMaterialsByProjectId(projectId: number, type?: string, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).getMaterialsByProjectId(projectId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public getProjectById(projectId: number, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).getProjectById(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public getProjectTaskById(taskId: number, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).getProjectTaskById(taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public getProjectTasks(projectId: number, type?: string, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).getProjectTasks(projectId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public getProjectTemplates(projectId: number, companyId: string, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).getProjectTemplates(projectId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public getProjects(options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).getProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public getTicketAccountingSummary(projectId: number, type?: string, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).getTicketAccountingSummary(projectId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public getUsersByProjectId(projectId: number, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).getUsersByProjectId(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public getWorkCategories(projectId: number, type?: string, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).getWorkCategories(projectId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {ILocationToProjectImport} iLocationToProjectImport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public importLocationToProject(projectId: number, iLocationToProjectImport: ILocationToProjectImport, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).importLocationToProject(projectId, iLocationToProjectImport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} activityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public removeActivity(projectId: number, activityId: number, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).removeActivity(projectId, activityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} collaboratorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public removeCollaborator(projectId: number, collaboratorId: number, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).removeCollaborator(projectId, collaboratorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} locationToProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public removeLocationToProject(projectId: number, locationToProjectId: number, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).removeLocationToProject(projectId, locationToProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} projectMaterialId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public removeProjectMaterial(projectId: number, projectMaterialId: number, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).removeProjectMaterial(projectId, projectMaterialId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public removeProjectSubMaterial(id: number, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).removeProjectSubMaterial(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} projectTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public removeProjectTemplate(projectId: number, projectTemplateId: number, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).removeProjectTemplate(projectId, projectTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} civilUsersToProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public removeUserFromProject(projectId: number, civilUsersToProjectId: number, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).removeUserFromProject(projectId, civilUsersToProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} workCategoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public removeWorkCategory(projectId: number, workCategoryId: number, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).removeWorkCategory(projectId, workCategoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {SaveProjectApiMapping} saveProjectApiMapping 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public saveApiMappingByProject(projectId: number, saveProjectApiMapping: SaveProjectApiMapping, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).saveApiMappingByProject(projectId, saveProjectApiMapping, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertProjectSubMaterial} upsertProjectSubMaterial 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public saveProjectSubMaterial(upsertProjectSubMaterial: UpsertProjectSubMaterial, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).saveProjectSubMaterial(upsertProjectSubMaterial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} companySubMatId 
     * @param {Array<UpsertProjectSubMaterial>} upsertProjectSubMaterial 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public saveProjectSubMaterialsConfiguration(projectId: number, companySubMatId: number, upsertProjectSubMaterial: Array<UpsertProjectSubMaterial>, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).saveProjectSubMaterialsConfiguration(projectId, companySubMatId, upsertProjectSubMaterial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {SetProjectLocationRequest} setProjectLocationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public setProjectLocation(projectId: number, setProjectLocationRequest: SetProjectLocationRequest, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).setProjectLocation(projectId, setProjectLocationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} civilLocationId 
     * @param {UpdateLocationByIdRequest} updateLocationByIdRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilProjectsApi
     */
    public updateLocationById(projectId: number, civilLocationId: number, updateLocationByIdRequest: UpdateLocationByIdRequest, options?: AxiosRequestConfig) {
        return CivilProjectsApiFp(this.configuration).updateLocationById(projectId, civilLocationId, updateLocationByIdRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CivilRequestsApi - axios parameter creator
 * @export
 */
export const CivilRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {IRequest} iRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRequest: async (iRequest: IRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iRequest' is not null or undefined
            assertParamExists('addRequest', 'iRequest', iRequest)
            const localVarPath = `/civil/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRequest: async (requestId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('removeRequest', 'requestId', requestId)
            const localVarPath = `/civil/requests/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [search] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRequests: async (projectId: number, search?: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('searchRequests', 'projectId', projectId)
            const localVarPath = `/civil/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IRequest} iRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRequest: async (iRequest: IRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iRequest' is not null or undefined
            assertParamExists('updateRequest', 'iRequest', iRequest)
            const localVarPath = `/civil/requests/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CivilRequestsApi - functional programming interface
 * @export
 */
export const CivilRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CivilRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {IRequest} iRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRequest(iRequest: IRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRequest(iRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeRequest(requestId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeRequest(requestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [search] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchRequests(projectId: number, search?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchRequests(projectId, search, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IRequest} iRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRequest(iRequest: IRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRequest(iRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CivilRequestsApi - factory interface
 * @export
 */
export const CivilRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CivilRequestsApiFp(configuration)
    return {
        /**
         * 
         * @param {IRequest} iRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRequest(iRequest: IRequest, options?: any): AxiosPromise<IRequest> {
            return localVarFp.addRequest(iRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRequest(requestId: number, options?: any): AxiosPromise<any> {
            return localVarFp.removeRequest(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [search] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRequests(projectId: number, search?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<Array<IRequest>> {
            return localVarFp.searchRequests(projectId, search, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IRequest} iRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRequest(iRequest: IRequest, options?: any): AxiosPromise<any> {
            return localVarFp.updateRequest(iRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CivilRequestsApi - object-oriented interface
 * @export
 * @class CivilRequestsApi
 * @extends {BaseAPI}
 */
export class CivilRequestsApi extends BaseAPI {
    /**
     * 
     * @param {IRequest} iRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilRequestsApi
     */
    public addRequest(iRequest: IRequest, options?: AxiosRequestConfig) {
        return CivilRequestsApiFp(this.configuration).addRequest(iRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} requestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilRequestsApi
     */
    public removeRequest(requestId: number, options?: AxiosRequestConfig) {
        return CivilRequestsApiFp(this.configuration).removeRequest(requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} [search] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilRequestsApi
     */
    public searchRequests(projectId: number, search?: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return CivilRequestsApiFp(this.configuration).searchRequests(projectId, search, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IRequest} iRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilRequestsApi
     */
    public updateRequest(iRequest: IRequest, options?: AxiosRequestConfig) {
        return CivilRequestsApiFp(this.configuration).updateRequest(iRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CivilSupplierPitTicketsApi - axios parameter creator
 * @export
 */
export const CivilSupplierPitTicketsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connexGlobalTask: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/civil/supplierPitTickets/connex-global-task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connexImportTicketsForCompany: async (companyId: string, startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('connexImportTicketsForCompany', 'companyId', companyId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('connexImportTicketsForCompany', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('connexImportTicketsForCompany', 'endDate', endDate)
            const localVarPath = `/civil/supplierPitTickets/connex-import-tickets/{companyId}/{startDate}/{endDate}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"startDate"}}`, encodeURIComponent(String(startDate)))
                .replace(`{${"endDate"}}`, encodeURIComponent(String(endDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVulcanPitTicket: async (requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('createVulcanPitTicket', 'requestBody', requestBody)
            const localVarPath = `/civil/supplierPitTickets/vulcan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        herculesGlobalTask: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/civil/supplierPitTickets/hercules-global-task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        herculesImportTicketsForCompany: async (companyId: string, startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('herculesImportTicketsForCompany', 'companyId', companyId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('herculesImportTicketsForCompany', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('herculesImportTicketsForCompany', 'endDate', endDate)
            const localVarPath = `/civil/supplierPitTickets/hercules-import-tickets/{companyId}/{startDate}/{endDate}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"startDate"}}`, encodeURIComponent(String(startDate)))
                .replace(`{${"endDate"}}`, encodeURIComponent(String(endDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CivilSupplierPitTicketsApi - functional programming interface
 * @export
 */
export const CivilSupplierPitTicketsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CivilSupplierPitTicketsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connexGlobalTask(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connexGlobalTask(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connexImportTicketsForCompany(companyId: string, startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connexImportTicketsForCompany(companyId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVulcanPitTicket(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateVulcanPitTicket200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVulcanPitTicket(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async herculesGlobalTask(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.herculesGlobalTask(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async herculesImportTicketsForCompany(companyId: string, startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.herculesImportTicketsForCompany(companyId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CivilSupplierPitTicketsApi - factory interface
 * @export
 */
export const CivilSupplierPitTicketsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CivilSupplierPitTicketsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connexGlobalTask(options?: any): AxiosPromise<void> {
            return localVarFp.connexGlobalTask(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connexImportTicketsForCompany(companyId: string, startDate: string, endDate: string, options?: any): AxiosPromise<void> {
            return localVarFp.connexImportTicketsForCompany(companyId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVulcanPitTicket(requestBody: { [key: string]: any; }, options?: any): AxiosPromise<CreateVulcanPitTicket200Response> {
            return localVarFp.createVulcanPitTicket(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        herculesGlobalTask(options?: any): AxiosPromise<void> {
            return localVarFp.herculesGlobalTask(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        herculesImportTicketsForCompany(companyId: string, startDate: string, endDate: string, options?: any): AxiosPromise<void> {
            return localVarFp.herculesImportTicketsForCompany(companyId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CivilSupplierPitTicketsApi - object-oriented interface
 * @export
 * @class CivilSupplierPitTicketsApi
 * @extends {BaseAPI}
 */
export class CivilSupplierPitTicketsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilSupplierPitTicketsApi
     */
    public connexGlobalTask(options?: AxiosRequestConfig) {
        return CivilSupplierPitTicketsApiFp(this.configuration).connexGlobalTask(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilSupplierPitTicketsApi
     */
    public connexImportTicketsForCompany(companyId: string, startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return CivilSupplierPitTicketsApiFp(this.configuration).connexImportTicketsForCompany(companyId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilSupplierPitTicketsApi
     */
    public createVulcanPitTicket(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return CivilSupplierPitTicketsApiFp(this.configuration).createVulcanPitTicket(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilSupplierPitTicketsApi
     */
    public herculesGlobalTask(options?: AxiosRequestConfig) {
        return CivilSupplierPitTicketsApiFp(this.configuration).herculesGlobalTask(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilSupplierPitTicketsApi
     */
    public herculesImportTicketsForCompany(companyId: string, startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return CivilSupplierPitTicketsApiFp(this.configuration).herculesImportTicketsForCompany(companyId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CivilTicketsApi - axios parameter creator
 * @export
 */
export const CivilTicketsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddTicketRequest} addTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTicket: async (addTicketRequest: AddTicketRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addTicketRequest' is not null or undefined
            assertParamExists('addTicket', 'addTicketRequest', addTicketRequest)
            const localVarPath = `/civil/tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTicketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {IPhoto} iPhoto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTicketPhoto: async (ticketId: number, iPhoto: IPhoto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('addTicketPhoto', 'ticketId', ticketId)
            // verify required parameter 'iPhoto' is not null or undefined
            assertParamExists('addTicketPhoto', 'iPhoto', iPhoto)
            const localVarPath = `/civil/tickets/{ticketId}/photos`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iPhoto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddTicketsFromManifestRequest} addTicketsFromManifestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTicketsFromManifest: async (addTicketsFromManifestRequest: AddTicketsFromManifestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addTicketsFromManifestRequest' is not null or undefined
            assertParamExists('addTicketsFromManifest', 'addTicketsFromManifestRequest', addTicketsFromManifestRequest)
            const localVarPath = `/civil/tickets/create-from-manifest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTicketsFromManifestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReceiptOnManifest: async (ticketId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('findReceiptOnManifest', 'ticketId', ticketId)
            const localVarPath = `/civil/tickets/{ticketId}/weigh-ticket-check`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketMessagesForTicket: async (ticketId: number, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('getTicketMessagesForTicket', 'ticketId', ticketId)
            const localVarPath = `/civil/tickets/{ticketId}/messages`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketPhotos: async (ticketId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('getTicketPhotos', 'ticketId', ticketId)
            const localVarPath = `/civil/tickets/{ticketId}/photos`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {string} [showActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketSummary: async (search?: string, showActive?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/civil/tickets/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (showActive !== undefined) {
                localVarQueryParameter['showActive'] = showActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manifestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestExistsOnTicket: async (manifestId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manifestId' is not null or undefined
            assertParamExists('manifestExistsOnTicket', 'manifestId', manifestId)
            const localVarPath = `/civil/tickets/manifestExistsOnTicket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manifestId !== undefined) {
                localVarQueryParameter['manifestId'] = manifestId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {AddInvoice409Response} addInvoice409Response 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectTicket: async (ticketId: number, addInvoice409Response: AddInvoice409Response, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('rejectTicket', 'ticketId', ticketId)
            // verify required parameter 'addInvoice409Response' is not null or undefined
            assertParamExists('rejectTicket', 'addInvoice409Response', addInvoice409Response)
            const localVarPath = `/civil/tickets/reject/{ticketId}`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addInvoice409Response, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTicket: async (ticketId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('removeTicket', 'ticketId', ticketId)
            const localVarPath = `/civil/tickets/{ticketId}`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {number} ticketMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTicketMessage: async (ticketId: number, ticketMessageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('removeTicketMessage', 'ticketId', ticketId)
            // verify required parameter 'ticketMessageId' is not null or undefined
            assertParamExists('removeTicketMessage', 'ticketMessageId', ticketMessageId)
            const localVarPath = `/civil/tickets/{ticketId}/messages/{ticketMessageId}`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)))
                .replace(`{${"ticketMessageId"}}`, encodeURIComponent(String(ticketMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {number} ticketPhotoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTicketPhoto: async (ticketId: number, ticketPhotoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('removeTicketPhoto', 'ticketId', ticketId)
            // verify required parameter 'ticketPhotoId' is not null or undefined
            assertParamExists('removeTicketPhoto', 'ticketPhotoId', ticketPhotoId)
            const localVarPath = `/civil/tickets/{ticketId}/photos/{ticketPhotoId}`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)))
                .replace(`{${"ticketPhotoId"}}`, encodeURIComponent(String(ticketPhotoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTicketPhotos: async (ticketId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('removeTicketPhotos', 'ticketId', ticketId)
            const localVarPath = `/civil/tickets/{ticketId}/photos`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {ITicketMessage} iTicketMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTicketMessage: async (ticketId: number, iTicketMessage: ITicketMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('saveTicketMessage', 'ticketId', ticketId)
            // verify required parameter 'iTicketMessage' is not null or undefined
            assertParamExists('saveTicketMessage', 'iTicketMessage', iTicketMessage)
            const localVarPath = `/civil/tickets/{ticketId}/messages`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iTicketMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [search] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {number} [invoiceId] 
         * @param {number} [locationId] 
         * @param {string} [isTransporter] 
         * @param {string} [isAccepted] 
         * @param {string} [showDeleted] 
         * @param {string} [isFromTrucking] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTickets: async (projectId: number, search?: string, startDate?: string, endDate?: string, invoiceId?: number, locationId?: number, isTransporter?: string, isAccepted?: string, showDeleted?: string, isFromTrucking?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('searchTickets', 'projectId', projectId)
            const localVarPath = `/civil/tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }

            if (isTransporter !== undefined) {
                localVarQueryParameter['isTransporter'] = isTransporter;
            }

            if (isAccepted !== undefined) {
                localVarQueryParameter['isAccepted'] = isAccepted;
            }

            if (showDeleted !== undefined) {
                localVarQueryParameter['showDeleted'] = showDeleted;
            }

            if (isFromTrucking !== undefined) {
                localVarQueryParameter['isFromTrucking'] = isFromTrucking;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ITicket} iTicket 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTicket: async (iTicket: ITicket, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iTicket' is not null or undefined
            assertParamExists('updateTicket', 'iTicket', iTicket)
            const localVarPath = `/civil/tickets/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iTicket, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CivilTicketsApi - functional programming interface
 * @export
 */
export const CivilTicketsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CivilTicketsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddTicketRequest} addTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTicket(addTicketRequest: AddTicketRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTicket(addTicketRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {IPhoto} iPhoto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTicketPhoto(ticketId: number, iPhoto: IPhoto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTicketPhoto(ticketId, iPhoto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddTicketsFromManifestRequest} addTicketsFromManifestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTicketsFromManifest(addTicketsFromManifestRequest: AddTicketsFromManifestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTicketsFromManifest(addTicketsFromManifestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findReceiptOnManifest(ticketId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findReceiptOnManifest(ticketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketMessagesForTicket(ticketId: number, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketMessagesForTicket(ticketId, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketPhotos(ticketId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IPhoto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketPhotos(ticketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {string} [showActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketSummary(search?: string, showActive?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITicketSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketSummary(search, showActive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manifestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manifestExistsOnTicket(manifestId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manifestExistsOnTicket(manifestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {AddInvoice409Response} addInvoice409Response 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectTicket(ticketId: number, addInvoice409Response: AddInvoice409Response, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectTicket(ticketId, addInvoice409Response, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTicket(ticketId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTicket(ticketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {number} ticketMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTicketMessage(ticketId: number, ticketMessageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTicketMessage(ticketId, ticketMessageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {number} ticketPhotoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTicketPhoto(ticketId: number, ticketPhotoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTicketPhoto(ticketId, ticketPhotoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTicketPhotos(ticketId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTicketPhotos(ticketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {ITicketMessage} iTicketMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTicketMessage(ticketId: number, iTicketMessage: ITicketMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTicketMessage(ticketId, iTicketMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [search] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {number} [invoiceId] 
         * @param {number} [locationId] 
         * @param {string} [isTransporter] 
         * @param {string} [isAccepted] 
         * @param {string} [showDeleted] 
         * @param {string} [isFromTrucking] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTickets(projectId: number, search?: string, startDate?: string, endDate?: string, invoiceId?: number, locationId?: number, isTransporter?: string, isAccepted?: string, showDeleted?: string, isFromTrucking?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ITicket>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTickets(projectId, search, startDate, endDate, invoiceId, locationId, isTransporter, isAccepted, showDeleted, isFromTrucking, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ITicket} iTicket 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTicket(iTicket: ITicket, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTicket(iTicket, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CivilTicketsApi - factory interface
 * @export
 */
export const CivilTicketsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CivilTicketsApiFp(configuration)
    return {
        /**
         * 
         * @param {AddTicketRequest} addTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTicket(addTicketRequest: AddTicketRequest, options?: any): AxiosPromise<ITicket> {
            return localVarFp.addTicket(addTicketRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {IPhoto} iPhoto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTicketPhoto(ticketId: number, iPhoto: IPhoto, options?: any): AxiosPromise<any> {
            return localVarFp.addTicketPhoto(ticketId, iPhoto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddTicketsFromManifestRequest} addTicketsFromManifestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTicketsFromManifest(addTicketsFromManifestRequest: AddTicketsFromManifestRequest, options?: any): AxiosPromise<number> {
            return localVarFp.addTicketsFromManifest(addTicketsFromManifestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReceiptOnManifest(ticketId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.findReceiptOnManifest(ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketMessagesForTicket(ticketId: number, order?: string, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.getTicketMessagesForTicket(ticketId, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketPhotos(ticketId: number, options?: any): AxiosPromise<Array<IPhoto>> {
            return localVarFp.getTicketPhotos(ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {string} [showActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketSummary(search?: string, showActive?: string, options?: any): AxiosPromise<ITicketSummary> {
            return localVarFp.getTicketSummary(search, showActive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manifestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manifestExistsOnTicket(manifestId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.manifestExistsOnTicket(manifestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {AddInvoice409Response} addInvoice409Response 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectTicket(ticketId: number, addInvoice409Response: AddInvoice409Response, options?: any): AxiosPromise<ITicket> {
            return localVarFp.rejectTicket(ticketId, addInvoice409Response, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTicket(ticketId: number, options?: any): AxiosPromise<any> {
            return localVarFp.removeTicket(ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {number} ticketMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTicketMessage(ticketId: number, ticketMessageId: number, options?: any): AxiosPromise<any> {
            return localVarFp.removeTicketMessage(ticketId, ticketMessageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {number} ticketPhotoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTicketPhoto(ticketId: number, ticketPhotoId: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeTicketPhoto(ticketId, ticketPhotoId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTicketPhotos(ticketId: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeTicketPhotos(ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {ITicketMessage} iTicketMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTicketMessage(ticketId: number, iTicketMessage: ITicketMessage, options?: any): AxiosPromise<any> {
            return localVarFp.saveTicketMessage(ticketId, iTicketMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {string} [search] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {number} [invoiceId] 
         * @param {number} [locationId] 
         * @param {string} [isTransporter] 
         * @param {string} [isAccepted] 
         * @param {string} [showDeleted] 
         * @param {string} [isFromTrucking] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTickets(projectId: number, search?: string, startDate?: string, endDate?: string, invoiceId?: number, locationId?: number, isTransporter?: string, isAccepted?: string, showDeleted?: string, isFromTrucking?: string, options?: any): AxiosPromise<Array<ITicket>> {
            return localVarFp.searchTickets(projectId, search, startDate, endDate, invoiceId, locationId, isTransporter, isAccepted, showDeleted, isFromTrucking, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ITicket} iTicket 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTicket(iTicket: ITicket, options?: any): AxiosPromise<ITicket> {
            return localVarFp.updateTicket(iTicket, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CivilTicketsApi - object-oriented interface
 * @export
 * @class CivilTicketsApi
 * @extends {BaseAPI}
 */
export class CivilTicketsApi extends BaseAPI {
    /**
     * 
     * @param {AddTicketRequest} addTicketRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilTicketsApi
     */
    public addTicket(addTicketRequest: AddTicketRequest, options?: AxiosRequestConfig) {
        return CivilTicketsApiFp(this.configuration).addTicket(addTicketRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ticketId 
     * @param {IPhoto} iPhoto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilTicketsApi
     */
    public addTicketPhoto(ticketId: number, iPhoto: IPhoto, options?: AxiosRequestConfig) {
        return CivilTicketsApiFp(this.configuration).addTicketPhoto(ticketId, iPhoto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddTicketsFromManifestRequest} addTicketsFromManifestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilTicketsApi
     */
    public addTicketsFromManifest(addTicketsFromManifestRequest: AddTicketsFromManifestRequest, options?: AxiosRequestConfig) {
        return CivilTicketsApiFp(this.configuration).addTicketsFromManifest(addTicketsFromManifestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ticketId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilTicketsApi
     */
    public findReceiptOnManifest(ticketId: number, options?: AxiosRequestConfig) {
        return CivilTicketsApiFp(this.configuration).findReceiptOnManifest(ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ticketId 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilTicketsApi
     */
    public getTicketMessagesForTicket(ticketId: number, order?: string, options?: AxiosRequestConfig) {
        return CivilTicketsApiFp(this.configuration).getTicketMessagesForTicket(ticketId, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ticketId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilTicketsApi
     */
    public getTicketPhotos(ticketId: number, options?: AxiosRequestConfig) {
        return CivilTicketsApiFp(this.configuration).getTicketPhotos(ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {string} [showActive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilTicketsApi
     */
    public getTicketSummary(search?: string, showActive?: string, options?: AxiosRequestConfig) {
        return CivilTicketsApiFp(this.configuration).getTicketSummary(search, showActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manifestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilTicketsApi
     */
    public manifestExistsOnTicket(manifestId: string, options?: AxiosRequestConfig) {
        return CivilTicketsApiFp(this.configuration).manifestExistsOnTicket(manifestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ticketId 
     * @param {AddInvoice409Response} addInvoice409Response 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilTicketsApi
     */
    public rejectTicket(ticketId: number, addInvoice409Response: AddInvoice409Response, options?: AxiosRequestConfig) {
        return CivilTicketsApiFp(this.configuration).rejectTicket(ticketId, addInvoice409Response, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ticketId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilTicketsApi
     */
    public removeTicket(ticketId: number, options?: AxiosRequestConfig) {
        return CivilTicketsApiFp(this.configuration).removeTicket(ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ticketId 
     * @param {number} ticketMessageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilTicketsApi
     */
    public removeTicketMessage(ticketId: number, ticketMessageId: number, options?: AxiosRequestConfig) {
        return CivilTicketsApiFp(this.configuration).removeTicketMessage(ticketId, ticketMessageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ticketId 
     * @param {number} ticketPhotoId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilTicketsApi
     */
    public removeTicketPhoto(ticketId: number, ticketPhotoId: number, options?: AxiosRequestConfig) {
        return CivilTicketsApiFp(this.configuration).removeTicketPhoto(ticketId, ticketPhotoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ticketId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilTicketsApi
     */
    public removeTicketPhotos(ticketId: number, options?: AxiosRequestConfig) {
        return CivilTicketsApiFp(this.configuration).removeTicketPhotos(ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ticketId 
     * @param {ITicketMessage} iTicketMessage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilTicketsApi
     */
    public saveTicketMessage(ticketId: number, iTicketMessage: ITicketMessage, options?: AxiosRequestConfig) {
        return CivilTicketsApiFp(this.configuration).saveTicketMessage(ticketId, iTicketMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {string} [search] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {number} [invoiceId] 
     * @param {number} [locationId] 
     * @param {string} [isTransporter] 
     * @param {string} [isAccepted] 
     * @param {string} [showDeleted] 
     * @param {string} [isFromTrucking] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilTicketsApi
     */
    public searchTickets(projectId: number, search?: string, startDate?: string, endDate?: string, invoiceId?: number, locationId?: number, isTransporter?: string, isAccepted?: string, showDeleted?: string, isFromTrucking?: string, options?: AxiosRequestConfig) {
        return CivilTicketsApiFp(this.configuration).searchTickets(projectId, search, startDate, endDate, invoiceId, locationId, isTransporter, isAccepted, showDeleted, isFromTrucking, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ITicket} iTicket 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CivilTicketsApi
     */
    public updateTicket(iTicket: ITicket, options?: AxiosRequestConfig) {
        return CivilTicketsApiFp(this.configuration).updateTicket(iTicket, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCompanyById', 'id', id)
            const localVarPath = `/companies/delete/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {Array<UserTypeGenerated>} [types] 
         * @param {string} [state] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {Array<PrismaCompaniesOrderByRelevanceFieldEnum>} [fields] 
         * @param {any} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesBySearch: async (search?: string, types?: Array<UserTypeGenerated>, state?: string, take?: number, skip?: number, fields?: Array<PrismaCompaniesOrderByRelevanceFieldEnum>, sort?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCompanyById', 'id', id)
            const localVarPath = `/companies/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompanyById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompanyById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {Array<UserTypeGenerated>} [types] 
         * @param {string} [state] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {Array<PrismaCompaniesOrderByRelevanceFieldEnum>} [fields] 
         * @param {any} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesBySearch(search?: string, types?: Array<UserTypeGenerated>, state?: string, take?: number, skip?: number, fields?: Array<PrismaCompaniesOrderByRelevanceFieldEnum>, sort?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompaniesBySearch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesBySearch(search, types, state, take, skip, fields, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyById200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCompanyById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {Array<UserTypeGenerated>} [types] 
         * @param {string} [state] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {Array<PrismaCompaniesOrderByRelevanceFieldEnum>} [fields] 
         * @param {any} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesBySearch(search?: string, types?: Array<UserTypeGenerated>, state?: string, take?: number, skip?: number, fields?: Array<PrismaCompaniesOrderByRelevanceFieldEnum>, sort?: any, options?: any): AxiosPromise<GetCompaniesBySearch200Response> {
            return localVarFp.getCompaniesBySearch(search, types, state, take, skip, fields, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById(id: string, options?: any): AxiosPromise<GetCompanyById200Response> {
            return localVarFp.getCompanyById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public deleteCompanyById(id: string, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).deleteCompanyById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {Array<UserTypeGenerated>} [types] 
     * @param {string} [state] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {Array<PrismaCompaniesOrderByRelevanceFieldEnum>} [fields] 
     * @param {any} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getCompaniesBySearch(search?: string, types?: Array<UserTypeGenerated>, state?: string, take?: number, skip?: number, fields?: Array<PrismaCompaniesOrderByRelevanceFieldEnum>, sort?: any, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getCompaniesBySearch(search, types, state, take, skip, fields, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getCompanyById(id: string, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getCompanyById(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompaniesConnectionsApi - axios parameter creator
 * @export
 */
export const CompaniesConnectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PickCreateCompanyConnectionParamsExcludeKeyofCreateCompanyConnectionParamsUserId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyConnection: async (body: PickCreateCompanyConnectionParamsExcludeKeyofCreateCompanyConnectionParamsUserId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createCompanyConnection', 'body', body)
            const localVarPath = `/companies-connections/create-connection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateNewConnectedCompanyParams} createNewConnectedCompanyParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewConnectedCompany: async (createNewConnectedCompanyParams: CreateNewConnectedCompanyParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createNewConnectedCompanyParams' is not null or undefined
            assertParamExists('createNewConnectedCompany', 'createNewConnectedCompanyParams', createNewConnectedCompanyParams)
            const localVarPath = `/companies-connections/create-connected-company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNewConnectedCompanyParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyConnection: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCompanyConnection', 'id', id)
            const localVarPath = `/companies-connections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyConnectionsByProjectMaterialIds: async (requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getCompanyConnectionsByProjectMaterialIds', 'requestBody', requestBody)
            const localVarPath = `/companies-connections/by-project-material-ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<ConnectedCompanyType>} types 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectedCompaniesByTypes: async (types: Array<ConnectedCompanyType>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'types' is not null or undefined
            assertParamExists('getConnectedCompaniesByTypes', 'types', types)
            const localVarPath = `/companies-connections/by-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (types) {
                localVarQueryParameter['types'] = types;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConnectionById', 'id', id)
            const localVarPath = `/companies-connections/by-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [parentCompanyId] 
         * @param {Array<ConnectedCompanyType>} [types] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {PrismaSortOrder} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConnectedCompanies: async (parentCompanyId?: string, types?: Array<ConnectedCompanyType>, search?: string, take?: number, skip?: number, sort?: PrismaSortOrder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies-connections/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (parentCompanyId !== undefined) {
                localVarQueryParameter['parentCompanyId'] = parentCompanyId;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PickUpdateCompanyConnectionParamsExcludeKeyofUpdateCompanyConnectionParamsUserId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyConnection: async (body: PickUpdateCompanyConnectionParamsExcludeKeyofUpdateCompanyConnectionParamsUserId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateCompanyConnection', 'body', body)
            const localVarPath = `/companies-connections/update-connection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesConnectionsApi - functional programming interface
 * @export
 */
export const CompaniesConnectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesConnectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PickCreateCompanyConnectionParamsExcludeKeyofCreateCompanyConnectionParamsUserId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompanyConnection(body: PickCreateCompanyConnectionParamsExcludeKeyofCreateCompanyConnectionParamsUserId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyConnectionWithCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompanyConnection(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateNewConnectedCompanyParams} createNewConnectedCompanyParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewConnectedCompany(createNewConnectedCompanyParams: CreateNewConnectedCompanyParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyConnectionWithCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewConnectedCompany(createNewConnectedCompanyParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompanyConnection(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompaniesConnections>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompanyConnection(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyConnectionsByProjectMaterialIds(requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyConnectionWithCompany>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyConnectionsByProjectMaterialIds(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<ConnectedCompanyType>} types 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectedCompaniesByTypes(types: Array<ConnectedCompanyType>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyConnectionWithCompany>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectedCompaniesByTypes(types, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectionById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyConnectionWithCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectionById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [parentCompanyId] 
         * @param {Array<ConnectedCompanyType>} [types] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {PrismaSortOrder} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchConnectedCompanies(parentCompanyId?: string, types?: Array<ConnectedCompanyType>, search?: string, take?: number, skip?: number, sort?: PrismaSortOrder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchConnectedCompanies200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchConnectedCompanies(parentCompanyId, types, search, take, skip, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PickUpdateCompanyConnectionParamsExcludeKeyofUpdateCompanyConnectionParamsUserId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyConnection(body: PickUpdateCompanyConnectionParamsExcludeKeyofUpdateCompanyConnectionParamsUserId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyConnectionWithCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyConnection(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompaniesConnectionsApi - factory interface
 * @export
 */
export const CompaniesConnectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesConnectionsApiFp(configuration)
    return {
        /**
         * 
         * @param {PickCreateCompanyConnectionParamsExcludeKeyofCreateCompanyConnectionParamsUserId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyConnection(body: PickCreateCompanyConnectionParamsExcludeKeyofCreateCompanyConnectionParamsUserId, options?: any): AxiosPromise<CompanyConnectionWithCompany> {
            return localVarFp.createCompanyConnection(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateNewConnectedCompanyParams} createNewConnectedCompanyParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewConnectedCompany(createNewConnectedCompanyParams: CreateNewConnectedCompanyParams, options?: any): AxiosPromise<CompanyConnectionWithCompany> {
            return localVarFp.createNewConnectedCompany(createNewConnectedCompanyParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyConnection(id: number, options?: any): AxiosPromise<CompaniesConnections> {
            return localVarFp.deleteCompanyConnection(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyConnectionsByProjectMaterialIds(requestBody: Array<number>, options?: any): AxiosPromise<Array<CompanyConnectionWithCompany>> {
            return localVarFp.getCompanyConnectionsByProjectMaterialIds(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<ConnectedCompanyType>} types 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectedCompaniesByTypes(types: Array<ConnectedCompanyType>, options?: any): AxiosPromise<Array<CompanyConnectionWithCompany>> {
            return localVarFp.getConnectedCompaniesByTypes(types, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionById(id: number, options?: any): AxiosPromise<CompanyConnectionWithCompany> {
            return localVarFp.getConnectionById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [parentCompanyId] 
         * @param {Array<ConnectedCompanyType>} [types] 
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {PrismaSortOrder} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConnectedCompanies(parentCompanyId?: string, types?: Array<ConnectedCompanyType>, search?: string, take?: number, skip?: number, sort?: PrismaSortOrder, options?: any): AxiosPromise<SearchConnectedCompanies200Response> {
            return localVarFp.searchConnectedCompanies(parentCompanyId, types, search, take, skip, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PickUpdateCompanyConnectionParamsExcludeKeyofUpdateCompanyConnectionParamsUserId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyConnection(body: PickUpdateCompanyConnectionParamsExcludeKeyofUpdateCompanyConnectionParamsUserId, options?: any): AxiosPromise<CompanyConnectionWithCompany> {
            return localVarFp.updateCompanyConnection(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompaniesConnectionsApi - object-oriented interface
 * @export
 * @class CompaniesConnectionsApi
 * @extends {BaseAPI}
 */
export class CompaniesConnectionsApi extends BaseAPI {
    /**
     * 
     * @param {PickCreateCompanyConnectionParamsExcludeKeyofCreateCompanyConnectionParamsUserId} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesConnectionsApi
     */
    public createCompanyConnection(body: PickCreateCompanyConnectionParamsExcludeKeyofCreateCompanyConnectionParamsUserId, options?: AxiosRequestConfig) {
        return CompaniesConnectionsApiFp(this.configuration).createCompanyConnection(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateNewConnectedCompanyParams} createNewConnectedCompanyParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesConnectionsApi
     */
    public createNewConnectedCompany(createNewConnectedCompanyParams: CreateNewConnectedCompanyParams, options?: AxiosRequestConfig) {
        return CompaniesConnectionsApiFp(this.configuration).createNewConnectedCompany(createNewConnectedCompanyParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesConnectionsApi
     */
    public deleteCompanyConnection(id: number, options?: AxiosRequestConfig) {
        return CompaniesConnectionsApiFp(this.configuration).deleteCompanyConnection(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesConnectionsApi
     */
    public getCompanyConnectionsByProjectMaterialIds(requestBody: Array<number>, options?: AxiosRequestConfig) {
        return CompaniesConnectionsApiFp(this.configuration).getCompanyConnectionsByProjectMaterialIds(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<ConnectedCompanyType>} types 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesConnectionsApi
     */
    public getConnectedCompaniesByTypes(types: Array<ConnectedCompanyType>, options?: AxiosRequestConfig) {
        return CompaniesConnectionsApiFp(this.configuration).getConnectedCompaniesByTypes(types, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesConnectionsApi
     */
    public getConnectionById(id: number, options?: AxiosRequestConfig) {
        return CompaniesConnectionsApiFp(this.configuration).getConnectionById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [parentCompanyId] 
     * @param {Array<ConnectedCompanyType>} [types] 
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {PrismaSortOrder} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesConnectionsApi
     */
    public searchConnectedCompanies(parentCompanyId?: string, types?: Array<ConnectedCompanyType>, search?: string, take?: number, skip?: number, sort?: PrismaSortOrder, options?: AxiosRequestConfig) {
        return CompaniesConnectionsApiFp(this.configuration).searchConnectedCompanies(parentCompanyId, types, search, take, skip, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PickUpdateCompanyConnectionParamsExcludeKeyofUpdateCompanyConnectionParamsUserId} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesConnectionsApi
     */
    public updateCompanyConnection(body: PickUpdateCompanyConnectionParamsExcludeKeyofUpdateCompanyConnectionParamsUserId, options?: AxiosRequestConfig) {
        return CompaniesConnectionsApiFp(this.configuration).updateCompanyConnection(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyFeatureFlagsApi - axios parameter creator
 * @export
 */
export const CompanyFeatureFlagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyFeatureFlags: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllCompanyFeatureFlags', 'companyId', companyId)
            const localVarPath = `/company-feature-flags/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} featureName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyFeatureFlagById: async (companyId: string, featureName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyFeatureFlagById', 'companyId', companyId)
            // verify required parameter 'featureName' is not null or undefined
            assertParamExists('getCompanyFeatureFlagById', 'featureName', featureName)
            const localVarPath = `/company-feature-flags/{companyId}/{featureName}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"featureName"}}`, encodeURIComponent(String(featureName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyFeatureFlagsApi - functional programming interface
 * @export
 */
export const CompanyFeatureFlagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyFeatureFlagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompanyFeatureFlags(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyFeatureFlag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompanyFeatureFlags(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} featureName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyFeatureFlagById(companyId: string, featureName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyFeatureFlagById(companyId, featureName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyFeatureFlagsApi - factory interface
 * @export
 */
export const CompanyFeatureFlagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyFeatureFlagsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyFeatureFlags(companyId: string, options?: any): AxiosPromise<Array<CompanyFeatureFlag>> {
            return localVarFp.getAllCompanyFeatureFlags(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} featureName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyFeatureFlagById(companyId: string, featureName: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.getCompanyFeatureFlagById(companyId, featureName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyFeatureFlagsApi - object-oriented interface
 * @export
 * @class CompanyFeatureFlagsApi
 * @extends {BaseAPI}
 */
export class CompanyFeatureFlagsApi extends BaseAPI {
    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyFeatureFlagsApi
     */
    public getAllCompanyFeatureFlags(companyId: string, options?: AxiosRequestConfig) {
        return CompanyFeatureFlagsApiFp(this.configuration).getAllCompanyFeatureFlags(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} featureName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyFeatureFlagsApi
     */
    public getCompanyFeatureFlagById(companyId: string, featureName: string, options?: AxiosRequestConfig) {
        return CompanyFeatureFlagsApiFp(this.configuration).getCompanyFeatureFlagById(companyId, featureName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomInputsApi - axios parameter creator
 * @export
 */
export const CustomInputsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomInputs: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCustomInputs', 'id', id)
            const localVarPath = `/custom-inputs/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} inputId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomInputById: async (inputId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inputId' is not null or undefined
            assertParamExists('getCustomInputById', 'inputId', inputId)
            const localVarPath = `/custom-inputs/{inputId}`
                .replace(`{${"inputId"}}`, encodeURIComponent(String(inputId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [types] 
         * @param {string} [companyIds] 
         * @param {10 | 20 | 50 | 100} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomInputs: async (name?: string, types?: string, companyIds?: string, count?: 10 | 20 | 50 | 100, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/custom-inputs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (types !== undefined) {
                localVarQueryParameter['types'] = types;
            }

            if (companyIds !== undefined) {
                localVarQueryParameter['companyIds'] = companyIds;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomInputsByCompanyId: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCustomInputsByCompanyId', 'companyId', companyId)
            const localVarPath = `/custom-inputs/companyId/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IGlobalCustomInputs} iGlobalCustomInputs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertCustomInput: async (iGlobalCustomInputs: IGlobalCustomInputs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iGlobalCustomInputs' is not null or undefined
            assertParamExists('upsertCustomInput', 'iGlobalCustomInputs', iGlobalCustomInputs)
            const localVarPath = `/custom-inputs/upsert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iGlobalCustomInputs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomInputsApi - functional programming interface
 * @export
 */
export const CustomInputsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomInputsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomInputs(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomInputs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomInputs(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} inputId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomInputById(inputId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGlobalCustomInputs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomInputById(inputId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [types] 
         * @param {string} [companyIds] 
         * @param {10 | 20 | 50 | 100} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomInputs(name?: string, types?: string, companyIds?: string, count?: 10 | 20 | 50 | 100, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetCustomInputs200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomInputs(name, types, companyIds, count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomInputsByCompanyId(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetCustomInputs200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomInputsByCompanyId(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IGlobalCustomInputs} iGlobalCustomInputs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertCustomInput(iGlobalCustomInputs: IGlobalCustomInputs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpsertCustomInput200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertCustomInput(iGlobalCustomInputs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomInputsApi - factory interface
 * @export
 */
export const CustomInputsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomInputsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomInputs(id: number, options?: any): AxiosPromise<CustomInputs> {
            return localVarFp.deleteCustomInputs(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} inputId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomInputById(inputId: number, options?: any): AxiosPromise<IGlobalCustomInputs> {
            return localVarFp.getCustomInputById(inputId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [types] 
         * @param {string} [companyIds] 
         * @param {10 | 20 | 50 | 100} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomInputs(name?: string, types?: string, companyIds?: string, count?: 10 | 20 | 50 | 100, options?: any): AxiosPromise<Array<GetCustomInputs200ResponseInner>> {
            return localVarFp.getCustomInputs(name, types, companyIds, count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomInputsByCompanyId(companyId: string, options?: any): AxiosPromise<Array<GetCustomInputs200ResponseInner>> {
            return localVarFp.getCustomInputsByCompanyId(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IGlobalCustomInputs} iGlobalCustomInputs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertCustomInput(iGlobalCustomInputs: IGlobalCustomInputs, options?: any): AxiosPromise<UpsertCustomInput200Response> {
            return localVarFp.upsertCustomInput(iGlobalCustomInputs, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomInputsApi - object-oriented interface
 * @export
 * @class CustomInputsApi
 * @extends {BaseAPI}
 */
export class CustomInputsApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomInputsApi
     */
    public deleteCustomInputs(id: number, options?: AxiosRequestConfig) {
        return CustomInputsApiFp(this.configuration).deleteCustomInputs(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} inputId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomInputsApi
     */
    public getCustomInputById(inputId: number, options?: AxiosRequestConfig) {
        return CustomInputsApiFp(this.configuration).getCustomInputById(inputId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {string} [types] 
     * @param {string} [companyIds] 
     * @param {10 | 20 | 50 | 100} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomInputsApi
     */
    public getCustomInputs(name?: string, types?: string, companyIds?: string, count?: 10 | 20 | 50 | 100, options?: AxiosRequestConfig) {
        return CustomInputsApiFp(this.configuration).getCustomInputs(name, types, companyIds, count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomInputsApi
     */
    public getCustomInputsByCompanyId(companyId: string, options?: AxiosRequestConfig) {
        return CustomInputsApiFp(this.configuration).getCustomInputsByCompanyId(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IGlobalCustomInputs} iGlobalCustomInputs 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomInputsApi
     */
    public upsertCustomInput(iGlobalCustomInputs: IGlobalCustomInputs, options?: AxiosRequestConfig) {
        return CustomInputsApiFp(this.configuration).upsertCustomInput(iGlobalCustomInputs, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DownloadsApi - axios parameter creator
 * @export
 */
export const DownloadsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PickDownloadRequestExcludeKeyofDownloadRequestUserIdOrCompanyId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePdfDownloadRequest: async (body: PickDownloadRequestExcludeKeyofDownloadRequestUserIdOrCompanyId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('generatePdfDownloadRequest', 'body', body)
            const localVarPath = `/downloads/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DownloadManifestsParams} downloadManifestsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multipleManifestDownloadRequest: async (downloadManifestsParams: DownloadManifestsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'downloadManifestsParams' is not null or undefined
            assertParamExists('multipleManifestDownloadRequest', 'downloadManifestsParams', downloadManifestsParams)
            const localVarPath = `/downloads/manifest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(downloadManifestsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DownloadsApi - functional programming interface
 * @export
 */
export const DownloadsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DownloadsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PickDownloadRequestExcludeKeyofDownloadRequestUserIdOrCompanyId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generatePdfDownloadRequest(body: PickDownloadRequestExcludeKeyofDownloadRequestUserIdOrCompanyId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generatePdfDownloadRequest(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DownloadManifestsParams} downloadManifestsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async multipleManifestDownloadRequest(downloadManifestsParams: DownloadManifestsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultipleManifestDownloadRequest200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.multipleManifestDownloadRequest(downloadManifestsParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DownloadsApi - factory interface
 * @export
 */
export const DownloadsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DownloadsApiFp(configuration)
    return {
        /**
         * 
         * @param {PickDownloadRequestExcludeKeyofDownloadRequestUserIdOrCompanyId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePdfDownloadRequest(body: PickDownloadRequestExcludeKeyofDownloadRequestUserIdOrCompanyId, options?: any): AxiosPromise<void> {
            return localVarFp.generatePdfDownloadRequest(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DownloadManifestsParams} downloadManifestsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multipleManifestDownloadRequest(downloadManifestsParams: DownloadManifestsParams, options?: any): AxiosPromise<MultipleManifestDownloadRequest200Response> {
            return localVarFp.multipleManifestDownloadRequest(downloadManifestsParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DownloadsApi - object-oriented interface
 * @export
 * @class DownloadsApi
 * @extends {BaseAPI}
 */
export class DownloadsApi extends BaseAPI {
    /**
     * 
     * @param {PickDownloadRequestExcludeKeyofDownloadRequestUserIdOrCompanyId} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadsApi
     */
    public generatePdfDownloadRequest(body: PickDownloadRequestExcludeKeyofDownloadRequestUserIdOrCompanyId, options?: AxiosRequestConfig) {
        return DownloadsApiFp(this.configuration).generatePdfDownloadRequest(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DownloadManifestsParams} downloadManifestsParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadsApi
     */
    public multipleManifestDownloadRequest(downloadManifestsParams: DownloadManifestsParams, options?: AxiosRequestConfig) {
        return DownloadsApiFp(this.configuration).multipleManifestDownloadRequest(downloadManifestsParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmailApi - axios parameter creator
 * @export
 */
export const EmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SendGeneratorsNewUnsignedManifestsNotificationParams} sendGeneratorsNewUnsignedManifestsNotificationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendGeneratorsNewUnsignedManifestsNotification: async (sendGeneratorsNewUnsignedManifestsNotificationParams: SendGeneratorsNewUnsignedManifestsNotificationParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendGeneratorsNewUnsignedManifestsNotificationParams' is not null or undefined
            assertParamExists('sendGeneratorsNewUnsignedManifestsNotification', 'sendGeneratorsNewUnsignedManifestsNotificationParams', sendGeneratorsNewUnsignedManifestsNotificationParams)
            const localVarPath = `/email/send-notification-generators-new-unsigned-manifests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendGeneratorsNewUnsignedManifestsNotificationParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manifestId 
         * @param {SendManifestRequest} sendManifestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendManifest: async (manifestId: string, sendManifestRequest: SendManifestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manifestId' is not null or undefined
            assertParamExists('sendManifest', 'manifestId', manifestId)
            // verify required parameter 'sendManifestRequest' is not null or undefined
            assertParamExists('sendManifest', 'sendManifestRequest', sendManifestRequest)
            const localVarPath = `/email/manifest/{manifestId}/pdf`
                .replace(`{${"manifestId"}}`, encodeURIComponent(String(manifestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendManifestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendManifestOutofGeofenceNotificationParams} sendManifestOutofGeofenceNotificationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendManifestOutofGeofenceNotification: async (sendManifestOutofGeofenceNotificationParams: SendManifestOutofGeofenceNotificationParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendManifestOutofGeofenceNotificationParams' is not null or undefined
            assertParamExists('sendManifestOutofGeofenceNotification', 'sendManifestOutofGeofenceNotificationParams', sendManifestOutofGeofenceNotificationParams)
            const localVarPath = `/email/send-manifest-out-of-geofence-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendManifestOutofGeofenceNotificationParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddUserToLicenseRequest} addUserToLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotificationActivateAccount: async (addUserToLicenseRequest: AddUserToLicenseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addUserToLicenseRequest' is not null or undefined
            assertParamExists('sendNotificationActivateAccount', 'addUserToLicenseRequest', addUserToLicenseRequest)
            const localVarPath = `/email/send-notification-activate-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addUserToLicenseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendQCIssuesEmails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/email/qc-issues-job`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oidcToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SendGeneratorsNewUnsignedManifestsNotificationParams} sendGeneratorsNewUnsignedManifestsNotificationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendGeneratorsNewUnsignedManifestsNotification(sendGeneratorsNewUnsignedManifestsNotificationParams: SendGeneratorsNewUnsignedManifestsNotificationParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendGeneratorsNewUnsignedManifestsNotification(sendGeneratorsNewUnsignedManifestsNotificationParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manifestId 
         * @param {SendManifestRequest} sendManifestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendManifest(manifestId: string, sendManifestRequest: SendManifestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendManifest(manifestId, sendManifestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendManifestOutofGeofenceNotificationParams} sendManifestOutofGeofenceNotificationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendManifestOutofGeofenceNotification(sendManifestOutofGeofenceNotificationParams: SendManifestOutofGeofenceNotificationParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendManifestOutofGeofenceNotification(sendManifestOutofGeofenceNotificationParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddUserToLicenseRequest} addUserToLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotificationActivateAccount(addUserToLicenseRequest: AddUserToLicenseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNotificationActivateAccount(addUserToLicenseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendQCIssuesEmails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendQCIssuesEmails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailApiFp(configuration)
    return {
        /**
         * 
         * @param {SendGeneratorsNewUnsignedManifestsNotificationParams} sendGeneratorsNewUnsignedManifestsNotificationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendGeneratorsNewUnsignedManifestsNotification(sendGeneratorsNewUnsignedManifestsNotificationParams: SendGeneratorsNewUnsignedManifestsNotificationParams, options?: any): AxiosPromise<boolean> {
            return localVarFp.sendGeneratorsNewUnsignedManifestsNotification(sendGeneratorsNewUnsignedManifestsNotificationParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manifestId 
         * @param {SendManifestRequest} sendManifestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendManifest(manifestId: string, sendManifestRequest: SendManifestRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.sendManifest(manifestId, sendManifestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendManifestOutofGeofenceNotificationParams} sendManifestOutofGeofenceNotificationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendManifestOutofGeofenceNotification(sendManifestOutofGeofenceNotificationParams: SendManifestOutofGeofenceNotificationParams, options?: any): AxiosPromise<boolean> {
            return localVarFp.sendManifestOutofGeofenceNotification(sendManifestOutofGeofenceNotificationParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddUserToLicenseRequest} addUserToLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotificationActivateAccount(addUserToLicenseRequest: AddUserToLicenseRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.sendNotificationActivateAccount(addUserToLicenseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendQCIssuesEmails(options?: any): AxiosPromise<void> {
            return localVarFp.sendQCIssuesEmails(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI {
    /**
     * 
     * @param {SendGeneratorsNewUnsignedManifestsNotificationParams} sendGeneratorsNewUnsignedManifestsNotificationParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public sendGeneratorsNewUnsignedManifestsNotification(sendGeneratorsNewUnsignedManifestsNotificationParams: SendGeneratorsNewUnsignedManifestsNotificationParams, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).sendGeneratorsNewUnsignedManifestsNotification(sendGeneratorsNewUnsignedManifestsNotificationParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manifestId 
     * @param {SendManifestRequest} sendManifestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public sendManifest(manifestId: string, sendManifestRequest: SendManifestRequest, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).sendManifest(manifestId, sendManifestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendManifestOutofGeofenceNotificationParams} sendManifestOutofGeofenceNotificationParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public sendManifestOutofGeofenceNotification(sendManifestOutofGeofenceNotificationParams: SendManifestOutofGeofenceNotificationParams, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).sendManifestOutofGeofenceNotification(sendManifestOutofGeofenceNotificationParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddUserToLicenseRequest} addUserToLicenseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public sendNotificationActivateAccount(addUserToLicenseRequest: AddUserToLicenseRequest, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).sendNotificationActivateAccount(addUserToLicenseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public sendQCIssuesEmails(options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).sendQCIssuesEmails(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeatureFlagsApi - axios parameter creator
 * @export
 */
export const FeatureFlagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddFeatureFlagDefinitionRequest} addFeatureFlagDefinitionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFeatureFlagDefinition: async (addFeatureFlagDefinitionRequest: AddFeatureFlagDefinitionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addFeatureFlagDefinitionRequest' is not null or undefined
            assertParamExists('addFeatureFlagDefinition', 'addFeatureFlagDefinitionRequest', addFeatureFlagDefinitionRequest)
            const localVarPath = `/feature-flags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addFeatureFlagDefinitionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureFlagDefinition: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFeatureFlagDefinition', 'id', id)
            const localVarPath = `/feature-flags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyFeatureFlag: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyFeatureFlag', 'companyId', companyId)
            const localVarPath = `/feature-flags/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureFlagDefinitions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/feature-flags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AddFeatureFlagDefinitionRequest} addFeatureFlagDefinitionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatureFlagDefinition: async (id: number, addFeatureFlagDefinitionRequest: AddFeatureFlagDefinitionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFeatureFlagDefinition', 'id', id)
            // verify required parameter 'addFeatureFlagDefinitionRequest' is not null or undefined
            assertParamExists('updateFeatureFlagDefinition', 'addFeatureFlagDefinitionRequest', addFeatureFlagDefinitionRequest)
            const localVarPath = `/feature-flags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addFeatureFlagDefinitionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertCompanyFeatureFlagRequest} upsertCompanyFeatureFlagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertCompanyFeatureFlag: async (upsertCompanyFeatureFlagRequest: UpsertCompanyFeatureFlagRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upsertCompanyFeatureFlagRequest' is not null or undefined
            assertParamExists('upsertCompanyFeatureFlag', 'upsertCompanyFeatureFlagRequest', upsertCompanyFeatureFlagRequest)
            const localVarPath = `/feature-flags/company/{companyId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertCompanyFeatureFlagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureFlagsApi - functional programming interface
 * @export
 */
export const FeatureFlagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureFlagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddFeatureFlagDefinitionRequest} addFeatureFlagDefinitionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFeatureFlagDefinition(addFeatureFlagDefinitionRequest: AddFeatureFlagDefinitionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyFeatureFlagDefinitions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFeatureFlagDefinition(addFeatureFlagDefinitionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeatureFlagDefinition(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyFeatureFlagDefinitions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeatureFlagDefinition(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyFeatureFlag(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyFeatureFlag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyFeatureFlag(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureFlagDefinitions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyFeatureFlagDefinitions>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureFlagDefinitions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AddFeatureFlagDefinitionRequest} addFeatureFlagDefinitionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeatureFlagDefinition(id: number, addFeatureFlagDefinitionRequest: AddFeatureFlagDefinitionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyFeatureFlagDefinitions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeatureFlagDefinition(id, addFeatureFlagDefinitionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertCompanyFeatureFlagRequest} upsertCompanyFeatureFlagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertCompanyFeatureFlag(upsertCompanyFeatureFlagRequest: UpsertCompanyFeatureFlagRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyFeatureFlag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertCompanyFeatureFlag(upsertCompanyFeatureFlagRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeatureFlagsApi - factory interface
 * @export
 */
export const FeatureFlagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureFlagsApiFp(configuration)
    return {
        /**
         * 
         * @param {AddFeatureFlagDefinitionRequest} addFeatureFlagDefinitionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFeatureFlagDefinition(addFeatureFlagDefinitionRequest: AddFeatureFlagDefinitionRequest, options?: any): AxiosPromise<CompanyFeatureFlagDefinitions> {
            return localVarFp.addFeatureFlagDefinition(addFeatureFlagDefinitionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureFlagDefinition(id: number, options?: any): AxiosPromise<CompanyFeatureFlagDefinitions> {
            return localVarFp.deleteFeatureFlagDefinition(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyFeatureFlag(companyId: string, options?: any): AxiosPromise<Array<CompanyFeatureFlag>> {
            return localVarFp.getCompanyFeatureFlag(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureFlagDefinitions(options?: any): AxiosPromise<Array<CompanyFeatureFlagDefinitions>> {
            return localVarFp.getFeatureFlagDefinitions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AddFeatureFlagDefinitionRequest} addFeatureFlagDefinitionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatureFlagDefinition(id: number, addFeatureFlagDefinitionRequest: AddFeatureFlagDefinitionRequest, options?: any): AxiosPromise<CompanyFeatureFlagDefinitions> {
            return localVarFp.updateFeatureFlagDefinition(id, addFeatureFlagDefinitionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertCompanyFeatureFlagRequest} upsertCompanyFeatureFlagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertCompanyFeatureFlag(upsertCompanyFeatureFlagRequest: UpsertCompanyFeatureFlagRequest, options?: any): AxiosPromise<CompanyFeatureFlag> {
            return localVarFp.upsertCompanyFeatureFlag(upsertCompanyFeatureFlagRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureFlagsApi - object-oriented interface
 * @export
 * @class FeatureFlagsApi
 * @extends {BaseAPI}
 */
export class FeatureFlagsApi extends BaseAPI {
    /**
     * 
     * @param {AddFeatureFlagDefinitionRequest} addFeatureFlagDefinitionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagsApi
     */
    public addFeatureFlagDefinition(addFeatureFlagDefinitionRequest: AddFeatureFlagDefinitionRequest, options?: AxiosRequestConfig) {
        return FeatureFlagsApiFp(this.configuration).addFeatureFlagDefinition(addFeatureFlagDefinitionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagsApi
     */
    public deleteFeatureFlagDefinition(id: number, options?: AxiosRequestConfig) {
        return FeatureFlagsApiFp(this.configuration).deleteFeatureFlagDefinition(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagsApi
     */
    public getCompanyFeatureFlag(companyId: string, options?: AxiosRequestConfig) {
        return FeatureFlagsApiFp(this.configuration).getCompanyFeatureFlag(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagsApi
     */
    public getFeatureFlagDefinitions(options?: AxiosRequestConfig) {
        return FeatureFlagsApiFp(this.configuration).getFeatureFlagDefinitions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AddFeatureFlagDefinitionRequest} addFeatureFlagDefinitionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagsApi
     */
    public updateFeatureFlagDefinition(id: number, addFeatureFlagDefinitionRequest: AddFeatureFlagDefinitionRequest, options?: AxiosRequestConfig) {
        return FeatureFlagsApiFp(this.configuration).updateFeatureFlagDefinition(id, addFeatureFlagDefinitionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertCompanyFeatureFlagRequest} upsertCompanyFeatureFlagRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagsApi
     */
    public upsertCompanyFeatureFlag(upsertCompanyFeatureFlagRequest: UpsertCompanyFeatureFlagRequest, options?: AxiosRequestConfig) {
        return FeatureFlagsApiFp(this.configuration).upsertCompanyFeatureFlag(upsertCompanyFeatureFlagRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FirebaseCallbacksApi - axios parameter creator
 * @export
 */
export const FirebaseCallbacksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} collection 
         * @param {string} changeType 
         * @param {string} documentId 
         * @param {OnDocumentUpdateRequest} onDocumentUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onDocumentUpdate: async (collection: string, changeType: string, documentId: string, onDocumentUpdateRequest: OnDocumentUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collection' is not null or undefined
            assertParamExists('onDocumentUpdate', 'collection', collection)
            // verify required parameter 'changeType' is not null or undefined
            assertParamExists('onDocumentUpdate', 'changeType', changeType)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('onDocumentUpdate', 'documentId', documentId)
            // verify required parameter 'onDocumentUpdateRequest' is not null or undefined
            assertParamExists('onDocumentUpdate', 'onDocumentUpdateRequest', onDocumentUpdateRequest)
            const localVarPath = `/firebase-callbacks/{collection}/{changeType}/{documentId}`
                .replace(`{${"collection"}}`, encodeURIComponent(String(collection)))
                .replace(`{${"changeType"}}`, encodeURIComponent(String(changeType)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(onDocumentUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManifestsPassedDay: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/firebase-callbacks/update-manifests-passed-day`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oidcToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FirebaseCallbacksApi - functional programming interface
 * @export
 */
export const FirebaseCallbacksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FirebaseCallbacksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} collection 
         * @param {string} changeType 
         * @param {string} documentId 
         * @param {OnDocumentUpdateRequest} onDocumentUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onDocumentUpdate(collection: string, changeType: string, documentId: string, onDocumentUpdateRequest: OnDocumentUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onDocumentUpdate(collection, changeType, documentId, onDocumentUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManifestsPassedDay(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateManifestsPassedDay200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateManifestsPassedDay(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FirebaseCallbacksApi - factory interface
 * @export
 */
export const FirebaseCallbacksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FirebaseCallbacksApiFp(configuration)
    return {
        /**
         * 
         * @param {string} collection 
         * @param {string} changeType 
         * @param {string} documentId 
         * @param {OnDocumentUpdateRequest} onDocumentUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onDocumentUpdate(collection: string, changeType: string, documentId: string, onDocumentUpdateRequest: OnDocumentUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.onDocumentUpdate(collection, changeType, documentId, onDocumentUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManifestsPassedDay(options?: any): AxiosPromise<UpdateManifestsPassedDay200Response> {
            return localVarFp.updateManifestsPassedDay(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FirebaseCallbacksApi - object-oriented interface
 * @export
 * @class FirebaseCallbacksApi
 * @extends {BaseAPI}
 */
export class FirebaseCallbacksApi extends BaseAPI {
    /**
     * 
     * @param {string} collection 
     * @param {string} changeType 
     * @param {string} documentId 
     * @param {OnDocumentUpdateRequest} onDocumentUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirebaseCallbacksApi
     */
    public onDocumentUpdate(collection: string, changeType: string, documentId: string, onDocumentUpdateRequest: OnDocumentUpdateRequest, options?: AxiosRequestConfig) {
        return FirebaseCallbacksApiFp(this.configuration).onDocumentUpdate(collection, changeType, documentId, onDocumentUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirebaseCallbacksApi
     */
    public updateManifestsPassedDay(options?: AxiosRequestConfig) {
        return FirebaseCallbacksApiFp(this.configuration).updateManifestsPassedDay(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvitedUsersApi - axios parameter creator
 * @export
 */
export const InvitedUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates new invite and sends invitation email.
         * @param {PickInvitedUsersExcludeKeyofinvitedUsersIdOrCreatedDateOrLastEmailDateOrAuth0Id} body - Object that represents invitation data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (body: PickInvitedUsersExcludeKeyofinvitedUsersIdOrCreatedDateOrLastEmailDateOrAuth0Id, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('create', 'body', body)
            const localVarPath = `/invited-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvitedUsersApi - functional programming interface
 * @export
 */
export const InvitedUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvitedUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates new invite and sends invitation email.
         * @param {PickInvitedUsersExcludeKeyofinvitedUsersIdOrCreatedDateOrLastEmailDateOrAuth0Id} body - Object that represents invitation data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(body: PickInvitedUsersExcludeKeyofinvitedUsersIdOrCreatedDateOrLastEmailDateOrAuth0Id, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitedUsers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvitedUsersApi - factory interface
 * @export
 */
export const InvitedUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvitedUsersApiFp(configuration)
    return {
        /**
         * Creates new invite and sends invitation email.
         * @param {PickInvitedUsersExcludeKeyofinvitedUsersIdOrCreatedDateOrLastEmailDateOrAuth0Id} body - Object that represents invitation data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(body: PickInvitedUsersExcludeKeyofinvitedUsersIdOrCreatedDateOrLastEmailDateOrAuth0Id, options?: any): AxiosPromise<InvitedUsers> {
            return localVarFp.create(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvitedUsersApi - object-oriented interface
 * @export
 * @class InvitedUsersApi
 * @extends {BaseAPI}
 */
export class InvitedUsersApi extends BaseAPI {
    /**
     * Creates new invite and sends invitation email.
     * @param {PickInvitedUsersExcludeKeyofinvitedUsersIdOrCreatedDateOrLastEmailDateOrAuth0Id} body - Object that represents invitation data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitedUsersApi
     */
    public create(body: PickInvitedUsersExcludeKeyofinvitedUsersIdOrCreatedDateOrLastEmailDateOrAuth0Id, options?: AxiosRequestConfig) {
        return InvitedUsersApiFp(this.configuration).create(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManifestAPILogApi - axios parameter creator
 * @export
 */
export const ManifestAPILogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetApiLogEntriesOptions} getApiLogEntriesOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiLogEntriesByProject: async (getApiLogEntriesOptions: GetApiLogEntriesOptions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getApiLogEntriesOptions' is not null or undefined
            assertParamExists('getApiLogEntriesByProject', 'getApiLogEntriesOptions', getApiLogEntriesOptions)
            const localVarPath = `/manifest_api_log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getApiLogEntriesOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManifestAPILogApi - functional programming interface
 * @export
 */
export const ManifestAPILogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManifestAPILogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetApiLogEntriesOptions} getApiLogEntriesOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiLogEntriesByProject(getApiLogEntriesOptions: GetApiLogEntriesOptions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetApiLogEntriesByProject200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiLogEntriesByProject(getApiLogEntriesOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManifestAPILogApi - factory interface
 * @export
 */
export const ManifestAPILogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManifestAPILogApiFp(configuration)
    return {
        /**
         * 
         * @param {GetApiLogEntriesOptions} getApiLogEntriesOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiLogEntriesByProject(getApiLogEntriesOptions: GetApiLogEntriesOptions, options?: any): AxiosPromise<GetApiLogEntriesByProject200Response> {
            return localVarFp.getApiLogEntriesByProject(getApiLogEntriesOptions, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManifestAPILogApi - object-oriented interface
 * @export
 * @class ManifestAPILogApi
 * @extends {BaseAPI}
 */
export class ManifestAPILogApi extends BaseAPI {
    /**
     * 
     * @param {GetApiLogEntriesOptions} getApiLogEntriesOptions 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManifestAPILogApi
     */
    public getApiLogEntriesByProject(getApiLogEntriesOptions: GetApiLogEntriesOptions, options?: AxiosRequestConfig) {
        return ManifestAPILogApiFp(this.configuration).getApiLogEntriesByProject(getApiLogEntriesOptions, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManifestProjectsCivilApi - axios parameter creator
 * @export
 */
export const ManifestProjectsCivilApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} manifestProjectId 
         * @param {string} manifestNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManifestByProfileAndManifestNumber: async (manifestProjectId: string, manifestNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manifestProjectId' is not null or undefined
            assertParamExists('getManifestByProfileAndManifestNumber', 'manifestProjectId', manifestProjectId)
            // verify required parameter 'manifestNumber' is not null or undefined
            assertParamExists('getManifestByProfileAndManifestNumber', 'manifestNumber', manifestNumber)
            const localVarPath = `/civil/manifest-projects/{manifestProjectId}/manifest-number/{manifestNumber}`
                .replace(`{${"manifestProjectId"}}`, encodeURIComponent(String(manifestProjectId)))
                .replace(`{${"manifestNumber"}}`, encodeURIComponent(String(manifestNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manifestProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextUnsignedManifest: async (manifestProjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manifestProjectId' is not null or undefined
            assertParamExists('getNextUnsignedManifest', 'manifestProjectId', manifestProjectId)
            const localVarPath = `/civil/manifest-projects/{manifestProjectId}/manifests/next-unsigned-manifest`
                .replace(`{${"manifestProjectId"}}`, encodeURIComponent(String(manifestProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} contractorCompanyId 
         * @param {string} scaleCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileNumbers: async (contractorCompanyId: string, scaleCompanyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractorCompanyId' is not null or undefined
            assertParamExists('getProfileNumbers', 'contractorCompanyId', contractorCompanyId)
            // verify required parameter 'scaleCompanyId' is not null or undefined
            assertParamExists('getProfileNumbers', 'scaleCompanyId', scaleCompanyId)
            const localVarPath = `/civil/manifest-projects/profile-numbers/contractor-company/{contractorCompanyId}/scale-company/{scaleCompanyId}`
                .replace(`{${"contractorCompanyId"}}`, encodeURIComponent(String(contractorCompanyId)))
                .replace(`{${"scaleCompanyId"}}`, encodeURIComponent(String(scaleCompanyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manifestProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransporterCompaniesByProjectPofileNumber: async (manifestProjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manifestProjectId' is not null or undefined
            assertParamExists('getTransporterCompaniesByProjectPofileNumber', 'manifestProjectId', manifestProjectId)
            const localVarPath = `/civil/manifest-projects/{manifestProjectId}/transporters`
                .replace(`{${"manifestProjectId"}}`, encodeURIComponent(String(manifestProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManifestProjectsCivilApi - functional programming interface
 * @export
 */
export const ManifestProjectsCivilApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManifestProjectsCivilApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} manifestProjectId 
         * @param {string} manifestNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManifestByProfileAndManifestNumber(manifestProjectId: string, manifestNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManifestByProfileAndManifestNumber(manifestProjectId, manifestNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manifestProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNextUnsignedManifest(manifestProjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNextUnsignedManifest(manifestProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} contractorCompanyId 
         * @param {string} scaleCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileNumbers(contractorCompanyId: string, scaleCompanyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetProfileNumbers200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileNumbers(contractorCompanyId, scaleCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manifestProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransporterCompaniesByProjectPofileNumber(manifestProjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransporterCompaniesByProjectPofileNumber(manifestProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManifestProjectsCivilApi - factory interface
 * @export
 */
export const ManifestProjectsCivilApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManifestProjectsCivilApiFp(configuration)
    return {
        /**
         * 
         * @param {string} manifestProjectId 
         * @param {string} manifestNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManifestByProfileAndManifestNumber(manifestProjectId: string, manifestNumber: string, options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.getManifestByProfileAndManifestNumber(manifestProjectId, manifestNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manifestProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextUnsignedManifest(manifestProjectId: string, options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.getNextUnsignedManifest(manifestProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} contractorCompanyId 
         * @param {string} scaleCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileNumbers(contractorCompanyId: string, scaleCompanyId: string, options?: any): AxiosPromise<Array<GetProfileNumbers200ResponseInner>> {
            return localVarFp.getProfileNumbers(contractorCompanyId, scaleCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manifestProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransporterCompaniesByProjectPofileNumber(manifestProjectId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getTransporterCompaniesByProjectPofileNumber(manifestProjectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManifestProjectsCivilApi - object-oriented interface
 * @export
 * @class ManifestProjectsCivilApi
 * @extends {BaseAPI}
 */
export class ManifestProjectsCivilApi extends BaseAPI {
    /**
     * 
     * @param {string} manifestProjectId 
     * @param {string} manifestNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManifestProjectsCivilApi
     */
    public getManifestByProfileAndManifestNumber(manifestProjectId: string, manifestNumber: string, options?: AxiosRequestConfig) {
        return ManifestProjectsCivilApiFp(this.configuration).getManifestByProfileAndManifestNumber(manifestProjectId, manifestNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manifestProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManifestProjectsCivilApi
     */
    public getNextUnsignedManifest(manifestProjectId: string, options?: AxiosRequestConfig) {
        return ManifestProjectsCivilApiFp(this.configuration).getNextUnsignedManifest(manifestProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} contractorCompanyId 
     * @param {string} scaleCompanyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManifestProjectsCivilApi
     */
    public getProfileNumbers(contractorCompanyId: string, scaleCompanyId: string, options?: AxiosRequestConfig) {
        return ManifestProjectsCivilApiFp(this.configuration).getProfileNumbers(contractorCompanyId, scaleCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manifestProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManifestProjectsCivilApi
     */
    public getTransporterCompaniesByProjectPofileNumber(manifestProjectId: string, options?: AxiosRequestConfig) {
        return ManifestProjectsCivilApiFp(this.configuration).getTransporterCompaniesByProjectPofileNumber(manifestProjectId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManifestsApi - axios parameter creator
 * @export
 */
export const ManifestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} manifestProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkForAvailableManifest: async (manifestProjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manifestProjectId' is not null or undefined
            assertParamExists('checkForAvailableManifest', 'manifestProjectId', manifestProjectId)
            const localVarPath = `/manifest-projects/{manifestProjectId}/manifests/check-unsigned-manifest`
                .replace(`{${"manifestProjectId"}}`, encodeURIComponent(String(manifestProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manifestProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimAvailableManifest: async (manifestProjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manifestProjectId' is not null or undefined
            assertParamExists('claimAvailableManifest', 'manifestProjectId', manifestProjectId)
            const localVarPath = `/manifest-projects/{manifestProjectId}/manifests/claim-next-unsigned-manifest`
                .replace(`{${"manifestProjectId"}}`, encodeURIComponent(String(manifestProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetLaneByManifestIdsType} setLaneByManifestIdsType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLaneByManifestIds: async (setLaneByManifestIdsType: SetLaneByManifestIdsType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setLaneByManifestIdsType' is not null or undefined
            assertParamExists('setLaneByManifestIds', 'setLaneByManifestIdsType', setLaneByManifestIdsType)
            const localVarPath = `/manifest-projects/manifests/set-lane`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setLaneByManifestIdsType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetManualReleaseForManifestType} setManualReleaseForManifestType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setManualReleaseForManifest: async (setManualReleaseForManifestType: SetManualReleaseForManifestType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setManualReleaseForManifestType' is not null or undefined
            assertParamExists('setManualReleaseForManifest', 'setManualReleaseForManifestType', setManualReleaseForManifestType)
            const localVarPath = `/manifest-projects/manifests/set-manual-release`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setManualReleaseForManifestType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SignManifestDriverType} signManifestDriverType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signManifestByDriverId: async (signManifestDriverType: SignManifestDriverType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signManifestDriverType' is not null or undefined
            assertParamExists('signManifestByDriverId', 'signManifestDriverType', signManifestDriverType)
            const localVarPath = `/manifest-projects/manifests/{manifestId}/sign-by-driver`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signManifestDriverType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manifestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unclaimAvailableManifest: async (manifestId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manifestId' is not null or undefined
            assertParamExists('unclaimAvailableManifest', 'manifestId', manifestId)
            const localVarPath = `/manifest-projects/manifests/{manifestId}/unclaim-by-driver`
                .replace(`{${"manifestId"}}`, encodeURIComponent(String(manifestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManifestsApi - functional programming interface
 * @export
 */
export const ManifestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManifestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} manifestProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkForAvailableManifest(manifestProjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkForAvailableManifest(manifestProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manifestProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimAvailableManifest(manifestProjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimAvailableManifest(manifestProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetLaneByManifestIdsType} setLaneByManifestIdsType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setLaneByManifestIds(setLaneByManifestIdsType: SetLaneByManifestIdsType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setLaneByManifestIds(setLaneByManifestIdsType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetManualReleaseForManifestType} setManualReleaseForManifestType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setManualReleaseForManifest(setManualReleaseForManifestType: SetManualReleaseForManifestType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setManualReleaseForManifest(setManualReleaseForManifestType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SignManifestDriverType} signManifestDriverType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signManifestByDriverId(signManifestDriverType: SignManifestDriverType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signManifestByDriverId(signManifestDriverType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manifestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unclaimAvailableManifest(manifestId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unclaimAvailableManifest(manifestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManifestsApi - factory interface
 * @export
 */
export const ManifestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManifestsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} manifestProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkForAvailableManifest(manifestProjectId: string, options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.checkForAvailableManifest(manifestProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manifestProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimAvailableManifest(manifestProjectId: string, options?: any): AxiosPromise<any> {
            return localVarFp.claimAvailableManifest(manifestProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetLaneByManifestIdsType} setLaneByManifestIdsType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLaneByManifestIds(setLaneByManifestIdsType: SetLaneByManifestIdsType, options?: any): AxiosPromise<any> {
            return localVarFp.setLaneByManifestIds(setLaneByManifestIdsType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetManualReleaseForManifestType} setManualReleaseForManifestType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setManualReleaseForManifest(setManualReleaseForManifestType: SetManualReleaseForManifestType, options?: any): AxiosPromise<any> {
            return localVarFp.setManualReleaseForManifest(setManualReleaseForManifestType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SignManifestDriverType} signManifestDriverType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signManifestByDriverId(signManifestDriverType: SignManifestDriverType, options?: any): AxiosPromise<any> {
            return localVarFp.signManifestByDriverId(signManifestDriverType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manifestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unclaimAvailableManifest(manifestId: string, options?: any): AxiosPromise<any> {
            return localVarFp.unclaimAvailableManifest(manifestId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManifestsApi - object-oriented interface
 * @export
 * @class ManifestsApi
 * @extends {BaseAPI}
 */
export class ManifestsApi extends BaseAPI {
    /**
     * 
     * @param {string} manifestProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManifestsApi
     */
    public checkForAvailableManifest(manifestProjectId: string, options?: AxiosRequestConfig) {
        return ManifestsApiFp(this.configuration).checkForAvailableManifest(manifestProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manifestProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManifestsApi
     */
    public claimAvailableManifest(manifestProjectId: string, options?: AxiosRequestConfig) {
        return ManifestsApiFp(this.configuration).claimAvailableManifest(manifestProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetLaneByManifestIdsType} setLaneByManifestIdsType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManifestsApi
     */
    public setLaneByManifestIds(setLaneByManifestIdsType: SetLaneByManifestIdsType, options?: AxiosRequestConfig) {
        return ManifestsApiFp(this.configuration).setLaneByManifestIds(setLaneByManifestIdsType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetManualReleaseForManifestType} setManualReleaseForManifestType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManifestsApi
     */
    public setManualReleaseForManifest(setManualReleaseForManifestType: SetManualReleaseForManifestType, options?: AxiosRequestConfig) {
        return ManifestsApiFp(this.configuration).setManualReleaseForManifest(setManualReleaseForManifestType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SignManifestDriverType} signManifestDriverType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManifestsApi
     */
    public signManifestByDriverId(signManifestDriverType: SignManifestDriverType, options?: AxiosRequestConfig) {
        return ManifestsApiFp(this.configuration).signManifestByDriverId(signManifestDriverType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manifestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManifestsApi
     */
    public unclaimAvailableManifest(manifestId: string, options?: AxiosRequestConfig) {
        return ManifestsApiFp(this.configuration).unclaimAvailableManifest(manifestId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PDFApi - axios parameter creator
 * @export
 */
export const PDFApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} manifestId 
         * @param {GetManifestPdfBufferRequest} getManifestPdfBufferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManifestPdfBuffer: async (manifestId: string, getManifestPdfBufferRequest: GetManifestPdfBufferRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manifestId' is not null or undefined
            assertParamExists('getManifestPdfBuffer', 'manifestId', manifestId)
            // verify required parameter 'getManifestPdfBufferRequest' is not null or undefined
            assertParamExists('getManifestPdfBuffer', 'getManifestPdfBufferRequest', getManifestPdfBufferRequest)
            const localVarPath = `/pdf/manifest/{manifestId}`
                .replace(`{${"manifestId"}}`, encodeURIComponent(String(manifestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getManifestPdfBufferRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PDFApi - functional programming interface
 * @export
 */
export const PDFApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PDFApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} manifestId 
         * @param {GetManifestPdfBufferRequest} getManifestPdfBufferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManifestPdfBuffer(manifestId: string, getManifestPdfBufferRequest: GetManifestPdfBufferRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManifestPdfBuffer(manifestId, getManifestPdfBufferRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PDFApi - factory interface
 * @export
 */
export const PDFApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PDFApiFp(configuration)
    return {
        /**
         * 
         * @param {string} manifestId 
         * @param {GetManifestPdfBufferRequest} getManifestPdfBufferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManifestPdfBuffer(manifestId: string, getManifestPdfBufferRequest: GetManifestPdfBufferRequest, options?: any): AxiosPromise<string> {
            return localVarFp.getManifestPdfBuffer(manifestId, getManifestPdfBufferRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PDFApi - object-oriented interface
 * @export
 * @class PDFApi
 * @extends {BaseAPI}
 */
export class PDFApi extends BaseAPI {
    /**
     * 
     * @param {string} manifestId 
     * @param {GetManifestPdfBufferRequest} getManifestPdfBufferRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PDFApi
     */
    public getManifestPdfBuffer(manifestId: string, getManifestPdfBufferRequest: GetManifestPdfBufferRequest, options?: AxiosRequestConfig) {
        return PDFApiFp(this.configuration).getManifestPdfBuffer(manifestId, getManifestPdfBufferRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatchesApi - axios parameter creator
 * @export
 */
export const PatchesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFieldsForUserSwitcher: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patches/users/modify-switcher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIsClaimedToAllManifestsNotSignedByDrivers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patches/manifests/add-is-claimed-to-all-manifests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeSpecificProjectScheduledTasks: async (projectId: string, startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('executeSpecificProjectScheduledTasks', 'projectId', projectId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('executeSpecificProjectScheduledTasks', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('executeSpecificProjectScheduledTasks', 'endDate', endDate)
            const localVarPath = `/patches/projects-scheduled-tasks/execute/{projectId}/between/{startDate}/and/{endDate}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"startDate"}}`, encodeURIComponent(String(startDate)))
                .replace(`{${"endDate"}}`, encodeURIComponent(String(endDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshManifestsProjectData: async (projectId: string, startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('refreshManifestsProjectData', 'projectId', projectId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('refreshManifestsProjectData', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('refreshManifestsProjectData', 'endDate', endDate)
            const localVarPath = `/patches/manifests/refresh-manifests-project-data/{projectId}/between/{startDate}/and/{endDate}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"startDate"}}`, encodeURIComponent(String(startDate)))
                .replace(`{${"endDate"}}`, encodeURIComponent(String(endDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshManifestsTemplatesForProject: async (projectId: string, startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('refreshManifestsTemplatesForProject', 'projectId', projectId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('refreshManifestsTemplatesForProject', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('refreshManifestsTemplatesForProject', 'endDate', endDate)
            const localVarPath = `/patches/manifests/refresh-manifests-templates-for-project/{projectId}/between/{startDate}/and/{endDate}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"startDate"}}`, encodeURIComponent(String(startDate)))
                .replace(`{${"endDate"}}`, encodeURIComponent(String(endDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} templateId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} startAfterDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCompanyBrokerLoadsTheSelectedTemplate: async (companyId: string, templateId: string, startDate: string, endDate: string, startAfterDocumentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('setCompanyBrokerLoadsTheSelectedTemplate', 'companyId', companyId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('setCompanyBrokerLoadsTheSelectedTemplate', 'templateId', templateId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('setCompanyBrokerLoadsTheSelectedTemplate', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('setCompanyBrokerLoadsTheSelectedTemplate', 'endDate', endDate)
            // verify required parameter 'startAfterDocumentId' is not null or undefined
            assertParamExists('setCompanyBrokerLoadsTheSelectedTemplate', 'startAfterDocumentId', startAfterDocumentId)
            const localVarPath = `/patches/broker-loads/set-company-broker-loads-the-selected-template/{companyId}/{templateId}/between/{startDate}/and/{endDate}/{startAfterDocumentId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)))
                .replace(`{${"startDate"}}`, encodeURIComponent(String(startDate)))
                .replace(`{${"endDate"}}`, encodeURIComponent(String(endDate)))
                .replace(`{${"startAfterDocumentId"}}`, encodeURIComponent(String(startAfterDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} lastId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMissedSecondaryTruckingCompany: async (lastId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lastId' is not null or undefined
            assertParamExists('updateMissedSecondaryTruckingCompany', 'lastId', lastId)
            const localVarPath = `/patches/manifests/update-missed-secondary-trucking-company/{lastId}`
                .replace(`{${"lastId"}}`, encodeURIComponent(String(lastId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatchesApi - functional programming interface
 * @export
 */
export const PatchesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatchesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFieldsForUserSwitcher(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddFieldsForUserSwitcher200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFieldsForUserSwitcher(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addIsClaimedToAllManifestsNotSignedByDrivers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addIsClaimedToAllManifestsNotSignedByDrivers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executeSpecificProjectScheduledTasks(projectId: string, startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExecuteSpecificProjectScheduledTasks200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.executeSpecificProjectScheduledTasks(projectId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshManifestsProjectData(projectId: string, startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateManifestsPassedDay200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshManifestsProjectData(projectId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshManifestsTemplatesForProject(projectId: string, startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateManifestsPassedDay200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshManifestsTemplatesForProject(projectId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} templateId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} startAfterDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCompanyBrokerLoadsTheSelectedTemplate(companyId: string, templateId: string, startDate: string, endDate: string, startAfterDocumentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetCompanyBrokerLoadsTheSelectedTemplate200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCompanyBrokerLoadsTheSelectedTemplate(companyId, templateId, startDate, endDate, startAfterDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} lastId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMissedSecondaryTruckingCompany(lastId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateMissedSecondaryTruckingCompany200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMissedSecondaryTruckingCompany(lastId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatchesApi - factory interface
 * @export
 */
export const PatchesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatchesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFieldsForUserSwitcher(options?: any): AxiosPromise<AddFieldsForUserSwitcher200Response> {
            return localVarFp.addFieldsForUserSwitcher(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIsClaimedToAllManifestsNotSignedByDrivers(options?: any): AxiosPromise<void> {
            return localVarFp.addIsClaimedToAllManifestsNotSignedByDrivers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeSpecificProjectScheduledTasks(projectId: string, startDate: string, endDate: string, options?: any): AxiosPromise<ExecuteSpecificProjectScheduledTasks200Response> {
            return localVarFp.executeSpecificProjectScheduledTasks(projectId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshManifestsProjectData(projectId: string, startDate: string, endDate: string, options?: any): AxiosPromise<UpdateManifestsPassedDay200Response> {
            return localVarFp.refreshManifestsProjectData(projectId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshManifestsTemplatesForProject(projectId: string, startDate: string, endDate: string, options?: any): AxiosPromise<UpdateManifestsPassedDay200Response> {
            return localVarFp.refreshManifestsTemplatesForProject(projectId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} templateId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} startAfterDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCompanyBrokerLoadsTheSelectedTemplate(companyId: string, templateId: string, startDate: string, endDate: string, startAfterDocumentId: string, options?: any): AxiosPromise<SetCompanyBrokerLoadsTheSelectedTemplate200Response> {
            return localVarFp.setCompanyBrokerLoadsTheSelectedTemplate(companyId, templateId, startDate, endDate, startAfterDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} lastId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMissedSecondaryTruckingCompany(lastId: string, options?: any): AxiosPromise<UpdateMissedSecondaryTruckingCompany200Response> {
            return localVarFp.updateMissedSecondaryTruckingCompany(lastId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatchesApi - object-oriented interface
 * @export
 * @class PatchesApi
 * @extends {BaseAPI}
 */
export class PatchesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatchesApi
     */
    public addFieldsForUserSwitcher(options?: AxiosRequestConfig) {
        return PatchesApiFp(this.configuration).addFieldsForUserSwitcher(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatchesApi
     */
    public addIsClaimedToAllManifestsNotSignedByDrivers(options?: AxiosRequestConfig) {
        return PatchesApiFp(this.configuration).addIsClaimedToAllManifestsNotSignedByDrivers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatchesApi
     */
    public executeSpecificProjectScheduledTasks(projectId: string, startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return PatchesApiFp(this.configuration).executeSpecificProjectScheduledTasks(projectId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatchesApi
     */
    public refreshManifestsProjectData(projectId: string, startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return PatchesApiFp(this.configuration).refreshManifestsProjectData(projectId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatchesApi
     */
    public refreshManifestsTemplatesForProject(projectId: string, startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return PatchesApiFp(this.configuration).refreshManifestsTemplatesForProject(projectId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} templateId 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {string} startAfterDocumentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatchesApi
     */
    public setCompanyBrokerLoadsTheSelectedTemplate(companyId: string, templateId: string, startDate: string, endDate: string, startAfterDocumentId: string, options?: AxiosRequestConfig) {
        return PatchesApiFp(this.configuration).setCompanyBrokerLoadsTheSelectedTemplate(companyId, templateId, startDate, endDate, startAfterDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} lastId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatchesApi
     */
    public updateMissedSecondaryTruckingCompany(lastId: string, options?: AxiosRequestConfig) {
        return PatchesApiFp(this.configuration).updateMissedSecondaryTruckingCompany(lastId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PostgresqlApi - axios parameter creator
 * @export
 */
export const PostgresqlApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearIdleConnections: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/postgresql/clear-idle-connections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateLatest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/postgresql/migrate-latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostgresqlApi - functional programming interface
 * @export
 */
export const PostgresqlApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostgresqlApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearIdleConnections(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClearIdleConnections200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearIdleConnections(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateLatest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrateLatest(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PostgresqlApi - factory interface
 * @export
 */
export const PostgresqlApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostgresqlApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearIdleConnections(options?: any): AxiosPromise<ClearIdleConnections200Response> {
            return localVarFp.clearIdleConnections(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateLatest(options?: any): AxiosPromise<any> {
            return localVarFp.migrateLatest(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostgresqlApi - object-oriented interface
 * @export
 * @class PostgresqlApi
 * @extends {BaseAPI}
 */
export class PostgresqlApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostgresqlApi
     */
    public clearIdleConnections(options?: AxiosRequestConfig) {
        return PostgresqlApiFp(this.configuration).clearIdleConnections(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostgresqlApi
     */
    public migrateLatest(options?: AxiosRequestConfig) {
        return PostgresqlApiFp(this.configuration).migrateLatest(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ConsumeGenericProfileRequest} consumeGenericProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumeGenericProfile: async (consumeGenericProfileRequest: ConsumeGenericProfileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consumeGenericProfileRequest' is not null or undefined
            assertParamExists('consumeGenericProfile', 'consumeGenericProfileRequest', consumeGenericProfileRequest)
            const localVarPath = `/projects/consume`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consumeGenericProfileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProjectLists: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProjectManifestTemplateOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/templates/manifests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProjectOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProjectReceiptTemplateOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/templates/receipts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProjectScaleOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/scales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertProject: async (requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('upsertProject', 'requestBody', requestBody)
            const localVarPath = `/projects/upsert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ConsumeGenericProfileRequest} consumeGenericProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consumeGenericProfile(consumeGenericProfileRequest: ConsumeGenericProfileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consumeGenericProfile(consumeGenericProfileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProjectLists(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserProjectLists200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProjectLists(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProjectManifestTemplateOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProjectManifestTemplateOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProjectOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserProjectOptions200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProjectOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProjectReceiptTemplateOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProjectReceiptTemplateOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProjectScaleOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<{ [key: string]: any; }>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProjectScaleOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertProject(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertProject(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * 
         * @param {ConsumeGenericProfileRequest} consumeGenericProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumeGenericProfile(consumeGenericProfileRequest: ConsumeGenericProfileRequest, options?: any): AxiosPromise<string> {
            return localVarFp.consumeGenericProfile(consumeGenericProfileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProjectLists(options?: any): AxiosPromise<GetUserProjectLists200Response> {
            return localVarFp.getUserProjectLists(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProjectManifestTemplateOptions(options?: any): AxiosPromise<Array<any>> {
            return localVarFp.getUserProjectManifestTemplateOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProjectOptions(options?: any): AxiosPromise<GetUserProjectOptions200Response> {
            return localVarFp.getUserProjectOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProjectReceiptTemplateOptions(options?: any): AxiosPromise<Array<any>> {
            return localVarFp.getUserProjectReceiptTemplateOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProjectScaleOptions(options?: any): AxiosPromise<Array<{ [key: string]: any; }>> {
            return localVarFp.getUserProjectScaleOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertProject(requestBody: { [key: string]: any; }, options?: any): AxiosPromise<string> {
            return localVarFp.upsertProject(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * 
     * @param {ConsumeGenericProfileRequest} consumeGenericProfileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public consumeGenericProfile(consumeGenericProfileRequest: ConsumeGenericProfileRequest, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).consumeGenericProfile(consumeGenericProfileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getUserProjectLists(options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getUserProjectLists(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getUserProjectManifestTemplateOptions(options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getUserProjectManifestTemplateOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getUserProjectOptions(options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getUserProjectOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getUserProjectReceiptTemplateOptions(options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getUserProjectReceiptTemplateOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getUserProjectScaleOptions(options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getUserProjectScaleOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public upsertProject(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).upsertProject(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectsScheduledTasksApi - axios parameter creator
 * @export
 */
export const ProjectsScheduledTasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDownloadsBucketsForExpiredLinks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects-scheduled-tasks/delete-downloads-buckets-for-expired-links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oidcToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeAllRepublicScheduledTasks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects-scheduled-tasks/execute-all-republic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oidcToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeAllTheProjectsScheduledTasks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects-scheduled-tasks/execute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oidcToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeSingleProjectScheduledTasks: async (projectId: string, startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('executeSingleProjectScheduledTasks', 'projectId', projectId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('executeSingleProjectScheduledTasks', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('executeSingleProjectScheduledTasks', 'endDate', endDate)
            const localVarPath = `/projects-scheduled-tasks/execute/{projectId}/between/{startDate}/and/{endDate}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"startDate"}}`, encodeURIComponent(String(startDate)))
                .replace(`{${"endDate"}}`, encodeURIComponent(String(endDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsScheduledTasksApi - functional programming interface
 * @export
 */
export const ProjectsScheduledTasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsScheduledTasksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDownloadsBucketsForExpiredLinks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDownloadsBucketsForExpiredLinks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executeAllRepublicScheduledTasks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.executeAllRepublicScheduledTasks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executeAllTheProjectsScheduledTasks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.executeAllTheProjectsScheduledTasks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executeSingleProjectScheduledTasks(projectId: string, startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.executeSingleProjectScheduledTasks(projectId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectsScheduledTasksApi - factory interface
 * @export
 */
export const ProjectsScheduledTasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsScheduledTasksApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDownloadsBucketsForExpiredLinks(options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteDownloadsBucketsForExpiredLinks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeAllRepublicScheduledTasks(options?: any): AxiosPromise<boolean> {
            return localVarFp.executeAllRepublicScheduledTasks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeAllTheProjectsScheduledTasks(options?: any): AxiosPromise<boolean> {
            return localVarFp.executeAllTheProjectsScheduledTasks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeSingleProjectScheduledTasks(projectId: string, startDate: string, endDate: string, options?: any): AxiosPromise<any> {
            return localVarFp.executeSingleProjectScheduledTasks(projectId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectsScheduledTasksApi - object-oriented interface
 * @export
 * @class ProjectsScheduledTasksApi
 * @extends {BaseAPI}
 */
export class ProjectsScheduledTasksApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsScheduledTasksApi
     */
    public deleteDownloadsBucketsForExpiredLinks(options?: AxiosRequestConfig) {
        return ProjectsScheduledTasksApiFp(this.configuration).deleteDownloadsBucketsForExpiredLinks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsScheduledTasksApi
     */
    public executeAllRepublicScheduledTasks(options?: AxiosRequestConfig) {
        return ProjectsScheduledTasksApiFp(this.configuration).executeAllRepublicScheduledTasks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsScheduledTasksApi
     */
    public executeAllTheProjectsScheduledTasks(options?: AxiosRequestConfig) {
        return ProjectsScheduledTasksApiFp(this.configuration).executeAllTheProjectsScheduledTasks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsScheduledTasksApi
     */
    public executeSingleProjectScheduledTasks(projectId: string, startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return ProjectsScheduledTasksApiFp(this.configuration).executeSingleProjectScheduledTasks(projectId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PubSubApi - axios parameter creator
 * @export
 */
export const PubSubApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBrokerLoadToDownloadStorageBucket: async (requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('addBrokerLoadToDownloadStorageBucket', 'requestBody', requestBody)
            const localVarPath = `/pub-sub/add-broker-load-to-download-storage-bucket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addManifestToDownloadStorageBucket: async (requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('addManifestToDownloadStorageBucket', 'requestBody', requestBody)
            const localVarPath = `/pub-sub/add-manifest-to-download-storage-bucket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDownloadZipArchiveAndAddItToStorageBucket: async (requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('createDownloadZipArchiveAndAddItToStorageBucket', 'requestBody', requestBody)
            const localVarPath = `/pub-sub/create-download-zip-archive-and-add-it-to-download-storage-bucket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DownloadBrokerLoadsParams} downloadBrokerLoadsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadBrokerLoads: async (downloadBrokerLoadsParams: DownloadBrokerLoadsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'downloadBrokerLoadsParams' is not null or undefined
            assertParamExists('downloadBrokerLoads', 'downloadBrokerLoadsParams', downloadBrokerLoadsParams)
            const localVarPath = `/pub-sub/download-broker-loads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(downloadBrokerLoadsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DownloadManifestsParams} downloadManifestsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadManifests: async (downloadManifestsParams: DownloadManifestsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'downloadManifestsParams' is not null or undefined
            assertParamExists('downloadManifests', 'downloadManifestsParams', downloadManifestsParams)
            const localVarPath = `/pub-sub/download-manifests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(downloadManifestsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gflApiGetTicketDetailsAndSyncManifestReceipt: async (requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('gflApiGetTicketDetailsAndSyncManifestReceipt', 'requestBody', requestBody)
            const localVarPath = `/pub-sub/gfl-api-get-ticket-details-and-sync-manifest-receipt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectScheduledTasksExecute: async (requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('projectScheduledTasksExecute', 'requestBody', requestBody)
            const localVarPath = `/pub-sub/project-scheduled-task-execute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshWasteConnectionsTicketsRequest} refreshWasteConnectionsTicketsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshWasteConnectionsTickets: async (refreshWasteConnectionsTicketsRequest: RefreshWasteConnectionsTicketsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshWasteConnectionsTicketsRequest' is not null or undefined
            assertParamExists('refreshWasteConnectionsTickets', 'refreshWasteConnectionsTicketsRequest', refreshWasteConnectionsTicketsRequest)
            const localVarPath = `/pub-sub/refresh-waste-connections-tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshWasteConnectionsTicketsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        republicApiGetTicketDetailsAndSyncManifestReceiptPubSub: async (requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('republicApiGetTicketDetailsAndSyncManifestReceiptPubSub', 'requestBody', requestBody)
            const localVarPath = `/pub-sub/republic-get-ticket-details-and-sync-manifest-receipt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} manifestNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteConnectionsApiGetTicketDetailsAndSyncManifestReceipt: async (projectId: string, manifestNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('wasteConnectionsApiGetTicketDetailsAndSyncManifestReceipt', 'projectId', projectId)
            // verify required parameter 'manifestNumber' is not null or undefined
            assertParamExists('wasteConnectionsApiGetTicketDetailsAndSyncManifestReceipt', 'manifestNumber', manifestNumber)
            const localVarPath = `/pub-sub/fetch-waste-connections-ticket-for-manifest/{projectId}/{manifestNumber}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"manifestNumber"}}`, encodeURIComponent(String(manifestNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteConnectionsApiGetTicketDetailsAndSyncManifestReceiptPubSub: async (requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('wasteConnectionsApiGetTicketDetailsAndSyncManifestReceiptPubSub', 'requestBody', requestBody)
            const localVarPath = `/pub-sub/waste-connections-get-ticket-details-and-sync-manifest-receipt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteManagementApi1GetTicketDetailsAndSyncManifestReceipt: async (requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('wasteManagementApi1GetTicketDetailsAndSyncManifestReceipt', 'requestBody', requestBody)
            const localVarPath = `/pub-sub/waste-manamement-api1-get-ticket-details-and-sync-manifest-receipt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PubSubApi - functional programming interface
 * @export
 */
export const PubSubApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PubSubApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBrokerLoadToDownloadStorageBucket(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addBrokerLoadToDownloadStorageBucket(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addManifestToDownloadStorageBucket(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addManifestToDownloadStorageBucket(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDownloadZipArchiveAndAddItToStorageBucket(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDownloadZipArchiveAndAddItToStorageBucket(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DownloadBrokerLoadsParams} downloadBrokerLoadsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadBrokerLoads(downloadBrokerLoadsParams: DownloadBrokerLoadsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadManifests200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadBrokerLoads(downloadBrokerLoadsParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DownloadManifestsParams} downloadManifestsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadManifests(downloadManifestsParams: DownloadManifestsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadManifests200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadManifests(downloadManifestsParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gflApiGetTicketDetailsAndSyncManifestReceipt(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gflApiGetTicketDetailsAndSyncManifestReceipt(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectScheduledTasksExecute(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectScheduledTasksExecute(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RefreshWasteConnectionsTicketsRequest} refreshWasteConnectionsTicketsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshWasteConnectionsTickets(refreshWasteConnectionsTicketsRequest: RefreshWasteConnectionsTicketsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshWasteConnectionsTickets200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshWasteConnectionsTickets(refreshWasteConnectionsTicketsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async republicApiGetTicketDetailsAndSyncManifestReceiptPubSub(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.republicApiGetTicketDetailsAndSyncManifestReceiptPubSub(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} manifestNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteConnectionsApiGetTicketDetailsAndSyncManifestReceipt(projectId: string, manifestNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wasteConnectionsApiGetTicketDetailsAndSyncManifestReceipt(projectId, manifestNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteConnectionsApiGetTicketDetailsAndSyncManifestReceiptPubSub(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wasteConnectionsApiGetTicketDetailsAndSyncManifestReceiptPubSub(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wasteManagementApi1GetTicketDetailsAndSyncManifestReceipt(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wasteManagementApi1GetTicketDetailsAndSyncManifestReceipt(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PubSubApi - factory interface
 * @export
 */
export const PubSubApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PubSubApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBrokerLoadToDownloadStorageBucket(requestBody: { [key: string]: any; }, options?: any): AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response> {
            return localVarFp.addBrokerLoadToDownloadStorageBucket(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addManifestToDownloadStorageBucket(requestBody: { [key: string]: any; }, options?: any): AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response> {
            return localVarFp.addManifestToDownloadStorageBucket(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDownloadZipArchiveAndAddItToStorageBucket(requestBody: { [key: string]: any; }, options?: any): AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response> {
            return localVarFp.createDownloadZipArchiveAndAddItToStorageBucket(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DownloadBrokerLoadsParams} downloadBrokerLoadsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadBrokerLoads(downloadBrokerLoadsParams: DownloadBrokerLoadsParams, options?: any): AxiosPromise<DownloadManifests200Response> {
            return localVarFp.downloadBrokerLoads(downloadBrokerLoadsParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DownloadManifestsParams} downloadManifestsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadManifests(downloadManifestsParams: DownloadManifestsParams, options?: any): AxiosPromise<DownloadManifests200Response> {
            return localVarFp.downloadManifests(downloadManifestsParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gflApiGetTicketDetailsAndSyncManifestReceipt(requestBody: { [key: string]: any; }, options?: any): AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response> {
            return localVarFp.gflApiGetTicketDetailsAndSyncManifestReceipt(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectScheduledTasksExecute(requestBody: { [key: string]: any; }, options?: any): AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response> {
            return localVarFp.projectScheduledTasksExecute(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshWasteConnectionsTicketsRequest} refreshWasteConnectionsTicketsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshWasteConnectionsTickets(refreshWasteConnectionsTicketsRequest: RefreshWasteConnectionsTicketsRequest, options?: any): AxiosPromise<RefreshWasteConnectionsTickets200Response> {
            return localVarFp.refreshWasteConnectionsTickets(refreshWasteConnectionsTicketsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        republicApiGetTicketDetailsAndSyncManifestReceiptPubSub(requestBody: { [key: string]: any; }, options?: any): AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response> {
            return localVarFp.republicApiGetTicketDetailsAndSyncManifestReceiptPubSub(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} manifestNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteConnectionsApiGetTicketDetailsAndSyncManifestReceipt(projectId: string, manifestNumber: string, options?: any): AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response> {
            return localVarFp.wasteConnectionsApiGetTicketDetailsAndSyncManifestReceipt(projectId, manifestNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteConnectionsApiGetTicketDetailsAndSyncManifestReceiptPubSub(requestBody: { [key: string]: any; }, options?: any): AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response> {
            return localVarFp.wasteConnectionsApiGetTicketDetailsAndSyncManifestReceiptPubSub(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wasteManagementApi1GetTicketDetailsAndSyncManifestReceipt(requestBody: { [key: string]: any; }, options?: any): AxiosPromise<CreateDownloadZipArchiveAndAddItToStorageBucket200Response> {
            return localVarFp.wasteManagementApi1GetTicketDetailsAndSyncManifestReceipt(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PubSubApi - object-oriented interface
 * @export
 * @class PubSubApi
 * @extends {BaseAPI}
 */
export class PubSubApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PubSubApi
     */
    public addBrokerLoadToDownloadStorageBucket(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return PubSubApiFp(this.configuration).addBrokerLoadToDownloadStorageBucket(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PubSubApi
     */
    public addManifestToDownloadStorageBucket(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return PubSubApiFp(this.configuration).addManifestToDownloadStorageBucket(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PubSubApi
     */
    public createDownloadZipArchiveAndAddItToStorageBucket(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return PubSubApiFp(this.configuration).createDownloadZipArchiveAndAddItToStorageBucket(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DownloadBrokerLoadsParams} downloadBrokerLoadsParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PubSubApi
     */
    public downloadBrokerLoads(downloadBrokerLoadsParams: DownloadBrokerLoadsParams, options?: AxiosRequestConfig) {
        return PubSubApiFp(this.configuration).downloadBrokerLoads(downloadBrokerLoadsParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DownloadManifestsParams} downloadManifestsParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PubSubApi
     */
    public downloadManifests(downloadManifestsParams: DownloadManifestsParams, options?: AxiosRequestConfig) {
        return PubSubApiFp(this.configuration).downloadManifests(downloadManifestsParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PubSubApi
     */
    public gflApiGetTicketDetailsAndSyncManifestReceipt(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return PubSubApiFp(this.configuration).gflApiGetTicketDetailsAndSyncManifestReceipt(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PubSubApi
     */
    public projectScheduledTasksExecute(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return PubSubApiFp(this.configuration).projectScheduledTasksExecute(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefreshWasteConnectionsTicketsRequest} refreshWasteConnectionsTicketsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PubSubApi
     */
    public refreshWasteConnectionsTickets(refreshWasteConnectionsTicketsRequest: RefreshWasteConnectionsTicketsRequest, options?: AxiosRequestConfig) {
        return PubSubApiFp(this.configuration).refreshWasteConnectionsTickets(refreshWasteConnectionsTicketsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PubSubApi
     */
    public republicApiGetTicketDetailsAndSyncManifestReceiptPubSub(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return PubSubApiFp(this.configuration).republicApiGetTicketDetailsAndSyncManifestReceiptPubSub(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} manifestNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PubSubApi
     */
    public wasteConnectionsApiGetTicketDetailsAndSyncManifestReceipt(projectId: string, manifestNumber: string, options?: AxiosRequestConfig) {
        return PubSubApiFp(this.configuration).wasteConnectionsApiGetTicketDetailsAndSyncManifestReceipt(projectId, manifestNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PubSubApi
     */
    public wasteConnectionsApiGetTicketDetailsAndSyncManifestReceiptPubSub(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return PubSubApiFp(this.configuration).wasteConnectionsApiGetTicketDetailsAndSyncManifestReceiptPubSub(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PubSubApi
     */
    public wasteManagementApi1GetTicketDetailsAndSyncManifestReceipt(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return PubSubApiFp(this.configuration).wasteManagementApi1GetTicketDetailsAndSyncManifestReceipt(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RedisApi - axios parameter creator
 * @export
 */
export const RedisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllRedisKeys: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/redis/delete-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manifestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWMTicketCacheForManifest: async (manifestId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manifestId' is not null or undefined
            assertParamExists('deleteWMTicketCacheForManifest', 'manifestId', manifestId)
            const localVarPath = `/redis/delete-ticket-cache-for-manifest/{manifestId}`
                .replace(`{${"manifestId"}}`, encodeURIComponent(String(manifestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RedisApi - functional programming interface
 * @export
 */
export const RedisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RedisApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllRedisKeys(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteWMTicketCacheForManifest200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllRedisKeys(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manifestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWMTicketCacheForManifest(manifestId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteWMTicketCacheForManifest200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWMTicketCacheForManifest(manifestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RedisApi - factory interface
 * @export
 */
export const RedisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RedisApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllRedisKeys(options?: any): AxiosPromise<DeleteWMTicketCacheForManifest200Response> {
            return localVarFp.deleteAllRedisKeys(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manifestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWMTicketCacheForManifest(manifestId: string, options?: any): AxiosPromise<DeleteWMTicketCacheForManifest200Response> {
            return localVarFp.deleteWMTicketCacheForManifest(manifestId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RedisApi - object-oriented interface
 * @export
 * @class RedisApi
 * @extends {BaseAPI}
 */
export class RedisApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedisApi
     */
    public deleteAllRedisKeys(options?: AxiosRequestConfig) {
        return RedisApiFp(this.configuration).deleteAllRedisKeys(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manifestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedisApi
     */
    public deleteWMTicketCacheForManifest(manifestId: string, options?: AxiosRequestConfig) {
        return RedisApiFp(this.configuration).deleteWMTicketCacheForManifest(manifestId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SMSApi - axios parameter creator
 * @export
 */
export const SMSApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddUserToLicenseRequest} addUserToLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendActivateAccountSms: async (addUserToLicenseRequest: AddUserToLicenseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addUserToLicenseRequest' is not null or undefined
            assertParamExists('sendActivateAccountSms', 'addUserToLicenseRequest', addUserToLicenseRequest)
            const localVarPath = `/sms/send-notification-activate-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addUserToLicenseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendGeneratorNewUnsignedManifestsNotificationSmsParams} sendGeneratorNewUnsignedManifestsNotificationSmsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendGeneratorNewUnsignedManifestsNotificationSms: async (sendGeneratorNewUnsignedManifestsNotificationSmsParams: SendGeneratorNewUnsignedManifestsNotificationSmsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendGeneratorNewUnsignedManifestsNotificationSmsParams' is not null or undefined
            assertParamExists('sendGeneratorNewUnsignedManifestsNotificationSms', 'sendGeneratorNewUnsignedManifestsNotificationSmsParams', sendGeneratorNewUnsignedManifestsNotificationSmsParams)
            const localVarPath = `/sms/send-notification-generators-new-unsigned-manifests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendGeneratorNewUnsignedManifestsNotificationSmsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddUserToLicenseRequest} addUserToLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInitialPassword: async (addUserToLicenseRequest: AddUserToLicenseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addUserToLicenseRequest' is not null or undefined
            assertParamExists('sendInitialPassword', 'addUserToLicenseRequest', addUserToLicenseRequest)
            const localVarPath = `/sms/send-initial-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addUserToLicenseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendManifestOuttoGeofenceNotificationSmsParams} sendManifestOuttoGeofenceNotificationSmsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendManifestOuttoGeofenceNotificationSms: async (sendManifestOuttoGeofenceNotificationSmsParams: SendManifestOuttoGeofenceNotificationSmsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendManifestOuttoGeofenceNotificationSmsParams' is not null or undefined
            assertParamExists('sendManifestOuttoGeofenceNotificationSms', 'sendManifestOuttoGeofenceNotificationSmsParams', sendManifestOuttoGeofenceNotificationSmsParams)
            const localVarPath = `/sms/send-manifest-out-of-geofence-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendManifestOuttoGeofenceNotificationSmsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SMSApi - functional programming interface
 * @export
 */
export const SMSApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SMSApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddUserToLicenseRequest} addUserToLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendActivateAccountSms(addUserToLicenseRequest: AddUserToLicenseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendActivateAccountSms(addUserToLicenseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendGeneratorNewUnsignedManifestsNotificationSmsParams} sendGeneratorNewUnsignedManifestsNotificationSmsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendGeneratorNewUnsignedManifestsNotificationSms(sendGeneratorNewUnsignedManifestsNotificationSmsParams: SendGeneratorNewUnsignedManifestsNotificationSmsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendGeneratorNewUnsignedManifestsNotificationSms(sendGeneratorNewUnsignedManifestsNotificationSmsParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddUserToLicenseRequest} addUserToLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInitialPassword(addUserToLicenseRequest: AddUserToLicenseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendInitialPassword(addUserToLicenseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendManifestOuttoGeofenceNotificationSmsParams} sendManifestOuttoGeofenceNotificationSmsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendManifestOuttoGeofenceNotificationSms(sendManifestOuttoGeofenceNotificationSmsParams: SendManifestOuttoGeofenceNotificationSmsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendManifestOuttoGeofenceNotificationSms(sendManifestOuttoGeofenceNotificationSmsParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SMSApi - factory interface
 * @export
 */
export const SMSApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SMSApiFp(configuration)
    return {
        /**
         * 
         * @param {AddUserToLicenseRequest} addUserToLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendActivateAccountSms(addUserToLicenseRequest: AddUserToLicenseRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.sendActivateAccountSms(addUserToLicenseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendGeneratorNewUnsignedManifestsNotificationSmsParams} sendGeneratorNewUnsignedManifestsNotificationSmsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendGeneratorNewUnsignedManifestsNotificationSms(sendGeneratorNewUnsignedManifestsNotificationSmsParams: SendGeneratorNewUnsignedManifestsNotificationSmsParams, options?: any): AxiosPromise<boolean> {
            return localVarFp.sendGeneratorNewUnsignedManifestsNotificationSms(sendGeneratorNewUnsignedManifestsNotificationSmsParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddUserToLicenseRequest} addUserToLicenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInitialPassword(addUserToLicenseRequest: AddUserToLicenseRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.sendInitialPassword(addUserToLicenseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendManifestOuttoGeofenceNotificationSmsParams} sendManifestOuttoGeofenceNotificationSmsParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendManifestOuttoGeofenceNotificationSms(sendManifestOuttoGeofenceNotificationSmsParams: SendManifestOuttoGeofenceNotificationSmsParams, options?: any): AxiosPromise<void> {
            return localVarFp.sendManifestOuttoGeofenceNotificationSms(sendManifestOuttoGeofenceNotificationSmsParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SMSApi - object-oriented interface
 * @export
 * @class SMSApi
 * @extends {BaseAPI}
 */
export class SMSApi extends BaseAPI {
    /**
     * 
     * @param {AddUserToLicenseRequest} addUserToLicenseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SMSApi
     */
    public sendActivateAccountSms(addUserToLicenseRequest: AddUserToLicenseRequest, options?: AxiosRequestConfig) {
        return SMSApiFp(this.configuration).sendActivateAccountSms(addUserToLicenseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendGeneratorNewUnsignedManifestsNotificationSmsParams} sendGeneratorNewUnsignedManifestsNotificationSmsParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SMSApi
     */
    public sendGeneratorNewUnsignedManifestsNotificationSms(sendGeneratorNewUnsignedManifestsNotificationSmsParams: SendGeneratorNewUnsignedManifestsNotificationSmsParams, options?: AxiosRequestConfig) {
        return SMSApiFp(this.configuration).sendGeneratorNewUnsignedManifestsNotificationSms(sendGeneratorNewUnsignedManifestsNotificationSmsParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddUserToLicenseRequest} addUserToLicenseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SMSApi
     */
    public sendInitialPassword(addUserToLicenseRequest: AddUserToLicenseRequest, options?: AxiosRequestConfig) {
        return SMSApiFp(this.configuration).sendInitialPassword(addUserToLicenseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendManifestOuttoGeofenceNotificationSmsParams} sendManifestOuttoGeofenceNotificationSmsParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SMSApi
     */
    public sendManifestOuttoGeofenceNotificationSms(sendManifestOuttoGeofenceNotificationSmsParams: SendManifestOuttoGeofenceNotificationSmsParams, options?: AxiosRequestConfig) {
        return SMSApiFp(this.configuration).sendManifestOuttoGeofenceNotificationSms(sendManifestOuttoGeofenceNotificationSmsParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SafeVersionsApi - axios parameter creator
 * @export
 */
export const SafeVersionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} safeVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkCodeForProjectIdRequest: async (safeVersion: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'safeVersion' is not null or undefined
            assertParamExists('getWorkCodeForProjectIdRequest', 'safeVersion', safeVersion)
            const localVarPath = `/safe-versions/{safeVersion}`
                .replace(`{${"safeVersion"}}`, encodeURIComponent(String(safeVersion)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SafeVersionsApi - functional programming interface
 * @export
 */
export const SafeVersionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SafeVersionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} safeVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkCodeForProjectIdRequest(safeVersion: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkCodeForProjectIdRequest(safeVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SafeVersionsApi - factory interface
 * @export
 */
export const SafeVersionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SafeVersionsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} safeVersion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkCodeForProjectIdRequest(safeVersion: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.getWorkCodeForProjectIdRequest(safeVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SafeVersionsApi - object-oriented interface
 * @export
 * @class SafeVersionsApi
 * @extends {BaseAPI}
 */
export class SafeVersionsApi extends BaseAPI {
    /**
     * 
     * @param {string} safeVersion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafeVersionsApi
     */
    public getWorkCodeForProjectIdRequest(safeVersion: string, options?: AxiosRequestConfig) {
        return SafeVersionsApiFp(this.configuration).getWorkCodeForProjectIdRequest(safeVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TemplateApi - axios parameter creator
 * @export
 */
export const TemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} manifestIds 
         * @param {boolean} [templateDisplay] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBulkManifestTemplateHTML: async (manifestIds: Array<string>, templateDisplay?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manifestIds' is not null or undefined
            assertParamExists('getBulkManifestTemplateHTML', 'manifestIds', manifestIds)
            const localVarPath = `/template/manifests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (templateDisplay !== undefined) {
                localVarQueryParameter['templateDisplay'] = templateDisplay;
            }

            if (manifestIds) {
                localVarQueryParameter['manifestIds'] = manifestIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCivilPitTicketTemplateHTML: async (ticketId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('getCivilPitTicketTemplateHTML', 'ticketId', ticketId)
            const localVarPath = `/template/civil-pit-ticket/{ticketId}`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCivilTicketTemplateHTML: async (ticketId: number, requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('getCivilTicketTemplateHTML', 'ticketId', ticketId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getCivilTicketTemplateHTML', 'requestBody', requestBody)
            const localVarPath = `/template/civil-ticket/{ticketId}`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manifestId 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManifestTemplateHTML: async (manifestId: string, requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manifestId' is not null or undefined
            assertParamExists('getManifestTemplateHTML', 'manifestId', manifestId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getManifestTemplateHTML', 'requestBody', requestBody)
            const localVarPath = `/template/manifest/{manifestId}`
                .replace(`{${"manifestId"}}`, encodeURIComponent(String(manifestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateDisplayHTML: async (requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getTemplateDisplayHTML', 'requestBody', requestBody)
            const localVarPath = `/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/template/template-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTruckingTicketTemplateHTML: async (ticketId: number, requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('getTruckingTicketTemplateHTML', 'ticketId', ticketId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getTruckingTicketTemplateHTML', 'requestBody', requestBody)
            const localVarPath = `/template/trucking-ticket/{ticketId}`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplateApi - functional programming interface
 * @export
 */
export const TemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<string>} manifestIds 
         * @param {boolean} [templateDisplay] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBulkManifestTemplateHTML(manifestIds: Array<string>, templateDisplay?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBulkManifestTemplateHTML(manifestIds, templateDisplay, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCivilPitTicketTemplateHTML(ticketId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCivilPitTicketTemplateHTML(ticketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCivilTicketTemplateHTML(ticketId: number, requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCivilTicketTemplateHTML(ticketId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manifestId 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManifestTemplateHTML(manifestId: string, requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManifestTemplateHTML(manifestId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplateDisplayHTML(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplateDisplayHTML(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplateTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTemplateTypes200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplateTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTruckingTicketTemplateHTML(ticketId: number, requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTruckingTicketTemplateHTML(ticketId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemplateApi - factory interface
 * @export
 */
export const TemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<string>} manifestIds 
         * @param {boolean} [templateDisplay] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBulkManifestTemplateHTML(manifestIds: Array<string>, templateDisplay?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.getBulkManifestTemplateHTML(manifestIds, templateDisplay, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCivilPitTicketTemplateHTML(ticketId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getCivilPitTicketTemplateHTML(ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCivilTicketTemplateHTML(ticketId: number, requestBody: { [key: string]: any; }, options?: any): AxiosPromise<string> {
            return localVarFp.getCivilTicketTemplateHTML(ticketId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manifestId 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManifestTemplateHTML(manifestId: string, requestBody: { [key: string]: any; }, options?: any): AxiosPromise<string> {
            return localVarFp.getManifestTemplateHTML(manifestId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateDisplayHTML(requestBody: { [key: string]: any; }, options?: any): AxiosPromise<string> {
            return localVarFp.getTemplateDisplayHTML(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateTypes(options?: any): AxiosPromise<GetTemplateTypes200Response> {
            return localVarFp.getTemplateTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {{ [key: string]: any; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTruckingTicketTemplateHTML(ticketId: number, requestBody: { [key: string]: any; }, options?: any): AxiosPromise<string> {
            return localVarFp.getTruckingTicketTemplateHTML(ticketId, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplateApi - object-oriented interface
 * @export
 * @class TemplateApi
 * @extends {BaseAPI}
 */
export class TemplateApi extends BaseAPI {
    /**
     * 
     * @param {Array<string>} manifestIds 
     * @param {boolean} [templateDisplay] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public getBulkManifestTemplateHTML(manifestIds: Array<string>, templateDisplay?: boolean, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).getBulkManifestTemplateHTML(manifestIds, templateDisplay, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ticketId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public getCivilPitTicketTemplateHTML(ticketId: number, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).getCivilPitTicketTemplateHTML(ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ticketId 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public getCivilTicketTemplateHTML(ticketId: number, requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).getCivilTicketTemplateHTML(ticketId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manifestId 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public getManifestTemplateHTML(manifestId: string, requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).getManifestTemplateHTML(manifestId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public getTemplateDisplayHTML(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).getTemplateDisplayHTML(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public getTemplateTypes(options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).getTemplateTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ticketId 
     * @param {{ [key: string]: any; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public getTruckingTicketTemplateHTML(ticketId: number, requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).getTruckingTicketTemplateHTML(ticketId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TimesheetsApi - axios parameter creator
 * @export
 */
export const TimesheetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTimesheet: async (body: PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createTimesheet', 'body', body)
            const localVarPath = `/timesheets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PickTruckingTimesheetReferencesDispatchId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTimesheetReference: async (id: number, body: PickTruckingTimesheetReferencesDispatchId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createTimesheetReference', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createTimesheetReference', 'body', body)
            const localVarPath = `/timesheets/references`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} timesheetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimesheet: async (timesheetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timesheetId' is not null or undefined
            assertParamExists('deleteTimesheet', 'timesheetId', timesheetId)
            const localVarPath = `/timesheets/{timesheetId}`
                .replace(`{${"timesheetId"}}`, encodeURIComponent(String(timesheetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimesheetReference: async (referenceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('deleteTimesheetReference', 'referenceId', referenceId)
            const localVarPath = `/timesheets/references/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} timesheetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimesheetById: async (timesheetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timesheetId' is not null or undefined
            assertParamExists('getTimesheetById', 'timesheetId', timesheetId)
            const localVarPath = `/timesheets/{timesheetId}`
                .replace(`{${"timesheetId"}}`, encodeURIComponent(String(timesheetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimesheetsByCompanyId: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTimesheetsByCompanyId', 'companyId', companyId)
            const localVarPath = `/timesheets/byCompany/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dispatchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimesheetsByDispatchId: async (dispatchId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dispatchId' is not null or undefined
            assertParamExists('getTimesheetsByDispatchId', 'dispatchId', dispatchId)
            const localVarPath = `/timesheets/byDispatch/{dispatchId}`
                .replace(`{${"dispatchId"}}`, encodeURIComponent(String(dispatchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} timesheetId 
         * @param {PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimesheet: async (timesheetId: number, body: PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timesheetId' is not null or undefined
            assertParamExists('updateTimesheet', 'timesheetId', timesheetId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateTimesheet', 'body', body)
            const localVarPath = `/timesheets/update/{timesheetId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (timesheetId !== undefined) {
                localVarQueryParameter['timesheetId'] = timesheetId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimesheetsApi - functional programming interface
 * @export
 */
export const TimesheetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimesheetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTimesheet(body: PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TruckingTimesheet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTimesheet(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PickTruckingTimesheetReferencesDispatchId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTimesheetReference(id: number, body: PickTruckingTimesheetReferencesDispatchId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTimesheetReference(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} timesheetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTimesheet(timesheetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTimesheet(timesheetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTimesheetReference(referenceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTimesheetReference(referenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} timesheetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimesheetById(timesheetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTimesheetById200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimesheetById(timesheetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimesheetsByCompanyId(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetTimesheetById200Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimesheetsByCompanyId(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dispatchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimesheetsByDispatchId(dispatchId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TruckingTimesheet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimesheetsByDispatchId(dispatchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} timesheetId 
         * @param {PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTimesheet(timesheetId: number, body: PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TruckingTimesheet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTimesheet(timesheetId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TimesheetsApi - factory interface
 * @export
 */
export const TimesheetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimesheetsApiFp(configuration)
    return {
        /**
         * 
         * @param {PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTimesheet(body: PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId, options?: any): AxiosPromise<TruckingTimesheet> {
            return localVarFp.createTimesheet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PickTruckingTimesheetReferencesDispatchId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTimesheetReference(id: number, body: PickTruckingTimesheetReferencesDispatchId, options?: any): AxiosPromise<void> {
            return localVarFp.createTimesheetReference(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} timesheetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimesheet(timesheetId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTimesheet(timesheetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimesheetReference(referenceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTimesheetReference(referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} timesheetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimesheetById(timesheetId: number, options?: any): AxiosPromise<GetTimesheetById200Response> {
            return localVarFp.getTimesheetById(timesheetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimesheetsByCompanyId(companyId: string, options?: any): AxiosPromise<Array<GetTimesheetById200Response>> {
            return localVarFp.getTimesheetsByCompanyId(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dispatchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimesheetsByDispatchId(dispatchId: number, options?: any): AxiosPromise<Array<TruckingTimesheet>> {
            return localVarFp.getTimesheetsByDispatchId(dispatchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} timesheetId 
         * @param {PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimesheet(timesheetId: number, body: PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId, options?: any): AxiosPromise<TruckingTimesheet> {
            return localVarFp.updateTimesheet(timesheetId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TimesheetsApi - object-oriented interface
 * @export
 * @class TimesheetsApi
 * @extends {BaseAPI}
 */
export class TimesheetsApi extends BaseAPI {
    /**
     * 
     * @param {PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetsApi
     */
    public createTimesheet(body: PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId, options?: AxiosRequestConfig) {
        return TimesheetsApiFp(this.configuration).createTimesheet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PickTruckingTimesheetReferencesDispatchId} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetsApi
     */
    public createTimesheetReference(id: number, body: PickTruckingTimesheetReferencesDispatchId, options?: AxiosRequestConfig) {
        return TimesheetsApiFp(this.configuration).createTimesheetReference(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} timesheetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetsApi
     */
    public deleteTimesheet(timesheetId: number, options?: AxiosRequestConfig) {
        return TimesheetsApiFp(this.configuration).deleteTimesheet(timesheetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} referenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetsApi
     */
    public deleteTimesheetReference(referenceId: number, options?: AxiosRequestConfig) {
        return TimesheetsApiFp(this.configuration).deleteTimesheetReference(referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} timesheetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetsApi
     */
    public getTimesheetById(timesheetId: number, options?: AxiosRequestConfig) {
        return TimesheetsApiFp(this.configuration).getTimesheetById(timesheetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetsApi
     */
    public getTimesheetsByCompanyId(companyId: string, options?: AxiosRequestConfig) {
        return TimesheetsApiFp(this.configuration).getTimesheetsByCompanyId(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dispatchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetsApi
     */
    public getTimesheetsByDispatchId(dispatchId: number, options?: AxiosRequestConfig) {
        return TimesheetsApiFp(this.configuration).getTimesheetsByDispatchId(dispatchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} timesheetId 
     * @param {PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetsApi
     */
    public updateTimesheet(timesheetId: number, body: PickTruckingTimesheetExcludeKeyoftruckingTimesheetCreatedByOrModifiedByOrCreatedDateOrModifiedDateOrId, options?: AxiosRequestConfig) {
        return TimesheetsApiFp(this.configuration).updateTimesheet(timesheetId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UnmatchedIncomingTicketsApi - axios parameter creator
 * @export
 */
export const UnmatchedIncomingTicketsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} apiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countUnmatchedTicketsByApiId: async (apiId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiId' is not null or undefined
            assertParamExists('countUnmatchedTicketsByApiId', 'apiId', apiId)
            const localVarPath = `/unmatched-incoming-tickets/api-id/{apiId}/count`
                .replace(`{${"apiId"}}`, encodeURIComponent(String(apiId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnmatchedIncomingTicketsApi - functional programming interface
 * @export
 */
export const UnmatchedIncomingTicketsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UnmatchedIncomingTicketsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} apiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countUnmatchedTicketsByApiId(apiId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countUnmatchedTicketsByApiId(apiId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UnmatchedIncomingTicketsApi - factory interface
 * @export
 */
export const UnmatchedIncomingTicketsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UnmatchedIncomingTicketsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} apiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countUnmatchedTicketsByApiId(apiId: number, options?: any): AxiosPromise<number> {
            return localVarFp.countUnmatchedTicketsByApiId(apiId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UnmatchedIncomingTicketsApi - object-oriented interface
 * @export
 * @class UnmatchedIncomingTicketsApi
 * @extends {BaseAPI}
 */
export class UnmatchedIncomingTicketsApi extends BaseAPI {
    /**
     * 
     * @param {number} apiId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnmatchedIncomingTicketsApi
     */
    public countUnmatchedTicketsByApiId(apiId: number, options?: AxiosRequestConfig) {
        return UnmatchedIncomingTicketsApiFp(this.configuration).countUnmatchedTicketsByApiId(apiId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates new user. This method does some validation.
         * @param {{ [key: string]: any; }} requestBody - Object that represents user\&#39;s data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('create', 'requestBody', requestBody)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Downloads specific manifest (pdf-file).
         * @param {string} id - Id of manifest.
         * @param {boolean} [notes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadManifestPdf: async (id: string, notes?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadManifestPdf', 'id', id)
            const localVarPath = `/users/download/manifest/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (notes !== undefined) {
                localVarQueryParameter['notes'] = notes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generates hashed key (by id of current user) for integration with Intercom.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateIntercomUserHash: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/get-intercom-user-hash`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches all users.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes existing user.
         * @param {string} id - User\&#39;s id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remove', 'id', id)
            const localVarPath = `/users/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates user\'s password. It uses \'current\' (by auth token) user.
         * @param {SetUserPasswordRequest} setUserPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserPassword: async (setUserPasswordRequest: SetUserPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setUserPasswordRequest' is not null or undefined
            assertParamExists('setUserPassword', 'setUserPasswordRequest', setUserPasswordRequest)
            const localVarPath = `/users/set-user-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setUserPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates existing user.
         * @param {string} id 
         * @param {{ [key: string]: any; }} requestBody - Object that represents user\&#39;s data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, requestBody: { [key: string]: any; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('update', 'requestBody', requestBody)
            const localVarPath = `/users/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates new user. This method does some validation.
         * @param {{ [key: string]: any; }} requestBody - Object that represents user\&#39;s data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Create200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Downloads specific manifest (pdf-file).
         * @param {string} id - Id of manifest.
         * @param {boolean} [notes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadManifestPdf(id: string, notes?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadManifestPdf(id, notes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generates hashed key (by id of current user) for integration with Intercom.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateIntercomUserHash(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateIntercomUserHash200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateIntercomUserHash(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetches all users.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<List200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes existing user.
         * @param {string} id - User\&#39;s id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates user\'s password. It uses \'current\' (by auth token) user.
         * @param {SetUserPasswordRequest} setUserPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserPassword(setUserPasswordRequest: SetUserPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserPassword(setUserPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates existing user.
         * @param {string} id 
         * @param {{ [key: string]: any; }} requestBody - Object that represents user\&#39;s data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, requestBody: { [key: string]: any; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Creates new user. This method does some validation.
         * @param {{ [key: string]: any; }} requestBody - Object that represents user\&#39;s data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(requestBody: { [key: string]: any; }, options?: any): AxiosPromise<Create200Response> {
            return localVarFp.create(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Downloads specific manifest (pdf-file).
         * @param {string} id - Id of manifest.
         * @param {boolean} [notes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadManifestPdf(id: string, notes?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.downloadManifestPdf(id, notes, options).then((request) => request(axios, basePath));
        },
        /**
         * Generates hashed key (by id of current user) for integration with Intercom.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateIntercomUserHash(options?: any): AxiosPromise<GenerateIntercomUserHash200Response> {
            return localVarFp.generateIntercomUserHash(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches all users.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(options?: any): AxiosPromise<Array<List200ResponseInner>> {
            return localVarFp.list(options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes existing user.
         * @param {string} id - User\&#39;s id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.remove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates user\'s password. It uses \'current\' (by auth token) user.
         * @param {SetUserPasswordRequest} setUserPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserPassword(setUserPasswordRequest: SetUserPasswordRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.setUserPassword(setUserPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates existing user.
         * @param {string} id 
         * @param {{ [key: string]: any; }} requestBody - Object that represents user\&#39;s data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, requestBody: { [key: string]: any; }, options?: any): AxiosPromise<boolean> {
            return localVarFp.update(id, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Creates new user. This method does some validation.
     * @param {{ [key: string]: any; }} requestBody - Object that represents user\&#39;s data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public create(requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).create(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Downloads specific manifest (pdf-file).
     * @param {string} id - Id of manifest.
     * @param {boolean} [notes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public downloadManifestPdf(id: string, notes?: boolean, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).downloadManifestPdf(id, notes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generates hashed key (by id of current user) for integration with Intercom.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public generateIntercomUserHash(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).generateIntercomUserHash(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches all users.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public list(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).list(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes existing user.
     * @param {string} id - User\&#39;s id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public remove(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).remove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates user\'s password. It uses \'current\' (by auth token) user.
     * @param {SetUserPasswordRequest} setUserPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public setUserPassword(setUserPasswordRequest: SetUserPasswordRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).setUserPassword(setUserPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates existing user.
     * @param {string} id 
     * @param {{ [key: string]: any; }} requestBody - Object that represents user\&#39;s data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public update(id: string, requestBody: { [key: string]: any; }, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).update(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkCodesApi - axios parameter creator
 * @export
 */
export const WorkCodesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} workCodeId 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCustomInputDefaultsForWorkCode: async (workCodeId: string, body: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workCodeId' is not null or undefined
            assertParamExists('addCustomInputDefaultsForWorkCode', 'workCodeId', workCodeId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addCustomInputDefaultsForWorkCode', 'body', body)
            const localVarPath = `/work-codes/{workCodeId}/add-custom-input-defaults`
                .replace(`{${"workCodeId"}}`, encodeURIComponent(String(workCodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CancelWorkCodeRequestBody} cancelWorkCodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelWorkCodeRequest: async (cancelWorkCodeRequestBody: CancelWorkCodeRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cancelWorkCodeRequestBody' is not null or undefined
            assertParamExists('cancelWorkCodeRequest', 'cancelWorkCodeRequestBody', cancelWorkCodeRequestBody)
            const localVarPath = `/work-codes/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelWorkCodeRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateWorkCodeRequestBody} createWorkCodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkCodeRequest: async (createWorkCodeRequestBody: CreateWorkCodeRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createWorkCodeRequestBody' is not null or undefined
            assertParamExists('createWorkCodeRequest', 'createWorkCodeRequestBody', createWorkCodeRequestBody)
            const localVarPath = `/work-codes/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkCodeRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workCodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomInputDefaultsForWorkCode: async (workCodeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workCodeId' is not null or undefined
            assertParamExists('getCustomInputDefaultsForWorkCode', 'workCodeId', workCodeId)
            const localVarPath = `/work-codes/{workCodeId}/custom-input-defaults`
                .replace(`{${"workCodeId"}}`, encodeURIComponent(String(workCodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workCodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkCodeDrivers: async (workCodeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workCodeId' is not null or undefined
            assertParamExists('getWorkCodeDrivers', 'workCodeId', workCodeId)
            const localVarPath = `/work-codes/{workCodeId}/drivers`
                .replace(`{${"workCodeId"}}`, encodeURIComponent(String(workCodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkCodeForProjectIdRequest: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getWorkCodeForProjectIdRequest', 'projectId', projectId)
            const localVarPath = `/work-codes/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workCodeId 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkCodeNickname: async (workCodeId: string, body: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workCodeId' is not null or undefined
            assertParamExists('updateWorkCodeNickname', 'workCodeId', workCodeId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateWorkCodeNickname', 'body', body)
            const localVarPath = `/work-codes/{workCodeId}/nickname`
                .replace(`{${"workCodeId"}}`, encodeURIComponent(String(workCodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyWorkCodeRequestBody} verifyWorkCodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyWorkCodeRequest: async (verifyWorkCodeRequestBody: VerifyWorkCodeRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyWorkCodeRequestBody' is not null or undefined
            assertParamExists('verifyWorkCodeRequest', 'verifyWorkCodeRequestBody', verifyWorkCodeRequestBody)
            const localVarPath = `/work-codes/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyWorkCodeRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddDriverRequestBody} addDriverRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workCodeAddDriverRequest: async (addDriverRequestBody: AddDriverRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addDriverRequestBody' is not null or undefined
            assertParamExists('workCodeAddDriverRequest', 'addDriverRequestBody', addDriverRequestBody)
            const localVarPath = `/work-codes/add-driver`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDriverRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} driverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workCodeRemoveDriverRequest: async (driverId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverId' is not null or undefined
            assertParamExists('workCodeRemoveDriverRequest', 'driverId', driverId)
            const localVarPath = `/work-codes/remove-driver/{driverId}`
                .replace(`{${"driverId"}}`, encodeURIComponent(String(driverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workCodeRemoveDriverRequestLegacy: async (driverId: string, projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverId' is not null or undefined
            assertParamExists('workCodeRemoveDriverRequestLegacy', 'driverId', driverId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('workCodeRemoveDriverRequestLegacy', 'projectId', projectId)
            const localVarPath = `/work-codes/remove-driver/{driverId}/{projectId}`
                .replace(`{${"driverId"}}`, encodeURIComponent(String(driverId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkCodesApi - functional programming interface
 * @export
 */
export const WorkCodesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkCodesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} workCodeId 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCustomInputDefaultsForWorkCode(workCodeId: string, body: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddCustomInputDefaultsForWorkCode200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCustomInputDefaultsForWorkCode(workCodeId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CancelWorkCodeRequestBody} cancelWorkCodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelWorkCodeRequest(cancelWorkCodeRequestBody: CancelWorkCodeRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateWorkCodeNickname200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelWorkCodeRequest(cancelWorkCodeRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateWorkCodeRequestBody} createWorkCodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkCodeRequest(createWorkCodeRequestBody: CreateWorkCodeRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateWorkCodeRequest200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkCodeRequest(createWorkCodeRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} workCodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomInputDefaultsForWorkCode(workCodeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomInputDefaultsForWorkCode200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomInputDefaultsForWorkCode(workCodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} workCodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkCodeDrivers(workCodeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWorkCodeDrivers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkCodeDrivers(workCodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkCodeForProjectIdRequest(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkCodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkCodeForProjectIdRequest(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} workCodeId 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkCodeNickname(workCodeId: string, body: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateWorkCodeNickname200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkCodeNickname(workCodeId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyWorkCodeRequestBody} verifyWorkCodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyWorkCodeRequest(verifyWorkCodeRequestBody: VerifyWorkCodeRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyWorkCodeRequest(verifyWorkCodeRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddDriverRequestBody} addDriverRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workCodeAddDriverRequest(addDriverRequestBody: AddDriverRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkCodeAddDriverRequest200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workCodeAddDriverRequest(addDriverRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} driverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workCodeRemoveDriverRequest(driverId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateWorkCodeNickname200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workCodeRemoveDriverRequest(driverId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workCodeRemoveDriverRequestLegacy(driverId: string, projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateWorkCodeNickname200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workCodeRemoveDriverRequestLegacy(driverId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkCodesApi - factory interface
 * @export
 */
export const WorkCodesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkCodesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} workCodeId 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCustomInputDefaultsForWorkCode(workCodeId: string, body: any, options?: any): AxiosPromise<AddCustomInputDefaultsForWorkCode200Response> {
            return localVarFp.addCustomInputDefaultsForWorkCode(workCodeId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CancelWorkCodeRequestBody} cancelWorkCodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelWorkCodeRequest(cancelWorkCodeRequestBody: CancelWorkCodeRequestBody, options?: any): AxiosPromise<UpdateWorkCodeNickname200Response> {
            return localVarFp.cancelWorkCodeRequest(cancelWorkCodeRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateWorkCodeRequestBody} createWorkCodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkCodeRequest(createWorkCodeRequestBody: CreateWorkCodeRequestBody, options?: any): AxiosPromise<CreateWorkCodeRequest200Response> {
            return localVarFp.createWorkCodeRequest(createWorkCodeRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workCodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomInputDefaultsForWorkCode(workCodeId: string, options?: any): AxiosPromise<GetCustomInputDefaultsForWorkCode200Response> {
            return localVarFp.getCustomInputDefaultsForWorkCode(workCodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workCodeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkCodeDrivers(workCodeId: string, options?: any): AxiosPromise<GetWorkCodeDrivers200Response> {
            return localVarFp.getWorkCodeDrivers(workCodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkCodeForProjectIdRequest(projectId: string, options?: any): AxiosPromise<WorkCodesResponse> {
            return localVarFp.getWorkCodeForProjectIdRequest(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workCodeId 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkCodeNickname(workCodeId: string, body: any, options?: any): AxiosPromise<UpdateWorkCodeNickname200Response> {
            return localVarFp.updateWorkCodeNickname(workCodeId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyWorkCodeRequestBody} verifyWorkCodeRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyWorkCodeRequest(verifyWorkCodeRequestBody: VerifyWorkCodeRequestBody, options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.verifyWorkCodeRequest(verifyWorkCodeRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddDriverRequestBody} addDriverRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workCodeAddDriverRequest(addDriverRequestBody: AddDriverRequestBody, options?: any): AxiosPromise<WorkCodeAddDriverRequest200Response> {
            return localVarFp.workCodeAddDriverRequest(addDriverRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} driverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workCodeRemoveDriverRequest(driverId: string, options?: any): AxiosPromise<UpdateWorkCodeNickname200Response> {
            return localVarFp.workCodeRemoveDriverRequest(driverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workCodeRemoveDriverRequestLegacy(driverId: string, projectId: string, options?: any): AxiosPromise<UpdateWorkCodeNickname200Response> {
            return localVarFp.workCodeRemoveDriverRequestLegacy(driverId, projectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkCodesApi - object-oriented interface
 * @export
 * @class WorkCodesApi
 * @extends {BaseAPI}
 */
export class WorkCodesApi extends BaseAPI {
    /**
     * 
     * @param {string} workCodeId 
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkCodesApi
     */
    public addCustomInputDefaultsForWorkCode(workCodeId: string, body: any, options?: AxiosRequestConfig) {
        return WorkCodesApiFp(this.configuration).addCustomInputDefaultsForWorkCode(workCodeId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CancelWorkCodeRequestBody} cancelWorkCodeRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkCodesApi
     */
    public cancelWorkCodeRequest(cancelWorkCodeRequestBody: CancelWorkCodeRequestBody, options?: AxiosRequestConfig) {
        return WorkCodesApiFp(this.configuration).cancelWorkCodeRequest(cancelWorkCodeRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateWorkCodeRequestBody} createWorkCodeRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkCodesApi
     */
    public createWorkCodeRequest(createWorkCodeRequestBody: CreateWorkCodeRequestBody, options?: AxiosRequestConfig) {
        return WorkCodesApiFp(this.configuration).createWorkCodeRequest(createWorkCodeRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workCodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkCodesApi
     */
    public getCustomInputDefaultsForWorkCode(workCodeId: string, options?: AxiosRequestConfig) {
        return WorkCodesApiFp(this.configuration).getCustomInputDefaultsForWorkCode(workCodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workCodeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkCodesApi
     */
    public getWorkCodeDrivers(workCodeId: string, options?: AxiosRequestConfig) {
        return WorkCodesApiFp(this.configuration).getWorkCodeDrivers(workCodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkCodesApi
     */
    public getWorkCodeForProjectIdRequest(projectId: string, options?: AxiosRequestConfig) {
        return WorkCodesApiFp(this.configuration).getWorkCodeForProjectIdRequest(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workCodeId 
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkCodesApi
     */
    public updateWorkCodeNickname(workCodeId: string, body: any, options?: AxiosRequestConfig) {
        return WorkCodesApiFp(this.configuration).updateWorkCodeNickname(workCodeId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyWorkCodeRequestBody} verifyWorkCodeRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkCodesApi
     */
    public verifyWorkCodeRequest(verifyWorkCodeRequestBody: VerifyWorkCodeRequestBody, options?: AxiosRequestConfig) {
        return WorkCodesApiFp(this.configuration).verifyWorkCodeRequest(verifyWorkCodeRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddDriverRequestBody} addDriverRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkCodesApi
     */
    public workCodeAddDriverRequest(addDriverRequestBody: AddDriverRequestBody, options?: AxiosRequestConfig) {
        return WorkCodesApiFp(this.configuration).workCodeAddDriverRequest(addDriverRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} driverId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkCodesApi
     */
    public workCodeRemoveDriverRequest(driverId: string, options?: AxiosRequestConfig) {
        return WorkCodesApiFp(this.configuration).workCodeRemoveDriverRequest(driverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} driverId 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkCodesApi
     */
    public workCodeRemoveDriverRequestLegacy(driverId: string, projectId: string, options?: AxiosRequestConfig) {
        return WorkCodesApiFp(this.configuration).workCodeRemoveDriverRequestLegacy(driverId, projectId, options).then((request) => request(this.axios, this.basePath));
    }
}


