import React, { ReactElement } from 'react';
import { Typography, SvgIcon, Grid, Box } from '@mui/material';
import {
  LocalShippingOutlined,
  CreateNewFolderOutlined,
  List,
  Handshake,
  Engineering,
  Grass,
  PrecisionManufacturing,
  InfoOutlined,
} from '@mui/icons-material';
import ManageCard from './ManageCard';
import {
  DumpTruck,
  DumpTruckPlus,
  MaterialSource,
  SmallTruck,
  SourceSupplier,
} from '../../CustomIcons';

export type manageButton = {
  label: string;
  pictogram: ReactElement;
  href: string;
  dispatcherOnly?: boolean;
};

const manageButtonsTruckingFull: manageButton[] = [
  {
    label: 'Manage Truck Types',
    pictogram: (
      <SvgIcon color="secondary">
        <List />
      </SvgIcon>
    ),
    href: './truck-types',
    dispatcherOnly: false,
  },
  {
    label: 'Manage Divisions',
    pictogram: (
      <SvgIcon color="secondary">
        <CreateNewFolderOutlined />
      </SvgIcon>
    ),
    href: './divisions',
    dispatcherOnly: false,
  },
  {
    label: 'Manage Trucks',
    pictogram: (
      <SvgIcon color="secondary">
        <LocalShippingOutlined />
      </SvgIcon>
    ),
    href: './trucks',
    dispatcherOnly: false,
  },
  {
    label: 'Manage Source Suppliers',
    pictogram: (
      <SvgIcon color="secondary">
        <PrecisionManufacturing />
      </SvgIcon>
    ),
    href: './source-suppliers',
    dispatcherOnly: true,
  },
  {
    label: 'Manage Materials',
    pictogram: (
      <SvgIcon color="secondary">
        <Grass />
      </SvgIcon>
    ),
    href: './company-materials',
    dispatcherOnly: true,
  },
  {
    label: 'Manage Brokers',
    pictogram: (
      <SvgIcon color="secondary">
        <Handshake />
      </SvgIcon>
    ),
    href: './brokers',
    dispatcherOnly: true,
  },
  {
    label: 'Manage Contractors',
    pictogram: (
      <SvgIcon color="secondary">
        <Engineering />
      </SvgIcon>
    ),
    href: './contractors',
    dispatcherOnly: true,
  },
];

const manageButtonsCivil: manageButton[] = [
  {
    label: 'Create Project',
    pictogram: (
      <SvgIcon color="secondary">
        <CreateNewFolderOutlined />
      </SvgIcon>
    ),
    href: '/civil/projects/add',
  },
  {
    label: 'Company Details',
    pictogram: (
      <SvgIcon color="secondary">
        <InfoOutlined />
      </SvgIcon>
    ),
    href: '/civil/settings/company-details',
  },
  {
    label: 'External Transporters',
    pictogram: (
      <SvgIcon color="secondary">
        <DumpTruck viewBox="0 0 42 21" />
      </SvgIcon>
    ),
    href: '/civil/settings/transporters',
  },
  {
    label: 'Source Suppliers',
    pictogram: (
      <SvgIcon color="secondary">
        <SourceSupplier viewBox="0 0 32 32" />,
      </SvgIcon>
    ),
    href: '/civil/settings/sources',
  },
  {
    label: 'Materials',
    pictogram: (
      <SvgIcon color="secondary">
        <MaterialSource viewBox="0 0 25 30" />,
      </SvgIcon>
    ),
    href: '/civil/settings/company-materials',
  },
  {
    label: 'Sub-Materials',
    pictogram: (
      <SvgIcon color="secondary">
        <MaterialSource viewBox="0 0 25 30" />,
      </SvgIcon>
    ),
    href: '/civil/settings/company-sub-materials',
  },
];

const manageButtonsTruckingCivil = [
  {
    label: 'Manage Truck Types',
    pictogram: (
      <SvgIcon color="secondary">
        <SmallTruck viewBox="0 0 24 16" />,
      </SvgIcon>
    ),
    href: '/civil/settings/truck-types',
  },
  {
    label: 'Manage Divisions',
    pictogram: (
      <SvgIcon color="secondary">
        <SmallTruck viewBox="0 0 24 16" />,
      </SvgIcon>
    ),
    href: '/civil/settings/divisions',
  },
  {
    label: 'Manage Trucks',
    pictogram: (
      <SvgIcon color="secondary">
        <DumpTruckPlus viewBox="0 0 42 22" />
      </SvgIcon>
    ),
    href: '/civil/settings/trucks',
  },
];

const projectButton: manageButton = {
  label: 'Projects',
  pictogram: (
    <SvgIcon color="secondary">
      <CreateNewFolderOutlined />
    </SvgIcon>
  ),
  href: './projects',
  dispatcherOnly: true,
};

const ManageSettings = ({ isCivilCompany }: { isCivilCompany: boolean }) => {
  let manageButtons;

  if (isCivilCompany) {
    manageButtons = (
      <>
        {manageButtonsCivil.map(button => (
          <ManageCard button={button} key={button.href} />
        ))}
        <Box borderBottom="1px solid #aaa" width="100%" marginY={2}></Box>
        {manageButtonsTruckingCivil.map(button => (
          <ManageCard button={button} key={button.href} />
        ))}
      </>
    );
  } else {
    manageButtons = (
      <>
        {manageButtonsTruckingFull.map(button => (
          <ManageCard button={button} key={button.href} />
        ))}
        <Box sx={{ my: 4, width: '100%', borderBottom: '1px solid #aaa' }} />
        <ManageCard button={projectButton} />
      </>
    );
  }

  return (
    <Box mt={4}>
      <Typography color="neutral.main">Manage</Typography>
      <Grid container spacing={1} alignItems="flex-start" justifyContent="flex-start">
        {manageButtons}
      </Grid>
    </Box>
  );
};

export default ManageSettings;
