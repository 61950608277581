import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'layouts/DashboardLayout';
import MainLayout from 'layouts/MainLayout';
import NoHeaderLayout from 'layouts/NoHeaderLayout';
import BareLayout from 'layouts/BareLayout';

import NotFoundView from 'sharedComponents/pictograms/NotFoundView';

import LoginView from 'modules/auth/LoginView';
import SetPasswordView from 'modules/auth/SetPasswordView';
import ForgotPasswordView from 'modules/auth/ForgotPasswordView';

import NotificationsView from 'sharedComponents/NotificationsView';

import ProjectsViewViewer from 'modules/viewer/ProjectsViewViewer';

import ManifestBatchPrintView from 'sharedComponents/ManifestBatchPrintView';

import UserView from 'sharedComponents/UserView';
import InviteUserView from 'sharedComponents/InviteUserView';

import CompanyView from 'modules/common/companies/CompanyView';
import CompaniesTable from 'modules/common/companies/CompaniesTable';

import DriversCurrentManifestView from 'modules/transporters/CurrentManifestView';
import DriversSelectScaleLaneView from 'modules/transporters/SelectScaleLaneView';
import AccountView from 'sharedComponents/AccountView';
import DriversGoGreenLightView from 'modules/transporters/GoGreenLightView';

import ScalesAdminsSettingsView from 'modules/scales/admins/SettingsView';
import ScalesIncomingManifestsView from 'modules/scales/IncomingManifestsView';
import ScalesProcessedManifestsView from 'modules/scales/ProcessedManifestsView';
import ScalesRejectedManifestsView from 'modules/scales/RejectedManifestsView';
import ScalesLanesView from 'modules/scales/LanesView';
import ScalesQualityControlView from 'modules/scales/QualityControlView';

import ProjectsTable from 'modules/superAdmins/ProjectsTable';
import SuperAdminCompanyView from 'modules/superAdmins/CompanyView';
import SuperAdminScalesView from 'modules/superAdmins/ScalesView';
import SuperAdminScaleView from 'modules/superAdmins/ScaleView';
import SuperAdminManifestReconcilliation from 'modules/superAdmins/SuperAdminManifestReconcilliation';
import AdministrationView from 'modules/superAdmins/AdministrationView';
import SuperAdminAdministrationExportUsersView from 'modules/superAdmins/ExportUsersView';
import SuperAdminUserVerificationSearch from 'modules/superAdmins/SuperAdminUserVerificationSearch';
import SuperAdminUserVerification from 'modules/superAdmins/SuperAdminUserVerification';

import ManifestView from 'sharedComponents/ManifestView';
import ManifestOnlyView from 'sharedComponents/ManifestOnlyView';
import ManifestMapView from 'sharedComponents/ManifestMapView';

import ProjectView from 'sharedComponents/ProjectView';
import ProjectMapView from 'sharedComponents/ProjectMapView';
import ProjectReportView from 'sharedComponents/ProjectReportView';
import CompanyProjectReportView from 'sharedComponents/CompanyProjectsReportView';
import BatchManifestsListView from 'sharedComponents/BatchManifestsListView';

import ScaleMapView from 'sharedComponents/ScaleMapView';

import LicenseAgreementView from 'sharedComponents/LicenseAgreementView';

import BrokerDriverDispatchesView from 'modules/brokers/drivers/DispatchesView';
import BrokerAdminDispatchesView from 'modules/brokers/admins/DispatchesView';
import BrokerLoadView from 'modules/brokers/shared/LoadView';
import BrokerLoadEditView from 'modules/brokers/shared/LoadEditView';
import BrokerLoadsView from 'modules/brokers/shared/LoadsView';

import AppDownloadView from 'modules/auth/AppDownloadView';
import TokenAuth from 'modules/auth/TokenAuth';

import CivilDashboardView from 'modules/civilAndTrucking/civil';
import TruckingDashboardView from 'modules/civilAndTrucking/trucking';
import TruckingBrokerDashboardView from 'modules/civilAndTrucking/truckingBroker';
import EditBatchManifestsView from 'sharedComponents/EditBatchManifestsView';
import BatchManifestDetailsListView from 'sharedComponents/BatchManifestDetailsListView';
import VerifyManifestView from './modules/scales/VerifyManifestView';
import {
  generateBrokerItems,
  generateContractorItems,
  generateCustomerAdminMenuItems,
  generateGeneratorItems,
  generateGlobalRouteMenuItem,
  generateScaleItems,
  generateSuperAdminMenuItems,
  generateTransporterAdminMenuItems,
  generateTransporterDriversMenuItems,
  generateViewerUserMenuItems,
} from 'modules/auth/logicDependentOnUserRole';
import UsersList from './sharedComponents/UsersList';
import CompanyFeatureFlagAdmin from './modules/superAdmins/FeatureFlagDefinition';
import GlobalCustomInputAdmin from './modules/superAdmins/GlobalCustomInputAdmin';
import { CustomInputDefinitionGlobalEdit } from 'sharedComponents/CustomInputDefinitionGlobalEdit';
import DriverSignManifest from 'modules/scales/DriverSignManifest';

const routes = [
  {
    path: '/',
    element: <NoHeaderLayout />,
    children: [
      // TODO: Auth0: revert this
      // { path: 'auth0-callback', element: <Auth0Callback /> },
      { path: 'login', element: <LoginView /> },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'forgot-password', element: <ForgotPasswordView /> },
      { path: 'set-password', element: <SetPasswordView /> },
      { path: 'license-agreement', element: <LicenseAgreementView /> },
      { path: 'app-download', element: <AppDownloadView /> },
      { path: 'custom', element: <TokenAuth /> },
      { path: '/', element: <Navigate to="/login" /> },
    ],
  },
  // TODO: remove in future, we need this for old version of mobile app
  {
    path: '/',
    element: <BareLayout />,
    children: [
      {
        path: '/manifest-standalone/:manifestId',
        element: <ManifestOnlyView />,
      },
      // {
      //   path: '/batch/print/:selectedManifestIds',
      //   element: <ManifestBatchPrintView />,
      // },
    ],
  },
];

const withUserRoutes = [
  {
    path: 'generators',
    element: <DashboardLayout navItems={generateGeneratorItems()} />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'projects', element: <ProjectsTable /> },
      { path: 'user/:id', element: <UserView /> },
      { path: 'users', element: <UsersList /> },
      // TODO: Auth0: revert this
      // { path: 'user', element: <InviteUserView /> },
      { path: 'user', element: <UserView /> },
      { path: '*', element: <NotFoundView /> },
    ],
  },
  {
    path: 'viewer',
    element: <DashboardLayout navItems={generateViewerUserMenuItems()} />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'projects', element: <ProjectsViewViewer /> },
      // TODO: Auth0: revert this
      // { path: 'user', element: <InviteUserView /> },
      { path: 'user', element: <UserView /> },
      { path: '*', element: <NotFoundView /> },
    ],
  },
  {
    path: 'contractors',
    element: <DashboardLayout navItems={generateContractorItems()} />,
    children: [
      { path: 'companies', element: <CompaniesTable /> }, // TODO: we don't see this route in menu
      { path: 'account', element: <AccountView /> },
      { path: 'company/:id', element: <CompanyView /> }, // TODO: we don't see this route in menu
      { path: 'company', element: <CompanyView /> }, // TODO: we don't see this route in menu
      { path: 'projects', element: <ProjectsTable /> },
      { path: 'users', element: <UsersList /> },
      { path: 'user/:id', element: <UserView /> },
      // TODO: Auth0: revert this
      // { path: 'user', element: <InviteUserView /> },
      { path: 'user', element: <UserView /> },
      { path: '*', element: <NotFoundView /> },
    ],
  },
  {
    path: 'transporters',
    element: <DashboardLayout navItems={generateTransporterAdminMenuItems()} />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'company', element: <CompanyView /> },
      { path: 'projects', element: <ProjectsTable /> },
      { path: 'users', element: <UsersList /> },
      { path: 'user/:id', element: <UserView /> },
      // TODO: Auth0: revert this
      // { path: 'user', element: <InviteUserView /> },
      { path: 'user', element: <UserView /> },
      { path: '*', element: <NotFoundView /> },
    ],
  },
  {
    path: 'scales',
    element: <DashboardLayout navItems={generateScaleItems()} />,
    children: [
      { path: 'settings', element: <ScalesAdminsSettingsView /> },
      { path: 'projects', element: <ProjectsTable /> },
      { path: 'users', element: <UsersList /> },
      { path: 'user/:id', element: <UserView /> },
      // TODO: Auth0: revert this
      // { path: 'user', element: <InviteUserView /> },
      { path: 'user', element: <UserView /> },
      { path: 'account', element: <AccountView /> },
      { path: 'lanes', element: <ScalesLanesView /> },
      { path: 'incoming-manifests', element: <ScalesIncomingManifestsView /> },
      {
        path: 'processed-manifests',
        element: <ScalesProcessedManifestsView />,
      },
      {
        path: 'rejected-manifests',
        element: <ScalesRejectedManifestsView />,
      },
      { path: 'verify-manifest', element: <VerifyManifestView /> },
      { path: 'driver-sign', element: <DriverSignManifest /> },
      { path: 'quality-control', element: <ScalesQualityControlView /> },
      { path: ':id/map', element: <ScaleMapView /> },
      { path: '*', element: <NotFoundView /> },
    ],
  },
  {
    //TODO: DRIVERS IS SHARED WITH MULTIPLE USER TYPES AND WILL REWRITE THE SIDE BAR
    path: 'drivers',
    element: <DashboardLayout navItems={generateTransporterDriversMenuItems()} />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'current-manifest', element: <DriversCurrentManifestView /> },
      { path: 'select-scale-lane', element: <DriversSelectScaleLaneView /> },
      { path: 'go', element: <DriversGoGreenLightView /> },
      { path: '*', element: <NotFoundView /> },
    ],
  },
  {
    path: 'brokers',
    element: <DashboardLayout navItems={generateBrokerItems()} />,
    children: [
      { path: 'load/:id', element: <BrokerLoadView /> },
      { path: 'load', element: <BrokerLoadEditView /> },
      { path: 'account', element: <AccountView /> },
      { path: 'loads/:dispatchId', element: <BrokerLoadsView /> },
      { path: 'loads', element: <BrokerLoadsView /> },
      { path: 'driver/dispatches', element: <BrokerDriverDispatchesView /> },
      { path: 'admin/dispatches', element: <BrokerAdminDispatchesView /> },
      { path: 'users', element: <UsersList /> },
      { path: 'user/:id', element: <UserView /> },
      // TODO: Auth0: revert this
      // { path: 'user', element: <InviteUserView /> },
      { path: 'user', element: <UserView /> },
      { path: '*', element: <NotFoundView /> },
    ],
  },
  {
    path: 'super-admin',
    element: <DashboardLayout navItems={generateSuperAdminMenuItems()} />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'administration', element: <AdministrationView /> },
      {
        path: 'administration/export-users',
        element: <SuperAdminAdministrationExportUsersView />,
      },
      {
        path: 'administration/reconcilliation',
        element: <SuperAdminManifestReconcilliation />,
      },
      {
        path: 'administration/company-feature-flag',
        element: <CompanyFeatureFlagAdmin />,
      },
      {
        path: 'administration/global-custom-inputs',
        element: <GlobalCustomInputAdmin />,
      },
      {
        path: 'administration/global-custom-inputs/:id',
        element: <CustomInputDefinitionGlobalEdit />,
      },
      {
        path: 'administration/verify-search-user',
        element: <SuperAdminUserVerificationSearch />,
      },
      {
        path: 'administration/verify-user/:id',
        element: <SuperAdminUserVerification />,
      },
      { path: 'projects', element: <ProjectsTable /> },
      { path: 'project', element: <ProjectView /> },
      { path: 'users', element: <UsersList /> },
      { path: 'user/:id', element: <UserView /> },
      // TODO: Auth0: revert this
      // { path: 'user', element: <InviteUserView /> },
      { path: 'user', element: <UserView /> },

      { path: 'companies', element: <CompaniesTable /> },
      { path: 'company/:id', element: <SuperAdminCompanyView /> },
      { path: 'company', element: <SuperAdminCompanyView /> },

      { path: 'scales', element: <SuperAdminScalesView /> },
      { path: 'scale/:id', element: <SuperAdminScaleView /> },
      { path: 'scale', element: <SuperAdminScaleView /> },
      { path: '*', element: <NotFoundView /> },
    ],
  },
  {
    path: 'customer-admin',
    element: <DashboardLayout navItems={generateCustomerAdminMenuItems()} />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'projects', element: <ProjectsTable /> },
      { path: 'project', element: <ProjectView /> },
      { path: 'users', element: <UsersList /> },
      { path: 'user/:id', element: <UserView /> },
      // TODO: Auth0: revert this
      // { path: 'user', element: <InviteUserView /> },
      { path: 'user', element: <UserView /> },

      { path: 'companies', element: <CompaniesTable /> },
      { path: 'company/:id', element: <SuperAdminCompanyView /> },
      { path: 'company', element: <SuperAdminCompanyView /> },
      { path: '*', element: <NotFoundView /> },
    ],
  },
  { path: 'civil/*', element: <CivilDashboardView /> },
  {
    path: 'trucking/*',
    element: <TruckingDashboardView />,
  },
  {
    path: 'trucking-broker/*',
    element: <TruckingBrokerDashboardView />,
  },
  {
    path: '/',
    element: <DashboardLayout navItems={[]} globalNavItems={generateGlobalRouteMenuItem} />,
    children: [
      { path: 'batched-manifests', element: <BatchManifestsListView /> },
      { path: 'batched-manifests/:id', element: <EditBatchManifestsView /> },
      { path: 'batched-manifests-details/:id', element: <BatchManifestDetailsListView /> },
      { path: 'manifests/:id', element: <ManifestView /> },
      { path: 'manifests/:id/map', element: <ManifestMapView /> },
      { path: 'projects/:id', element: <ProjectView /> },
      { path: 'projects/:id/map', element: <ProjectMapView /> },
      { path: 'projects/:id/reports', element: <ProjectReportView /> },
      { path: 'company/reports', element: <CompanyProjectReportView /> },
      { path: '/notifications', element: <NotificationsView /> },
      { path: '*', element: <NotFoundView /> },
    ],
  },
  ...routes,
];

export { routes, withUserRoutes };
