import axios from 'axios';
import moment from 'moment';
import { auth } from 'services/firebase';
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const refreshManifestsProjectData = async (projectId: string, startDate: Date, endDate: Date) => {
  const token = await auth.currentUser!.getIdToken(true);
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);

  const response = await axios.get(
    `/patches/manifests/refresh-manifests-project-data/${projectId}/between/${startDateMoment.format(
      'YYYY-MM-DD'
    )}/and/${endDateMoment.format('YYYY-MM-DD')}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const refreshProjectScheduledTasks = async (projectId: string, startDate: Date, endDate: Date) => {
  const token = await auth.currentUser!.getIdToken(true);
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);
  const response = await axios.get(
    `/projects-scheduled-tasks/execute/${projectId}/between/${startDateMoment.format(
      'YYYY-MM-DD'
    )}/and/${endDateMoment.format('YYYY-MM-DD')}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const setCompanyBrokerLoadsTheSelectedTemplate = async (
  companyId: string,
  templateId: string,
  startDate: Date,
  endDate: Date
) => {
  const token = await auth.currentUser!.getIdToken(true);
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);

  const response = await axios.get(
    `/patches/broker-loads/set-company-broker-loads-the-selected-template/${companyId}/${templateId}/between/${startDateMoment.format(
      'YYYY-MM-DD'
    )}/and/${endDateMoment.format('YYYY-MM-DD')}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export {
  refreshManifestsProjectData,
  refreshProjectScheduledTasks,
  setCompanyBrokerLoadsTheSelectedTemplate,
};
