import React from 'react';
import { Container } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { IJoinCivilCompanyWithData } from 'lg-helpers/dist/civil/interfaces/IJoinCivilLocation';

export const ReportsView = () => {
  const activeCompany: IJoinCivilCompanyWithData | undefined = useSelector(
    (state: RootState) => state.civil.companies.activeCompany
  );

  return (
    <Container
      sx={{
        background: '#E0E0E0',
        display: 'flex',
        justifyContent: 'center',
        width: '90vw',
        margin: '24px -16px',
        maxWidth: '90vw',
      }}
    >
      <iframe
        className="lg-iframe"
        title="Reports"
        width={1400}
        height={800}
        style={{ border: 'none' }}
        src={
          activeCompany?.reportUrl
            ? activeCompany?.reportUrl
            : `//datastudio.google.com/embed/u/0/reporting/840353e8-49b4-4cb9-8737-28bb64472ffe/page/5H3TC?params=%7B%22companyId%22:%22${activeCompany?.id}%22%7D`
        }
        allowFullScreen
      />
    </Container>
  );
};

export default ReportsView;
