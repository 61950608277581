import {
  ApplicationTokensApi,
  AuthApi,
  BackupApi,
  BigQueryApi,
  EmailApi,
  PatchesApi,
  ProjectsScheduledTasksApi,
  PubSubApi,
  RedisApi,
  SMSApi,
  UsersApi,
  CivilCompaniesApi,
  CivilDownloadsApi,
  CivilInvoicesApi,
  ManifestProjectsCivilApi,
  CivilProjectsApi,
  CivilRequestsApi,
  CivilSupplierPitTicketsApi,
  CivilTicketsApi,
  FeatureFlagsApi,
  TemplateApi,
  CompanyFeatureFlagsApi,
  CustomInputsApi,
  DownloadsApi,
  CompaniesConnectionsApi,
  CompaniesApi,
  InvitedUsersApi,
  WorkCodesApi,
  UnmatchedIncomingTicketsApi,
} from './autogenerated-code';
import { registerController } from './sdk-helpers';
export * from './autogenerated-code';

const registeredApiControllers = {
  auth: registerController(AuthApi),
  applicationTokens: registerController(ApplicationTokensApi),
  backup: registerController(BackupApi),
  bigQuery: registerController(BigQueryApi),
  email: registerController(EmailApi),
  patches: registerController(PatchesApi),
  projectsScheduledTasks: registerController(ProjectsScheduledTasksApi),
  pubSub: registerController(PubSubApi),
  redis: registerController(RedisApi),
  sms: registerController(SMSApi),
  users: registerController(UsersApi),
  invitedUsers: registerController(InvitedUsersApi),
  template: registerController(TemplateApi),
  companiesConnections: registerController(CompaniesConnectionsApi, {
    getConnectedCompaniesByTypes: {
      invalidatedBy: [
        'createCompanyConnection',
        'createNewConnectedCompany',
        'deleteCompanyConnection',
        'updateCompanyConnection',
      ],
    },
  }),

  downloads: registerController(DownloadsApi),
  featureFlags: registerController(FeatureFlagsApi),
  companies: registerController(CompaniesApi),
  companyFeatureFlags: registerController(CompanyFeatureFlagsApi),
  customInputs: registerController(CustomInputsApi),

  civilCompanies: registerController(CivilCompaniesApi),
  civilDownloads: registerController(CivilDownloadsApi),
  civilInvoices: registerController(CivilInvoicesApi),
  civilManifestProjects: registerController(ManifestProjectsCivilApi),
  civilProjects: registerController(CivilProjectsApi),
  civilRequests: registerController(CivilRequestsApi),
  civilSupplierPitTickets: registerController(CivilSupplierPitTicketsApi),
  civilTickets: registerController(CivilTicketsApi),
  workCode: registerController(WorkCodesApi),
  unmatchedIncomingTicketsFromApi: registerController(UnmatchedIncomingTicketsApi),
} as const;

export type ApiModule = keyof typeof registeredApiControllers;

export default registeredApiControllers;
