import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { usePermissionsContext } from 'services/permissions';
import api from 'services/api/autogenerated';
import CompaniesApi from 'services/postgres/CompaniesApi';
import {
  browserName,
  fullBrowserVersion,
  deviceType,
  isIPad13,
  osName,
  mobileVendor,
  mobileModel,
  isWindows,
  isMacOs,
  isMobileOnly,
  isTablet,
} from 'react-device-detect';

const companiesApi = new CompaniesApi();

export default () => {
  const { boot, update, shutdown } = useIntercom();
  const { user } = usePermissionsContext();

  useEffect(() => {
    // Convert list of companies to usable intercom digestible array
    const fetchCompanyData = async () => {
      let parsedCompanies = await Promise.all(
        user.companies.map(async (company: any) => {
          const companyData = await companiesApi.getById(company.id);
          return {
            companyId: company.id,
            name: company.name,
            phone: companyData.phone,
            email: companyData.email,
            types: companyData.types,
            address: companyData.address,
            city: companyData.city,
            state: companyData.state,
            zip: companyData.zip,
          };
        })
      );

      if (user)
        api.users
          .generateIntercomUserHash()
          .then(({ data: { hash } }) => {
            boot({
              email: user.email,
              name: `${user.firstName} ${user.lastName}`,
              userId: user.id,
              userHash: hash,
              companies: parsedCompanies,
              customAttributes: {
                user_type: user.role,
                os_name: osName,
                is_mobile_only: isMobileOnly,
                is_tablet: isTablet,
                is_ipad_13: isIPad13,
                is_windows: isWindows,
                is_mac_os: isMacOs,
                mobile_model: mobileModel,
                mobile_vendor: mobileVendor,
                device_type: deviceType,
                browser_name: browserName,
                full_browser_version: fullBrowserVersion,
              },
            });
          })
          .then(() => {
            update();
          })
          .catch(err => {
            console.error('Intercom error:', err);
          });
    };

    fetchCompanyData();

    return () => shutdown();
  }, [user, boot, update, shutdown]);

  return null;
};
