import React, { useEffect, useState } from 'react';
import Executer from 'services/firebase/Executer';
import Query from 'services/firebase/Query';
import { Grid, Button, Typography, TextField, IconButton } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import api, { WorkCodes } from '../../../services/api/autogenerated';
import moment from 'moment';
import Delete from '@mui/icons-material/Delete';
import DeleteConfirmationModal from './DeleteWorkCodeModal';
import WorkCodeCustomInputModal from './WorkCodeCustomInputsModal';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';
import { Edit, Save } from '@mui/icons-material';

interface WorkCodeCancelProps {
  projectId: string;
  workCode: WorkCodes;
  onWorkCodeUpdate: () => void;
}

const executer = new Executer();
const query = new Query();

export function workCodeFormattedDate(date: string) {
  return moment(date).format('MM/DD/YYYY hh:mm A');
}

const CancelWorkCode: React.FC<WorkCodeCancelProps> = ({
  projectId,
  workCode,
  onWorkCodeUpdate,
}) => {
  const [projectCustomInputDefinitions, setProjectCustomInputDefinitions] = useState<any[]>();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [editingNickname, setEditingNickname] = useState(false);
  const [nickname, setNickname] = useState(workCode.nickname);

  const handleEditClick = () => {
    setEditingNickname(true);
  };

  const handleSaveClick = async () => {
    // Stub for the API request
    await api.workCode.updateWorkCodeNickname(workCode.work_code_id, { nickname });
    setEditingNickname(false);
  };

  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const [openCustomInputsModal, setOpenCustomInputsModal] = useState(false);
  const handleOpenCustomInputsModal = () => setOpenCustomInputsModal(true);
  const handleCloseCustomInputsModal = () => setOpenCustomInputsModal(false);

  useEffect(() => {
    const load = async () => {
      // Check firebase for project.customInputDefinitions
      const project: any = await executer.getSingleDocument(
        query.base.getById(QueryBase.PROJECTS_COLLECTION(), projectId!)
      );

      setProjectCustomInputDefinitions(project.customInputsDefinitions || []);
    };

    load();
  }, [projectId]);

  return (
    <>
      <Grid container>
        <Grid
          item
          sm={6}
          style={{
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Typography variant="h6">Drivers can login using:</Typography>
        </Grid>
        <Grid item sm={5}>
          {projectCustomInputDefinitions && projectCustomInputDefinitions?.length > 0 && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleOpenCustomInputsModal()}
            >
              Custom Defaults
            </Button>
          )}
        </Grid>
        <Grid item sm={1}>
          <Button color="error" variant="contained" onClick={() => handleOpenDeleteModal()}>
            <Delete />
          </Button>
        </Grid>
      </Grid>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid style={{ padding: 10 }} container spacing={4}>
          <Grid item sm={3}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="body1">Nickname:</Typography>
              </Grid>
              <Grid item>
                {editingNickname ? (
                  <>
                    <TextField
                      value={nickname}
                      onChange={e => setNickname(e.target.value)}
                      size="small"
                    />
                    <IconButton onClick={handleSaveClick} size="small">
                      <Save />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Typography component="span" sx={{ fontSize: '24px' }}>
                      {nickname}
                    </Typography>
                    <IconButton onClick={handleEditClick} size="small">
                      <Edit />
                    </IconButton>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={3}>
            <Typography variant="body1">Work Code:</Typography>
            <Typography component="span" sx={{ letterSpacing: '8px', fontSize: '24px' }}>
              {workCode.work_code_id}
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="body1">Expiration Date:</Typography>
            <Typography component="span" sx={{ fontSize: '24px' }}>
              {workCodeFormattedDate(workCode.expiration_date)}
            </Typography>
          </Grid>
        </Grid>
      </LocalizationProvider>

      {projectCustomInputDefinitions && projectCustomInputDefinitions?.length > 0 && (
        <WorkCodeCustomInputModal
          workCodeId={workCode.work_code_id}
          customInputsDefinitions={projectCustomInputDefinitions}
          open={openCustomInputsModal}
          onClose={handleCloseCustomInputsModal}
          onWorkCodeUpdate={() => {
            console.log('upsert custom input defaults for work code');
          }}
        />
      )}

      <DeleteConfirmationModal
        workCodeId={workCode.work_code_id}
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        onWorkCodeUpdate={onWorkCodeUpdate}
      />
    </>
  );
};

export default CancelWorkCode;
